import React, { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useWatch } from 'react-hook-form';
import { ModuleContext, workerPreviewInfo } from '../util';
import Option1 from './Option1';
// import Option2 from './Option2';
// import Option3 from './Option3';
import { groupBy, upperCase } from 'lodash';
import SubTitle from '../../../@components/layout/Subtitle';
import Photo from '../../Branch/components/Photo';

// const viewType = [Option1, Option2, Option3]
function Preview() {
  const { t } = useTranslation()
  const { control } = useContext(ModuleContext);
  const footer_description = useWatch({ control, name: `footer_description` })
  const signatures = useWatch({ control, name: `signatures` })
  // const template_version = useWatch({ control, name: `template_version` })

  const workerInfo = groupBy(workerPreviewInfo, 'column');

  return (
    <div className="container-fluid" id="preview">
      <section className="">
        <div className="page preview-wrap">
          <div className='preview-header'>
            <div className='preview-header-title'>
              <span className='title'>{upperCase(t("settlement"))}</span>
              <span className='subtitle'>{`<<Nombre de la empresa>>`}</span>
            </div>
            <Photo photo={null} size={100} showButton={false} />
          </div>
          <SubTitle
            asDivider
            title={`<<${t("name-worker")}>>`}
            className='worker-subtitle'
          />
          <div className='preview-worker-body'>
            {Object.entries(workerInfo)?.map((element, i) => {
              const fields = element[1];
              return (
                <div className='preview-worker-body-column' key={i}>
                  {fields.map((element, j) => {
                    return (
                      <div className='column-item' key={j}>
                        <div className='item-wrap-text'>
                          <span> {element?.fieldName}:</span>
                          <span className='blue-text'> {element?.format ?? "XXXXX"}</span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
          <Option1 />
          <div className="text-justify">
            <p>
              RECIBI DE OPERADORA HOTELERA SECRETS EDM, la cantidad de: 33,379.59 ( treinta y tres mil trescientos setenta y nueve pesos y cincuenta y nueve centavos M.N. )
            </p>
            <div>
              {footer_description}
            </div>
            <p>
              QUINTANA ROO A 14/03/2024 21:56
            </p>
          </div>

          <div className="signatures">
            <table className="table table-borderless">
              <tbody>
                <tr>
                  {signatures?.map((m, i) => (
                    m.is_hidden ? null :
                      <th className="text-center py-0" key={i}>
                        __________________________
                      </th>
                  ))}
                </tr>
                <tr>

                  {signatures?.map((m, i) => (
                    m.is_hidden ? null : <th key={i} className="text-center pt-0">
                      {m.signature.map((m2, i) => (
                        <div key={i}>{m2.name}</div>
                      ))}
                    </th>
                  ))}

                </tr>
              </tbody>
            </table>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <th colSpan="3" className="text-center">
                    __________________________
                  </th>
                </tr>
                <tr>
                  <th colSpan="3" className="text-center">
                    Firma del trabajador
                  </th>
                </tr>
              </tbody>
            </table>
          </div>

        </div>


      </section>

    </div>
  )
}

export default Preview