import { useState, useContext, useEffect } from "react";
import { useWatch, useForm } from "react-hook-form";
import * as yup from "yup";
import es from 'date-fns/locale/es';
import { format } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { dateValidation } from "../../../common/validations/dateValidation";
import { Context } from "../contexts/Context";
import { successCreated } from "../../../common/notification-messages";
import { lockedWindow, unlockedWindow } from "../../../../store/actions";
import { implementService } from "../../../services/implemet-service";
import { mopersClockWorker, mopersComments } from "../../../services/mopers";
import { resolveError } from "../../../common/resolve-error";
import { workgroupsApi } from "../../../services/administrator";
import { stringValidation } from "../../../common/validations/stringValidation";
import { showSuccessNotification } from "../../../../App/components/Notifications";

export const initValues = {
  clock_in: null,
  day_in: null,
  date_in: null,
  check_in_time: new Date(),
  clock_out: null,
  day_out: null,
  date_out: null,
  origin_day: new Date(),
  check_out_time: null,
  request_comment: null,
  hours: null
};

function useCheckForm(handleRefresh) {

  const { worker } = useContext(Context);
  const dispatch = useDispatch();
  const tenant = useSelector((state) => state.tenant);
  const [verifiedMoper, setVerifiedMoper] = useState(true);

  const [openCheckForm, setOpenCheckForm] = useState(false);
  const [comments, setComments] = useState([]);
  const [selected, setSelected] = useState({});

  const validationSchema = yup.object().shape({
    origin_day: dateValidation(true),
    request_comment: stringValidation({required: true, max: 250}),
    check_in_time: yup.date().nullable(true).when('check_out_time', {
      is: (value) => !value,
      then: () => dateValidation(true),
    }),
    check_out_time: yup.date().nullable(true).when('check_in_time', {
      is: (value) => !value,
      then: () => dateValidation(true),
    }),
  }, ['check_in_time', 'check_out_time']);

  const {
    reset,
    control,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors },
    clearErrors
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initValues,
    mode: "onChange",
  });

  const checkInTime = useWatch({
    control,
    name: "check_in_time",
  });

  const checkOutTime = useWatch({
    control,
    name: "check_out_time",
  });

  const verifyMoper = async () => {
    try {
      const response = await workgroupsApi.getOne(worker.workgroup.id, { include: "mopers.*" })
      const isVerified = response.mopers.find(f => f.moper_catalog.key === 'ajuste_asist')?.is_active;
      setVerifiedMoper(isVerified);
    } catch (error) {
      resolveError(error)
    }
  }

  const handleOpenCheckForm = async () => {
    setOpenCheckForm(true);
    getCommentList();
    setSelected({});
    reset(initValues);
  };

  const getCommentList = async () => {
    dispatch(lockedWindow());

    try {

      let response = await mopersComments.get({
        tenant: tenant.current?.id,
        tree: ""
      });

      if (response.length > 0) {
        setComments(response.map(item => item.comment))
      }
    } catch (error) {
      resolveError(error);
    } finally {
      dispatch(unlockedWindow());
    }
  };

  const handleEditCheckForm = (item) => {
    setOpenCheckForm(true);

    let timeIn = null;
    let timeOut = null;
    let originDay = null;
    let dayIn = null;
    let dayOut = null;

    if (item.check_in) {
      timeIn = new Date(item.check_in.substr(0, 10).split('-').join('/'));
      timeIn.setHours(item.check_in.substr(11, 2));
      timeIn.setMinutes(item.check_in.substr(14, 2));
      dayIn = format(new Date(item.check_in.slice(0, -1)), "EEEEEE", { locale: es });
    }

    if (item.check_out) {
      timeOut = new Date(item.check_out.substr(0, 10).split('-').join('/'));
      timeOut.setHours(item.check_out.substr(11, 2));
      timeOut.setMinutes(item.check_out.substr(14, 2));
      dayOut = format(new Date(item.check_out.slice(0, -1)), "EEEEEE", { locale: es });
    }

    if (item?.origin_day) {
      originDay = new Date(item.origin_day.split('-').join('/'));
    }

    let newItem = {
      ...item,
      day_in: dayIn ? dayIn.charAt(0).toUpperCase() + dayIn.slice(1) : null,
      day_out: dayOut ? dayOut.charAt(0).toUpperCase() + dayOut.slice(1) : null,
      origin_day: originDay,
      check_in_time: timeIn,
      check_out_time: timeOut,
      request_comment: item.comment
    }

    setSelected(newItem);
    getCommentList();
  };

  const handleCloseCheckForm = () => {
    setOpenCheckForm(false);
    setSelected({});
    clearErrors();
  };

  function getHoursMinutes(minutes) {
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    return `${(hours < 10) ? '0' + hours.toString() : hours.toString()}:${(minutes < 10) ? '0' + minutes.toString() : minutes.toString()}`;
  }

  function getMinutesBetweenDates(startDate, endDate) {

    const startTime = startDate.getTime();
    const endTime = endDate.getTime();
    const timeDiff = endTime > startTime ? endTime - startTime : startTime - endTime;
    const minutesDiff = Math.round((timeDiff) / 60000);
    return minutesDiff;
  }

  function getDay(field, date) {
    let capitalizeDay = date;
    if (date) {
      let day = format(date, "EEEEEE", { locale: es });
      capitalizeDay = day.charAt(0).toUpperCase() + day.slice(1)
    }
    setValue(field, capitalizeDay);
  }

  useEffect(() => {
    verifyMoper();
  }, [worker]);

  useEffect(() => {
    if (checkInTime && checkOutTime) {
      let minutes = getMinutesBetweenDates(checkInTime, checkOutTime);
      setValue("hours", getHoursMinutes(minutes), { shouldValidate: true });
    } else {
      setValue("hours", "");
    }

    // eslint-disable-next-line
  }, [checkInTime, checkOutTime]);

  const dateTimeServer = (dateCheck) => {
    if (!dateCheck) return null;
    const date = format(dateCheck, 'yyyy-MM-dd');
    let hour = ("0" + dateCheck.getHours()).slice(-2);
    let minute = ("0" + dateCheck.getMinutes()).slice(-2);

    return `${date}T${hour}:${minute}:00Z`
  };

  function handleSaveCheck(item) {

    const newItem = {
      moper: {
        moper_catalog: 4,
        worker: worker.id
      },
      origin_day: format(item.origin_day, 'yyyy-MM-dd'),
      check_in: dateTimeServer(item?.check_in_time),
      check_out: dateTimeServer(item?.check_out_time),
      request_comment: item.request_comment === null || item.request_comment === "" ? null : item.request_comment.toUpperCase(),
      type_requisition: selected.id ? 1 : 0
    };

    postData(selected.id ? { ...newItem, clock_worker: selected.id } : newItem);
  }

  // @post
  function postData(request) {
    implementService(mopersClockWorker.post(request), () => {
      handleCloseCheckForm();
      handleRefresh();
      showSuccessNotification(successCreated());
    });
  }

  return {
    reset,
    trigger,
    errors,
    clearErrors,
    control,
    setValue,
    handleSubmit,
    openCheckForm,
    checkInTime,
    checkOutTime,
    selected,
    handleEditCheckForm,
    handleOpenCheckForm,
    handleCloseCheckForm,
    handleSaveCheck,
    getDay,
    comments,
    verifiedMoper
  };
}

export default useCheckForm;
