import * as yup from "yup";
import { forEach, size } from "lodash";
import i18next from "i18next";
const { t } = i18next;
import { current_month, current_year } from "../../../../App/components/Dates/utilities";

// General initial

export const initialPage = { skip: 0, take: 6 };

export const initValues = {
  year: current_year,
  indicate_days: 'ALL',
  active_date: false,
  sort_by: "KEY",
  sort_dir: "UP",
  attendance_mode: 'compact',
  ordering: 'key',
  view_switch_period_filters: true,
  view_switch_access_filters: true,
};

//Advanced filters

export const daysType = () => {
  return [
    { key: 1, id: 1, value: 'ALL', label: t('Todos') },
    // { value: 'late', label: t('Retardos / Puntual') },
    { key: 2, id: 2, value: 'ADJUST', label: t('Ajuste manual de asistencia') },
    { key: 3, id: 3, value: 'OVER', label: t('Hrs +/- a la Jornada') },
    // { value: 'incidence', label: t('Incidencia de horarios') }
  ];
};

export const timeOptions = () => {
  return [
    { key: 1, value: 1, label: t('Tiempo por tiempo') },
    { key: 2, value: 6, label: t('Ausentismos con goce') },
    { key: 3, value: 5, label: t('Ausentismos sin goce') },
    { key: 4, value: 4, label: t('Vacaciones') },
    { key: 5, value: 7, label: t('Descansos') },
    { key: 6, value: 3, label: t('Ajustes') },
  ];
};

export const excludeInputs = [
  'workgroup',
  'year',
  'month',
  'payroll_type',
  'period',
  'active_date',
  'count',
  'sort_by',
  'sort_dir',
  'ordering',
  'indicate_days',
  'administrative_court',
  'open_period_date',
  'initial_date',
  'end_date',
  'attendance_mode',
  'ordering',
  'employer_registration',
  'branch',
];

export const counterInputs = [
  'absenteeism',
  'filter_type',
  'job_position',
  'department',
  'area',
];

// Cell order

export const orderOptions = [
  { id: 0, value: "KEY", ordering: "key", label: 'Clave del colaborador' },
  { id: 3, value: "NAME", ordering: "name", label: 'Nombre del colaborador' },
  { id: 0, value: "JOB_POSITION,KEY", ordering: "job_position,key", label: 'Puesto, clave del colaborador' },
];

export const orderType = [
  { value: 'UP', label: t('sort-ascending') },
  { value: 'DOWN', label: t('sort-descending') }
];

// Modals

export const AbsenOptions = () => {
  return [
    { key: 2, value: 'ausen_con_goce', name: t('permission-c') },
    { key: 3, value: 'ausen_sin_goce', name: t('permission-g') }
  ]
}

export const calendarChecksInitValues = {
  month: current_month,
  year: current_year,
  worker_loading: true,
  calendar_loading: true,
};

export const printValidationSchema = yup.object().shape({
  print_type: yup.object(),
  list_content: yup.object(),
  worker_signature: yup.object(),
  worker_type: yup.object(),
  dates: yup.object(),
  structure: yup.object(),
  separate_by: yup.object(),
  order: yup.object(),
});

export const printInitValues = ({
  catalog,
  workerTypes,
  filter,
}) => {
  let wTypes = {};
  if (size(workerTypes)) {
    forEach(workerTypes, function (type) {
      wTypes[type.name] = false;
    });
  }
  const initDate = filter?.initial_date ?? '0000-00-00';
  const endDate = filter?.end_date ?? '0000-00-00';
  const splitInit = initDate.split('-');
  const splitEnd = endDate.split('-');

  return {
    print_type: {
      complete_list: false,
      visualized_list: false,
      personalized_list: true,
    },
    list_content: {
      with_data: true,
    },
    worker_signature: {
      print_signature: false,
    },
    worker_type: {
      all: true,
      ...wTypes,
    },
    dates: {
      initial_date: new Date(
        splitInit[0],
        splitInit[1] - 1,
        splitInit[2],
      ),
      final_date: new Date(
        splitEnd[0],
        splitEnd[1] - 1,
        splitEnd[2],
      ),
    },
    structure: {
      branch: null,
      employer_registration: null,
      [catalog]: null,
    },
    separate_by: {
      employer_registration: false,
      branch: false,
      [catalog]: false,
    },
    order: {
      employer_registration: false,
      employer_registration_branch: false,
      [`employer_registration_branch_${catalog}`]: false,
    },
  };
}

// Others

export const paymentTypes = [
  { value: 1, name: 'PD', desc: 'Prima Dominical' },
  { value: 2, name: 'FT', desc: 'Festivo Trabajado' },
  { value: 3, name: 'DT', desc: 'Descanso Trabajado' },
  { value: 4, name: 'TD', desc: 'Turno Doble' },
  { value: 5, name: 'Dinero', icon: "money", desc: 'Dinero' },
  { value: 6, name: 'TXT', desc: 'Tiempo por tiempo', icon: "txt" },
];

export const status = ['preprocess', 'process', 'authorized', 'canceled', 'to-request', 'multi'];

export const attendanceValues = {
  'f': { key: 'f', value: "apply_absence_individual", name: "Faltas" },
  'd': { value: "apply_rest_individual", name: "Descansos" },
  'df': { value: "apply_rest_individual", name: "Descansos" }
};

// Footer

export const symbols = [
  { description: "Existe un ajuste de asistencia en el día", corner_value: "adjust" },
  { description: "Las horas laboradas fueron mayores a la jornada", corner_value: "over" },
  { description: "Las horas laboradas fueron menor a la jornada", corner_value: "less" },
  { description: "Retardo", corner_value: "late" },
  { description: "Incidencia de horarios", corner_value: "incidence" },
  { label: "F", description: "Falta", color: " var(--scarletRed, #fe1f1f) !important" },
  { label: "FR", description: "Falta de registro (No cuenta como falta)", color: " var(--scarletRed, #fe1f1f) !important" },
  { label: ".", description: "Checada completa (entrada y salida)", color: "var(--egyptianBlue) !important" },
  { label: "/", description: "Checada incompleta (falta una entrada o salida)", color: "var(--egyptianBlue) !important" },
  { label: "?", description: "Checada dentro de un día en el que hay ausentismos", color: "var(--egyptianBlue) !important" },
  { icon: "apply-breaks", className: "footbar-icon apply-breaks", description: "Día de descanso" },
  { icon: "apply-holidays", className: "footbar-icon apply-holidays", description: "Día festivo" },
  { icon: "vacation", className: "footbar-icon vacations", description: "Vacaciones" },
  { icon: "con_goce", className: "footbar-icon con-goce", description: "Permiso con goce" },
  { icon: "sin_goce", className: "footbar-icon sin-goce", description: "Permiso sin goce" },
  { icon: "imss", className: "footbar-icon imss", description: "Incapacidad" }
];