import React, { lazy } from "react";

const FederalEntitiesTaxes = lazy(() => import('../../../../modules/FederalEntitiesTaxes')); //Revisar duplicidad en Abrhil y Modulos
const Attendance = lazy(() => import("../../../../modules/Attendance"));
const MopersByWorker = lazy(() => import("../../../../modules/MOPERSByWorker"));
const MopersSignature = lazy(() => import('../../../../modules/MOPERSSignature'));
const GraphicSignatures = lazy(() => import('../../../../modules/GraphicSignatures'));
const SignaturesByWorker = lazy(() => import('../../../../modules/SignaturesByWorker'));
const Workgroup = lazy(() => import('../../../../modules/Workgroup'));
const Users = lazy(() => import('../../../../modules/User'));
// const Inability = lazy(() => import("../../../../modules/Inability"));
const Contracts = lazy(() => import("../../../../modules/Worker/Contracts"));
const Kardex = lazy(() => import('../../../../modules/Worker/Kardex'));
const AddWorker = lazy(() => import('../../../../modules/Worker/Add'));
const JobChanges = lazy(() => import('../../../../modules/JobChanges'));
const Requirements = lazy(() => import('../../../../modules/Requirements'));
const ExtraData = lazy(() => import('../../../../modules/ExtraData'));
const ReasonLeaving = lazy(() => import('../../../../modules/ReasonLeaving'));
const SalaryAdjustment = lazy(() => import('../../../../modules/SalaryAdjustment'));
const Validity = lazy(() => import('../../../../modules/Validity'));
const Branch = lazy(() => import('../../../../modules/Branch'));
const SubBranch = lazy(() => import('../../../../modules/SubBranch'));
const Area = lazy(() => import('../../../../modules/Area'));
const SubArea = lazy(() => import('../../../../modules/SubArea'));
const Depto = lazy(() => import('../../../../modules/Depto'));
const SubDepto = lazy(() => import('../../../../modules/SubDepto'));
const GroupingPosition = lazy(() => import('../../../../modules/GroupingPosition'));
const HierarchicalLevel = lazy(() => import('../../../../modules/HierarchicalLevel'));
const IdentifierPosition = lazy(() => import('../../../../modules/IdentifierPosition'));
const JobPosition = lazy(() => import('../../../../modules/JobPosition'));
const Management = lazy(() => import('../../../../modules/Management'));
const IntegratingFactorType = lazy(() => import('../../../../modules/IntegratingFactorType'));
const IntegrationFactorHistory = lazy(() => import('../../../../modules/IntegrationFactorHistory'));
const PatronalRegistration = lazy(() => import('../../../../modules/PatronalRegistration'));
const StructureEnterprise = lazy(() => import('../../../../modules/StructureEnterprise'));
const PatronalRegistrationSettings = lazy(() => import('../../../../modules/PatronalRegistrationSettings'));
const DegreeDocuments = lazy(() => import('../../../../modules/DegreeDocuments'));
const Education = lazy(() => import('../../../../modules/Education'));
const Gender = lazy(() => import('../../../../modules/Gender'));
const CivilStatus = lazy(() => import('../../../../modules/CivilStatus'));
const SUAtransfer = lazy(() => import('../../../../modules/SUAtransfer'));
const EmaEba = lazy(() => import('../../../../modules/EMA-EBA'));
const AffiliateReport = lazy(() => import('../../../../modules/AffiliateReport'));
const Liquidation = lazy(() => import('../../../../modules/Liquidation'));
const IDSEConfirmation = lazy(() => import('../../../../modules/IDSEConfirmation'));
const IDSEAudit = lazy(() => import('../../../../modules/IDSEAudit'));
const WorkerMovementsReport = lazy(() => import('../../../../modules/WorkerMovementsReport'));
const MSettlementComparasion = lazy(() => import('../../../../modules/MSettlementComparasion'));
const BSettlementComparasion = lazy(() => import('../../../../modules/BSettlementComparasion'));
const PayrollConcepts = lazy(() => import('../../../../modules/PayrollConcepts'));
const PayrollConceptsAccounts = lazy(() => import('../../../../modules/PayrollConceptsAccounts'));
const PayrollCalculate = lazy(() => import('../../../../modules/PayrollCalculate'));
const PayrollDispersion = lazy(() => import('../../../../modules/PayrollDispersion'));
const VoucherDispersion = lazy(() => import('../../../../modules/VoucherDispersion'));
const RequestVoucherAccounts = lazy(() => import('../../../../modules/RequestVoucherAccounts'));
const ImportVoucherAccounts = lazy(() => import('../../../../modules/ImportVoucherAccounts'));
const Alimony = lazy(() => import('../../../../modules/Alimony'));
const ScheduledConcepts = lazy(() => import('../../../../modules/ScheduledConcepts'));
const PayrollPeriods = lazy(() => import('../../../../modules/PayrollPeriods'));
const LoansAndPaids = lazy(() => import('../../../../modules/LoansAndPaids'));
const BimonthlyVariables = lazy(() => import('../../../../modules/BimonthlyVariables'));
const SATValidation = lazy(() => import('../../../../modules/TimbrasoftApp/SATValidation'));
const WorkRiskHistory = lazy(() => import('../../../../modules/WorkRiskHistory'));
const HistoryCyV = lazy(() => import('../../../../modules/AbrhilApp/HistoryCyV'));
const ConceptAbrSAT = lazy(() => import('../../../../modules/TimbrasoftApp/ConceptAbrSAT'));
const Rfc = lazy(() => import('../../../../modules/TimbrasoftApp/RFC'));
const Taxes = lazy(() => import('../../../../modules/Taxes'));
const Journeys = lazy(() => import('../../../../modules/Journeys'));
const Schedules = lazy(() => import('../../../../modules/Schedules'));
const PredefinedComments = lazy(() => import('../../../../modules/PredefinedComments'));
const Timbrasoft = lazy(() => import('../../../../modules/TimbrasoftApp/Timbrasoft'));
const SettingsRH = lazy(() => import('../../../../modules/SettingsRH'));
const SettingsIMSS = lazy(() => import('../../../../modules//SettingsIMSS'));
const SettingsPayroll = lazy(() => import('../../../../modules/SettingsPayroll'));
const SettingsTraining = lazy(() => import('../../../../modules/SettingsTraining'));
const SettingsAttendance = lazy(() => import('../../../../modules/SettingsAttendance'));
const ContractAbrSAT = lazy(() => import('../../../../modules/TimbrasoftApp/ContractAbrSAT'));
const PeriodicityAbrSAT = lazy(() => import('../../../../modules/TimbrasoftApp/PeriodicityAbrSAT'));
const ProfessionalMinimum = lazy(() => import('../../../../modules/ProfessionalMinimum'));
const ConfigTimbrasoft = lazy(() => import('../../../../modules/TimbrasoftApp/ConfigTimbrasoft'));
const StampNotification = lazy(() => import('../../../../modules/TimbrasoftApp/StampNotification'));
const ConfigStampReceipt = lazy(() => import('../../../../modules/ConfigStampReceipt'));
const Stampconcept = lazy(() => import('../../../../modules/TimbrasoftApp/StampConcept/StampConceptContainer'));
const CatAlimony = lazy(() => import('../../../../modules/CatAlimony'));
const PostalCode = lazy(() => import('../../../../modules/PostalCode'));
const UMA = lazy(() => import('../../../../modules/UMA'));
const UMI = lazy(() => import('../../../../modules/UMI'));
const DailyMinimum = lazy(() => import('../../../../modules/AbrhilApp/DailyMinimum'));
const Professions = lazy(() => import('../../../../modules/Professions'));
const WorkerType = lazy(() => import('../../../../modules/WorkerType'));
const JobPositionType = lazy(() => import('../../../../modules/AbrhilApp/JobPositionType'));
const AbsenteeismReasons = lazy(() => import('../../../../modules/AbrhilApp/AbsenteeismReasons'));
const QuotaIMSS = lazy(() => import('../../../../modules/QuotaIMSS'));
const ImssReasonLeaving = lazy(() => import('../../../../modules/ImssReasonLeaving'));
const Courses = lazy(() => import('../../../../modules/Courses'));
const CoursesByWorker = lazy(() => import('../../../../modules/CoursesByWorker'));
const ThematicAreas = lazy(() => import('../../../../modules/ThematicAreas'));
const ProductiveArea = lazy(() => import('../../../../modules/ProductiveArea'));
const OccupationsWorkers = lazy(() => import('../../../../modules/OccupationsWorkers'));
const AgentsType = lazy(() => import('../../../../modules/AgentsType'));
const JobMapping = lazy(() => import('../../../../modules/JobMapping'));
const CoursesClassifications = lazy(() => import('../../../../modules/CoursesClassifications'))
const TrainingPlaces = lazy(() => import('../../../../modules/TrainingPlaces'))
const ReasonAbsence = lazy(() => import('../../../../modules/ReasonAbsence'))
const InstructorsTraining = lazy(() => import('../../../../modules/InstructorsTraining'))
const CoursesCatalog = lazy(() => import('../../../../modules/CoursesCatalog'))
const StatusTraining = lazy(() => import('../../../../modules/StatusTraining'))
const TrainingObjectives = lazy(() => import('../../../../modules/TrainingObjectives'));
const InstitutionTypes = lazy(() => import('../../../../modules/InstitutionTypes'));
const Disabilities = lazy(() => import('../../../../modules/Disabilities'));
const TrainingModalities = lazy(() => import('../../../../modules/TrainingModalities'));
const EstablishmentKey = lazy(() => import('../../../../modules/EstablishmentKey'));
const StudyLevels = lazy(() => import('../../../../modules/StudyLevels'));
const EducationRH = lazy(() => import('../../../../modules/EducationRH'));
const OvertimeUtilities = lazy(() => import('../../../../modules/OvertimeUtilities'));
const Reports = lazy(() => import('../../../../modules/Reports'));

import { faUtensils } from "@fortawesome/pro-light-svg-icons";

const moduleItems = [
    // RAIZ //
    {
        id: "modules@inability",
        type: "item",
        icon: 'sick-leaves',
        showInMenu: false,
    },

    {
        id: "modules@contracts-and-formats",
        type: "item",
        icon: 'contractsandformats',
        componentElm: <Contracts />,
    },
    {
        id: "modules@dining-room",
        type: "item",
        icon: faUtensils,
        inConstruction: true,
        componentElm: true
    },
    // CAPACITACION //
    {
        id: "modules@training",
        type: "item",
        icon: "training",
        inConstruction: true,
        componentElm: true
    },
    {
        id: "modules@courses",
        type: "item",
        package_permissions: ["training"],
        componentElm: <Courses />,
    },
    {
        id: "modules@courses-by-worker",
        type: "item",
        package_permissions: ["training"],
        componentElm: <CoursesByWorker />,
    },
    {
        id: 'modules@txt-util',
        type: 'item',
        componentElm: <OvertimeUtilities />,
    },
    {
        id: "modules@establishment-key",
        type: "item",
        package_permissions: ["training", "training.establishment_key"],
        componentElm: <EstablishmentKey />,
    },
    {
        id: "abrhil@training-objectives",
        type: "item",
        package_permissions: ["training", "training.training_goals"],
        componentElm: <TrainingObjectives />,
    },
    {
        id: "abrhil@intitution-types",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: <InstitutionTypes />,
    },
    {
        id: "modules@study-levels",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: <StudyLevels />,
    },
    {
        id: 'modules@education-mapping',
        type: 'item',
        package_permissions: ["training", "training.stps"],
        componentElm: <Education />,
    },
    {
        id: "modules@disabilities",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: <Disabilities />,
    },
    {
        id: "abrhil@training-modalities",
        type: "item",
        package_permissions: ["training", "training.modality"],
        componentElm: <TrainingModalities />,
    },
    {
        id: "abrhil@thematic-areas",
        type: "item",
        package_permissions: ["training", "training.thematic_area"],
        componentElm: <ThematicAreas />,
    },
    {
        id: "abrhil@productive-area",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: <ProductiveArea />,
    },
    {
        id: "abrhil@occupations-workers",
        type: "item",
        package_permissions: ["training", "training.national_catalogs_of_occupations"],
        componentElm: <OccupationsWorkers />,
    },
    {
        id: "abrhil@agents-types",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: <AgentsType />,
    },
    {
        id: "modules@job-mapping",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: <JobMapping />,
    },
    {
        id: "abrhil@courses-classifications",
        type: "item",
        package_permissions: ["training"],
        componentElm: <CoursesClassifications />,
    }, {
        id: "modules@training-places",
        type: "item",
        package_permissions: ["training"],
        componentElm: <TrainingPlaces />,
    },
    {
        id: "modules@reason-for-absence",
        type: "item",
        package_permissions: ["training"],
        componentElm: <ReasonAbsence />,
    }, {
        id: "modules@instructors",
        type: "item",
        package_permissions: ["training"],
        componentElm: <InstructorsTraining />,
    },
    {
        id: "modules@courses-catalog",
        type: "item",
        package_permissions: ["training"],
        componentElm: <CoursesCatalog />,
    },
    {
        id: "abrhil@status",
        type: "item",
        package_permissions: ["training"],
        componentElm: <StatusTraining />,
    },
    // RECURSOS HUMANOS //
    {
        id: 'modules@attendance-catalogs',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@timbrasoft-catalogs',
        type: 'item',
        package_permissions: ["payroll_package"],
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@timbrasoft-settings',
        package_permissions: ["payroll_package"],
        type: 'item',
        componentElm: <ConfigTimbrasoft />,
    },
    {
        id: 'modules@payroll-catalogs',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@payroll-settings',
        type: 'item',
        componentElm: <SettingsPayroll />,
    },
    {
        id: 'modules@training-settings',
        type: 'item',
        componentElm: <SettingsTraining />,
    },
    {
        id: 'modules@attendance-settings',
        type: 'item',
        componentElm: <SettingsAttendance />,
    },
    {
        id: 'modules@payroll-report',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@imss-catalogs',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@imss-settings',
        type: 'item',
        componentElm: <SettingsIMSS />
    },
    {
        id: 'modules@human-resources-catalogs',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@human-resources-settings',
        type: 'item',
        componentElm: <SettingsRH />
    },
    {
        id: 'modules@users-list',
        type: 'item',
        componentElm: <Users type='attendance' />,
    },
    {
        id: 'modules@mopers-signatures-general',
        type: 'item',
        icon: 'mopers',
        componentElm: <MopersSignature />
    },
    {
        id: 'modules@working-days',
        type: 'item',
        componentElm: <Journeys />
    },
    {
        id: 'modules@schedules',
        type: 'item',
        componentElm: <Schedules />
    },
    {
        id: 'modules@comment-predefined',
        type: 'item',
        componentElm: <PredefinedComments />
    },
    {
        id: 'modules@workgroups',
        type: 'item',
        icon: 'workgroups',
        componentElm: <Workgroup />
    },
    {
        id: 'modules@signatures',
        type: 'item',
        icon: 'graphicsignatures',
        componentElm: <GraphicSignatures />
    },
    {
        id: "modules@signatures-by-worker",
        type: "item",
        icon: 'signaturesbyworker',
        showInMenu: false,
        componentElm: <SignaturesByWorker />,
    },
    {
        id: "modules@attendance",
        type: "item",
        icon: "attendance",
        componentElm: <Attendance />,
    },
    {
        id: "modules@mopers",
        type: "item",
        icon: 'moperssign',
        componentElm: <MopersByWorker />
    },
    {
        id: 'modules@worker-add',
        type: 'item',
        icon: 'worker-add',
        componentElm: <AddWorker />
    },
    {
        id: 'modules@kardex',
        type: 'item',
        icon: 'worker-kardex',
        componentElm: <Kardex />
    },
    {
        id: 'modules@structure-enterprise',
        type: 'item',
        componentElm: <StructureEnterprise />
    },
    {
        id: 'modules@employer-registration',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: <PatronalRegistration />
    },
    {
        id: 'modules@patronal-registration-settings',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: <PatronalRegistrationSettings />
    },
    {
        id: 'modules@branch',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.branch"],
        componentElm: <Branch />
    },
    {
        id: 'modules@sub-branch',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.sub_branch"],
        componentElm: <SubBranch />
    },
    {
        id: 'modules@area',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.area"],
        componentElm: <Area />
    },
    {
        id: 'modules@sub-area',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.sub_area"],
        componentElm: <SubArea />
    },
    {
        id: 'modules@department',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.department"],
        componentElm: <Depto />
    },
    {
        id: 'modules@sub-department',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.sub_department"],
        componentElm: <SubDepto />
    },
    {
        id: 'modules@job-position',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.job_position"],
        componentElm: <JobPosition />
    },
    {
        id: 'modules@jobs-management',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.management"],
        componentElm: <Management />
    },
    {
        id: 'modules@job-grouping',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.group"],
        componentElm: <GroupingPosition />
    },
    {
        id: 'modules@identifier-position',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.position_identifier"],
        componentElm: <IdentifierPosition />
    },
    {
        id: 'modules@jobs-hierarchical-level',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.hierarchical_level"],
        componentElm: <HierarchicalLevel />
    },
    {
        id: 'modules@position-professional-salary',
        type: 'item',
        componentElm: <ProfessionalMinimum />
    },
    {
        id: 'modules@gender',
        type: 'item',
        componentElm: <Gender />,
    },
    {
        id: 'modules@marital-status',
        type: 'item',
        componentElm: <CivilStatus />
    },
    {
        id: 'modules@job-changes',
        type: 'item',
        componentElm: <JobChanges />,
    },
    {
        id: 'modules@salary-adjustment',
        type: 'item',
        componentElm: <SalaryAdjustment />,
    },
    {
        id: 'modules@reason-leaving',
        type: 'item',
        componentElm: <ReasonLeaving />
    },
    {
        id: 'modules@validities',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.extra_validities"],
        componentElm: <Validity />
    },
    {
        id: 'modules@requirements',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.extra_requirements"],
        componentElm: <Requirements />
    },
    {
        id: 'modules@extra-fields',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.extra_fields"],
        componentElm: <ExtraData />
    },
    {
        id: 'modules@bans',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@degree-documents',
        type: 'item',
        package_permissions: ["training", "training.stps"],
        componentElm: <DegreeDocuments />
    },
    {
        id: 'modules@education',
        type: 'item',
        componentElm: <EducationRH />
    },
    {
        id: 'modules@manage-corporate',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'modules@integration-factor-type',
        type: 'item',
        componentElm: <IntegratingFactorType />
    },
    {
        id: 'modules@integration-factor-history',
        type: 'item',
        componentElm: <IntegrationFactorHistory />
    },
    // IMSS //
    {
        id: 'modules@imss-catalogs',
        title: 'catalogs',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@imss-settings',
        title: 'settings',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@affiliate-report',
        type: 'item',
        icon: 'idsetransfer',
        componentElm: <AffiliateReport />
    },
    {
        id: 'modules@confirmation',
        type: 'item',
        icon: 'idseconfirmation',
        componentElm: <IDSEConfirmation />
    },
    {
        id: 'modules@idse-audit',
        type: 'item',
        icon: 'idseaudit',
        componentElm: <IDSEAudit />
    },
    {
        id: 'modules@worker-movement-report',
        type: 'item',
        componentElm: <WorkerMovementsReport />
    },
    {
        id: 'modules@transfer',
        type: 'item',
        componentElm: <SUAtransfer />
    },
    {
        id: 'modules@liquidation',
        type: 'item',
        icon: 'settlement',
        componentElm: <Liquidation />
    },
    {
        id: 'modules@monthly-settlement-comparison',
        type: 'item',
        icon: 'compare',
        componentElm: <MSettlementComparasion />
    },
    {
        id: 'modules@bimonthly-settlement-comparison',
        type: 'item',
        icon: 'compare',
        componentElm: <BSettlementComparasion />
    },
    {
        id: 'modules@EMA-EBA',
        type: 'item',
        icon: 'emaeba',
        componentElm: <EmaEba />
    },
    {
        id: 'modules@variables',
        type: 'item',
        package_permissions: ["payroll_package"],
        icon: 'bimonthlyvariables',
        componentElm: <BimonthlyVariables />,
    },
    {
        id: 'modules@work-risk-history',
        type: 'item',
        icon: 'workriskhistory',
        componentElm: <WorkRiskHistory />
    },
    {
        id: 'modules@periods',
        type: 'item',
        componentElm: <PayrollPeriods />
    },
    {
        id: 'modules@box',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'modules@cash',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'modules@billing-companies',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'modules@concepts',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: <PayrollConcepts />
    },
    {
        id: 'modules@concepts-accounts',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: <PayrollConceptsAccounts />
    },
    {
        id: 'modules@alimony',
        type: 'item',
        icon: 'alimony',
        componentElm: <Alimony />
    },
    {
        id: "modules@calculate",
        type: "item",
        icon: 'payroll-kardex',
        package_permissions: ["payroll_package"],
        componentElm: <PayrollCalculate />,
    },
    {
        id: "modules@payroll-dispersion",
        type: "item",
        icon: 'payroll-dispersion',
        package_permissions: ["payroll_package"],
        componentElm: <PayrollDispersion />,
    },
    {
        id: "modules@voucher-dispersion",
        type: "item",
        icon: 'voucher-dispersion',
        package_permissions: ["payroll_package"],
        componentElm: <VoucherDispersion />,
    },
    {
        id: "modules@request-voucher-accounts",
        type: "item",
        icon: 'voucher-dispersion',
        package_permissions: ["payroll_package"],
        componentElm: <RequestVoucherAccounts />,
    },
    {
        id: "modules@import-voucher-accounts",
        type: "item",
        icon: 'voucher-dispersion',
        package_permissions: ["payroll_package"],
        componentElm: <ImportVoucherAccounts />,
    },
    {
        id: "modules@scheduled-concepts",
        type: "item",
        package_permissions: ["payroll_package"],
        icon: 'scheduled-concepts',
        componentElm: <ScheduledConcepts />,
    },
    {
        id: "modules@loans-and-paids",
        type: "item",
        icon: 'loans-and-paids',
        package_permissions: ["payroll_package"],
        componentElm: <LoansAndPaids />,
    },
    {
        id: 'modules@validation_c_p',
        package_permissions: ["payroll_package"],
        type: 'item',
        componentElm: <SATValidation />
    },
    {
        id: 'modules@periodicity-abr-sat',
        type: 'item',
        componentElm: <PeriodicityAbrSAT />
    },
    {
        id: 'modules@contract-abr-sat',
        type: 'item',
        componentElm: <ContractAbrSAT />
    },
    {
        id: 'modules@config-report-stamp',
        type: 'item',
        inConstruction: true,
        componentElm: true,
        //componentElm: <ConfigReportStamp />
    },
    {
        id: 'modules@concept-abr-sat',
        type: 'item',
        componentElm: <ConceptAbrSAT />
    },
    {
        id: 'modules@timbrasoft_app',
        type: 'item',
        componentElm: <Timbrasoft />
    },
    {
        id: 'modules@stamp-notifications',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: <StampNotification />,
    },
    {
        id: 'modules@config-stamp-receipt',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: <ConfigStampReceipt />,
    },
    {
        id: 'modules@receipt_vault',
        type: 'item',
        package_permissions: ["payroll_package"],
        inConstruction: true,
        componentElm: true,
        //componentElm: <Mod />
    },
    {
        id: 'modules@confronts',
        type: 'item',
        package_permissions: ["payroll_package"],
        inConstruction: true,
        componentElm: true,
        //componentElm: <Mod />
    },
    //* MIRRORS
    {
        id: 'modules@rfc',
        type: 'item',
        componentElm: <Rfc />
    },
    {
        id: 'modules@concept-sat-mirror',
        type: 'item',
        componentElm: <Stampconcept />
    },
    {
        id: 'modules@child-support-mirror',
        type: 'item',
        componentElm: <CatAlimony />
    },
    {
        id: 'modules@zipcode-mirror',
        type: 'item',
        componentElm: <PostalCode />
    },
    {
        id: 'modules@uma-mirror',
        type: 'item',
        componentElm: <UMA />
    },
    {
        id: 'modules@umi-mirror',
        type: 'item',
        componentElm: <UMI />
    },
    {
        id: 'modules@daily-minimun-salary-mirror',
        type: 'item',
        componentElm: <DailyMinimum />
    },
    {
        id: 'modules@professions-mirror',
        type: 'item',
        componentElm: <Professions />
    },
    {
        id: 'modules@payment-percentage-history-mirror',
        type: 'item',
        componentElm: <HistoryCyV />
    },
    {
        id: 'modules@federal-entities-taxes-mirror',
        type: 'item',
        componentElm: <FederalEntitiesTaxes />
    },
    {
        id: 'modules@profesional-minimun-salary-mirror',
        type: 'item',
        componentElm: <ProfessionalMinimum />
    },
    {
        id: 'modules@nationalities-mirror',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'modules@coworker-type-mirror',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.worker_type"],
        componentElm: <WorkerType />
    },
    {
        id: 'modules@job-position-type-mirror',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.job_position_type"],
        componentElm: <JobPositionType />
    },
    {
        id: 'modules@absenteeism-reasons-mirror',
        type: 'item',
        componentElm: <AbsenteeismReasons />
    },
    {
        id: 'modules@quota-imss-mirror',
        type: 'item',
        componentElm: <QuotaIMSS />
    },
    {
        id: 'modules@imss-reason-leaving-mirror',
        type: 'item',
        componentElm: <ImssReasonLeaving />
    },
    {
        id: 'modules@tax-tables-mirror',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: <Taxes />
    },
    {
        id: 'modules@thematic-areas-mirror',
        type: 'item',
        package_permissions: ["training", "training.thematic_area"],
        componentElm: <ThematicAreas />
    },
    {
        id: 'modules@courses-classifications-mirror',
        type: 'item',
        package_permissions: ["training"],
        componentElm: <CoursesClassifications />
    },
    {
        id: 'modules@occupations-workers-mirror',
        package_permissions: ["training", "training.national_catalogs_of_occupations"],
        type: 'item',
        componentElm: <OccupationsWorkers />
    },
    {
        id: 'modules@training-objectives-mirror',
        type: 'item',
        package_permissions: ["training", "training.training_goals"],
        componentElm: <TrainingObjectives />
    },
    {
        id: 'modules@training-modalities-mirror',
        type: 'item',
        package_permissions: ["training", "training.modality"],
        componentElm: <TrainingModalities />
    },
    {
        id: 'modules@agents-types-mirror',
        type: 'item',
        package_permissions: ["training", "training.stps"],
        componentElm: <AgentsType />
    },
    {
        id: 'modules@status-mirror',
        type: 'item',
        package_permissions: ["training"],
        componentElm: <StatusTraining />
    },
    {
        id: 'modules@intitution-types-mirror',
        type: 'item',
        package_permissions: ["training", "training.stps"],
        componentElm: <InstitutionTypes />
    },
    {
        id: "modules@productive-area-mirror",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: <ProductiveArea />,
    },
    {
        id: "modules@degree-documents-training-mirror",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: <DegreeDocuments />,
    },
    //* SECCION DE REPORTES *//
    // Se hace referencia 2 veces, una para la busqueda del modulo y otra para abrirlo al clickear el tab de reportes

    // REPORTS DE CAPACITACION
    {
        id: "modules@training-reports",
        type: "item",
        componentElm: <Reports tag="culture" />,
    },
    {
        id: "modules-training-reports",
        type: "item",
        title: 'Reportes de capacitación',
        componentElm: <Reports tag="culture" />,
    },
    // REPORTES DE RH
    {
        id: 'modules@human-resources-reports',
        type: 'item',
        componentElm: <Reports tag="culture" />,
    },
    {
        id: 'modules-human-resources-reports-group',
        type: 'item',
        title: 'Reportes de recursos humanos',
        componentElm: <Reports tag="culture" />,
    },
    // REPORTES DE ASISTENCIA Y MOPERS
    {
        id: 'modules@attendance-reports',
        type: 'item',
        componentElm: <Reports tag="culture" />,
    },
    {
        id: 'modules-attendance-list-reports-group',
        type: 'item',
        title: 'Reportes de asistencia y mopers',
        componentElm: <Reports tag="culture" />,
    },
    // REPORTES TIMBRASOFT
    {
        id: 'modules@timbrasoft-reports',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: <Reports tag="payroll" />,
    },
    // REPORTES DE NOMINA
    {
        id: 'modules@payroll-reports',
        type: 'item',
        componentElm: <Reports tag="payroll" />,
    },
    {
        id: 'modules-payroll-reports',
        type: 'item',
        title: 'Reportes de nómina',
        componentElm: <Reports tag="payroll" />,
    },
    // REPORTES IMSS
    {
        id: 'modules@imss-reports',
        type: 'item',
        componentElm: <Reports tag="payroll" />,
    },
    {
        id: 'imss-report-group',
        type: 'item',
        title: 'Reportes de IMSS',
        componentElm: <Reports tag="payroll" />,
    },
    // REPORTES BITACORA
    {
        id: 'modules@audit-reports',
        type: 'item',
        componentElm: <Reports tag="audit" />
    },
];

export default moduleItems;