
import React from "react";
import { useTranslation } from "react-i18next";
/* components */
import Avatar from '@mui/material/Avatar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRightFromBracket,
} from "@fortawesome/pro-regular-svg-icons";

/* own components */
import ThemeOption from "./ThemeOption";
import ChangePasswordOption from "./ChangePasswordOption";
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ToggleMic from "./ToggleMic";
import ModalContent from "./ModalContent";

const MenuOptions = ({
    user,
    photo,
    onClose,
    handleLogOut,
}) => {
    const { t } = useTranslation();
    return (
        <>
            <MenuItem onClick={onClose}>
                <Avatar alt={user} src={photo} sx={{ width: 35, height: 35 }} />
                {user}
            </MenuItem>
            <Divider />
            <ModalContent onClose={onClose}/>
            <ChangePasswordOption onClose={onClose} />
            <ToggleMic />
            <ThemeOption />
            <MenuItem onClick={handleLogOut}>
                <ListItemIcon className='user-menu-icon'>
                    <FontAwesomeIcon icon={faRightFromBracket} />
                </ListItemIcon>
                {t('logout')}
            </MenuItem>
        </>
    );
};

export default MenuOptions;