import React from 'react';
import { useTranslation } from "react-i18next";
import { size as _size } from "lodash";
import { parseDatetime, valueOrOption } from '../../../../core/common/GeneralUtilities';
import { reversDate, payrollValueType, isClosed, isLocked } from './itemUtilities';


export const TooltipContentTemplate = ({ item }) => {

	const { t } = useTranslation();

	if (!_size(item)) { return ""; }

	const title = {
		"1-1": t("period-both"),
		"0-1": t("period-pay"),
		"1-0": t("period-admin"),
	}[payrollValueType(item)] ?? "";

	const closed = isClosed(item) ? 1 : 0;
	const locked = isLocked(item) ? 1 : 0;
	const titleStatus = {
		"1-0": t("closed"),
		"0-1": t("locked"),
		"1-1": `${t("closed")} ${t("and")} ${t("locked")}`
	}[`${closed}-${locked}`] ?? t("opened");

	const style = {
		fontWeight: "bold",
		paddingRight: "1em"
	};

	return (
		<table>
			<tbody>
				{title !== "" &&
					<tr>
						<th colSpan={2} className="text-center" style={style}>
							{title}
						</th>
					</tr>}
				<tr>
					<td style={style}> {t(`period-pay-dates`)} </td>
					<td>{reversDate(item.start_date)} - {reversDate(item.end_date)}</td>
				</tr>
				<tr>
					<td style={style}> {t("period-admin-dates")} </td>
					<td>{reversDate(item.start_admin_court_date)} - {reversDate(item.end_admin_court_date)}</td>
				</tr>
				<tr>
					<td style={style}> {t(`status`)} </td>
					<td>{titleStatus}</td>
				</tr>
				<tr>
					<td style={style}> {t(locked ? "locked-at" : "locked-on")} </td>
					<td>{parseDatetime(item.locked_at, "dd/MM/yyyy HH:mm")}</td>
				</tr>
				<tr>
					<td style={style}> {t("payroll-locked")} </td>
					<td>{t(`locked-${valueOrOption(item?.payroll_locked, false)}`)}</td>
				</tr>
			</tbody>
		</table>
	);
};
