import PropTypes from "prop-types"
import React from 'react';
import Grid from '@mui/material/Grid';
import MainCard from '../../../App/components/MainCard';
import { hasValue, valueOrOption } from '../../common/GeneralUtilities';
/**
 * Este item esta contenido por un grid de mui, por lo que se le pueden pasar todas las propiedades de este
 * @param {object} params
 * @param {string} params.title - El titulo de la sección
 * @param {*} params.children - El contenido de la sección
 * @param {number} params.spacing - El espaciado que se quiere manejar para los inputs de la seccion
 * @param {boolean} params.isRequired - Indica si se agrega el decorador * en rojo para maracar los requeridos
 * @param {object} params.columns - Inidica las columnas que se quieren manejar dentro del fieldset, por default siempre esta en 12
 * @param {object} params.innerProps - Las propiedades extras para el contenedor interno del fieldset
 * @param {object} params.fieldsetStyle - Las propiedades que se quieran aplicar directamente al fieldset HTML nativo
 * @param {"default"|"box"|"simple"} params.design - El tipo de feldsed a mostrar
 *
 * @returns {FieldsetComponent}
 * @example
 *
 *	<Fieldset title={t("title")} >
 *		<Grid item xs={6}>
 *			hello 1
 *		</Grid>
 *		<Grid item xs={6}>
 *			hello 2
 *		</Grid>
 *	</Fieldset>
 */
const Fieldset = ({
	title,
	children,
	spacing,
	isRequired,
	columns = {},
	fieldsetStyle = {},
	innerProps = {},
	design = "default",
	headChilds,
	...others
}) => {

	const commons = {
		fieldsetStyle,
		isRequired,
		title,
		spacing,
		columns,
		innerProps,
		headChilds,
	};

	return (
		<Grid
			container
			item
			{...others}
		>
			{{
				"box": <Boxedfieldset {...commons} >{children}</Boxedfieldset>,
				"simple": <Simplefieldset {...commons} >{children}</Simplefieldset>,
			}[design] ?? <Defaultfieldset {...commons} >{children}</Defaultfieldset>}
		</Grid>
	);
};

Fieldset.propTypes = {
  children: PropTypes.any,
  columns: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number
  ]),
  design: PropTypes.string,
  fieldsetStyle: PropTypes.object,
  headChilds: PropTypes.any,
  innerProps: PropTypes.object,
  isRequired: PropTypes.any,
  spacing: PropTypes.number,
  title: PropTypes.string
}

export default Fieldset;

const Defaultfieldset = ({
	fieldsetStyle = {},
	isRequired,
	title,
	spacing,
	columns,
	innerProps,
	children,
}) => (
	<fieldset className={"abrhil-fieldset"} style={fieldsetStyle}>
		{hasValue(title) && (
			<legend className={`abrhil-legend ${isRequired ? "requiredLegend" : ""}`}>
				{title}
			</legend>
		)}

		<Grid container spacing={valueOrOption(spacing, 2)} columns={{ xs: 12, md: 12, lg: 12, xl: 12, ...columns }} {...innerProps}>
			{children}
		</Grid>
	</fieldset>
);

Defaultfieldset.propTypes = {
  children: PropTypes.any,
  columns: PropTypes.oneOfType([
	PropTypes.object,
	PropTypes.number
  ]),
  fieldsetStyle: PropTypes.object,
  innerProps: PropTypes.any,
  isRequired: PropTypes.any,
  spacing: PropTypes.number,
  title: PropTypes.string
}

const Boxedfieldset = ({
	fieldsetStyle = {},
	isRequired,
	title,
	spacing,
	columns,
	innerProps,
	headChilds,
	children,
}) => (
	<MainCard className='abrhil-box-fieldset' style={fieldsetStyle}>
		<div className={`fieldset-header ${isRequired ? "required-fieldset" : ""}`}>
			<span>{title}</span>
			{hasValue(headChilds) && (
				<div className='fieldset-head-childs'>
					{headChilds}
				</div>
			)}
			<span className='required-mark'></span>
		</div>
		<div className='fieldset-body'>
			<Grid container spacing={valueOrOption(spacing, 2)} columns={{ xs: 12, md: 12, lg: 12, xl: 12, ...columns }} {...innerProps}>
				{children}
			</Grid>
		</div>
	</MainCard>
);

Boxedfieldset.propTypes = {
  children: PropTypes.any,
  columns: PropTypes.oneOfType([
	PropTypes.object,
	PropTypes.number
  ]),
  fieldsetStyle: PropTypes.object,
  headChilds: PropTypes.any,
  innerProps: PropTypes.any,
  isRequired: PropTypes.any,
  spacing: PropTypes.number,
  title: PropTypes.string
}

const Simplefieldset = ({
	fieldsetStyle = {},
	spacing,
	columns,
	innerProps,
	children,
}) => (
	<div className='abrhil-simple-fieldset' style={fieldsetStyle}>
		<Grid
			container
			spacing={valueOrOption(spacing, 2)}
			columns={{ xs: 12, md: 12, lg: 12, xl: 12, ...columns }} {...innerProps}
		>
			{children}
		</Grid>
	</div>
);
Simplefieldset.propTypes = {
  children: PropTypes.any,
  columns: PropTypes.oneOfType([
	PropTypes.object,
	PropTypes.number
  ]),
  fieldsetStyle: PropTypes.object,
  innerProps: PropTypes.any,
  spacing: PropTypes.number
}
