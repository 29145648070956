import i18next from "i18next";
import { string } from "yup";
import { hasValue, valueOrOption } from '../GeneralUtilities';

export const alphanumericKey = /^[a-zA-Z0-9_-\s]*$/;
export const allowedHyphens = /^[a-zA-Z0-9_]*$/;
export const alphaspecials = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚäÄëËïÏöÖüÜ'():;/&",._-\s]*$/;
export const numeric = /^[0-9_-\s]*$/;
export const onlyNumeric = /^[0-9.\s]*$/;
export const onlyNumericPositive = /^0*[1-9]\d*$/;
export const alphanumericPC = /^[a-zA-Z0-9ñÑ\s]*$/;
export const url = /^[a-zA-Z0-9.:/_-]*$/;
export const capital = /^[a-zA-ZñÑáéíóúÁÉÍÓÚäÄëËïÏöÖüÜ'\s]*$/;
export const capitalNumber = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚäÄëËïÏöÖüÜ'\s]*$/;
export const booleanNumeric = /^[0-1]*$/;
export const folio = /^[a-zA-Z]{2}\d{6}$/;
export const dateServer = /^\d{4}-\d{2}-\d{2}$/;

const { t } = i18next;

/**
 * Validación de tipo string para usar en los formularios del sistema, basado en YUP
 * @param {object} params
 * @param {boolean?} params.required - Indica si el campo a validar es requerido
 * @param {?"alphanumericKey"|"alphaspecials"|"numeric"|"onlyNumeric"|"onlyNumericPositive"|"alphanumericPC"|"url"|"capital"|"text"|"booleanNumeric"|"folio"|"capitalNumber"} params.type - Indica el tipo de string a evaluar por default alphaspecials
 * @param {RegExp?} params.regex - Una expresión regular custom si se requiere
 * @param {string?} params.msg - Un mensaje custom cuando el campo configurado no cumpla con la expresión regular usada
 * @param {number?} params.min - Validación para el mínimo de caracteres
 * @param {number?} params.max - Validación para el máximo de caracteres
 * @param {number?} params.length - Validación para validar un número exacto de caracteres
 * @param {?string} params.customRequiredMsg - Mensaje custom para mostrar cuando un campo es requerido y no se quiera usar el mensaje por default, eje. la seleccion de un item de tabla
 *
 * @example stringValidation({required : true, type : "alphanumericPC"})
 */

export const stringValidation = ({
	required = false,
	type = "alphaspecials",
	regex,
	msg,
	min = null,
	max = null,
	length = null,
	customRequiredMsg,
}) => {

	const defaultV = { msg: "alpha", regex: capital };

	const values = {
		alphanumericKey: { msg: "alpha_dash", regex: alphanumericKey },
		alphaspecials: { msg: "alpha", regex: alphaspecials },
		numeric: { msg: "numbers_dash", regex: numeric },
		onlyNumeric: { msg: "onlynumbers", regex: onlyNumeric },
		onlyNumericPositive: { msg: "numeric-positive", regex: onlyNumericPositive },
		alphanumericPC: { msg: "alpha_num", regex: alphanumericPC },
		url: { msg: "active_url", regex: url },
		capital: { msg: "alpha", regex: capital },
		text: { msg: "alpha", regex: capital },
		booleanNumeric: { msg: "boolean", regex: booleanNumeric },
		folio: { msg: "folio", regex: folio },
		capitalNumber: { msg: "alpha_num", regex: capitalNumber },
		hyphens: { msg: "hyphens", regex: allowedHyphens }
	}[type] ?? defaultV;

	let validation = string()
		.transform((curr, orig) => (orig === "" ? null : curr))
		.matches(regex ?? values.regex, { message: t(msg ?? `validation:${values.msg}`) });

	if (required) {
		validation = validation.required(t(customRequiredMsg ?? 'validation:required'));
	} else {
		validation = validation.nullable();
	}

	if (hasValue(length) || (min === max && hasValue(min))) {
		length = valueOrOption(length, max);
		validation = validation.length(length, t('validation:length', { length: length }));
	} else {
		if (hasValue(min)) {
			validation = validation.min(min, t('validation:min.string', { min: min }));
		}

		if (hasValue(max)) {
			validation = validation.max(max, t('validation:max.string', { max: max }));
		}
	}

	return validation;
};

export const ValidationByValue = (value, required, maxLength, type) => {
	value = (value === null || value === "" || typeof value === "undefined") ? "" : value;
	maxLength = isNaN(maxLength) ? 500 : maxLength;
	let message = "";

	switch (type) {
		case "alphanumericKey":
			if (!alphanumericKey.test(value)) {
				message = i18next.t('validation:alpha_num');
			}
			break;
		case "numeric":
			if (!numeric.test(value)) {
				message = i18next.t('validation:numbers_dash');
			}
			break;
		case "onlyNumeric":
			if (!onlyNumeric.test(value)) {
				message = i18next.t('validation:onlynumbers');
			}
			break;
		case "alpha_num":
			if (!alphanumericPC.test(value)) {
				message = i18next.t('validation:alpha_num');
			}
			break;
		case "alpha":
			if (!capital.test(value)) {
				message = i18next.t('validation:alpha');
			}
			break;
		case "hyphens": {
			if (!allowedHyphens.test(value)) {
				message = i18next.t('validation:hyphens');
			}
			break;
		}
		case "alphanumeric":
		default:
			if (!alphaspecials.test(value)) {
				message = i18next.t('validation:alpha_num');
			}
			break;
	}

	if (message === "" && required && value.toString().length === 0) {
		message = i18next.t('validation:required');
	}

	if (message === "" && value.toString().length > parseInt(maxLength)) {
		message = i18next.t('validation:max.string', { max: maxLength });
	}

	return {
		message: message,
		valid: message === ""
	};
};