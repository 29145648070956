import React, { useContext } from 'react'
import { maxBy } from "lodash";
import { useWatch } from 'react-hook-form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faEye, faUpDownLeftRight, faEyeSlash, faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { faEyeSlash as faEyeSlashDuotone } from "@fortawesome/pro-duotone-svg-icons";
//kendo and MUI
import { Sortable } from "@progress/kendo-react-sortable";
import IconButton from '@mui/material/IconButton';
//components
import List from "./List";
import { ModuleContext } from '../../util';
import SortableEmptyItemUI from './SortableEmptyItemUI';

function Card(props) {
  const { style, attributes, forwardRef, dataItem } = props;
  const { control, setValue, clearErrors } = useContext(ModuleContext);
  const signatures = useWatch({ control, name: `signatures` })
  const findIndex = signatures.findIndex(f => f.order === dataItem.order);


  const onDragOver = (event) => {
    setValue(`signatures[${findIndex}].signature`, event.newState)
  };

  function disabledCard() {
    setValue(`signatures[${findIndex}].is_hidden`, !dataItem.is_hidden)
  }

  function addList() {
    const list = signatures[findIndex].signature
    list.push({
      idParent: dataItem.order,
      id: (maxBy(list, "id")?.id || 0) + 1,
      name: ""
    })
    setValue(`signatures[${findIndex}].signature`, list)
  }

  function removeCard() {
    const list = signatures
    list.splice(findIndex, 1)
    setValue(`signatures`, list)
    clearErrors('signatures')
  }

  return (
    <React.Fragment>
      <div
        ref={forwardRef}
        {...attributes}
        style={{
          ...style,
          float: "left",
          display: "inline-block",
          margin: 4,
          height: "calc(100vh - 700px)",
          overflow: "overlay",
          border: "1px solid #DEDFE1",
          borderRadius: "5px",
          background: dataItem.is_hidden ? "#F6F6F6" : "white",
        }}
      >
        <div style={{
          display: "inline-flex",
          justifyContent: "flex-end",
          width: "100%",
          backgroundColor: "#dedfe1",
        }}>
          {/* {JSON.stringify(dataItem.order)} */}
          <div style={{ cursor: "move", padding: "5px", fontSize: "16px" }}>
            <FontAwesomeIcon icon={faUpDownLeftRight} />
          </div>
          <IconButton aria-label="eye" style={{ padding: "4px", color: "var(--tableHeadbg)" }} onClick={disabledCard}>
            <FontAwesomeIcon icon={dataItem.is_hidden ? faEye : faEyeSlash} size="xs" />
          </IconButton>

          <IconButton aria-label="delete" style={{ padding: "4px", color: "var(--red)" }} onClick={removeCard}>
            <FontAwesomeIcon icon={faXmark} size="xs" />
          </IconButton>
        </div>
        <div style={{ position: "relative" }}>

          {dataItem.is_hidden && <div style={{
            padding: "4px", left: "25%",
            top: "45%", position: "absolute",
          }}>
            <FontAwesomeIcon icon={faEyeSlashDuotone} size="5x" />
          </div>}
          {<Sortable
            navigation={false}
            idField={"id"}
            data={signatures[findIndex].signature}
            itemUI={List}
            onDragOver={onDragOver}
            emptyItemUI={(kProps)=> <SortableEmptyItemUI {...kProps} findIndex={findIndex}/> }
          />}
          {(!dataItem.is_hidden && signatures[findIndex].signature.length != 4) && <div style={{ textAlignLast: "center" }}>
            <IconButton aria-label="add" style={{ color: "var(--tableHeadbg)" }} onClick={addList}>
              <FontAwesomeIcon icon={faCirclePlus} size="sm" />
            </IconButton>
          </div>}
        </div>

      </div>

    </React.Fragment>
  );
};

export default Card