import React from "react";

//MUI
import Portal from '@mui/material/Portal';

// Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// FontAwesome
import useSocketsController from './useSocketsController';

export const toastContainerId = 'abr-toast-container';

const NotificationSocket = () => {

	useSocketsController();

	return (
		<Portal>
			<ToastContainer
				id={toastContainerId}
				closeOnClick={false}
				tapToDissmiss={false}
				draggablePercent={50}
				draggable
				stacked
			/>
		</Portal>
	);
};
export default NotificationSocket;