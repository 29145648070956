import { Card } from "@mui/material";
import React, { lazy, Suspense, useEffect, useRef } from "react";
import { useDialogContext } from "../../../hooks/useDialog";
import { AnimatePresence, motion } from "framer-motion";
import { customAnimation } from "../../../../../../@components/form/Permission/Utils";
import GenericContent from "./GenericContent";
import ViewSkeleton from "./ViewSkeleton";

// Lazy imports
const General = lazy(() => import('./views/General'));
const Security = lazy(() => import('./views/Security'));
const Roles = lazy(() => import('./views/Roles'));
const Tokens = lazy(() => import('./views/Tokens'));
const Status = lazy(() => import('./views/Status'));
const Picture = lazy(() => import('./views/Picture'));

const views = {
    general: <General />,
    external_roles: <Roles />,
    security: <Security />,
    tokens: <Tokens />,
    status: <Status />,
    picture: <Picture />,
};

function DynamicRender() {
    const { currentTab: { key } } = useDialogContext();

    return (
        <AnimatePresence mode="wait">
            <Card
                key={`current-view-${key}`}
                className="company-sidecard"
                component={motion.div}
                {...customAnimation(cardAnimation())}
            >
                <GenericContent>
                    <Suspense fallback={<ViewSkeleton />}>
                        {views[key]}
                    </Suspense>
                </GenericContent>
            </Card>
        </AnimatePresence>
    );
};

export default DynamicRender;

export const companyTransition = { type: 'spring', stiffness: 900, damping: 80 };

export const cardAnimation = () => {
    const firstRender = useRef(true);

    useEffect(() => {
        setTimeout(() => {
            firstRender.current = false;
        }, 200);
    }, [])

    return {
        initial: { y: '40px', opacity: 0 },
        animate: { y: 0, opacity: 1 },
        exit: { y: '40px', opacity: 0 },
        transition: {
            ...companyTransition,
            delay: firstRender.current ? 0.4 : 0,
            stiffness: firstRender.current ? 500 : 900,
        },
    }
};