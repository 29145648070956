import { LOAD_ATTENDANCE } from "../actions";

const initialState = {
  show: false
}

const attendance = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ATTENDANCE:
      return {
        ...state,
        show: true
      };
    default:
      return state;
  }
};

export default attendance;