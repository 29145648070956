import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BaseButton } from '../../../../../App/components/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import FormInput from '../../../form/Field';
import { CompaniesDropdown } from '../windows/EnterpriseTab/CompaniesDropdown';
import { RolesDropdown } from '../windows/EnterpriseTab/RolesDropdown';
import { currentUserInfo } from '../../../../common/validate-tenant';
import { implementService } from '../../../../services/implemet-service';
import { ValidationSchema, defaultValues, setObjStorage } from '../windows/EnterpriseTab/utilities';
import { isAbrhil } from '../../../../common/auth';
import { valueOrOption } from '../../../../common/GeneralUtilities';
import { Form } from '../../../form/Container';
import { userOptionsApi } from '../../../../services/administrator';
import { usePackages } from '../../../../@hooks/usePackages';
import { closeAllWindows } from '../../../../../store/triggers/navigate';
import { changeGroup, changeTenant, lockedWindow, unlockedWindow } from '../../../../../store/actions';

const dropdownProps = {
	size: "small",
	noDefault: true,
	valueField: "id",
	textField: "name",
	fieldInput: "Dropdown",
};

const ModalContent = ({onClose}) => {

	const { t } = useTranslation();
	
	const {
		user_info: {
			id,
		} = {}
	} = currentUserInfo() ?? {};

	const { select } = useSelector((state) => state.group);

	const {
		loadPackages,
		resetPackages,
		loadMenu,
		getUserGroups,
		getUserConfigs,
	} = usePackages();

	const dispatch = useDispatch();
	const tenant = useSelector((state) => state.tenant);
	const [corporates, setCorporates] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [roles, setRoles] = useState([]);

	const loadData = () => {
		const api = userOptionsApi();
		implementService(api.get({}),
			response => {
				setCorporates(valueOrOption(response?.corporates, []));
				setCompanies(valueOrOption(response?.companies, []));
				setRoles(valueOrOption(response?.roles, []));
			}
		);
	};

	const {
		reset,
		control,
		setValue,
		getValues,
		handleSubmit
	} = useForm({
		resolver: yupResolver(ValidationSchema),
		mode: "onSubmit",
		defaultValues
	});

	useEffect(() => {
		loadData();
		const current = tenant?.current;
		const newValues = {
			corporate: valueOrOption(current?.corporate?.id, current?.corporate),
			company: current?.id,
			companyObj: current,
			role: select?.id,
		};
		reset(newValues);
		//eslint-disable-next-line
	}, [id]);

	const onSubmit = async (data) => {
		onClose();
		dispatch(lockedWindow());
		const current = tenant?.current;
		dispatch(closeAllWindows());
		if (data.company !== current?.id) {
			onChangeTenant(data.companyObj);
			const userData = await getUserConfigs(current?.id);
			const isSuper = userData?.is_superuser;
			if (isSuper) {
				return onChangeGroup(current?.id, data.roleObj);
			}
			getUserGroups(current?.id, data.role);
		}
		if (data.role !== select?.id) {
			return onChangeGroup(current?.id, data.roleObj);
		}
		dispatch(unlockedWindow());
	};

	const onChangeTenant = (newValue) => {
		dispatch(changeTenant(newValue));
		if (newValue) {
			localStorage.setItem("tenantStorage", JSON.stringify(newValue));
			loadPackages(newValue.id);
			return;
		}
		localStorage.removeItem("tenantStorage");
		resetPackages();
	};

	const onChangeGroup = async (tenant, newValue) => {
		dispatch(changeGroup(newValue));
		setObjStorage("roleStorage", newValue);
		await loadMenu(tenant, newValue?.id);
		return dispatch(unlockedWindow());
	};

	return (
		<div className='p-2' style={{ width: "300px" }}>
			<Grid container spacing={1} justifyContent={"center"} alignItems={"center"}>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<FormInput
								control={control}
								{...dropdownProps}
								data={valueOrOption(corporates, [])}
								name={"corporate"}
								label={t("corporate")}
								disabled={!isAbrhil()}
							/>
						</Grid>
						<Grid item xs={12}>
							<CompaniesDropdown
								control={control}
								dropdownProps={dropdownProps}
								companies={companies}
								setValue={setValue}
								getValues={getValues}
							/>
						</Grid>
						<Grid item xs={12}>
							<RolesDropdown
								control={control}
								roles={roles}
								dropdownProps={dropdownProps}
								setValue={setValue}
								getValues={getValues}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={"auto"}>
					<Form
						onSubmit={onSubmit}
						handleSubmit={handleSubmit}
						defaultValues={defaultValues}
					>
						<BaseButton
							label={t("accept")}
							design={"contained"}
							type="submit"
						/>
					</Form>
				</Grid>
			</Grid>
		</div>
	);
};

ModalContent.propTypes = {
  onClose: PropTypes.func
}

export default ModalContent;
