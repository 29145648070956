import { lazy } from '../utilities';

const abrhilIcons = {
	corporate: {
		allowedTag: "Corporate",
		Icon: lazy(async () => import("./Corporativo.svg"))
	},
	enterprise: {
		allowedTag: "Enterprise",
		Icon: lazy(async () => import("./Empresas.svg")),
	},
	imss: {
		allowedTag: "Imss",
		Icon: lazy(async () => import("./Imss.svg")),
	},
	attendance: {
		allowedTag: "Attendance",
		Icon: lazy(async () => import("./AttendanceList.svg")),
	},
	migration: {
		allowedTag: "Migration",
		Icon: lazy(async () => import("./Migracion.svg")),
	},
	payrolls: {
		allowedTag: "Payrolls",
		Icon: lazy(async () => import("./Nominas.svg")),
	},
	piechart: {
		allowedTag: "Pie-Chart",
		Icon: lazy(async () => import("./PieChart.svg")),
	},
	tools: {
		allowedTag: "Tools",
		Icon: lazy(async () => import("./herramientas.svg")),
	},
	pushpin: {
		allowedTag: "Pushpin",
		Icon: lazy(async () => import("./pushpin.svg")),
	},
	token: {
		allowedTag: "Token",
		Icon: lazy(async () => import("./token.svg")),
	},
	copy: {
		allowedTag: "Copy",
		Icon: lazy(async () => import("./Copy.svg")),
	},
	settings: {
		allowedTag: "Settings",
		Icon: lazy(async () => import("./settings.svg")),
	},
	picture: {
		allowedTag: "Picture",
		Icon: lazy(async () => import("./picture.svg")),
	},
	shield: {
		allowedTag: "Shield",
		Icon: lazy(async () => import("./shield.svg")),
	},
	externalroles: {
		allowedTag: "ExternalRoles",
		Icon: lazy(async () => import("./external_roles.svg")),
	},
	poweronoff: {
		allowedTag: "PowerOnOff",
		Icon: lazy(async () => import("./power_on_off.svg")),
	},
	plus: {
		allowedTag: "Plus",
		Icon: lazy(async () => import("./plus.svg")),
	}
};

export default abrhilIcons;
