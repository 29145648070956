import React from 'react';
import Grid from "@mui/material/Grid";
import Grid2 from "@mui/material/Grid2";
import Alert from "@mui/material/Alert";
import { AnimatePresence, motion } from "framer-motion";

/**
 * Alerta basada en Material, para mas propiedades leer la documentación oficial
 * @param {object} props
 * @param {?number} props.xs - Tamaño que cubira horizontalmente en pantallas xs, default 12
 * @param {?number} props.sm - Tamaño que cubira horizontalmente en pantallas sm, default 12
 * @param {?number} props.md - Tamaño que cubira horizontalmente en pantallas md, default 12
 * @param {?number} props.lg - Tamaño que cubira horizontalmente en pantallas lg, default 12
 * @param {?number} props.xl - Tamaño que cubira horizontalmente en pantallas xl, default 12
 * @param {"error"|"warning"|"success"|"info"} props.severity - El color del mensaje por default Info
 * @param {*} props.children - Cualquier cosa que se quiera contener dentro de la alerta
 * @param {*} props.others - Cualquier otra ó otras porpiedades que pueda tener la alerta en la documentación oficial
 * @returns {AlertComponent}
 * @example
 * <MuiAlert severity="warning">
 *		Mensaje de advertencia
 *	</MuiAlert>
 */
const MuiAlert = ({
	xs = 12,
	sm = 12,
	md = 12,
	lg = 12,
	xl = 12,
	severity = "info",
	children,
	asV2 = false,
	...others
}) => {

	const sizes = { xs, sm, md, lg, xl };
	const gridProps = asV2 ? { size: { ...sizes, ...others?.size } } : { item: true, ...sizes };
	const Container = asV2 ? Grid2 : Grid;

	return (
		<Container {...gridProps}>
			<AnimatePresence>
				<motion.div
					initial={{ opacity: 0, y: -50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3 }}
				>
					<Alert severity={severity} {...others}>
						{children}
					</Alert>
				</motion.div>
			</AnimatePresence>
		</Container>
	);
};

export default MuiAlert;