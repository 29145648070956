import { omit } from "lodash";
import { formatedDate } from "../../../../../SignaturesByWorker/components/Details/components/models";
import { moperConceptModel } from "../../../../../SignaturesByWorker/components/Details/components/models/models";

export const createdAtFormatted = { value: 'created_at', format: (value, moper) => formatedDate(value, moper, false, false, true), key: 'created_at' };

export const specialConceptModel = {
    moper_concept: {
        model: {
            ...omit(moperConceptModel.model, ['Monto']),
            'Fecha de captura': createdAtFormatted,
            'Monto': moperConceptModel.model.Monto
        }
    }
};
