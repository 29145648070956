import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { workgroupsApi } from "../../../../../../services/administrator";
import { resolveError } from "../../../../../../common/resolve-error";
import { useDispatch } from "react-redux";
import { isFunction } from "lodash";
import { showNotificationWarning } from "../../../../../../../store/actions";
import { useRequestLoad } from "./useResolveIncidence";
import { workersApi } from "../../../../../../services/worker";
import { hasValue } from "../../../../../../common/GeneralUtilities";
import { store } from "../../../../../../../store";

const includeFields = [
    'workgroup',
    'enterprise_structure_organization.settings.*'
].join(',');

const fieldsReq = [
    'id',
    'key',
    'workgroup',
    'enterprise_structure_organization.settings.overtime_config',
].join(',');

const mopersKeywords = [
    'mopers',
    'attendance'
];

export const useFromMopers = () => {
    const focused = store.getState().navigate?.focused_window;
    const currentElement = store.getState().navigate?.[focused];
    const moduleId = currentElement?.id;
    const modulePath = currentElement?.path;
    const from_mopers = mopersKeywords.some(el => moduleId?.includes(el) || modulePath?.includes(el));
    return from_mopers ? { from_mopers } : {};
}

function useModal({
    worker: propWorker,
    moperPermsData,
    ...others
}) {
    const dispatch = useDispatch();
    const [verifiedMoper, setVerifiedMoper] = useState(true);
    const [selected, setSelected] = useState({});
    const [worker, setWorker] = useState(null);
    const { loading, fetch } = useRequestLoad();

    // START MODAL STATES
    const [openTimePayment, setOpenTimePayment] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [openDayCalculate, setOpenDayCalculate] = useState(false);
    const [openResolve, setOpenResolve] = useState(false);
    const [openApplyRest, setOpenApplyRest] = useState(false);
    // END MODAL STATES

    const modalValues = [
        { value: openTimePayment, setState: setOpenTimePayment },
        { value: openPayment, setState: setOpenPayment },
        { value: openDayCalculate, setState: setOpenDayCalculate },
        { value: openResolve, setState: setOpenResolve },
        { value: openApplyRest, setState: setOpenApplyRest },
    ];

    useEffect(() => {
        handleInitialValues();
    }, [propWorker])

    const handleInitialValues = () => {
        const workerConfig = propWorker?.enterprise_structure_organization?.settings?.overtime_config;
        if (hasValue(workerConfig)) {
            setWorker(propWorker);
            initialVerifyMoper(propWorker);
        } else {
            getWorker(propWorker?.id ?? propWorker);
        }
    }

    const initialVerifyMoper = (worker) => {
        if (hasValue(moperPermsData)) {
            const isVerified = moperPermsData?.find(f => f.moper_catalog.key === 'txt')?.is_active;
            setVerifiedMoper(isVerified);
            return;
        }
        verifyMoper(worker);
    }

    const getWorker = (id) => {
        if (!id) return;
        fetch({
            api: workersApi.getOne(id, { include: includeFields, fields: fieldsReq, ...useFromMopers() }),
            callback: (resp) => {
                initialVerifyMoper(resp);
                setWorker(resp);
            },
        });
    }

    const verifyMoper = async (worker) => {
        try {
            const response = await workgroupsApi.getOne(worker?.workgroup?.id, { include: "mopers.*" });
            const isVerified = response.mopers?.find(f => f.moper_catalog.key === 'txt')?.is_active;
            setVerifiedMoper(isVerified);
        } catch (error) {
            resolveError(error);
        }
    }

    function handleClose(key, reset) {
        const { setState } = modalValues[key];
        setState(false);
        setSelected({});
        if (isFunction(reset)) {
            reset({});
        }
    };

    async function handleOpen(key, item) {
        if (verifiedMoper) {
            const { setState } = modalValues[key];
            setSelected(item);
            setState(true);
        } else {
            dispatch(showNotificationWarning({ message: "El grupo de trabajo del colaborador no permite solicitar Moper de Tiempo Extraordinario" }));
        }
    };

    return {
        ...others,
        worker,
        loading,
        selected,
        modalValues,
        verifiedMoper,
        handleClose,
        handleOpen,
    };
};

const ModalsContext = createContext({});

export const useModalsContext = () => useContext(ModalsContext);

export default function ModalsController({ worker, children, ...others }) {
    const modalValues = useModal({ worker, ...others });

    return (
        <ModalsContext.Provider value={modalValues}>
            {children}
        </ModalsContext.Provider>
    );
};

ModalsController.propTypes = {
    worker: PropTypes.object,
    children: PropTypes.any
};