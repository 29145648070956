import { API, config } from "./config";
import {
  ParseParams,
  ServerAPI,
  encodeURIParams,
  getUrl,
} from './ServerAPI';
import axios from 'axios';

export const IDSEMissingMovementRecordAPI = ServerAPI("idse-missing-movement-record");

export const IDSETransferMovementAPI =  {
  postHiring: (params, extra, include_tenant = true) => {
		const url = getUrl(`idse-transfer-hiring-movement`, include_tenant);
		const payload = ParseParams({ params, include_tenant });
		return axios.post(url, payload, config(extra)).then((response) => response.data)
	},
  postMovements: (params, extra, include_tenant = true) => {
		const url = getUrl(`idse-transfer-all-movements`, include_tenant);
		const payload = ParseParams({ params, include_tenant });
		return axios.post(url, payload, config(extra)).then((response) => response.data)
	},
}
export const IDSEReportHiringdAPI = ServerAPI("idse-report/hiring");
export const IDSEReportAllAPI = ServerAPI("idse-report/all-idse-movements");


/**
 * @param {{ tenant: number, month:number, year:number, employer_list: Array, movement_type: number }} request
 */
const IdseReportApi = () => {

  const baseName = "idse-report";

  const baseApi = ServerAPI(baseName);

  const downloadFile = async (type, body) => {
    let headers = new Headers(config().headers);
    headers.append("Content-Type", "application/json");

    const url = getUrl(`${baseName}/${type}`, true);

    return fetch(
      url,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          return Promise.reject("error 404");
        }
      })
      .then((blob) => blob);
  };

  const workersReport = async (body) => {
    const url = getUrl(`${baseName}/workers`, true);

    return axios.post(url, body, config())
      .then(async (response) => response.data);
  }

  return {
    ...baseApi,
    downloadFile,
    workersReport,
  }
}

export const idseReportApi = IdseReportApi();

const IdseConfirmationApi = () => {
  const baseName = "idse-confirmation";

  const baseApi = ServerAPI(baseName);

  const include_config = true;

  const getConfirmation = (params) => {

    const url = getUrl(`${"idse-confirmation"}`);

    return axios.get(url, ParseParams({ params, include_config }))
      .then((response) => response.data);
  };

  const generalUpdate = async (params, body) => {

    const request = encodeURIParams(params);

    const url = (API + `/${baseName}/general-update/${request}`);

    return axios.put(url, body, config())
      .then((response) => response.data);
  }

  function downloadConfigXML(params) {
    const url = getUrl(`${baseName}/export-excel`);

    return axios.get(url, ParseParams({ params, include_config }))
      .then((response) => response.data);
  }

  return {
    ...baseApi,
    getConfirmation,
    generalUpdate,
    downloadConfigXML
  }
}

export const idseConfirmationApi = IdseConfirmationApi();

const IdseAuditApi = () => {
  const baseName = "idse-missing-movements";

  const baseApi = ServerAPI(baseName);

  const downloadReport = async (body) => {

    const url = getUrl(`${baseName}`, true);

    return axios.post(url, body, config())
      .then(async (response) => response.data);
  };

  return {
    ...baseApi,
    downloadReport
  }

}

export const idseAuditApi = IdseAuditApi();