import React, { useState } from "react";
import PropTypes from "prop-types";
import { toast } from 'react-toastify';
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEyeSlash,
	faXmark
} from "@fortawesome/pro-solid-svg-icons";
import { isNumber } from "lodash";
import { variants, transition } from './constants';
import Message from './Message';
import { customAnimation } from "../../../core/@components/form/Permission/Utils";

const BodyMessage = ({
	message,
	taskId,
	percentage,
	tasks,
	onCancel,
}) => {

	BodyMessage.propTypes = {
		message: PropTypes.string,
		taskId: PropTypes.string,
		percentage: PropTypes.number,
		tasks: PropTypes.array,
		onCancel: PropTypes.func,
	};

	const [cancel, setCancel] = useState(false);
	const [hide, setHide] = useState(false);
	const handleCancel = () => {
		setCancel(!cancel);
	};

	const handleConfirmCancel = () => {
		onCancel(taskId);
		toast.dismiss(taskId);
		setCancel(false);
	};

	const handleConfirmHide = () => {
		for (let name in tasks) {
			if (tasks?.[name]?.taskID === taskId) {
				tasks[name].dimissed = true;
			}
		}
		toast.dismiss(taskId);
		handleHide();
	};

	const handleHide = () => {
		setHide(!hide);
	};

	return (
		<div className="body-message-socket">
			<AnimatePresence mode='wait' initial={false}>
				{!cancel && !hide ?
					<motion.div
						className='socket-message'
						key={`body-socket-${taskId}`}
						{...customAnimation(variants, transition)}
					>
						{message}
					</motion.div>
					: <Message
						cancel={cancel}
						taskId={taskId}
						handleHide={handleHide}
						handleCancel={handleCancel}
						handleConfirmHide={handleConfirmHide}
						handleConfirmCancel={handleConfirmCancel} />}
			</AnimatePresence>
			<AnimatePresence mode='wait' initial={false}>
				<>
					{isNumber(percentage) ?
						<span className="percentage">
							{`${percentage}%`}
						</span>
						:
						null}
					{!hide && !cancel ?
						<div className="socket-icons-wrapper">
							<motion.span
								className="hide-socket"
								onClick={handleHide}
								key={`hide-button-socket-${taskId}`}
								{...customAnimation(variants, transition)}
							>
								<FontAwesomeIcon icon={faEyeSlash} />
							</motion.span>
							<motion.span
								className="cancel-socket"
								onClick={handleCancel}
								key={`cancel-button-socket-${taskId}`}
								{...customAnimation(variants, transition)}
							>
								<FontAwesomeIcon icon={faXmark} />
							</motion.span>
						</div>
						:
						null}
				</>
			</AnimatePresence>
		</div>
	);
};

export default BodyMessage;