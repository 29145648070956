
import * as yup from "yup";
import { stringValidation } from "../../../../common/validations/stringValidation";
import { validateNumeric } from "../../../../common/validations/numeric";
import { uniqueFileValidation } from "../../../../common/validations/fileValidation";
import arrayValidation from "../../../../common/validations/array";

export const initialForm = {
	open: false,
	isEdit: false,
	item: {}
};

const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi

const urlProps = {
	regex: urlRegex,
	msg: 'Introduzca una URL válida',
}

const certExt = ['.crt', '.cer'];

export const validations = yup.object().shape({
	name: stringValidation({ required: true, max: 100 }),
	owner: stringValidation({ required: true }),
	corporate: stringValidation({ required: true, max: 18, type: "numeric" }),
	slug: stringValidation({ required: true, max: 50, type: "hyphens" }),/* subdomain */
	securitytype: stringValidation({ required: true, max: 18, type: "numeric" }),
	country: validateNumeric({ required: false }),
	login_mode: stringValidation({ required: true, type: "numeric" }),
	sso_config: yup.object().when('login_mode', {
		is: (value) => parseInt(value) === 2,
		then: () => yup.object().shape({
			entity_id_idp: stringValidation({ required: true }),
			single_sign_on_service_idp: stringValidation({ required: true, ...urlProps }),
			single_logout_service_idp: stringValidation({ ...urlProps }),
			cert_sp: uniqueFileValidation({ required: true, allowedExtensions: certExt, allowString: true }),
			key_sp: uniqueFileValidation({ required: true, allowedExtensions: ['.key'], allowString: true }),
			cert_idp: uniqueFileValidation({ required: true, allowedExtensions: [...certExt, '.pem'], allowString: true }),
			username: stringValidation({ required: true, min: 3, max: 100 }),
			email: stringValidation({ required: true, min: 3, max: 100 }),
		})
	}),
	roles_map: arrayValidation({ required: false }).of(
		yup.object().shape({
			rol_id: validateNumeric({ required: true }),
			equivalence: stringValidation({ required: true }),
		})
	),
});

export const defaultValues = {
	image: null,
	name: "",
	corporate: null,
	slug: "",/* subdomain */
	schema_name: "",
	securitytype: 1,
	is_active: true,
	is_migration_tenant: false,
	/* non controled values (importants) */
	owner: null,/* super admin of all */
	is_delete: false,
	migration_status: 0,
	/* TOMAKE : posibleDelete */
	address: "",
	roles_map: [],
	logo: {},
	sso_config: {
		key_sp: "",
		cert_sp: "",
		cert_idp: "",
	},
	contact: [],
	country: 1,
	language: "es_mx",
	postal_code: "",
};

export const securityTypes = [
	{ id: 1, name: "Web users" },
	{ id: 2, name: "Active Directory Auth" },
	{ id: 3, name: "Active Directory Group" },
];

export const arrayscurrency = [
	{ id: 1, name: "CLP(Peso Chileno)" },
	{ id: 2, name: "CRC(Costa Rica)" },
	{ id: 3, name: "DOP(Peso Dominicano)" },
	{ id: 4, name: "JMD(Dolar Jamaicano)" },
	{ id: 5, name: "MXN(Peso Mexicano)" },
	{ id: 6, name: "PAB(Balboa Panameño)" },
	{ id: 6, name: "USD(Dolar Estadounidense)" },
];


export const arraysad = [
	{ id: 1, name: "Partial Access" },
	{ id: 2, name: "Full Access" }
];

export const authenticationTypes = [
	{ id: 0, name: "Usuario y contraseña" },
	{ id: 1, name: "Correo y contraseña" },
	{ id: 2, name: "SSO" },
	{ id: 3, name: "Active Directory" },
	{ id: 4, name: "Azure Active Directory" }
];

export const titleView = {
	general: { title: 'Configuración General de la Empresa', subtitle: 'Datos básicos de la empresa que se utilizarán en la autenticación y administración.' },
	external_roles: { title: 'Configuración de Seguridad y SSO', subtitle: 'Define los métodos de autenticación y las credenciales de seguridad de la empresa.' },
	security: { title: 'Otros Roles', subtitle: 'Configura cómo los campos del sistema de inicio de sesión (SSO) se relacionan con los campos de tu Abrhillcloud.' },
	tokens: { title: 'Generación de Token', subtitle: 'Crea un token de uso único para autenticar accesos en plataformas externas o sistemas conectados. Este token será utilizado una sola vez y es esencial para la seguridad de las integraciones.' },
	picture: { title: 'Imagen de Perfil de la Empresa', subtitle: 'Puedes actualizar o cambiar la imagen de la empresa' },
	status: { title: 'Estado de la Empresa', subtitle: 'Activa o desactiva la empresa en el sistema' },
};