import i18next from 'i18next';
import { array } from "yup";
import { hasValue } from '../GeneralUtilities';

const { t } = i18next;

const arrayValidation = ({ required, min, max }) => {

	let validation = array();

	/* if array selection is required */
	if (required) {
		validation = validation.required(t("validation:required"));
	} else {
		validation = validation.nullable();
	}

	/* if array need a minimun selection */
	if (hasValue(min)) {
		const msg = min === 1 ? "array-one" : "array";
		validation = validation.min(min, t(`validation:min.${msg}`, { min: min }));
	}

	/* if array need a maximun selection */
	if (hasValue(max)) {
		validation = validation.max(max, t("validation:max.array", { max: max }));
	}

	return validation;
};

export default arrayValidation;