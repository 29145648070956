import React, { useEffect, useState } from "react";
import FormDialog from "../../../../../../../App/components/dialog/FormDialog";
import DialogContent from "../../../../../../styled/DialogContent";
import { useModalsContext } from "../hooks/useModals";
import { useTranslation } from "react-i18next";
import { BasicDialogActions } from "../../../../../../styled/DialogActions";
import { Grid } from "@mui/material";
import FormInput from "../../../../../../@components/form/Field";
import { useRequestLoad } from "../../../../../MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { validateNumeric } from "../../../../../../common/validations/numeric";
import { companyApi } from "../../../../../../services/company";
import { showSuccessNotification } from "../../../../../../../App/components/Notifications";
import { successUpdated } from "../../../../../../common/notification-messages";

function Settings() {
    const { t } = useTranslation();
    const { openSettings, handleCloseSettings } = useModalsContext();

    return (
        <FormDialog
            open={openSettings}
            className="minimalist-dialog-design"
            handleClose={handleCloseSettings}
            title={t('Copiar Paquetes desde otra empresa')}
            innerSubtitle="Selecciona una empresa de origen y otra de destino para copiar sus paquetes. Esta acción replicará las configuraciones seleccionadas de la empresa origen a la empresa destino."
            maxWidth="sm"
            headerDivider
        >
            <Content />
        </FormDialog>
    );
};

export default Settings;

const validationSchema = yup.object().shape({
    from_tenant: validateNumeric({ required: true }),
});

const Content = () => {
    const { t } = useTranslation();
    const { handleCloseSettings, selected } = useModalsContext();
    const { loading, fetch } = useRequestLoad();
    const { loading: loadingData, fetch: fetchData } = useRequestLoad();
    const [data, setData] = useState([]);

    const {
        control,
        handleSubmit,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        fetchData({
            api: companyApi.get({ tree: true, fields: 'id,name', '~id': selected.id }),
            callback: setData
        });
    }

    const onSubmit = data => {
        fetch({
            api: companyApi.cloneSettings(selected.id, data),
            callback: onSuccess,
        });
    }

    const onSuccess = () => {
        showSuccessNotification(successUpdated());
        handleCloseSettings();
    }

    return (
        <>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} className="new-height-input">
                        <FormInput
                            fieldInput="Dropdown"
                            control={control}
                            name="from_tenant"
                            data={data}
                            loading={loadingData}
                            textField="name"
                            valueField="id"
                            label={t('settings')}
                            noDefault
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <BasicDialogActions
                onAccept={handleSubmit(onSubmit)}
                onCancel={handleCloseSettings}
                loading={loading}
                submitLabel={t('copy')}
            />
        </>
    );
}