import React from 'react';
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack';
//--material-ui
import MUIDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { hasValue } from '../common/GeneralUtilities';

/**
 * Cabecera usada en los dialogos del sistema
 * @param {object} params
 * @param {?string} params.title
 * @param {?string} params.subtitle
 * @param {function} params.onClose
 * @param {boolean} props.onCloseIcon
 * @param {"default"|"modern"} props.design - El estilo del modal a mostrar
 * @returns {DialogTitleComponent}
 */
const DialogTitle = ({
	title,
	subtitle,
	titleCenter,
	onClose,
	onCloseIcon = true,
	design = "default"
}) => {

	const dialogStyle = {
		default: "",
		// modern: "blue-header"
	}[design] ?? "";

	return (
		<MUIDialogTitle className={`abrhil-dialog-title ${dialogStyle}`} >
			<Grid
				container
				spacing={0}
				direction="row"
				justifyContent="flex-end"
				alignItems="center"
				columns={{ lg: 14, xl: 15 }}
				className="dialog-title-wrap"
			>
				<Grid item xs sm md lg xl >
					<Grid container spacing={0} id="draggable-dialog-title hola" >
						<Grid item className="dialog-title">
							{title}
						</Grid>
						{hasValue(titleCenter) &&
							<Grid item md className={"dialog-title-center"}>
								{titleCenter}
							</Grid>}
						{hasValue(subtitle) && (
							<Grid item md className={"dialog-subtitle"}>
								{subtitle}
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
					{(onClose && onCloseIcon) ?
						(
							<Stack spacing={1} direction="row" justifyContent={"flex-end"} >
								<IconButton aria-label="close" onClick={onClose} sx={{ padding: "8px 12px" }}>
									<FontAwesomeIcon icon={faXmark} className="closeModalIcon" />
								</IconButton>
							</Stack>
						) : null}
				</Grid>
			</Grid>
		</MUIDialogTitle>
	);
};

export default DialogTitle;