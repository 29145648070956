import React from 'react';
import { isArray, omit, size } from 'lodash';
import MegaMenuTimbrasoft from '../../../@components/navigation/components/SideMenu/MenuTimbrasoft';
import { ItemIcon } from '../../../@components/navigation/components/SideMenu/Search/NavItem';
import { CustomTooltip } from '../../../../App/components/Templates/cells';
import useAbrhilNavigation from '../../../@components/navigation/contextsAndControllers/useAbrhilNavigation';
import { MegaMenuProvider } from '../../../@components/navigation/contextsAndControllers/useMegaMenu';
import useAbrhilSideMenu from '../../../@components/navigation/contextsAndControllers/useSideMenu';
import { isSelected } from '../../../@components/navigation/utilities';

const validateChildrens = (childs) => {
	if (!isArray(childs)) {
		return null;
	}

	return childs.map(el => {
		const child = { ...el };
		if (size(child.children) > 0) {
			child.children = validateChildrens(child.children);
		}

		if (
			(child.type === "item" && child.showInMenu) ||
			size(child.children) > 0
		) {
			return child;
		}
		return null;
	}).filter(item => item !== null);
};

const invalidItem = ({ children, showInMenu, type, key, }) => {
	const finalChilds = validateChildrens(children);

	const isValid = (
		!showInMenu ||
		!["item", "collapse"].includes(type) ||
		(size(finalChilds) <= 0 && type === "collapse") ||
		['abrhilhelpdesk', 'welcome', 'help'].includes(key)
	);

	return { isValid, children: finalChilds };
};

const StampMenu = ({ items = [] }) => {
  return (
	items.map((item, index) => {
		const { children } = invalidItem(item);
		
		const Item = !children ? SimpleItem : MegaMenuItem;

		return <Item key={index} item={item} childItems={children} />;
	})
)};

export default StampMenu;

const SimpleItem = ({ item, disabled }) => {
	const { title, bread, icon } = item;
	const { breadcrumbs } = useAbrhilNavigation();
	const { openWindowItem } = useAbrhilSideMenu();

	const openWindow = () => {
    if(item.key === 'welcome-timbra'){
      return
    }

    openWindowItem(item);
  };
	
	return (
		<CustomTooltip title={title} className="menu-item-tooltip" placement="left">
			<span className={`sidebar-item ${isSelected(bread, breadcrumbs)}`} >
				<span className='side-menu-item-icon stamp-icon' onClick={disabled ? ()=>{} : openWindow}>
					<ItemIcon icon={icon} />
				</span>
			</span>
		</CustomTooltip>
	);
};

const MegaMenuItem = ({ item, childItems }) => {
	const final = { ...item, children: childItems };
	return (
		<MegaMenuProvider
			menuKey={final.key}
			elements={childItems}
			groupsAtFirstLevel={["timbrasoft"].includes(final.key)}
			{...omit(final, ["key", "children"])}
		>
			<MegaMenuTimbrasoft item={final} />
		</MegaMenuProvider>
	);
};