import { each, last, uniq, uniqBy } from 'lodash';
import menuTriggers from '../triggers/navigate';
import { hasValue } from '../../core/common/GeneralUtilities';

const initialWindowState = {
	id: null
};

const initialState = {
	cosidereMobile: 991.98,
	menuCollapsed: false,
	change_password_open: false,
	baseName: '/app', // only at build time to set, like /datta-able
	breadcrumbs: [],
	zoom: 100,
	routes: [],
	current_path: "",
	active_elements: [],
	active_windows: [],
	focused_window: null,
	window_1: { ...initialWindowState },
	window_2: { ...initialWindowState },
	window_3: { ...initialWindowState },
	window_4: { ...initialWindowState },
	window_5: { ...initialWindowState },
	window_6: { ...initialWindowState },
	isStamp: false,
};

const navigate = (state = initialState, action = {}) => {
	let element = null;
	let elements = [];
	let active_elements = [];
	let active_windows = [];
	switch (action.type) {
		case menuTriggers.toggle_menu:
			return {
				...state,
				menuCollapsed: !state.menuCollapsed
			};
		case menuTriggers.toggle_change_password:
			return {
				...state,
				change_password_open: !state.change_password_open
			};
		case menuTriggers.routes:
			return {
				...state,
				routes: action.routes
			};
		case menuTriggers.breadcrumb:
			return {
				...state,
				breadcrumbs: action.breadcrumbs
			};
		case menuTriggers.open_window:
			element = action.item?.id;
			if (!hasValue(element) || state.active_elements.includes(element)) {
				return state;
			}
			active_elements = uniq([...state.active_elements, element]);
			localStorage.setItem("active_windows", active_elements);
			return {
				...state,
				[action.window_key]: action.item,
				focused_window: action.window_key,
				active_elements: active_elements,
				active_windows: uniqBy([
					...state.active_windows,
					{
						window_key: action.window_key,
						title: action.item?.title
					}
				], "window_key"),
			};
		case menuTriggers.close_window:
			element = state[action.window_key]?.id;
			elements = state.active_windows.filter(item => item.window_key !== action.window_key);
			active_elements = state.active_elements.filter(item => item !== element);
			active_elements = uniq(active_elements);
			elements = uniqBy(elements, "window_key");
			localStorage.setItem("active_windows", active_elements);
			return {
				...state,
				[action.window_key]: { ...initialWindowState },
				active_elements: active_elements,
				active_windows: elements,
				focused_window: (last(elements)?.window_key ?? null)
			};
		case menuTriggers.close_window_focused:
			return focusedChange(state);
		case menuTriggers.focus_window:
			return {
				...state,
				focused_window: action.window_key
			};
		case menuTriggers.reopen_windows:
			if (!hasValue(action.toFocus) || !hasValue(action.items)) {
				return state;
			}

			elements = {
				window_1: { ...initialWindowState },
				window_2: { ...initialWindowState },
				window_3: { ...initialWindowState },
				window_4: { ...initialWindowState },
				window_5: { ...initialWindowState },
				window_6: { ...initialWindowState }
			};
			active_elements = [];
			active_windows = [];
			each(action.items, (item, index) => {
				element = `window_${index + 1}`;
				elements[element] = item;
				active_elements.push(item?.id);
				active_windows.push({
					window_key: element,
					title: item?.title
				});
				if (action?.toFocus?.id && action?.toFocus?.id === item.id) {
					elements["focused_window"] = element;
				}
			});

			localStorage.setItem("active_windows", active_elements);
			return {
				...state,
				...elements,
				active_elements: active_elements,
				active_windows: active_windows,
			};

		case menuTriggers.zoom_update:
			return {
				...state,
				zoom: action.zoom
			};

		case menuTriggers.close_windows:
			localStorage.setItem("active_windows", []);
			return {
				...state,
				active_elements: [],
				active_windows: [],
				focused_window: null,
				window_1: { ...initialWindowState },
				window_2: { ...initialWindowState },
				window_3: { ...initialWindowState },
				window_4: { ...initialWindowState },
				window_5: { ...initialWindowState },
				window_6: { ...initialWindowState },
			};

		case menuTriggers.toggle_stamp:
			return {
				...state,
				isStamp: !state.isStamp
			};

		default:
			return state;
	}
};

export default navigate;

const focusedChange = (state) => {
	const windowKey = state.focused_window;
	let element = state[windowKey]?.id;
	let elements = state.active_windows.filter(item => item.window_key !== windowKey);
	let active_elements = state.active_elements.filter(item => item !== element);
	active_elements = uniq(active_elements);
	elements = uniqBy(elements, "window_key");
	localStorage.setItem("active_windows", active_elements);
	return {
		...state,
		[windowKey]: { ...initialWindowState },
		active_elements: active_elements,
		active_windows: elements,
		focused_window: (last(elements)?.window_key ?? null)
	};
};