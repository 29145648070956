import { useForm, useWatch } from "react-hook-form";
import { useEffect } from "react";
import { resolveError } from "../../../../../../common/resolve-error";
import { useDispatch } from "react-redux";
import { lockedWindow, showConfirmNotification, unlockedWindow } from "../../../../../../../store/actions";
import { overtimeMopersAPI } from "../../../../../../services/mopers";
import { isFunction, size } from "lodash";
import { showSuccessNotification } from "../../../../../../../App/components/Notifications";
import { useTranslation } from "react-i18next";

export const MODAL_KEY = 2;

function useDayCalculate({
    modalValues,
    handleClose,
    handleRefresh,
    worker,
    selected,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { value: open } = modalValues[MODAL_KEY];

    const initialChoice = selected?.overtime?.type_calculation ?? selected?.type_calculation;

    const hasRequests = size(selected?.overtime?.request_details) > 0;

    const {
        control,
        handleSubmit,
        reset,
        setValue,
    } = useForm({
        // resolver: yupResolver(otValidationSchema),
        defaultValues: { option: 1 },
        mode: "onChange",
    });

    const selection = useWatch({ control, name: 'option' });

    useEffect(() => {
        if (open) {
            reset({ option: initialChoice });
        }
    }, [open])

    function onChange(e) {
        const newValue = parseInt(e.target.value ?? "");
        setValue('option', newValue);
    }

    async function onSubmit(e, pass = false) {
        if (e.option !== initialChoice && hasRequests && pass !== true) {
            dispatch(showConfirmNotification({
                maxWidth: 'sm',
                title: t('warning-general-title'),
                message: 'Los mopers de este día serán cancelados',
                description: 'Los mopers automáticos serán cancelados y recalculados',
                onConfirm: () => onSubmit(e, true),
            }));
            return;
        }
        dispatch(lockedWindow());
        const request = {
            worker: worker?.id,
            origin_day: selected?.origin_day,
            type_calculation: e?.option,
        };
        try {
            const response = await overtimeMopersAPI.changeCalc(request);
            handleClose(MODAL_KEY);
            dispatch(unlockedWindow());
            if (isFunction(handleRefresh)) {
                handleRefresh(false, selected);
            }
            showSuccessNotification(response?.detail);
        } catch (error) {
            dispatch(unlockedWindow());
            resolveError(error);
        }
    }

    return {
        control,
        handleSubmit,
        initialChoice,
        handleClose,
        selection,
        onChange,
        onSubmit,
        selected,
        open,
    };
}

export default useDayCalculate;