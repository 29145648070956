import { useEffect, useState, useCallback } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useForm, useFieldArray, useWatch } from 'react-hook-form'
import { usePackages } from "../../usePackages";
import { set, format, parseISO } from 'date-fns';
import { showNotificationSuccess, showNotificationWarning } from "../../../../../store/actions";
import { orderBy, isArray } from 'lodash'
import { implementService } from "../../../../services/implemet-service";
import { packagesV2Api } from "../../../../services/packages";
import { successUpdated } from "../../../../common/notification-messages";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function useItem(source, getData) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { chill, selectPackage, tenant } = usePackages()
  const [changes, setChanges] = useState([])
  const [modalDiff, setModalDiff] = useState(false)

  const schema = yup.object({
    data: yup.array().of(
      yup.object().shape({
        value: yup.lazy((value,{parent}) => {
          if (parent?.datatype === 'numeric') {
            const min = parent?.configuration?.min_value
            const max = parent?.configuration?.max_value

            return yup.number()
            .min(min, t('validation:gte.numeric', { value: min }))
            .max(max, t('validation:lte.numeric', { value: max }))
            .test('custom-validation', 'Custom error message: This field cannot be null.', function (value) {
              return value !== null;
            })
            .required(t('validation:required'));
          } else {
            return yup.mixed().nullable(true).required(t('validation:required'));
          }
        }),
      })
    )
  });

  const { control, handleSubmit, reset, setValue} = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })
  const data = useWatch({ control, name: 'data' })

  const { fields, update } = useFieldArray({
    control,
    name: 'data'
  })
  const [edit, setEdit] = useState(false)

  const toggle = useCallback(() => {
    setModalDiff(!modalDiff)
  }, [modalDiff])

  useEffect(() => {
    const data = orderBy(source.settings, ['index'], ['asc']).map(m => {
      if (m.datatype == "time") {
        if (typeof (m.value) === "string") {
          const currentDate = new Date()
          const [hours, minutes] = m.value.split(':');
          const newDate = set(currentDate, { hours: parseInt(hours, 10), minutes: parseInt(minutes, 10) });
          m.value = newDate
        }
      }
      return m
    })
    reset({
      data
    })
    setEdit(false)
    // eslint-disable-next-line
  }, [source.settings])

  useEffect(() => {
    if (selectPackage.value) return
    if (!selectPackage.value && edit) {
      if (chill) return
      handleCancel()
    }
    // eslint-disable-next-line
  }, [selectPackage.value, edit])

  function handleCancel() {
    setEdit(false)
    const data = orderBy(source.settings, ['index'], ['asc'])
    reset({
      data
    })
  }

  function handleSend(data) {
    middleware(data)
  }

  function validityChildren(parent, values) {
    const configuration = parent.configuration
    const { children } = configuration
    if (!children?.length) return
    children.forEach((i) => {
      const index = data.findIndex((f) => f.key === i)
      const item = data[index]
      if(item){
        item.editable = values[i]?.editable
        let value = values[i]?.value
        if(item.datatype === "time"){
          value = values[i]?.value ? new Date(values[i]?.value) : null
        }
        item.value = value ?? item.value
        update(index, item)
        setValue(`data[${index}]`, item)
      }
    })
  }

  function handleChange(e, item) {
    if (item.datatype === 'dropdown' && item.configuration.options) {
      const values = item.configuration.options.find((f) => f.id === e.target.value)?.children
      validityChildren(item, values)
    }

    if (item.datatype === 'switch' && item.configuration.options) {
      const values = item.configuration.options[e.value]
      validityChildren(item, values)
    }
    return e
  }

  function getValue({ value, datatype, configuration }) {
    if (datatype === 'dropdown') return configuration.options.find((f) => f.id === value)?.name
    if (datatype === 'time') return format(value, 'HH:mm')
    if (datatype === 'date') return format(parseISO(value), 'dd/MM/yyyy')
    if (datatype === 'catalog' || datatype === 'multiselect')
      if (isArray(value)) {
        return configuration.options
          .filter((f) => value.includes(f.id))
          .map((m) => m.name)
          .toString()
      } else {
        return configuration.options.find((f) => f.id === value)?.name
      }
    return value
  }

  function middleware({ data }) {
    const origin = orderBy(source.settings, ['index'], ['asc'])
    const diff = []
    data.forEach((element, index) => {
      const obj = { changes: [] }
      obj.title = element.name
      //Checamos si hay cambios en los valores
      if (JSON.stringify(element?.value) !== JSON.stringify(origin[index]?.value)) {
        obj.changes = [{ after: getValue(element), before: getValue(origin[index]), title: t('state') }]
      }
      //Checamos si hay cambios en el alias
      if (JSON.stringify(element?.alias) !== JSON.stringify(origin[index]?.alias)) {
        obj.changes.push({ after: element?.alias, before: origin[index]?.alias, title: t('alias') })
      }
      //si hay cambios hacemos push
      if (obj.changes.length) {
        diff.push(obj)
      }
    })
    setChanges(diff)
    if (diff.length) {
      setModalDiff(true)
    } else {
      dispatch(
        showNotificationWarning({
          message: t('no-changes')
        })
      )
    }
  }

  function updatePackages() {
    const params = {
      settings: data
        .map(({ key, value, editable, alias, datatype }) => {
          if (datatype == "time") {
            value = format(value, 'HH:mm')
          }
          return { key, value, editable, alias }
        })
    }
    implementService(packagesV2Api(tenant).settingsUpdate.putConcatName(params), () => {
      getData()
      dispatch(showNotificationSuccess(successUpdated()))
      setModalDiff(false)
    })
  }

  return {
    updatePackages,
    fields,
    handleCancel,
    handleSubmit,
    handleSend,
    control,
    edit,
    setEdit,
    toggle,
    changes,
    modalDiff,
    handleChange
  };
}

export default useItem;
