import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as EndSvg } from "../../assets/svg/tab_end.svg";
import MuiIB from '@mui/material/IconButton';
import { faXmark } from "@fortawesome/pro-solid-svg-icons";

export const Tab = ({
	active = false,
	title = "",
	onClick,
	onClose,
	onDoubleClick = () => {},
}) => {

	return (
		<div className={`abrhil-window-tab ${active ? "active" : ""}`}>
			<div className="tab-start">
				<EndSvg />
			</div>
			<div className="tab-content" onDoubleClick={onDoubleClick}>
				<span className='tab-text' onClick={onClick}>
					{title}
				</span>
				<MuiIB size={"small"} onClick={onClose}>
					<FontAwesomeIcon icon={faXmark} />
				</MuiIB>
			</div>
			<div className="tab-end">
				<EndSvg />
			</div>
			<div className='tab-shadow'></div>
		</div>
	);
};
