import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faXmark,
	faCheck
} from "@fortawesome/pro-solid-svg-icons";
import { variants, transition } from './constants';
import { customAnimation } from "../../../core/@components/form/Permission/Utils";

const Message = ({
	cancel,
	taskId,
	handleCancel,
	handleConfirmCancel,
	handleHide,
	handleConfirmHide,
}) => {

	Message.propTypes = {
		taskId: PropTypes.string,
		cancel: PropTypes.bool,
		handleCancel: PropTypes.func,
		handleConfirmCancel: PropTypes.func,
		handleConfirmHide: PropTypes.func,
		handleHide: PropTypes.func,
	};

	const { t } = useTranslation('notifications');

	if (cancel) {
		return (
			<ConfirmMessage
				taskId={taskId}
				handleCancel={handleCancel}
				handleConfirm={handleConfirmCancel}
				message={t('cancel-task')} />
		);
	}

	return (
		<ConfirmMessage
			taskId={taskId}
			handleCancel={handleHide}
			handleConfirm={handleConfirmHide}
			message={t('hide-task')} />
	);
};

export default Message;

const ConfirmMessage = (props) => {
	const {
		taskId,
		message,
		handleConfirm,
		handleCancel
	} = props;

	ConfirmMessage.propTypes = {
		taskId: PropTypes.string,
		message: PropTypes.string,
		handleCancel: PropTypes.func,
		handleConfirm: PropTypes.func,
	};

	return (
		<motion.div
			className="confirm-message-socket"
			key={`cancel-socket-${taskId}`}
			{...customAnimation(variants, transition)}
		>
			{message}
			<div className="socket-actions-wrapper">
				<button
					className="cancel-icon"
					onClick={handleCancel}
				>
					<FontAwesomeIcon icon={faXmark} />
				</button>
				<button
					className="confirm-icon"
					onClick={handleConfirm}
				>
					<FontAwesomeIcon icon={faCheck} />
				</button>
			</div>
		</motion.div>
	);
};
