import React from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import { Grid as GridTable, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import DialogContent from '../../../core/styled/DialogContent';
import { pageable, selectedItem } from "../GridCustomProps";
import GridNoRecordsRender from '../Templates/GridNoRecordsRender';
import { CustomSelectCell, CustomSelectHeader } from '../../../core/@components/grid/CustomCells';
import { valueOrOption } from '../../../core/common/GeneralUtilities';

export const Table = ({
	data,
	page,
	total,
	init,
	pageChange,
	filter,
	filterChange,
	selectRow,
	onDblClick,
	multi,
	onSelectPage,
	selection,
	selectBy,
	children,
	persistedSelection,
	disabledSelection,
	tableHeight,
}) => {

	const finalData = selectedItem(data, selection, selectBy);
	return (
		<Grid item xs={12}>
			<GridTable
				style={{
					height: valueOrOption(tableHeight, "calc(100vh - 364px)")
				}}
				className="principal-grid"
				data={finalData}
				/* pagination */
				{...page}
				total={total}
				pageable={pageable(total)}
				onPageChange={pageChange}
				/* filter */
				selectedField="selected"
				filter={filter}
				onFilterChange={filterChange}
				onRowClick={selectRow}
				onRowDoubleClick={onDblClick}
			>
				<GridNoRecords>
					<GridNoRecordsRender init={init} filter={filter}/>
				</GridNoRecords>
				<GridColumn
					locked
					field={"selected"}
					width={50}
					headerCell={headerProps => (
						<CustomSelectHeader
							{...headerProps}
							multiSelection={multi}
							generalSelect={false}
							data={finalData}
							onSelectPage={onSelectPage}
						/>
					)}
					cell={cellProps => (
						<CustomSelectCell
							{...cellProps}
							onRowClick={selectRow}
							generalSelect={false}
							persistedSelection={persistedSelection}
							disabledSelection={disabledSelection}
							selectBy={selectBy}
						/>
					)}
				/>
				{children}
			</GridTable>
		</Grid>
	);
};


export const Container = ({ children }) => {
	return (
		<DialogContent dividers className="modal-content">
			<Card>
				<CardContent>
					<Grid container spacing={2} >
						{children}
					</Grid>
				</CardContent>
			</Card>
		</DialogContent>
	);
};
