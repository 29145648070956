import { useDispatch } from "react-redux";
import { useForm as useReactForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import i18next from "i18next";
import { ValidationSchema, initValues } from "../util";
import {
  lockedWindow,
  unlockedWindow,
} from "../../../../store/actions";
import { idseAuditApi } from "../../../services/idse";
import FileSaver from "file-saver";
import { socketsApi } from "../../../services/socket-report";
import TriggerNotificationSocket from "../../../common/trigger-notification-socket";
import { resolveError } from "../../../common/resolve-error";

const { t } = i18next;

function useForm() {
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors
  } = useReactForm({
    resolver: yupResolver(ValidationSchema),
    mode: "onChange",
    defaultValues: initValues,
  });

  const handleDownload = (values) => {
    getReport(values);
  };

  async function getReport(values) {
    dispatch(lockedWindow());

    const params = {
      all: values.check_all,
      employers_registration: values.check_all ? [] : values.data,
      missing_confirm: parseInt(values.idse_option) !== 0,
      initial_date: values.audit_date
    };


    try {
      let response = await idseAuditApi.downloadReport(params);
      TriggerNotificationSocket(`missing_idse`, response.task_id);
    } catch (error) {
      resolveError(error);
    } finally {
      dispatch(unlockedWindow());
    }
  }

  return {
    handleDownload,
    handleSubmit,
    control,
    clearErrors,
    setValue,
  };
}

export default useForm;


export const downloadMissingIdseReport = async (taskID) => {
  try {
    let response = await socketsApi.downloadReport(taskID);

    FileSaver.saveAs(
      response,
      `${t("missing-idse-report")}_${format(
        new Date(),
        "yyyy-MM-dd hh:mm:ss"
      )}`
    );
  } catch (error) {
    return error;
  }
}