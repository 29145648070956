import { useMediaQuery } from 'react-responsive';

const ValidateDisplay = (sizes) => {
	return useMediaQuery(sizes);
}

/**
 * Función para evaluar que tipo de pantalla estamos usando
 * @param {"custom"|"desktop"|"tablet"|"mobile"|"default"} display - El tipo de pantalla que estan evaluando
 * @param {{minWidth,maxWidth}} customSizes - Solo se evaluara cuando se use el display custom y se tiene que pasar al menus uno de los parametros
 * @returns {boolean}
 */


export const Display = (display, customSizes = { minWidth: 768 }) => {
	display = (display || "Default").toLocaleLowerCase();
	return {
		"custom": ValidateDisplay(customSizes),
		"desktop": ValidateDisplay({ minWidth: 992 }),
		"tablet": ValidateDisplay({ minWidth: 768, maxWidth: 991 }),
		"mobile": ValidateDisplay({ maxWidth: 767 }),
	}[display] ?? ValidateDisplay({ minWidth: 768 });
}

export const Desktop = ({ children }) => {
	return Display("Desktop") ? children : null;
}

export const Tablet = ({ children }) => {
	return Display("Tablet") ? children : null;
}

export const Mobile = ({ children }) => {
	return Display("Mobile") ? children : null;
}

export const Default = ({ children }) => {
	return Display("NotMobile") ? children : null;
}