import i18next from "i18next";
import * as yup from "yup";
import arrayValidation from "../../common/validations/array";
import { stringDateValidation } from "../../common/validations/dateValidation";
const { t } = i18next;
import { format } from "date-fns";

export const initValues = {
  audit_date: format(new Date(), "yyyy-MM-dd"),
  data: [],
  idse_option: 0
};


export const ValidationSchema = yup.object().shape({
  data: arrayValidation({ required: true, min: 1 }),
  audit_date: stringDateValidation({ required: true }),
});

export const data = [
  { id: 0, name: t("subscribes") },
  { id: 1, name: t("unsubscribes") },
  { id: 2, name: t("readmissions") },
  { id: 3, name: t("modifications") },
];

export const idseOptions = [
  { id: 0, name: t("not-sent-records-idse") },
  { id: 1, name: t("not-confirm-records-idse") },
]
