import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import KendoTable from '../../../../../@components/grid/KendoTable';
import { GridColumn } from '@progress/kendo-react-grid';
import { structurePackagesAPI } from '../../../../../services/enterprise-structure';
import useOvertime from './useOvertime';
import { columnProps } from '../../../../../../App/components/GridCustomProps';
import ButtonConfig from './ButtonConfig';
import ButtonEdit from './ButtonEdit';
import { CustomTooltip } from '../../../../../../App/components/Templates/cells';
import FormInput from '../../../../../@components/form/Field';
import { structureSteps, structureLabel } from '../../../../../common/StructureUtilities';
import { showConfirmNotification } from '../../../../../../store/actions';
import { resolveError } from '../../../../../common/resolve-error';
import { statusLabel } from '../../../../../../App/components/StatusColumn';
import { usePackages } from '../../../usePackages';
import { ServerAPI } from '../../../../../services/ServerAPI';

const KEYS = {
  'pay_holidays': 'pay_holidays',
  'pay_sunday_bonus': 'pay_sunday_bonus',
  'double_multiple': 'pay_double_extra_time',
  'triple_multiple': 'pay_triple_extra_time',
  'multiple_double_shift': 'pay_breaks_double_shift',
  'multiple_rest_worked': 'pay_breaks_worked',
  'multiple_time_for_time': 'pay_time_for_time',
  'time_for_time_cancel': 'time_for_time_cancel',
}

const results = {
  dropdown: (data, value) => {
    const result = data.find(f => f.id === value)
    return result?.key ? `${result?.key} - ${result?.description}` : result?.name
  },
  numeric: (dataItem, field) => dataItem?.settings?.overtime_config[field],
  switch: (dataItem, field) => {
    const value = dataItem?.settings?.overtime_config[field]
    return statusLabel(value)

  },
}

const MODE = [
  {
    id: 0,
    name: "Manual"
  },
  {
    id: 2,
    name: "Automático"
  }
]

const MODE_1 = [
  {
    id: 1,
    name: "Por Checada"
  },
  {
    id: 2,
    name: "Horario"
  }
]

const MODE_2 = [
  {
    id: 0,
    name: "No Calcula"
  },
  {
    id: 1,
    name: "Bonus"
  },
  {
    id: 2,
    name: "Dinero"
  },
  {
    id: 3,
    name: "Tiempo por tiempo"
  },
  {
    id: 4,
    name: "Libre"
  }
]

const MODE_3 = [
  {
    id: 0,
    name: "Solicitud Manual"
  },
  {
    id: 1,
    name: "Solicitud Automatica"
  },
  {
    id: 2,
    name: "Autorización Automatica"
  },
]

function Overtime() {
  const { selectPackage, tenant } = usePackages()
  const locked = selectPackage?.value
  const providerValues = useOvertime()
  const {
    filters,
    setFilters,
    refreshCounter,
    edit,
    refresh,
    control,
    config,
    setValue
  } = providerValues
  const dispatch = useDispatch();

  const configuration = useSelector((state) => state.configuration.steps) ?? [];
  const employer_registration = configuration.find(f => f.step === 'employer_registration');
  const steps = employer_registration ? [...structureSteps(configuration, ['employer_registration']), employer_registration] : [...structureSteps(configuration)];
  const [concepts, setConcepts] = useState([])
  useEffect(() => {
    getConcepts()
    // eslint-disable-next-line
  }, [])

  async function getConcepts() {
    try {
      const result = await ServerAPI('concepts',false).get({ tree: "", tenant })
      setConcepts(result)
    } catch (error) {
      resolveError(error)
    }
  }

  function checkLaborLaw(value, field, name) {
    if (field !== "pay_double_extra_time" && field !== "pay_triple_extra_time") return
    if (field === "pay_double_extra_time" && value <= 1) {
      dispatch(
        showConfirmNotification({
          maxWidth: 'sm',
          message: 'La configuración que esta realizando no esta establecida en la Ley Federal del Trabajo Articulo 68',
          description: '¿Desea continuar?',
          onCancel: () => setValue(name, 2),
        })
      );
    } else if (value <= 2) {
      dispatch(
        showConfirmNotification({
          maxWidth: 'sm',
          message: 'La configuración que esta realizando no esta establecida en la Ley Federal del Trabajo Articulo 68',
          description: '¿Desea continuar?',
          onCancel: () => setValue(name, 3),
        })
      );
    }
  }

  function HeaderTitle({ title }) {
    const value = title.split('|')
    return <CustomTooltip title={value[1]}>
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: '700' }}>
        {value[0]}
      </div>
    </CustomTooltip>
  }
  
  
  function HeaderCellRender({ field, type, data, format_input, disabled = false }) {
    if (!edit) return <div style={{ height: '35px' }}></div>
    if (!config.length) {
      return <div></div>
    }
    const result = config.find(f => f.key === KEYS[field]);
    let max_value, min_value
    if (result) {
      max_value = result.max_value
      min_value = result.min_value
    }
    const typeProps = {
      dropdown: {
        noDefault: true,
        dataItemKey: "id",
        valueField: "id"
      }
    }
    const props = typeProps[type]
    return <div>
      <FormInput
        className='numeric_input'
        name={`all.${field}`}
        fieldInput={type}
        data={data}
        format={format_input}
        {...props}
        min={parseInt(min_value)}
        max={parseInt(max_value)}
        control={control}
        disabled={disabled}
        onChange={(e) => {
          if (field === "double_mode") {
            changeModeChill(`all.triple_mode`, e.value)
          }
          checkLaborLaw(e.target.value, field, `all.${field}`)
          return e
        }}
      />
    </div>
  }

  function changeModeChill(name, value) {
    setValue(name, value)
  }

  function CellInput({ dataItem, field, type, data, format_input, disabled = false }) {
    if (!config.length) {
      return <td></td>
    }
    const value = dataItem?.settings?.overtime_config[field]
    const result = config.find(f => f.key === KEYS[field]);
    let max_value, min_value
    if (result) {
      max_value = result.max_value
      min_value = result.min_value
    }
    if (edit) {
      const typeProps = {
        dropdown: {
          textField:"name",
          noDefault: true,
          dataItemKey: "id",
          valueField: "id"
        }
      }
      const props = typeProps[type]
      return <td>
        <FormInput
          className='numeric_input'
          name={`data.${field}_${dataItem.settings.overtime_config.id}`}
          fieldInput={type}
          {...props}
          data={data}
          value={value}
          format={format_input}
          disabled={disabled}
          min={parseInt(min_value)}
          max={parseInt(max_value)}
          control={control}
          onChange={(e) => {
            if (field === "double_mode") {
              changeModeChill(`data.triple_mode_${dataItem.settings.overtime_config.id}`, e.value)
            }
            checkLaborLaw(e.target.value, field, `data.${field}_${dataItem.settings.overtime_config.id}`)
            return e
          }}
        />
      </td>
    } else {
      const title = type === "dropdown" ?
      results.dropdown(data, dataItem?.settings?.overtime_config[field]) :
      results[type](dataItem, field)
      return <CustomTooltip title={title}>
      <td className={type === "dropdown" ? "text-left" : "text-right"}>
        {title}
      </td>
    </CustomTooltip>
    }
  }

  return (
    <div>
      <ButtonConfig refresh={refresh} locked={locked} />
      <KendoTable
        style={{
          maxHeight: "calc(100vh - 216px)",
        }}
        pageSizes={[20,30,40,50]}
        Api={structurePackagesAPI}
        extraOptions={{
          include: "enterprise_structure.*,employer_registration,settings",
          tenant
        }}
        filters={filters}
        setFilters={setFilters}
        refreshCounter={refreshCounter}
        onRefresh={refresh}
        is_active={false}
        actions={false}
      >
        {steps.map((item, index) => (
          <GridColumn
            key={index}
            width={200}
            {...columnProps(item?.step === "employer_registration" ? item?.step : `enterprise_structure.${[item?.step]}`, filters, [(item?.step !== "employer_registration" ? "keyName" : "keyNameRfc"), item?.step])}
            format={item?.step === "employer_registration" ? "withRFC" : ""}
            title={structureLabel(item)}
          />
        ))}
        <GridColumn title="General|General" headerCell={HeaderTitle}>
          <GridColumn title="Calcular Turno|Calcular Turno" headerCell={HeaderTitle}>
            <GridColumn width={140} field="day_calculation"
              headerCell={(kProps) => <HeaderCellRender format_input={'id|name'} data={MODE_1} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput format_input={'id|name'} data={MODE_1} type="dropdown" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Pago Implicita|Pago Implicita" headerCell={HeaderTitle}>
            <GridColumn width={140} field="pay_implicit"
              headerCell={(kProps) => <HeaderCellRender format_input={'id|name'} data={MODE_2} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput format_input={'id|name'} data={MODE_2} type="dropdown" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Pago con tipo Nomina|Pago con tipo Nomina" headerCell={HeaderTitle}>
            <GridColumn width={90} field="payroll_type_pay"
              headerCell={(kProps) => <HeaderCellRender type="switch" {...kProps} />}
              cell={(kProps) => <CellInput type="switch" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Modo de solicitud|Modo de solicitud" headerCell={HeaderTitle}>
            <GridColumn width={140} field="request_mode"
              headerCell={(kProps) => <HeaderCellRender format_input={'id|name'} data={MODE_3} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput format_input={'id|name'} data={MODE_3} type="dropdown" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Fest. Trab|Múltiplo para pagar festivo trabajado" headerCell={HeaderTitle}>
            <GridColumn width={90} field="pay_holidays"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Pri. Dom|Múltiplo para pagar prima dominical" headerCell={HeaderTitle}>
            <GridColumn width={90} field="pay_sunday_bonus"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
        </GridColumn>

        <GridColumn title="Horarios|Horarios" headerCell={HeaderTitle}>
          <GridColumn title="Lun-Vier|Horas de trabajo de Lun-Vier" headerCell={HeaderTitle}>
            <GridColumn width={90} field="week_journey_hours"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Sab-Dom|Horas de trabajo de Sab-Dom" headerCell={HeaderTitle}>
            <GridColumn width={90} field="weekend_journey_hours"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
        </GridColumn>

        <GridColumn title="Horas dobles|Horas dobles" headerCell={HeaderTitle}>
          <GridColumn title="Múltiplo|Múltiplo" headerCell={HeaderTitle}>
            <GridColumn width={90} field="double_multiple"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Modo|Modo" headerCell={HeaderTitle}>
            <GridColumn width={140} field="double_mode"
              headerCell={(kProps) => <HeaderCellRender format_input={'id|name'} data={MODE} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput format_input={'id|name'} data={MODE} type="dropdown" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Concepto|Concepto" headerCell={HeaderTitle}>
            <GridColumn width={140} field="double_concept"
              headerCell={(kProps) => <HeaderCellRender disabled={true} format_input={'key|description'} data={concepts} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput disabled={true} format_input={'key|description'} data={concepts} type="dropdown"  {...kProps} />} />
          </GridColumn>
        </GridColumn>

        <GridColumn title="Horas triples|Horas triples" headerCell={HeaderTitle}>
          <GridColumn title="Múltiplo|Múltiplo" headerCell={HeaderTitle}>
            <GridColumn width={90} field="triple_multiple"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Modo|Modo" headerCell={HeaderTitle}>
            <GridColumn width={140} field="triple_mode"
              headerCell={(kProps) => <HeaderCellRender format_input={'id|name'} disabled={true} data={MODE} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput format_input={'id|name'} disabled={true} data={MODE} type="dropdown" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Concepto|Concepto" headerCell={HeaderTitle}>
            <GridColumn width={140} field="triple_concept"
              headerCell={(kProps) => <HeaderCellRender disabled={true} format_input={'key|description'} data={concepts} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput disabled={true} format_input={'key|description'} data={concepts} type="dropdown"  {...kProps} />} />
          </GridColumn>
        </GridColumn>

        <GridColumn title="Turno Doble|Turno Doble" headerCell={HeaderTitle}>
          <GridColumn title="Múltiplo|Múltiplo" headerCell={HeaderTitle}>
            <GridColumn width={90} field="multiple_double_shift"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Modo|Modo" headerCell={HeaderTitle}>
            <GridColumn width={140} field="double_shift_mode"
              headerCell={(kProps) => <HeaderCellRender format_input={'id|name'} data={MODE} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput format_input={'id|name'} data={MODE} type="dropdown" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Concepto|Concepto" headerCell={HeaderTitle}>
            <GridColumn width={140} field="double_shift_concept"
              headerCell={(kProps) => <HeaderCellRender disabled={true} format_input={'key|description'} data={concepts} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput disabled={true} format_input={'key|description'} data={concepts} type="dropdown"  {...kProps} />} />
          </GridColumn>
        </GridColumn>

        <GridColumn title="Descanso trabajado|Descanso trabajado" headerCell={HeaderTitle}>
          <GridColumn title="Múltiplo|Múltiplo" headerCell={HeaderTitle}>
            <GridColumn width={90} field="multiple_rest_worked"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Modo|Modo" headerCell={HeaderTitle}>
            <GridColumn width={140} field="rest_worked_mode"
              headerCell={(kProps) => <HeaderCellRender format_input={'id|name'} data={MODE} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput format_input={'id|name'} data={MODE} type="dropdown" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Concepto|Concepto" headerCell={HeaderTitle}>
            <GridColumn width={140} field="rest_worked_concept"
              headerCell={(kProps) => <HeaderCellRender disabled={true} format_input={'key|description'} data={concepts} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput disabled={true} format_input={'key|description'} data={concepts} type="dropdown"  {...kProps} />} />
          </GridColumn>
        </GridColumn>

        <GridColumn title="Tiempo por tiempo|Tiempo por tiempo" headerCell={HeaderTitle}>
          <GridColumn title="Múltiplo|Múltiplo" headerCell={HeaderTitle}>
            <GridColumn width={90} field="multiple_time_for_time"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Modo|Modo" headerCell={HeaderTitle}>
            <GridColumn width={140} field="time_for_time_mode"
              headerCell={(kProps) => <HeaderCellRender format_input={'id|name'} data={MODE} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput format_input={'id|name'} data={MODE} type="dropdown" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Cancelar|Cancelar" headerCell={HeaderTitle}>
            <GridColumn width={90} field="time_for_time_cancel"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Adelantar|Adelantar" headerCell={HeaderTitle}>
            <GridColumn width={90} field="time_for_time_pay_advance"
              headerCell={(kProps) => <HeaderCellRender type="switch" {...kProps} />}
              cell={(kProps) => <CellInput type="switch" {...kProps} />} />
          </GridColumn>
        </GridColumn>

        <GridColumn title="Horas Nocturnas |Horas Nocturnas" headerCell={HeaderTitle}>
          <GridColumn title="Implicita|Implicita" headerCell={HeaderTitle}>
            <GridColumn width={90} field="implicit_night_multiple"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Hrs2|Hrs2" headerCell={HeaderTitle}>
            <GridColumn width={90} field="double_night_multiple"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Hrs3|Hrs3" headerCell={HeaderTitle}>
            <GridColumn width={90} field="triple_night_multiple"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Modo|Modo" headerCell={HeaderTitle}>
            <GridColumn width={140} field="night_mode"
              headerCell={(kProps) => <HeaderCellRender format_input={'id|name'} data={MODE} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput format_input={'id|name'} data={MODE} type="dropdown" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Concepto|Concepto" headerCell={HeaderTitle}>
            <GridColumn width={140} field="night_concept"
              headerCell={(kProps) => <HeaderCellRender format_input={'key|description'} data={concepts} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput format_input={'key|description'} data={concepts} type="dropdown"  {...kProps} />} />
          </GridColumn>
        </GridColumn>

        <GridColumn title="Horario Mixto |Horario Mixto" headerCell={HeaderTitle}>
          <GridColumn title="Implicita|Implicita" headerCell={HeaderTitle}>
            <GridColumn width={90} field="mix_implicit"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Múltiplo|Múltiplo" headerCell={HeaderTitle}>
            <GridColumn width={90} field="multiple_mix"
              headerCell={(kProps) => <HeaderCellRender type="numeric" {...kProps} />}
              cell={(kProps) => <CellInput type="numeric" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Modo|Modo" headerCell={HeaderTitle}>
            <GridColumn width={140} field="mix_mode"
              headerCell={(kProps) => <HeaderCellRender format_input={'id|name'} data={MODE} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput format_input={'id|name'} data={MODE} type="dropdown" {...kProps} />} />
          </GridColumn>
          <GridColumn title="Pagar|Pagar" headerCell={HeaderTitle}>
            <GridColumn width={140} field="mix_concept"
              headerCell={(kProps) => <HeaderCellRender format_input={'key|description'} disabled={true} data={concepts} type="dropdown" {...kProps} />}
              cell={(kProps) => <CellInput format_input={'key|description'} disabled={true} data={concepts} type="dropdown"  {...kProps} />} />
          </GridColumn>
        </GridColumn>
      </KendoTable>
      <div style={{marginTop: "33px"}}>
       <ButtonEdit {...providerValues} locked={locked} />
      </div>
    </div>
  );
}

export default Overtime;
