import i18next from 'i18next';
import { each } from 'lodash';
import { contractsAPI } from "../../services/contract";
import structureFilters, { getStructureFields } from '../filter/structureFilters';
// import { orderTypes } from './constants';

const { t } = i18next;


const changeCallback = (advancedFilters, index, structure, onChange) => {
	const afterFields = getStructureFields(index, structure, "after");
	each(afterFields, step => {
		if (advancedFilters[step]) {
			delete advancedFilters[step];
		}
	});
	changeFilterFields(structure, advancedFilters, onChange);
}

const basicFields = (useActive, cloneConfig) => {

	
	const basic = [
		["key", "key", "input"],
		["first_surname", "first-surname", "input", { validationRules: { valueFormat: "alpha" } }],
		["second_surname", "second-surname", "input", { validationRules: { valueFormat: "alpha" } }],
		["name", "name", "input", { validationRules: { valueFormat: "alpha" } }],
		["active", t("valid"), "dropdown", [[{key: true, label: t('valid')}, {key: false, label: t('not-valid')}], "key", "label"]],
	]

	return {
		// "sort-title": [
		// 	["ordering", "sort-by", "dropdown", [orderTypes(), "value", "name"], { noDefault: true }],
		// ],
		"basic-filters": useActive ? basic : basic.slice(0, -1),
		"complementary-filters": cloneConfig ? [
			["rfc", "rfc", "input"],
			["curp", "curp", "input"],
			["nss", "nss", "input", { validationRules: { valueFormat: "onlyNumeric" } }],
			["is_duplicated", "is-duplicated", "switch"],
		]:[
			["rfc", "rfc", "input"],
			["curp", "curp", "input"],
			["nss", "nss", "input", { validationRules: { valueFormat: "onlyNumeric" } }],
		],
		[`${t("filter-by")} ${t("contract")}`]: [
			["contract", "", "dateRange", {
				isRequired: false,
				startProps: { label: t("from") },
				endProps: { label: t("until") },
			}],
			["contract_type", "contract-type", "serverdropdown",
				[contractsAPI.get, "id", "name"],
				{
					searchBy: "search",
					filterEfields: "key|name",
					filterSplit: "-",
					format: "key|name",
					fields: "id,key,name",
				}],
		],
		[`${t("filter-by")} ${t("date-discharge")}`]: [
			["unsubscribe", "", "dateRange", {
				isRequired: false,
				startProps: { label: t("from") },
				endProps: { label: t("until") }
			}],

		],
	}

}

const changeFilterFields = (structure, values, onChange, cloneConfig) => {

	const handleChange = (advancedFilters, index) => changeCallback(advancedFilters, index, structure, onChange);

	const fields = {
		...basicFields(true, cloneConfig),
		[`${t("filter-by")} ${t("structure-enterprise")}`]: structureFilters(structure, values, handleChange, false),
	};

	onChange(fields);
};

export const changeFilterFields2 = (structure, values, onChange, cloneConfig) => {

	const handleChange = (advancedFilters, index) => changeCallback(advancedFilters, index, structure, onChange);

	const fields = {
		...basicFields(false, cloneConfig),
		[`${t("filter-by")} ${t("structure-enterprise")}`]: structureFilters(structure, values, handleChange, true),
	};

	onChange(fields);
};

export default changeFilterFields;