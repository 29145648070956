import PropTypes from "prop-types"
import React from 'react'
import "./styles.scss";
import { useTranslation } from 'react-i18next'
import { useWatch, Controller } from 'react-hook-form'
import { FloatingLabel } from "@progress/kendo-react-labels";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import { MaskedTexForTimeInput } from '../../../MOPERSByWorker/components/container/AssistSetting/CheckForm';
import FormInput from '../../../../@components/form/Field';
import { backDateFormat } from '../../../../../App/components/Dates/utilities';
import { buildTitle, searchLabel } from '../../util';
import { CustomTooltip } from '../../../../../App/components/Templates/cells';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons'

const TITLE = {
  maxWidth: "370px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}

const CONTAINER = {
  display: "flex",
  alignItems: "center",
  gap: "3px"
}

function Item({ m, i, edit, control, handleChange }) {
  const { t } = useTranslation()
  const value = useWatch({ control, name: `data[${i}].value` })

  let valueResult
  if (m.datatype === 'dropdown') {
    valueResult = m.configuration?.options.find((f) => f.id === value)?.name
  } else if (m.datatype === 'catalog') {
    if (m.configuration.config_type === 'dropdown') {
      valueResult = m.configuration?.options?.find((f) => f.id === value)?.name
    } else {
      valueResult = value.map(valorX => {
        return m.configuration?.options.find(objetoY => objetoY.id === valorX).name;
      }).toString()
    }
  } else if (m.datatype === 'textfield') {
    m.datatype = 'textarea'
  }

  return (
    <div style={CONTAINER} className='mb-2'>
      <CustomTooltip title={t(buildTitle(m).toUpperCase())}>
        <div style={TITLE} className={`${searchLabel(m.alias) ? '':'capitalize-first-letter'}`}>
          {t(buildTitle(m).toUpperCase())}
        </div>
      </CustomTooltip>
      <div style={{ width: m.datatype === "switch" ? "auto" : "35%" }}>
        <CustomTooltip disableInteractive={false} title={!edit ? valueResult || '' : ''}>
          <span className={edit ? '' : 'input-focus'}>
            <GetComponent m={m} control={control} edit={edit} i={i} handleChange={handleChange}/>
          </span>
        </CustomTooltip>
      </div>
      <div>
        <CustomTooltip disableInteractive={false} title={<div>{m.description}</div>}>
          <FontAwesomeIcon
            icon={faCircleQuestion}
            size='lg'
            className='icon-helper'
            style={{ color: '#004F82', marginTop: '5px' }}
          />
        </CustomTooltip>
      </div>
    </div>
  )
}

Item.propTypes = {
  control: PropTypes.any,
  edit: PropTypes.any,
  handleChange: PropTypes.func,
  i: PropTypes.any,
  m: PropTypes.any
}

export default Item

function addPropDynamic() {

  return {
    valueFormat: backDateFormat,
    outFormat: backDateFormat,
    textField: 'name',
    dataItemKey: 'id',
    valueField: 'id',
    keyField: 'id',
    valuesByKeyField: true,
    noDefault: true
  }
}


function GetComponent({m, control, edit , i, handleChange}) {
  if (m.datatype === "time") {
    return <Controller
      name={`data[${i}].value`}
      control={control}
      render={(props) =>
        <FloatingLabel
          editorId="configuration.default"
          editorValue={true}
          required={true}
        >
          <TimePicker
            id="configuration.default"
            format="HH:mm"
            disabled={!m.editable || !edit}
            value={props.field.value}
            onChange={props.field.onChange}
            formatPlaceholder={{ mask: 'HH:mm' }}
            dateInput={MaskedTexForTimeInput}
          />
        </FloatingLabel>
      }
    />
  } else {
    return <FormInput
      fieldInput={m.datatype !== 'catalog' ? m.datatype : m.configuration.config_type}
      control={control}
      name={`data[${i}].value`}
      options={m.configuration?.options}
      data={m.configuration?.options}
      {...((m.datatype !== 'textarea' && m.datatype !== 'numeric') ? addPropDynamic() : {})}
      onChange={(e) => handleChange(e, m)}
      disabled={!m.editable || !edit}
      rows={m.datatype === 'textarea' ? m.value.length <= 16 ? 1 : 4 : 1}
      max={m.configuration?.max_value +1}
      min={m.configuration?.min_value - 1}
      format={m.datatype === 'numeric' ? `n${m.configuration?.decimals}` : 'name'}
    />
  }
}

GetComponent.propTypes = {
  control: PropTypes.any,
  edit: PropTypes.any,
  handleChange: PropTypes.func,
  i: PropTypes.any,
  m: PropTypes.any
}
