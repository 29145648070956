import React, { useEffect, useState } from 'react';
//--idioma
import { useTranslation } from 'react-i18next';
//--react-bootstrap
import Form from 'react-bootstrap/Form';
import Grid from '@mui/material/Grid';
// -- Componentes
import Dialog from '../../../core/styled/Dialog';
import DialogTitle from '../../../core/styled/DialogTitle';
import DialogContent from '../../../core/styled/DialogContent';
import DialogActions from '../../../core/styled/DialogActions';
import Button from '../../../core/styled/Button';
import Fields from './Components/Fields/Fields';

import {downloadFactory, fieldsColumnsToString, fieldsToString} from "../../../core/common/export-catalog";
import { parseRequest } from '../../../core/common/parse-request';

const ExportDialog = (props) => {

  const {
    open,
    handleClose,
    currentTenant,
    fieldSelection,
    setFieldSelection,
    filters,
    page,
    moduleFactory
  } = props;

  const { t } = useTranslation();
  const [firstState, setFirstState] = useState([])
  const type = 'review';
  const [flag, setFlag] = React.useState(true);
  const [checkedAll, setCheckedAll] = useState(true);
  const hasColumns = Array.isArray(fieldSelection.at(0))

  useEffect(() => {
    setFirstState(JSON.parse(JSON.stringify(fieldSelection)))
  }, [])

  /**
   * se busca en las columnas si algun campo esta marcado
   *
   * @param {Array} columns
   *
   * @returns {boolean}
   */
  const findInColumns = (columns) => {
    let flagValue = false;

    columns.forEach(column => {
      const hasSelected = column.find(field => field.isSelected)

      if (hasSelected) {
        flagValue = true;
      }
    })

    return flagValue;
  }

  const findInOneColumn = (column) => {
    return column.some(e => e.isSelected)
  }

  const checkSelect = () => {
    let flagValue = hasColumns
      ? findInColumns(fieldSelection)
      : findInOneColumn(fieldSelection);

    setFlag(flagValue);

    return flagValue;
  }

  const handleExport = () => {

    let params = {};
    let data = {};

    if (checkSelect() === false) {
      setFlag(false);
      return;
    }

    if (type === 'import') {
      data = {
        tenant: currentTenant,
        values: fieldsToString(fieldSelection),
        tree: "",
        export_type: "backup"
      }
    } else if (type === 'example') {
      data = {
        tenant: currentTenant,
        values: fieldsToString(fieldSelection),
        tree: "",
        export_type: "empty"
      }
    } else {
      data = {
        tenant: currentTenant,
        values: hasColumns
          ? fieldsColumnsToString(fieldSelection)
          : fieldsToString(fieldSelection),
        tree: "",
        export_type: "detail"
      }
    }

    params = parseRequest(data, filters, page);

    downloadFactory(moduleFactory, params);

    setFieldSelection(firstState)
    handleClose();
  }

  function close() {
    setFieldSelection(firstState)
    handleClose();
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={open}
        aria-labelledby="export-dialog"
      >
        <DialogTitle
          title={t('export')}
          onClose={close}
        />
        <Form>
          <DialogContent dividers>
            <Grid
              container
              spacing={1}
              direction="column"
              justifyContent="center"
              alignItems="stretch"
            >
              <Grid item>
                <Fields
                  fieldSelection={fieldSelection}
                  setFieldSelection={setFieldSelection}
                  checkedAll={checkedAll}
                  setCheckedAll={setCheckedAll}
                  type={type}
                />
                {
                  flag === false
                    ?
                    <div className="float-left mt-1">
                      {t("filter-export-atleast-one")}
                    </div>
                    : ""
                }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              design="text"
              type="button"
              onClick={close}
            >
              {t('cancel')}
            </Button>
            <Button
              design="contained"
              onClick={handleExport}
            >
              {t('export')}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}

export default ExportDialog;