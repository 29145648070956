import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { TimePaymentContext } from "../../contexts/TimePaymentContext";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import FormInput from "../../../../../../../@components/form/Field";
import { useWatch } from "react-hook-form";
import { MODAL_KEY } from "../../hooks/useTimePayment";
import { currentTenant, currentUserInfo } from "../../../../../../../common/validate-tenant";
import { resolveError } from "../../../../../../../common/resolve-error";
import { mopersComments, overtimeMopersAPI } from "../../../../../../../services/mopers";
import { CommonActions } from "./CustomModal";
import { Avatar, GenericLabel, Loader, useCanViewSalary } from "../../../../../../SignaturesByWorker/components/Shared";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { workerFullName } from "../../../../../../../@components/employees/constants";
import { formatMoney } from "../../../../../../GraphicSignatures/utilities/utils";
import { toTitleCase } from '../../../../../../../@components/navigation/components/userSection/utilities';
import { useRequestLoad } from "../../hooks/useResolveIncidence";
import SearchWorker from "../../../../../../../@components/employees/PersonalInformation/Search";
import { debounce, isString } from "lodash";
import { Card, Collapse } from "@mui/material";
import { getStructure, keyName } from "../../../../../../SignaturesByWorker/utils/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-regular-svg-icons";
import { useHasTxT } from "../TxTGate";
import FormDialog from "../../../../../../../../App/components/dialog/FormDialog";
import DialogContent from "../../../../../../../styled/DialogContent";
import IconUse from "../../../../../../../common/IconUse";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { reversDate } from '../../../../../../../../App/components/Select/PayrollPeriod/itemUtilities';
import SubTitle from "../../../../../../../@components/layout/Subtitle";
import { uid } from "uid";
import { workersApi } from "../../../../../../../services/worker";
import { buildSummary } from "../Cards/Overtime";
import { useFromMopers } from "../../hooks/useModals";
import "../../../../../styles/style.scss";

const getHour = minutes => (minutes ?? 0) / 60;

export const titleStyles = {
    marginLeft: '0 !important',
    fontSize: '14px !important',
    fontWeight: '500 !important'
};

function TimePayment() {
    const { t } = useTranslation();

    const userPerms = currentUserInfo()?.user_info?.moper_attendance?.filter(el => el.has_perm)?.map(el => el.description);
    const canReqMoney = userPerms?.includes(7);
    const canReqTxt = userPerms?.includes(8);

    const {
        control,
        worker,
        setError,
        clearErrors,
        handleSubmit,
        handleClose,
        trigger,
        setValue,
        selected,
        onSubmit,
        open,
    } = useContext(TimePaymentContext);

    const balance = useWatch({ control, name: 'balance' });
    const moneyTime = useWatch({ control, name: 'money_request_time' });
    const txtTime = useWatch({ control, name: 'txt_request_time' });
    const balanceHours = getHour(balance);
    const remaining = balanceHours - (moneyTime + txtTime);

    const diffTxt = balanceHours - moneyTime;
    const diffMoney = balanceHours - txtTime;

    const showTxT = useHasTxT(worker);

    return (
        <FormDialog
            open={open}
            style={{
                width: '700px',
                margin: 'auto',
                transition: 'all 300ms'
            }}
            title={t('Horas extra')}
            className='minimalist-dialog-design'
            innerSubtitle={'Solicite el pago de horas extras para sus colaboradores: elija entre dinero o TxT'}
            handleClose={() => handleClose(MODAL_KEY)}
            headerDivider
        >
            <DialogContent>
                <Card className="time-payment-dialog-container">
                    <Grid container spacing={2}>
                        <MultiplesConfig item={selected?.overtime ?? selected} />
                        <GeneralInfo worker={worker} selected={selected} remaining={remaining} />
                        <SummarySection item={selected} worker={worker} />
                        <SubTitle title={'Tipo de pago'} blueTitle={false} titleStyle={titleStyles}>
                            <Grid item container spacing={1} paddingTop={'10px'}>
                                <Grid item xs={6}>
                                    <FormInput
                                        fieldInput="Numeric"
                                        control={control}
                                        name="money_request_time"
                                        triggerChange={() => trigger('txt_request_time')}
                                        selectOnClick
                                        label={t('Horas en dinero')}
                                        max={diffMoney}
                                        disabled={!canReqMoney}
                                        min={0}
                                        format={"n0"}
                                    />
                                </Grid>
                                {showTxT &&
                                    <Grid item xs={6}>
                                        <FormInput
                                            fieldInput="Numeric"
                                            control={control}
                                            disabled={!canReqTxt}
                                            name="txt_request_time"
                                            selectOnClick
                                            label={t('Horas en tiempo')}
                                            triggerChange={() => trigger('money_request_time')}
                                            max={diffTxt}
                                            min={0}
                                            format={"n0"}
                                        />
                                    </Grid>}
                            </Grid>
                        </SubTitle>
                        <DynamicReason
                            control={control}
                            setValue={setValue}
                            worker={worker}
                            selected={selected}
                            setError={setError}
                            clearErrors={clearErrors}
                        />
                        <Grid item container spacing={1} xs={12}>
                            <TotalsRequest
                                open={open}
                                worker={worker}
                                control={control}
                                selected={selected}
                            />
                        </Grid>
                    </Grid>
                </Card>
            </DialogContent>
            <CommonActions onCancel={() => handleClose(MODAL_KEY)} onSubmit={handleSubmit(onSubmit)} />
        </FormDialog>
    );
};

export default TimePayment;

export const SolidDivider = ({ className = '', vertical = false }) => <hr className={`solid-divider ${className} ${vertical ? 'vertical' : ''}`} />

export const GeneralInfo = ({ worker, selected, remaining }) => {
    const { t } = useTranslation();

    return (
        <Grid item container spacing={2} xs={12}>
            <Grid item xs={12}>
                <GenericLabel
                    label={t('worker')}
                    value={workerFullName({ ...worker, include_key: true })}
                    fontSize="14px"
                />
            </Grid>
            <Grid item xs={6}>
                <GenericLabel
                    label={t('origin-day')}
                    value={reversDate(selected?.origin_day)}
                    fontSize="14px"
                />
            </Grid>
            <Grid item xs={6}>
                <GenericLabel
                    className={`hours ${remaining === 0 ? 'red' : ''}`}
                    label={'Horas disponibles para solicitar'}
                    value={`${remaining} horas`}
                    fontSize="14px"
                />
            </Grid>
        </Grid>
    );
}

export const DynamicReason = ({
    control,
    setValue,
    worker,
    selected,
    setError,
    clearErrors,
}) => {

    return (
        <SubTitle title={'Motivo'} blueTitle={false} titleStyle={titleStyles}>
            <Grid item container paddingTop={'10px'}>
                <FormInput
                    fieldInput="Radiogroup"
                    name="cover_reason"
                    control={control}
                    valueField="id"
                    textField="label"
                    row={false}
                    gap={10}
                    onChange={(e) => {
                        if (e.value === 2) {
                            setValue('worker', null);
                            setValue('worker_sel', null);
                        } else {
                            setValue('comment', '');
                        }
                        clearErrors();
                        return e;
                    }}
                    isNumericValue
                    highlight
                    outlined
                    modernDesign
                    options={[
                        {
                            id: 1,
                            label: 'Cubriendo a un colaborador',
                            desc: 'Elija esta opción si las horas extras se realizaron para cubrir el turno de un colaborador ausente.',
                            bottomRender: <SelectWorker control={control} setValue={setValue} worker={worker} selected={selected} clearErrors={clearErrors} setError={setError} />
                        },
                        {
                            id: 2,
                            label: 'Otro',
                            desc: 'Seleccione esta opción si las horas extras se deben a un motivo diferente al de cubrir a un colaborador. Deberá especificar el motivo en la caja de comentarios.',
                            bottomRender: <CommentInput control={control} />
                        }
                    ]}
                />
            </Grid>
        </SubTitle>
    );
}

export const CommentInput = ({
    control,
}) => {
    const { t } = useTranslation();
    const [comments, setComments] = useState([]);

    const reason = useWatch({ control, name: 'cover_reason' });

    useEffect(() => {
        getCommentOptions();
    }, [open]);

    async function getCommentOptions() {
        const request = {
            tenant: currentTenant(),
            tree: true,
            type_comment: 1,
        };
        try {
            const response = await mopersComments.get(request);
            const justComment = response.map(el => el?.comment);
            setComments(justComment);
        } catch (error) {
            resolveError(error);
        }
    }

    return (
        <FormInput
            control={control}
            name="comment"
            fieldInput="customselect"
            freeSolo
            label={t("Comentario")}
            options={comments}
            isRequired={true}
            disableClearable
            disabled={reason !== 2}
            inputProps={{ maxLength: 251 }}
            onInputChange={(e, _, onChange) => {
                if (isString(e.value)) {
                    onChange(e);
                }
            }}
        />
    );
};

const SelectWorker = ({ control, setValue, worker, selected, clearErrors, setError }) => {
    const { fetch } = useRequestLoad();

    const reason = useWatch({ control, name: 'cover_reason' });
    const selectedWorker = useWatch({ control, name: 'worker_sel' });

    const handleSelect = e => {
        setValue('worker_sel', e?.key);
        setValue('worker', e?.id);
        consultWorker(e?.id);
    };

    const consultWorker = id => {
        fetch({
            api: overtimeMopersAPI.verifyCoverWorker({
                worker: worker?.id,
                cover_worker: id,
                origin_day: selected?.origin_day,
            }),
            callback: () => clearErrors('worker_sel'),
            onFailed: err => setError('worker_sel', { type: "custom", message: err?.response?.data?.detail }),
        });
    }

    const onEnter = useRef(debounce(e => {
        const key = e.target.value;
        fetch({
            api: workersApi.get({
                tree: true,
                worker: key,
                fields: 'id,key',
                ...useFromMopers()
            }),
            callback: ([w] = []) => handleSelect(w)
        });
    }, 400)).current;

    return (
        <FormInput
            control={control}
            name="worker_sel"
            fieldInput="WorkerSearch"
            disabled={reason !== 1}
            inputProps={{ maxLength: 11 }}
            handleSelect={handleSelect}
            selected={selectedWorker}
            onKeyPress={onEnter}
            fromMopers
            isRequired
            selectBy=""
        />
    );
}

export const DynamicRequestTime = ({
    control,
    hours,
}) => {
    const config = false;
    const balance = Math.floor(((hours ?? 0) / 60));
    const data = [...Array(balance).keys()].map(el => ({ value: el + 1, name: `${el + 1} horas` }));

    return (
        <>
            {
                config ?
                    <FormInput
                        fieldInput="numeric"
                        label="Tiempo a cancelar"
                        isRequired={true}
                        control={control}
                        name="minutes_canceled"
                    />
                    :
                    <FormInput
                        fieldInput="dropdown"
                        control={control}
                        label="Tiempo a cancelar"
                        isRequired={true}
                        name="minutes_canceled"
                        data={data}
                        valueField="value"
                        textField="name"
                        noDefault
                    />
            }
        </>
    );
};

export const SummarySection = ({ item, worker }) => {

    const data = buildSummary(item, worker);

    return (
        <SubTitle title={'Resumen'} blueTitle={false} titleStyle={titleStyles}>
            <Grid item container spacing={1} xs={12}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    {data.map(el =>
                        <GenericLabel
                            key={`ov-sum-${el.label}`}
                            label={el.label}
                            value={el.value}
                            labelIcon={el.icon}
                            fontSize="14px"
                            disableTooltip
                        />)}
                </Grid>
            </Grid>
        </SubTitle>
    );
}

const WorkerSelection = ({
    setValue,
    selected,
}) => {
    const [selectedWorker, setSelectedWorker] = useState(null);

    const onWorker = e => {
        setSelectedWorker(e);
        setValue('worker', e?.id);
    }

    return (
        <>
            <SearchWorker setWorker={onWorker} />
            <WorkerData
                setValue={setValue}
                selected={selectedWorker}
                dayItem={selected}
            />
        </>
    );
};

const WorkerData = ({ selected, dayItem, setValue }) => {
    const { t } = useTranslation();
    const { loading, fetch } = useRequestLoad();
    const fullName = workerFullName(selected ?? {});
    const workerStructure = getStructure(selected ?? {});

    const [isValid, setIsValid] = useState(true);

    const iconRender = isValid ? faCircleCheck : faCircleXmark;
    const labelRender = isValid ? 'Colaborador apto para cubrir' : 'El colaborador no es apto para cubrir';

    useEffect(() => {
        consultWorker();
    }, [selected?.id])

    const consultWorker = () => {
        if (!selected) return;
        fetch({
            api: overtimeMopersAPI.verifyCoverWorker({
                worker: selected.id,
                origin_day: dayItem?.origin_day
            }),
            callback: handleResponse,
            onFailed: (err) => handleResponse(err, true),
        });
    }

    const handleResponse = (response, isError) => {
        if (response?.response?.status !== 400 && isError) return;
        setValue('worker', !isError ? selected.id : null, { shouldValidate: true });
        setIsValid(!isError);
    }

    return (
        <Collapse in={!!selected}>
            <div className={`ov-cover-worker-data`}>
                <Avatar
                    className={`sbw-details-avatar`}
                    fontSize={'20px'}
                    width="60px"
                    border={false}
                    worker={selected}
                    withTooltip={false}
                />
                <div className="ov-cover-worker-infowrap">
                    <span className="ov-cover-worker-name">
                        {toTitleCase(fullName)}
                    </span>
                    <GenericLabel
                        label={t('area')}
                        value={keyName(workerStructure?.area)}
                    />
                    {!loading ?
                        <span className={`ov-cover-worker-status ${isValid ? 'valid' : 'invalid'}`}>
                            <FontAwesomeIcon icon={iconRender} />
                            {labelRender}
                        </span>
                        :
                        null}
                </div>
                {loading && <Loader size={25} />}
            </div>
        </Collapse>
    );
}

export const MultiplesConfig = ({ item }) => {
    const triple_multiple = item?.settings?.triple_multiple;
    const double_multiple = item?.settings?.double_multiple;

    return (
        <>
            <Grid item xs={6}>
                <InfoTag label={`Multiplo de horas Dobles: x${double_multiple ?? 0}`} />
            </Grid>
            <Grid item xs={6}>
                <InfoTag label={`Multiplo de horas Triples: x${triple_multiple ?? 0}`} />
            </Grid>
        </>
    );
};

export const RequesInfo = () => {
    const userInfo = useSelector((state) => state?.userConfigs?.user_info);
    const username = toTitleCase((userInfo?.external_name ?? workerFullName(userInfo?.worker)));

    const data = [
        { label: 'Fecha y hora de solicitud', value: <RealTime /> },
        { label: 'Solicita', value: username },
    ];

    return (
        <div className="request-info-wrap">
            <div className="detail-info">
                {data.map(el => <GenericLabel key={el.label} label={el.label} value={el.value} />)}
            </div>
        </div>
    );
};

const TotalsRequest = ({ control, selected, open, worker }) => {
    const { fetch } = useRequestLoad();
    const salariesPerm = useCanViewSalary();
    const money = useWatch({ control, name: 'money_request_time' });
    const txt = useWatch({ control, name: 'txt_request_time' });

    const [calcData, setCalcData] = useState({});

    useEffect(() => {
        if (!salariesPerm || !open || !selected || !worker) return;
        calculate();
    }, [money, worker])

    const calculate = () => {
        fetch({
            api: overtimeMopersAPI.calculate({
                worker: worker?.id,
                origin_day: selected?.origin_day,
                money_hours: money ?? 0,
            }),
            callback: onCalculate
        });
    }

    const onCalculate = (resp) => setCalcData(resp.result);

    const data = [
        { label: 'Montos dobles', value: formatMoney(calcData.double_amount ?? 0.00) },
        { label: 'Montos triples', value: formatMoney(calcData.triple_amount ?? 0.00) },
        (useHasTxT(worker) ? { label: 'Horas tiempo por tiempo', value: txt ?? 0.00 } : {}),
    ].filter(el => el.label);

    return (
        <>
            {data.map(el =>
                <Grid key={el.label ?? uid()} item container className="ov-rq-totals-wrapper">
                    <Grid item xs>
                        <GenericLabel label={el.label} fontSize="14px" />
                    </Grid>
                    <Grid item>
                        <GenericLabel value={el.value} blueValue={false} />
                    </Grid>
                </Grid>
            )}
        </>
    );
}

const getActualTime = () => format(new Date(), "dd/MM/yyyy HH:mm:ss a");

export const RealTime = () => {
    const [time, setTime] = useState(getActualTime());

    useEffect(() => {
        setInterval(updateClock, 1000);
    }, [])

    const updateClock = () => {
        const fDate = getActualTime();
        setTime(fDate)
    }

    return (
        <span className="realtime">
            {time}
        </span>
    );
};

export const InfoTag = ({
    label,
    icon = faCircleInfo,
    style = {}
}) => {

    const isFA = !!icon?.prefix;

    return (
        <div className="ov-info-tag-wrap" style={style}>
            {isFA ? <FontAwesomeIcon icon={icon} /> : <IconUse icon={icon} />}
            <span>{label}</span>
        </div>
    );
}

InfoTag.propTypes = {
    label: PropTypes.string,
    style: PropTypes.object,
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
};

SolidDivider.propTypes = {
    className: PropTypes.string,
    vertical: PropTypes.bool,
};

DynamicRequestTime.propTypes = {
    control: PropTypes.object,
    hours: PropTypes.any,
};

WorkerSelection.propTypes = {
    setValue: PropTypes.func,
    selected: PropTypes.object,
};

MultiplesConfig.propTypes = {
    item: PropTypes.object,
};

WorkerData.propTypes = {
    selected: PropTypes.object,
    dayItem: PropTypes.object,
    setValue: PropTypes.func
};

TotalsRequest.propTypes = {
    open: PropTypes.bool,
    worker: PropTypes.object,
    control: PropTypes.object,
    selected: PropTypes.object
};

GeneralInfo.propTypes = {
    worker: PropTypes.object,
    selected: PropTypes.object,
    remaining: PropTypes.number,
};

SummarySection.propTypes = {
    item: PropTypes.object,
    worker: PropTypes.object,
};

SelectWorker.propTypes = {
    control: PropTypes.object,
    setValue: PropTypes.func,
    worker: PropTypes.object,
    selected: PropTypes.object,
    clearErrors: PropTypes.func,
    setError: PropTypes.func,
};

CommentInput.propTypes = {
    control: PropTypes.object,
};

DynamicReason.propTypes = {
    control: PropTypes.object,
    setValue: PropTypes.func,
    worker: PropTypes.object,
    selected: PropTypes.object,
    setError: PropTypes.func,
    clearErrors: PropTypes.func,
};
