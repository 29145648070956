import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

export const SETTINGS_KEY = 1;
export const CONTRACTS_KEY = 2;
export const TOKEN_KEY = 3;
export const PAYROLL_KEY = 4;

function useModals() {
    const [modalState, setModalState] = useState({ 1: false, 2: false, 3: false, 4: false });
    const [selected, setSelected] = useState({});

    const handleCloseSettings = () => handleGeneralState(SETTINGS_KEY, false);

    const handleCloseContracts = () => handleGeneralState(CONTRACTS_KEY, false);

    const handleCloseToken = () => handleGeneralState(TOKEN_KEY, false);

    const handleClosePayroll = () => handleGeneralState(PAYROLL_KEY, false);

    const handleGeneralState = (key, value, item = {}) => {
        setModalState(prev => ({ ...prev, [key]: value }));
        setSelected(item);
    };

    return {
        openSettings: modalState[SETTINGS_KEY],
        openContracts: modalState[CONTRACTS_KEY],
        openToken: modalState[TOKEN_KEY],
        openPayroll: modalState[PAYROLL_KEY],
        selected,
        handleGeneralState,
        handleCloseContracts,
        handleCloseSettings,
        handleCloseToken,
        handleClosePayroll,
    };
};

const ModalsContext = createContext({});

export const useModalsContext = () => useContext(ModalsContext);

export default function ModalsController({ children }) {
    const modalValues = useModals();

    return (
        <ModalsContext.Provider value={modalValues}>
            {children}
        </ModalsContext.Provider>
    );
};

ModalsController.propTypes = {
    worker: PropTypes.object,
    children: PropTypes.any
};