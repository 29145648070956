import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { genericPaymentRequest, paymentInitValues } from "../utils/utilities";
import { overtimeMopersAPI } from "../../../../../../services/mopers";
import { useDispatch } from "react-redux";
import { lockedWindow, unlockedWindow } from "../../../../../../../store/actions";
import { successCreated } from "../../../../../../common/notification-messages";
import { resolveError } from "../../../../../../common/resolve-error";
import { otValidationSchema } from "../utils/validations";
import { isFunction } from "lodash";
import { showSuccessNotification } from "../../../../../../../App/components/Notifications";

export const MODAL_KEY = 0;

function useTimePayment({
    modalValues,
    handleClose,
    handleRefresh,
    selected,
    worker,
}) {
    const dispatch = useDispatch();
    const [paymentTypes, setPaymentTypes] = useState([]);

    const { value: open } = modalValues[MODAL_KEY];

    const {
        control,
        reset,
        trigger,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(otValidationSchema()),
        defaultValues: paymentInitValues,
        mode: "onChange",
    });

    useEffect(() => {
        if (!open) return;
        getPaymentTypes();
        initializeValues();
    }, [open])

    const initializeValues = () => {
        const initValues = {
            balance: selected?.balance,
        };
        reset({ ...paymentInitValues, ...initValues });
    }

    async function getPaymentTypes() {
        const request = {
            worker: worker?.id,
            day: selected?.origin_day,
            tree: true,
        };
        try {
            const response = await overtimeMopersAPI.getPaymentsAvailable(request);
            setPaymentTypes(response);
        } catch (error) {
            resolveError(error);
        }
    }

    async function onSubmit(params) {
        dispatch(lockedWindow());
        const request = genericPaymentRequest(params, worker, selected, params?.pay_type);
        try {
            await overtimeMopersAPI.createMoper(request);
            handleClose(MODAL_KEY, reset);
            dispatch(unlockedWindow());
            showSuccessNotification(successCreated());
            if (isFunction(handleRefresh)) {
                handleRefresh(false, selected);
            }
        } catch (error) {
            dispatch(unlockedWindow());
            resolveError(error);
        }
    }

    return {
        control,
        errors,
        handleSubmit,
        clearErrors,
        trigger,
        setError,
        handleClose,
        paymentTypes,
        setValue,
        worker,
        onSubmit,
        selected,
        open,
    };
}

export default useTimePayment;