import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useForm, useWatch } from 'react-hook-form';
import { implementService } from "../../../services/implemet-service";
import { attendanceListAPI } from "../../../services/RRHH";
import { size } from "lodash";
import { resolveError } from "../../../common/resolve-error";
import { format, getMonth, getYear, parseISO } from "date-fns";
import { useDispatch } from "react-redux";
import { lockedWindow, unlockedWindow } from "../../../../store/actions";
import TriggerNotificationSocket from "../../../common/trigger-notification-socket";
import { socketsApi } from "../../../services/socket-report";
import FileSaver from "file-saver";
import { calendarChecksInitValues } from "../ConstantAndUtilities/constants";
import { current_date } from "../../../../App/components/Dates/utilities";
import { currentGroup } from "../../../common/validate-tenant";
import { hasValue } from "../../../common/GeneralUtilities";

export const useCalendarMenu = ({ filter, initialDate, currentWorker = {} }) => {
    const [open, setOpen] = useState(false);
    const [worker, setWorker] = useState(null);
    const [workers, setWorkers] = useState([]);
    const [disabledPrev, setDisabledPrev] = useState(false);
    const [disabledNext, setDisabledNext] = useState(false);
    const [date, setDate] = useState(current_date);
    const direction = useRef(0);
    const [page, setPage] = useState(0);
    const dispatch = useDispatch();

    const {
        control,
        handleSubmit,
        setValue,
    } = useForm({
        // resolver: yupResolver(ValidationSchema),
        defaultValues: calendarChecksInitValues,
        mode: 'onChange',
    });

    const month = useWatch({ control, name: 'calendar_month' });
    const year = useWatch({ control, name: 'calendar_year' });

    useEffect(() => {
        if (initialDate && open) {
            const dateToUse = new Date(parseISO(initialDate, { representation: 'complete' }));
            setValue('start_date', initialDate);
            setValue('calendar_month', getMonth(dateToUse) + 1);
            setValue('calendar_year', getYear(dateToUse));
        }
    }, [initialDate, open]);

    useEffect(() => {
        if (open && !hasValue(currentWorker)) {
            getWorkers();
        }
    }, [open])

    useEffect(() => {
        if (hasValue(currentWorker)) {
            setPage(worker?.id);
        }
    }, [currentWorker]);

    useEffect(() => {
        if (size(workers)) {
            const index = workers.findIndex(item => item?.id === worker?.id);
            setPage(index);
        }
    }, [workers]);

    useEffect(() => {
        if (!open) {
            return
        }
        if ((page === null || !size(workers)) && !hasValue(currentWorker)) return;
        if (page === workers?.length - 1) {
            setDisabledNext(true);
        } else {
            setDisabledNext(false);
        }
        if (page === 0) {
            setDisabledPrev(true);
        } else {
            setDisabledPrev(false);
        }
        if (size(workers)) {
            setValue('calendar_loading', true);
            const id = workers[page]?.id;
            getWorker(id);
        }
        if (hasValue(currentWorker)) {
            setDisabledPrev(true);
            setDisabledNext(true);
            setValue('calendar_loading', true);
            getWorker(worker?.id);
        }
    }, [page, date])

    function handleOpen(worker, field) {
        if (field && typeof field === 'string') {
            const sDate = field.split('-');
            const fDate = new Date(sDate[0], sDate[1] - 1, sDate[2]);
            setValue('month', sDate[1]);
            setValue('year', sDate[0]);
            setDate(fDate);
        }
        setWorker({
            key: worker?.key,
            id: worker?.id
        });
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        setWorkers(null);
        setPage(null);
        setWorker(null);
    }

    function handlePrev() {
        if (page === 0) return;
        setValue('worker_loading', true);
        direction.current = -1;
        setPage((prevPage) => prevPage - 1);
    }

    function handleNext() {
        if (page === workers.length - 1) return;
        setValue('worker_loading', true);
        direction.current = 1;
        setPage((prevPage) => prevPage + 1);
    }

    async function handlePrint(params) {
        dispatch(lockedWindow());
        const request = {
            month: parseInt(params.calendar_month),
            year: parseInt(params.calendar_year),
            ...(filter?.workgroup ? { workgroup: filter.workgroup } : {}),
            worker: worker?.id,
        }
        try {
            const response = await attendanceListAPI.exportEntriesExits(request);
            TriggerNotificationSocket(`entries_exits_print`, response.task);
        } catch (error) {
            dispatch(unlockedWindow());
            resolveError(error);
        } finally {
            dispatch(unlockedWindow());
        }
    }

    function handleRefresh() {
        if (hasValue(currentWorker)) {
            getWorker(currentWorker?.id);
            return;
        }
        getWorkers();
    }

    function getWorkers() {
        setValue('worker_loading', true);
        setValue('calendar_loading', true);
        setWorkers(null);
        setPage(null);
        const request = {
            tree: "",
            fields: 'id',
            include:
                "absenteeisms,enterprise_structure_organization.enterprise_structure.*",
            ...filter,
            month,
            year,
            current_user_group: currentGroup(),
        };
        implementService(attendanceListAPI.get(request), (response) => {
            setWorkers(response);
        }, null, false, {}, false);
    }

    async function getWorker(id) {
        const request = {
            tree: "",
            include:
                "absenteeisms,enterprise_structure_organization.enterprise_structure.*",
            ...filter,
            month,
            year,
            current_user_group: currentGroup(),
        };
        try {
            const response = await attendanceListAPI.getOne(id, request);
            let dataUpdated = [];
            for (let date in response?.attendance_list) {
                const sDate = date.split('-');
                const fDate = new Date(sDate[0], sDate[1] - 1, sDate[2]);
                let data = response.attendance_list[date];
                const newItem = {
                    date: fDate,
                    data: data,
                };
                dataUpdated.push(newItem);
            }
            setWorker(response);
            setValue('require_check', response?.required_check);
            setValue('attendance', dataUpdated);
            setValue('worker_loading', false);
            setValue('calendar_loading', false);
        } catch (error) {
            setValue('worker_loading', false);
            setValue('calendar_loading', false);
            resolveError(error);
        }
    }

    return {
        control,
        handleOpen,
        handleClose,
        handlePrev,
        handleNext,
        handlePrint,
        handleRefresh,
        handleSubmit,
        disabledNext,
        disabledPrev,
        direction,
        open,
        worker,
        date,
        setValue,
    }
}

export const CalendarMenuControllerContext = createContext();

export const CalendarMenuControllerProvider = ({ children, ...others }) => {
    const values = useCalendarMenu({ children, ...others });
    return <CalendarMenuControllerContext.Provider value={values}>
        {children}
    </CalendarMenuControllerContext.Provider>
};

CalendarMenuControllerProvider.propTypes = {
    children: PropTypes.any,
}

export const useCalendarMenuController = () => useContext(CalendarMenuControllerContext);

export default useCalendarMenuController;

export const downloadEntriesExits = async (taskID) => {
    try {
        let response = await socketsApi.downloadReport(taskID);
        FileSaver.saveAs(
            response,
            `${"Entradas y salidas"}_${format(
                new Date(),
                "yyyy-MM-dd hh:mm:ss"
            )}`
        );
    } catch (error) {
        return error;
    }
}