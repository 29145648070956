import { es } from 'date-fns/locale';
import { format, parseISO, isBefore, parse, getDay, isToday, isSameMonth, isWithinInterval, isSameDay } from 'date-fns';
import { capitalize, concat, isEmpty, isNumber, lowerCase, replace, size, split, sumBy, isNil, isObject } from 'lodash'
import { hasValue, parseDatetime, valueOrOption } from "../../../../../common/GeneralUtilities";
import { AttendanceSymbols, Flags, ChecksSymbols, middleCells, requireFault } from './constants';
import { paymentTypes } from '../../../ConstantAndUtilities/constants';

export const getPayments = ({ overtime }) => {
  let paymentsToShow = [];
  const hasSundayBonus = overtime?.is_generate_sunday_bonus;
  const hasWorkerHoliday = overtime?.is_generate_worked_holiday;
  const restWorker = overtime?.is_rest_worked;
  const doubleShift = overtime?.is_double_shift;
  const hasMoney = overtime?.moper_overtime_money?.status;
  const hasTxt = overtime?.moper_overtime_txt?.status;

  const payValue = [hasSundayBonus, hasWorkerHoliday, restWorker, doubleShift, hasMoney, hasTxt]

  if (hasValue(overtime)) {
    paymentsToShow = paymentTypes.map((payment, key) => {
      let needShow = payValue[key];
      let status = 2;
      if (key > 1 && key < 4) {
        status = payValue[key]?.can_request === true ? 4 : 0;
        if (payValue[key]?.status) {
          status = payValue[key]?.status
        }
        if (status !== 0) {
          needShow = true;
        }
      } else if (key > 3) {
        needShow = hasValue(payValue[key]);
        status = payValue[key];
      }
      return {
        ...payment,
        status: status,
        show: needShow
      };
    }).filter(e => e.show === true);
  }
  return paymentsToShow;
}

export const getAssistAdjust = ({ assist_adjust }) => {
  if (hasValue(assist_adjust)) {
    const status = assist_adjust[0]?.moper?.status ?? 2;
    return {
      show: true,
      status
    }
  }
  return null;
}

export const getCompleteShift = ({ completeShift }) => {
  if (hasValue(completeShift)) {
    let status = 0
    status = completeShift?.can_request ? 4 : (completeShift?.requested ? 2 : completeShift?.status ?? status);
    return {
      show: true,
      status
    }
  }
  return null;
}

export const validateInfo = (checks, absenteeisms, assistAdjust) => {
  return hasValue(checks) || hasValue(absenteeisms) || hasValue(assistAdjust);
}

export const formattedDate = (field) => {
  return capitalize(format(new Date(parseISO(field, { representation: 'complete' })), 'PPPP', { locale: es }));
}

export function parseHours(totalMinutes, formatValue = 'HH:mm') {
  if (!totalMinutes) {
    return '';
  }
  const hours = Math.floor(totalMinutes / 60)
  const remainingMinutes = Math.round(totalMinutes % 60)
  return format(new Date().setHours(hours, remainingMinutes), formatValue, { locale: es })
}

export function parseTotalHours(data) {
  if (!data) {
    return
  }
  return parseHours(sumBy(data, item => Number(item.total_minutes)), 'H')
}

export function parseDate(dateString, formatValue = 'HH:mm') {
  if (!dateString) return '[--:--]'
  const date = parseDatetime(dateString, formatValue, false)
  return date
}


export function parseDate2(dateString) {
  if (!dateString) return '[--:--]'
  const date = parseDatetime(dateString, 'HH:mm', false)
  return date
}

export function formatDate(date) {
  if (!date) {
    return 'N/A';
  }
  return format(new Date(parseISO(date, { representation: 'complete' })), "dd/MM/yyyy");
}

export const formatDayCell = (data) => {

  const {
    date,
    checks = [],
    absenteeisms = [],
    group_date = [],
    daysFilter = 'all',
    attendanceMode = 'compact',
    worker = {},
    overtime = [],
    assist_adjust = [],
    holiday_record = [],
    all_info = false,
    month = "",
    year = "",
    failedDates,
    validDates
  } = data;

  const cleanData = data;

  const TODAY = new Date();
  const fieldDate = parse(date, 'yyyy-MM-dd', new Date());
  const dayNumber = getDayNumber(date);
  const isBeforeActualDate = isBefore(fieldDate, TODAY);
  const parsedDate = new Date(parseISO(date, { representation: 'complete' }));
  const monthAndYear = new Date(year, parseInt(month) - 1, 1);
  const isThisMonthCell = isSameMonth(parsedDate, monthAndYear);
  const isTodayCell = isToday(new Date(parseISO(date, { representation: 'complete' })));
  if (!isBeforeActualDate && !hasValue(absenteeisms) && !all_info && !hasValue(holiday_record)) {
    return;
  }

  const footerIconsToShow = getPayments({ overtime });
  const isSunday = getDay(fieldDate) === 0;

  //Simbolo que se tiene por defecto. Es el que se utiliza para el manejo del componente y estilos.
  let symbol;
  //Simbolo que se altera en casos como el grupo(puedo ser vacio). Es el que se muestra al usuario,
  let altSymbol;
  //Icono dentro de las celdas
  let icon;
  //Icono para detalle
  let detailIcon;
  //Card ClassName
  let className = 'attendance-td';
  //CardContent ClassName
  let cardContentClassName = `attendance ${attendanceMode}-cell`;
  //Indica si hay una checada y ausentismos(se coloca solo cuando esta el icono)
  let questionIcon = false;
  //Indica si se debe mostrar el icono para hacer un ajuste de asistencia
  let requiredCheck = false;

  let hoverTooltip = null;
  //Indica si el festivo está aplicado
  let holidayApplied = false;
  //Incapacidades
  let hasInanilites = false;

  const flagsInfo = getFlags({ daysFilter, checks, assist_adjust });
  const checkSymbol = flagsInfo?.symbol;
  symbol = checkSymbol
  const cornerClasses = flagsInfo?.flagsClassName;
  const symbolInfo = getSymbol({ worker, actualSymbol: symbol, absenteeisms, checks, isBeforeActualDate, isTodayCell });
  symbol = symbolInfo.symbol;
  requiredCheck = symbolInfo.requiredCheck;
  if (hasValue(holiday_record)) {
    const holidayInfo = getHolidayInfo(symbol, holiday_record);
    symbol = holidayInfo.symbol;
    requiredCheck = holidayInfo.requiredCheck;
    holidayApplied = holidayInfo.isApplied;
  }
  icon = getSymbolIcon(symbol);
  const isRestDay = lowerCase(symbol).includes('d') && (hasValue(absenteeisms) || hasValue(checks));
  const newAbsenteeisms = absenteeisms.filter(item => item?.flow_signature);
  const notFlowAbsen = absenteeisms.filter(item => !item?.flow_signature && item?.key !== 'I');
  if (hasValue(absenteeisms)) {
    if (absenteeisms[0]?.key === 'I') {
      hoverTooltip = `Incapacidad del ${formatDate(absenteeisms[0]?.initial_date)} al ${formatDate(absenteeisms[0]?.end_date)}`;
      hasInanilites = true;
    }
    detailIcon = icon;
  }
  if (hasValue(newAbsenteeisms) && isNil(hoverTooltip)) {
    const status = newAbsenteeisms[0]?.moper?.status !== 2 ? 'process' : '';
    className = `${className} ${status}`;
    const statusMessage = status === 'process' ? 'en proceso de autorización' : 'autorizado';
    hoverTooltip = `Moper de ${newAbsenteeisms[0]?.name}, ${statusMessage} del ${formatDate(absenteeisms[0]?.start_date)} al ${formatDate(absenteeisms[0]?.end_date)}`;
  }
  if (hasValue(group_date)) {
    const groupInfo = gruopCells(symbol, checkSymbol, icon, group_date, checks);
    symbol = groupInfo.groupSymbol;
    altSymbol = groupInfo.newSymbol;
    className = `${className} ${groupInfo?.className}`;
    questionIcon = groupInfo?.questionIcon;
    icon = groupInfo.newIcon;
  } else {
    altSymbol = symbol;
    className = `${className} alone`;
    questionIcon = hasValue(absenteeisms) && (hasValue(checks) || hasValue(assist_adjust));
  }
  symbol = isNumber(symbol) ? '' : symbol;

  let absentSymbol = valueOrOption(symbol ?? '', "none")?.toLowerCase();
  className = `${className} ${attendanceMode} absent_${absentSymbol} ${(isSunday || hasValue(holiday_record)) ? 'sunday' : ''}`;

  const adjustCounter = size(assist_adjust);
  let scrollViewdata = concat(assist_adjust, absenteeisms);
  scrollViewdata = scrollViewdata?.filter(element => element?.key || element?.id);

  let calendarCardDayClassName = '';
  let cardTooltip = '';
  if (all_info) {
    if (hasValue(
      validDates)) {
      const successInfo = handleSuccessCalendar(validDates, fieldDate);
      calendarCardDayClassName = successInfo?.className;
      cardTooltip = successInfo?.hoverTooltip;
      if (hasValue(successInfo?.icon)) {
        icon = { icon: successInfo?.icon };
        absentSymbol = 'd';
      }
    } else {
      const errorInfo = handleErrorCalendar(failedDates, fieldDate);
      calendarCardDayClassName = errorInfo?.className;
      cardTooltip = errorInfo?.hoverTooltip;
    }
  }

  altSymbol = hasInanilites ? "" : altSymbol;

  cardTooltip = hasValue(cardTooltip) ? `${dayNumber} - ${cardTooltip}` : formattedDate(date);

  calendarCardDayClassName = `att-calendar-day-cell  ${isThisMonthCell ? '' : 'other-month'} ${hasValue(holiday_record) ? 'holiday' : ''} absent_${absentSymbol} ${calendarCardDayClassName}`;

  requiredCheck = (isBeforeActualDate || isTodayCell) ? requiredCheck : false;
  if (validSymbol(symbol, '', true)) {
    const data = absenteeisms.find(element => lowerCase(element.key) === lowerCase(symbol));
    return {
      symbol: altSymbol,
      className,
      icon,
      cardContentClassName,
      absenteeisms: [data],
      worker,
      date,
      detailIcon,
      adjustCounter,
      scrollViewdata,
      notFlowAbsen,
      assist_adjust,
      holidayApplied,
      cornerClasses,
      altSymbol,
      dayNumber,
      isTodayCell,
      isBeforeActualDate,
      isThisMonthCell,
      calendarCardDayClassName,
      absentSymbol,
      requiredCheck,
      cardTooltip,
      all_info,
      cleanData
    }
  }

  cardContentClassName = `${cardContentClassName} ${flagsInfo?.flagsClassName}`;

  return {
    ...data,
    className,
    symbol,
    altSymbol,
    icon,
    cardContentClassName,
    questionIcon,
    requiredCheck,
    detailIcon,
    footerIconsToShow,
    isRestDay,
    hoverTooltip,
    adjustCounter,
    scrollViewdata,
    notFlowAbsen,
    assist_adjust,
    holidayApplied,
    cornerClasses,
    dayNumber,
    isTodayCell,
    isBeforeActualDate,
    isThisMonthCell,
    calendarCardDayClassName,
    absentSymbol,
    cardTooltip,
    all_info,
    cleanData
  }
}


const handleSuccessCalendar = (data, date) => {
  if (!hasValue(data)) {
    return;
  }
  let className = '';
  let icon = null;
  let hoverTooltip = '';
  const initialDate = new Date(parseISO(data?.start_date, { representation: 'complete' }));
  const endDate = new Date(parseISO(data?.end_date, { representation: 'complete' }));
  const presentationDate = new Date(parseISO(data?.presentation_date, { representation: 'complete' }));

  const isIntervar = isWithinInterval(date, { start: initialDate, end: endDate });

  if (isIntervar) {
    className = `group-select success`;
    hoverTooltip = isSameDay(initialDate, date) ? 'Inicio de ausentismo' : 'Ausentismo';
  }

  const daysOff = data?.days_offs;
  if (hasValue(daysOff)) {
    daysOff.forEach(element => {
      const isEqual = isSameDay(new Date(parseISO(element, { representation: 'complete' })), date);
      if (isEqual) {
        className = `${className} rest-day`;
        icon = 'apply-breaks';
        hoverTooltip = 'Día de descanso';
      }
    })
  }

  if (isSameDay(presentationDate, date)) {
    className = `${className} presentation-date`;
    hoverTooltip = 'Día de presentación';
  }

  return {
    className,
    icon,
    hoverTooltip
  }
}

const handleErrorCalendar = (data, date) => {
  if (!hasValue(data)) {
    return;
  }
  let className = '';
  let hoverTooltip = '';
  const cleanData = isObject(data[0]) ? data : [data];
  cleanData.forEach(element => {
    const errorDate = new Date(parseISO(element?.day, { representation: 'complete' }));
    const isEqual = isSameDay(errorDate, date);
    if (isEqual) {
      className = `group-select error`;
      hoverTooltip = element?.description;
    }
  })

  return {
    className,
    hoverTooltip
  }
}

const getFlags = ({ daysFilter, checks, assist_adjust }) => {
  let flagsCheck = Flags;
  let symbol;
  let cornerClasses = '';
  let isComplete = true;
  if (daysFilter === 'adjust') {
    flagsCheck = ['adjust'];
  } else if (daysFilter === 'over') {
    flagsCheck = ['over', 'less',];
  }
  if (hasValue(checks)) {
    //verify checks
    let value = [];
    checks.forEach(f => {
      if (f.check_in) {
        value.push(true);
      }
      if (f.check_out) {
        value.push(true);
      }
      if (!f.is_complete) {
        isComplete = false;
      }
    });
    //verify flags
    flagsCheck.forEach(f => {
      if (checks[0][f]) {
        cornerClasses += ` ${f}`;
      }
    });
    symbol = value.length % 2 === 0 ? ['•'] : ['/'];
  }

  const realListAdjust = assist_adjust?.filter(element => element?.moper?.status !== 3);

  if (hasValue(realListAdjust) && !cornerClasses.includes('adjust') && daysFilter !== 'over') {
    cornerClasses = `${cornerClasses} adjust`;
  }

  const flagsClassName = `corner-marks ${cornerClasses}`;
  return {
    symbol,
    flagsCheck,
    flagsClassName,
    isComplete
  }
}

const getSymbol = ({ actualSymbol, absenteeisms, checks, worker, isBeforeActualDate, isTodayCell }) => {
  let symbol = actualSymbol;
  let requiredCheck = false;
  if (size(absenteeisms)) {
    symbol = (absenteeisms ?? []).map((absent) => {
      absent.key = absent?.status === 'Procesando' ? absent.key.toLowerCase() : absent.key;
      return absent.key;
    });
  }

  let requireCheck = worker?.required_check;
  if (isNil(requireCheck)) {
    requireCheck = worker?.enterprise_structure_organization?.settings?.config?.required_check;
  }

  if (!size(symbol) && !size(checks)) {
    symbol = (requireCheck && (isBeforeActualDate || isTodayCell)) ? ['FR'] : [''];
    requiredCheck = requireCheck;
  }

  if (symbol?.length >= 2) {
    const search = symbol.findIndex(f => f === 'D');
    if (search !== -1) {
      symbol.splice(search, 1);
      symbol.splice(0, 0, "D");
    }
  }
  symbol = symbol.join('-');
  return {
    symbol,
    requiredCheck
  };
};

const gruopCells = (symbol, checkSymbol, icon, group_date, checks) => {
  let newSymbol;
  let newIcon = middleCells.includes(group_date?.value) ? icon : null;
  let className = `group-cell ${group_date?.value ?? ''}`;
  let questionIcon = false;

  if (!middleCells.includes(group_date?.value) || ChecksSymbols.includes(checkSymbol)) {
    newSymbol = checkSymbol;
  }
  if (hasValue(checks) && middleCells.includes(group_date?.value)) {
    questionIcon = true;
  }

  let groupSymbol = symbol;
  const key = split(group_date?.key, '-')[0];
  if (!isEmpty(symbol) && lowerCase(symbol) !== lowerCase(key)) {
    groupSymbol = group_date?.key;
    groupSymbol = replace(groupSymbol, /\d+/g, '');
    if (middleCells.includes(group_date?.value)) {
      newIcon = getSymbolIcon(groupSymbol);
    }
  }

  return {
    newIcon,
    newSymbol,
    className,
    questionIcon,
    groupSymbol
  }
}

const validSymbol = (symbol, value, cleanCell = false) => {
  if (isEmpty(symbol)) {
    return false;
  }
  if (cleanCell) {
    const emptyCells = AttendanceSymbols.filter(e => e.cleanCell);
    return emptyCells.some(e => e?.prefix === symbol);
  }
  const prefixValue = AttendanceSymbols.find(e => e.name === value)?.prefix;
  return lowerCase(symbol) === lowerCase(prefixValue);
}

export const getSymbolIcon = (symbol) => {
  let value = lowerCase(symbol).replace(' ', '-');

  switch (value) {
    case 'assist_adjust':
    case 'assist-adjust':
      return {
        title: "assist-adjust",
        icon:
          "assist-adjustment"
      };
    case 'd':
      return {
        title: "break",
        icon:
          "apply-breaks"
      };
    case 'i':
      return {
        title: "medical-leave",
        icon: "imss"
      };
    case 'pc':
      return {
        title: "permission-c",
        icon: "con_goce"
      };
    case 'pg':
    case 's':
      return {
        title: "permission-g",
        icon: "sin_goce"
      };
    case 'fr':
    case 'f':
      return {
        title: "faults",
      };
    case 'dv':
    case 'v':
      return {
        title: "vacation",
        icon: 'vacation'
      };
    case 'df':
    case 'hr':
      return {
        title: 'holiday_record',
        icon: 'apply-holidays'
      }
    default:
      return null;
  }
}

export const IsCheckSymbol = (symbol) => {
  return ChecksSymbols.includes(symbol);
}

const getHolidayInfo = (actualSymbol, holiday_record) => {
  let symbol = actualSymbol;
  const element = holiday_record[0];
  const detailIcon = element?.is_applied ? getSymbolIcon('hr') : null;

  if ((ChecksSymbols.includes(actualSymbol) || requireFault.includes(actualSymbol) || isEmpty(actualSymbol)) && element?.is_applied) {
    symbol = 'hr';
  }
  return {
    symbol,
    requiredCheck: false,
    icon: detailIcon,
    isApplied: element?.is_applied
  };
}

export const weekDays = Array.from({ length: 7 }, (_, i) => {
  return format(new Date(2022, 0, 2 + i), 'EEE', { locale: es });
});

export const getDayNumber = (date) => {
  const parsedDate = parseISO(date, { representation: 'complete' });
  return parsedDate.getDate();
};