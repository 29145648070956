import React, {
	useContext,
	createContext,
	useState,
	useEffect,
} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { closeWindow, setBreadCrumbs, setRoutes, reopenWindows, toggleStamp } from '../../../../store/triggers/navigate';
import { each, last, size, uniqBy } from 'lodash';
import { buildMenu, findItemByPath, getCurrentPath, getNavItem, itemsPermisions } from '../utilities';
import { hasValue, valueOrOption } from '../../../common/GeneralUtilities';
import useAbrhilNavigation from './useAbrhilNavigation';

const useController = () => {

	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		window_1, window_2, window_3,
		window_4, window_5, window_6,
		active_elements, isStamp
	} = useSelector(state => state.navigate);
	const { openWindowItem } = useAbrhilNavigation();
	const configuration = useSelector(state => state.configuration);
	const menuStructure = useSelector(state => state.menuStructure);
	const [completeMenu, setCompleteMenu] = useState([]);
	const current_location = (location?.pathname ?? location ?? "welcome").toString();

	const getPathItem = () => {
		let current = getCurrentPath();
		if (/\/(\d+)\//.test(current)) {
			current = current.replace(/\/\d+\//, '/:id/');
			current = `${current}*`;
		}
		return findItemByPath(current, completeMenu);
	};

	useEffect(() => {
		if (size(completeMenu) > 0) {
			const item = getPathItem();
			if (item?.bread) {
				dispatch(setBreadCrumbs(item.bread));
			}
		}
		// eslint-disable-next-line
	}, [location, completeMenu]);

	const reopenItems = () => {
		if (size(completeMenu) > 0) {
			if (current_location.includes("packages")) {
				return;
			}

			let storedItems = [];
			let stored = window.localStorage.getItem("active_windows");
			stored = valueOrOption(stored, "").split(",");
			let item = null;
			let focus_item = null;
			each(stored, itemKey => {
				item = getNavItem(itemKey, false, true);
				if (hasValue(item)) {
					storedItems.push(item);
				}
			});
			focus_item = last(storedItems);
			if (!current_location.includes("welcome")) {
				item = getPathItem();
				if (hasValue(item?.key)) {
					storedItems.push(item);
					focus_item = item;
				}
				navigate("/app/welcome", {
					search: location.search
				});
			}

			if (!hasValue(focus_item)) {
				return;
			}
			storedItems = uniqBy(storedItems, "id");
			dispatch(reopenWindows(storedItems, focus_item));
		}
	};

	useEffect(() => {
		reopenItems();
		// eslint-disable-next-line
	}, [completeMenu]);

	useEffect(() => {
		if (menuStructure && configuration) {
			const newMenu = buildMenu(menuStructure);
			const items = itemsPermisions(newMenu, configuration);
			dispatch(setRoutes(items));
			setCompleteMenu(items);
		}
	}, [configuration, menuStructure]);

	const onFixedWindow = (item) => {
		const windows = { window_1, window_2, window_3, window_4, window_5, window_6, };
		for (let index = 1; index <= 6; index++) {
			const key = `window_${index}`;
			if (windows?.[key]?.id === item?.id) {
				dispatch(closeWindow(key));
				break;
			}
		}
	};

	const changeStamp = () => {
		dispatch(toggleStamp(!isStamp))
	}

	return {
		completeMenu,
		onFixedWindow,
		openWindowItem,
		active_elements: valueOrOption(active_elements, []),
		current_location,
		isStamp,
		changeStamp
	};
};

const AbrhilSideMenuContext = createContext();

export const AbrhilSideMenuProvider = ({ children }) => (
	<AbrhilSideMenuContext.Provider value={useController()}>
		{children}
	</AbrhilSideMenuContext.Provider>
);

/**
 * Controlador de contexto para los conceptos de nomina
 * @returns {useController}
 * */
export const useAbrhilSideMenu = () => useContext(AbrhilSideMenuContext);

export default useAbrhilSideMenu;