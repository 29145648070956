import PropTypes from "prop-types"
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
	faXmark,
	faCheck,
	faLock,
} from "@fortawesome/pro-light-svg-icons";
import {
	faPen as faPenAlt,
	faTrash as faTrashAlt,
	faCheck as faCheckAlt,
	faXmark as faXmarkAlt,
	faFloppyDisk as faFloppyDiskAlt,
	faLock as faLockAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { IconButton } from "../../../App/components/Buttons";
import { valueOrOption } from '../../common/GeneralUtilities';
import { isFunction } from 'lodash';

export const conditional = (condition, ifValue, elseValue) => condition ? ifValue : elseValue;

/**
 * Componente custom para las ediciones en linea(por row) de los grids locales
 * @param {object} params
 * @param {string} params.className - El classname que se usa en la columna
 * @param {object} params.style - Los estilos que se usan en la columna
 * @param {string} params.editField - La propiedad que se va a usar para saber si se esta editando o no la linea
 * @param {object} params.update - la funcion que se va a ralizar cuando le demos click al boton update
 * @param {object} params.cancel - la funcion que se va a ralizar cuando le demos click al boton cancel
 * @param {object} params.edit - la funcion que se va a ralizar cuando le demos click al boton edit
 * @param {object} params.remove - la funcion que se va a ralizar cuando le demos click al boton remove
 * @param {object} params.add - la funcion que se va a ralizar cuando le demos click al boton add
 * @param {object} params.discard - la funcion que se va a ralizar cuando le demos click al boton discard
 * @param {object} params.dataItem - El datatitem al que hace referencia la celda
 * @param {object} params.editButtonProps - Propiedades custumizadas para el boton edit
 * @param {object} params.deleteButtonProps - Propiedades custumizadas para el boton delete
 * @param {?{disabled : boolean, title : string}} params.lockButtons - Propiedades custumizadas para mostrar un canado y que tengan un bloquedo de acciones
 * @returns {Node}
 */


const CommandCell = ({
	className,
	style,
	dataItem,
	dataIndex,
	showRemove: showRemoveProp = true,
	editField: editFieldProp = "inEdit",
	newField: newFieldProp = "isNew",
	add: addProp,
	discard: discardProp,
	update: updateProp,
	cancel: cancelProp,
	edit: editProp,
	remove: removeProp,
	editButtonProps: editButtonPropsProp = {},
	deleteButtonProps: deleteButtonPropsProp = {},
	lockButtons: lockButtonsProp = {},
	cells = {}
}) => {
	const { t } = useTranslation();

	const showRemove = valueOrOption(cells?.showRemove, showRemoveProp);
	const editField = valueOrOption(cells?.editField, editFieldProp);
	const newField = valueOrOption(cells?.newField, newFieldProp);
	const editButtonProps = valueOrOption(cells?.editButtonProps, editButtonPropsProp);
	const deleteButtonProps = valueOrOption(cells?.deleteButtonProps, deleteButtonPropsProp);
	const lockButtons = valueOrOption(cells?.lockButtons, lockButtonsProp);
	const add = conditional(isFunction(cells?.add), cells?.add, addProp);
	const discard = conditional(isFunction(cells?.discard), cells?.discard, discardProp);
	const update = conditional(isFunction(cells?.update), cells?.update, updateProp);
	const cancel = conditional(isFunction(cells?.cancel), cells?.cancel, cancelProp);
	const edit = conditional(isFunction(cells?.edit), cells?.edit, editProp);
	const remove = conditional(isFunction(cells?.remove), cells?.remove, removeProp);

	const inEdit = dataItem?.[editField];
	const isNewItem = dataItem?.[newField];

	if (lockButtons?.disabled || (editButtonProps?.disabled && deleteButtonProps?.disabled)) {
		return (
			<Cell className={className} style={style} >
				<IconButton
					title={t(lockButtons?.title ?? "disabled-actions")}
					buttonProps={{ ...lockButtons, disabled: false }}
					icon={faLock}
					altIcon={faLockAlt}
				/>
			</Cell>
		);
	}

	if (inEdit || isNewItem) {
		return (
			<Cell className={className} style={style} >
				<IconButton
					title={t("save")}
					buttonProps={{ name: "save_command_button", color: "success" }}
					onClick={() => isNewItem ? add(dataItem, dataIndex) : update(dataItem, dataIndex)}
					icon={isNewItem ? "save" : faCheck}
					altIcon={isNewItem ? faFloppyDiskAlt : faCheckAlt}
				/>
				<IconButton
					title={t("cancel")}
					buttonProps={{ name: "cancel_command_button", color: "error" }}
					onClick={() => isNewItem ? discard(dataItem) : cancel(dataItem)}
					icon={faXmark}
					altIcon={faXmarkAlt}
				/>
			</Cell>
		);
	}

	return (
		<Cell className={className} style={style} >
			<IconButton
				title={t("edit")}
				buttonProps={{ name: "edit_command_button", ...editButtonProps }}
				onClick={() => edit(dataItem, dataIndex)}
				icon={editButtonProps?.disabled ? faLock : "edit"}
				altIcon={editButtonProps?.disabled ? faLockAlt : faPenAlt}
			/>
			{showRemove && <IconButton
				title={t("remove")}
				buttonProps={{ name: "remove_command_button", ...deleteButtonProps, color: "error" }}
				onClick={() => remove(dataItem, dataIndex)}
				icon={deleteButtonProps?.disabled ? faLock : "delete"}
				altIcon={deleteButtonProps?.disabled ? faLockAlt : faTrashAlt}
			/>}
		</Cell>
	);
};

CommandCell.propTypes = {
  add: PropTypes.func,
  cancel: PropTypes.func,
  cells: PropTypes.object,
  className: PropTypes.any,
  dataIndex: PropTypes.any,
  dataItem: PropTypes.any,
  deleteButtonProps: PropTypes.object,
  discard: PropTypes.any,
  edit: PropTypes.func,
  editButtonProps: PropTypes.object,
  editField: PropTypes.string,
  lockButtons: PropTypes.any,
  newField: PropTypes.string,
  remove: PropTypes.func,
  showRemove: PropTypes.bool,
  style: PropTypes.any,
  update: PropTypes.any
}

export default CommandCell;

const Cell = ({
	className,
	style,
	children
}) => (
	<td className={`k-command-cell local-commands ${className ?? ""}`} style={style}>
		<div className="container">
			{children}
		</div>
	</td>
);
Cell.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.any
}
