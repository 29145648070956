import { useCallback, useEffect, useState } from "react";
// --redux
import { useDispatch, useSelector } from "react-redux";
// --language
import { useTranslation } from 'react-i18next';
//--pdf download
import FileSaver from "file-saver";
import { format, addDays } from 'date-fns';
//--hook
import { useForm as useReactForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//--kendo
import { getter } from "@progress/kendo-react-common";
import { getSelectedState } from "@progress/kendo-react-grid";
//--constants
import { ValidationSchema, defaultPrint, initialDataState } from "../utils/constants";
import { StructureFunctions, structureSteps } from "../../../../common/StructureUtilities";
// --sockets
import { socketsApi } from "../../../../services/socket-report";
import TriggerNotificationSocket from "../../../../common/trigger-notification-socket";
// --api
import { contractsFormatsAPI } from "../../../../services/contract";
import { parseRequest } from "../../../../common/parse-request";
// --notificaciones
import { showNotificationError } from "../../../../../store/actions";
import { toString } from "lodash";

// Report data
let pdf_name;
let pdf_key;

function usePrintFormats({ handleCloseDialogForm, formValues }) {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(true);

  //steps
  const [firstStep, setFirstStep] = useState(true);

  //Edit Modal
  const [editModal, setEditModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);

  //Workers selections
  let structure = useSelector((state) => state?.configuration?.steps) ?? [];
  structure = structureSteps(structure);

  const [workersSelected, setWorkersSelected] = useState([]);
  const [workersSelectedData, setWorkersSelectedData] = useState([]);

  //Pagination
  const [page, setPage] = useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState();

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
  } = useReactForm({
    resolver: yupResolver(ValidationSchema),
    mode: "onChange",
    defaultValues: defaultPrint,
  });

  useEffect(() => {
    setValue("my_type", formValues.my_type);
  }, [formValues]);

  useEffect(() => {
    setValue("check_all", true);
    const e = {
      target: {
        checked: true,
      },
    };
    handleCheck_all(e);
  }, [firstStep]);

  //Check
  const [selectedRecord, setSelectedRecord] = useState({});
  const SELECTED_FIELD = 'selected';
  const DATA_ITEM_KEY = "id";
  const idGetter = getter(DATA_ITEM_KEY);

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedRecord = getSelectedState({
        event,
        selectedState: selectedRecord,
        dataItemKey: DATA_ITEM_KEY
      })

      setValue("check_all", false);
      if (Object.values(newSelectedRecord).filter((f) => f).length === workersSelectedData.length) {
        setValue("check_all", true);
      }
      setSelectedRecord(newSelectedRecord);

      // eslint-disable-next-line
    },
    [selectedRecord, DATA_ITEM_KEY]
  );

  const onHeaderSelectionChange = useCallback(
    (event) => {
      const checkboxElement = event.syntheticEvent.target
      const checked = checkboxElement.checked
      const newSelectedRecord = {}
      event.dataItems.forEach((item) => {
        newSelectedRecord[idGetter(item)] = checked
      })
      if (JSON.stringify(selectedRecord) === '{}') {
        setSelectedRecord(newSelectedRecord);
      } else {
        const items = { ...selectedRecord, ...newSelectedRecord };
        setSelectedRecord(items);
        if (Object.values(items).filter((f) => f).length === workersSelectedData.length) {
          setValue("check_all", true);
        }
      }

      // eslint-disable-next-line
    },
    [selectedRecord, DATA_ITEM_KEY]
  );

  function handleCheck_all(e) {
    const oldIds = Object.values(workersSelectedData).map(item => {
      return item.id;
    }).filter(id => id !== null);
    const event = {
      oldIds,
      syntheticEvent: {
        target: { checked: e.target.checked },
      },
    };

    if (!e.target.checked) {
      event.remove = true;
    } else {
      event.remove = false;
    }

    setValue(`check_all`, e.target.checked);

    if (event.remove) {
      setSelectedRecord({});
    } else {
      const newSelectedRecordFull = {};
      oldIds.forEach((item) => {
        newSelectedRecordFull[item] = e.target.checked;
      });
      setSelectedRecord(newSelectedRecordFull);
    }
  }

  const massiveEdition = useWatch({ control, name: "is_massive" });

  const trueIds = Object.entries(selectedRecord)
    .filter(([, value]) => value === true)
    .map(([id]) => id);


  // steps
  function handleToggleNext() {
    if (trueIds && !firstStep) {
      const oldIds = Object.values(workersSelectedData).map(item => {
        return item.id;
      }).filter(id => id !== null);
      setWorkersSelected(oldIds);
    }
    setFirstStep((value) => !value);
  }

  function resetFirstStep() {
    setFirstStep(true);
  }
  //Pagination

  const paginatedData = workersSelectedData.slice(page.skip, page.take + page.skip);

  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take =
      targetEvent.value === "All" ? workersSelectedData.length : event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  //edit modal
  function handleOpenEditModal(item) {
    setSelectedItem(item);

    if (trueIds.length > 1) {
      setValue("is_massive", true, { shouldValidate: true });
      setValue("print_type", null, { shouldValidate: true });
      setValue("print_days", null, { shouldValidate: true });
      setValue("authorize", null, { shouldValidate: true });
      setValue("comment", null, { shouldValidate: true });
    } else {
      setValue("is_massive", false, { shouldValidate: true });
      setValue("print_type", item.print_type, { shouldValidate: true });
      setValue("print_days", item.print_days, { shouldValidate: true });
      setValue("authorize", item.authorize, { shouldValidate: true });
      setValue("comment", item.comment, { shouldValidate: true });
    }
    setEditModal(true);
  }

  function handleCloseEditModal() {
    setEditModal(false);
  }

  const handleEdit = (values) => {
    const newData = massiveEdition ? updateMassiveWorkerData(values) : updateOneWorkerData(values);
    setWorkersSelectedData(newData);
    handleCloseEditModal();
  }

  const updateOneWorkerData = (values) => {
    if (formValues.my_type === 2) {
      values = handleDates(values, selectedItem);
    }
    const newData = workersSelectedData.map(dato => {
      if (selectedItem.id === dato.id) {
        return { ...dato, ...values };
      }
      return dato;
    })
    return newData;
  }

  const updateMassiveWorkerData = (values) => {
    const editWorkersId = Object.keys(selectedRecord)
      .filter(id => selectedRecord[id])
      .join(',');
    const editWorkersIdArray = editWorkersId.split(',');
    const newData = workersSelectedData.map(dato => {
      if (editWorkersIdArray.includes(toString(dato.id))) {
        if (formValues.my_type === 2) {
          values = handleDates(values, dato);
        }
        return { ...dato, ...values };
      }
      return dato;
    });
    return newData;
  }

  const handleDates = (values, worker) => {
    let newInitialDate = null;
    let newEndDate = null;

    if (values.print_type === 'S' && worker.last_contract_end_date) {
      const initialDate = new Date(worker.last_contract_end_date.replaceAll("-", "/"));
      const incrementedDate = addDays(initialDate, 1);
      const endDate = addDays(incrementedDate, parseInt((values.print_days - 1)));

      newInitialDate = format(incrementedDate, 'dd/MM/yyyy');
      newEndDate = format(endDate, 'dd/MM/yyyy');
    } else if (values.print_type === 'I' && worker.last_contract_date) {
      const initialDate = new Date(worker.last_contract_date.replaceAll("-", "/"));
      newInitialDate = format(initialDate, 'dd/MM/yyyy');
      const endDate = addDays(initialDate, parseInt((values.print_days - 1)));
      newEndDate = format(endDate, 'dd/MM/yyyy');
    }

    const newValues = {
      ...values,
      print_initial_date: newInitialDate,
      print_end_date: newEndDate,
    };

    return newValues;
  }

  //Worker selections
  const {
    handleSelect,
  } = StructureFunctions({ structure, setValue, getValues });

  const onWorkerSelect = (selection) => {
    setWorkersSelected(selection);
    handleSelect("workers", selection);
  }
  //Save contract
  const onSubmit = async () => {

    const workers = trueIds.join(',');

    const request = parseRequest(
      {
        workers
      }
    );

    try {
      let response = await contractsFormatsAPI.getContract(formValues.id, request);
      pdf_name = formValues.title;
      pdf_key = formValues.key;
      TriggerNotificationSocket(`contracts_catalog`, response.task);
      handleCloseDialogForm();
    } catch (error) {
      dispatch(
        showNotificationError({
          message: t("no-results"),
        })
      );
    }
  }


  return {
    formValues,
    //hook
    handleSubmit,
    control,
    setValue,
    getValues,
    //modal
    openModal,
    setOpenModal,
    firstStep,
    handleToggleNext,
    resetFirstStep,
    //workers selection
    workersSelectedData,
    setWorkersSelectedData,
    workersSelected,
    onWorkerSelect,
    trueIds,
    //save data
    onSubmit,
    //check
    SELECTED_FIELD,
    onHeaderSelectionChange,
    onSelectionChange,
    DATA_ITEM_KEY,
    selectedRecord,
    idGetter,
    //pagination
    page,
    pageSizeValue,
    pageChange,
    paginatedData,
    //edit modal
    handleOpenEditModal,
    handleCloseEditModal,
    editModal,
    selectedItem,
    handleEdit,
    handleCheck_all,
  }
}

export const downloadContractReport = async (taskID) => {
  try {
    const response = await socketsApi.downloadReport(taskID, "format");
    FileSaver.saveAs(
      response,
      `${pdf_key}-${pdf_name}_${format(
        new Date(),
        "yyyy-MM-dd hh:mm:ss"
      )}`
    );
  } catch (error) {
    return error;
  }
}

export default usePrintFormats;