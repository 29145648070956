import { each, isUndefined } from 'lodash';

/* dropdown services */
import {
	branchesAPI,
	subBranchesApi,
	areaAPI,
	subAreaApi,
	departmentAPI,
	subDepartmentsApi,
	jobPositionsApi,
	employerRegistrationAPI
} from "../../services/enterprise-structure";
import { structureLabel } from "../../common/StructureUtilities";
import { fromAttendance } from '../navigation/utilities';

const thisStructure = [
	'branch',
	'sub_branch',
	'area',
	'sub_area',
	'department',
	'sub_department',
	'job_position',
	'employer_registration'
];

const getApi = (step) => {
	switch (step) {
		case 'branch':
			return branchesAPI.get;
		case 'sub_branch':
			return subBranchesApi.get;
		case 'area':
			return areaAPI.get;
		case 'sub_area':
			return subAreaApi.get;
		case 'department':
			return departmentAPI.get;
		case 'sub_department':
			return subDepartmentsApi.get;
		case 'job_position':
			return jobPositionsApi.get;
		case 'employer_registration':
			return employerRegistrationAPI.get;
		default:
			return null;
	}
};

export const getStructureFields = (step, structure, type) => {
	let fields = [];
	each(structure, (field, index) => {
		if (
			((type === "before" && index < step) || (type === "after" && index > step))
			&& thisStructure.includes(field.step)
		) {
			fields.push(field.step);
		}
	});
	return fields;
};

const getExtras = (considere, values) => {
	let extras = {
		with_permission_user: values?.filters?.with_permission_user ?? values?.with_permission_user ?? true,
		...(values?.from_list ? { from_list: values.from_list } : {}),
		...(values?.workgroups ? { workgroups: values.workgroups } : {})
	};
	if (considere) {
		each(considere, extra => {
			const extraValue = values[extra];
			if (!isUndefined(extraValue) && extraValue !== null && extraValue !== "") {
				extras[extra] = extraValue;
			}
		});
	}
	return extras;
};

const structureFilters = (structure, values, changeCallback, noDefault = false, extraProps = {}) => {
	let filters = [];
	const attendance = fromAttendance();
	each(structure, (field, index) => {
		if (thisStructure.includes(field.step)) {
			filters.push([
				field.step,
				structureLabel(field),
				"ServerDropdown",
				[getApi(field.step), "id", "name"],
				{
					searchBy: "search",
					filterEfields: "key|name",
					filterSplit: "-",
					format: "key|rfc|name",
					fields: "id,key,name,rfc",
					noDefault: noDefault,
					changeCallback: (advancedFilters) => {
						if (typeof changeCallback === "function") {
							changeCallback(advancedFilters, index);
						}
					},
					...extraProps,
					extraFilters: { ...getExtras(getStructureFields(index, structure, "before"), values), select: true, ...attendance },
				}
			]);
		}
	});
	return filters;
};

export default structureFilters;