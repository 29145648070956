
import React, {
	Suspense,
	cloneElement,
	isValidElement,
	useEffect,
	useState,
} from 'react';
import useAbrhilWindow from '../../contextsAndControllers/useWindow';
import PageSkeleton from '../PageSkeleton';
import MainCard from '../../../../../App/components/MainCard';

const Window = ({ id }) => {

	const {
		open,
		isFocused,
	} = useAbrhilWindow();

	const focusClass = isFocused ? "isFocused" : "";
	if (!open) {
		return null;
	}

	return (
		<div id={id} className={`module-window ${focusClass}`}>
			<div className='module-window-container'>
				<Suspense fallback={<PageSkeleton />} >
					<ElementContainer />
				</Suspense>
			</div>
		</div>
	);
};

export default Window;

const ElementContainer = () => {

	const {
		item: { element, path },
	} = useAbrhilWindow();

	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setLoaded(!!element);
		}, 300);
		//eslint-disable-next-line
	}, [element]);


	if (!element) {
		return null;
	}

	if (!isValidElement(element) || !loaded) {
		return <MainCard principal />;
	}

	return (
		cloneElement(element, {
			path,
			inWindow: true,
		})
	);

};