import React, { useState, useEffect, useContext, useRef } from "react";
import { addDays, format, isValid, parse } from 'date-fns';
import { useWatch } from "react-hook-form";
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { useTranslation } from "react-i18next";
//--material-ui
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Alert from "@mui/material/Alert";
import CardContent from "@mui/material/CardContent";
//--Componentes
import Button from "../../../../../../styled/Button";
import FormInput from "../../../../../../@components/form/Field";
import DialogContent from "../../../../../../styled/DialogContent";
import DialogActions from "../../../../../../styled/DialogActions";
import FormDialog from "../../../../../../../App/components/dialog/FormDialog";
import { ContextAssistSetting } from "../../../../contexts/ContextAssistSetting";
import { rules, validTime } from "../../../../../../../App/components/Dates/utilities";
import { ContextCheck } from "../../../../contexts/ContextCheck";
import { Context } from "../../../../contexts/Context";
import { debounce, isDate, isString } from "lodash";
import { initValues } from "../../../../hooks/useCheckForm";

const getDateValue = dateValue => {
  if (isString(dateValue)) {
    return dateValue;
  }
  if (isDate(dateValue) && isValid(dateValue)) {
    return format(dateValue, 'HH:mm');
  }
  return 'HH:mm';
}

export const MaskedTexForTimeInput = ({
  value,
  onChange,
  name,
  formatPlaceholder,
  ...others
}) => {

  const [maskValue, setMaskValue] = useState(getDateValue(value));

  useEffect(() => {
    if (value !== null) {
      const formated = format(value, 'HH:mm');
      if (formated !== maskValue) {
        setMaskValue(formated);
      }
      // return;
    }

    // setMaskValue(formatPlaceholder?.mask);
    // eslint-disable-next-line
  }, [value]);

  const handleChange = (e) => {
    if (maskValue === e.target.value) {
      return;
    }

    const eValue = e.value || "";
    const timeParts = eValue.split(":");

    const time = validTime({
      hour: timeParts[0],
      minutes: timeParts[1],
    });

    setMaskValue(time[1]);

    onChange({
      value: time[0] ? parse(`${time[1]}`, "HH:mm", new Date()) : null,
      syntheticEvent: e,
      target: this,
    });
  };

  return (
    <MaskedTextBox
      {...others}
      value={maskValue}
      mask="hH:nN"
      rules={rules}
      name={name}
      onChange={handleChange}
      size={formatPlaceholder?.maskSize}
    />
  );
};

function CheckForm() {

  const { t } = useTranslation();


  const { daysAssistant } = useContext(Context);

  const {
    startDate,
  } = React.useContext(ContextAssistSetting);

  const {
    openCheckForm,
    handleCloseCheckForm,
    handleSaveCheck,
    reset,
    control,
    handleSubmit,
    getDay,
    clearErrors,
    comments,
    selected,
    trigger,
  } = React.useContext(ContextCheck);

  const daysValidation = (originDay, endDay, days) => {

    if (originDay || endDay) {
      const date1 = new Date();
      const diffTime = Math.abs((originDay ?? endDay) - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return (diffDays - 1) <= days
    } else {
      return true
    }

  }

  React.useEffect(() => {

    clearErrors();

    if (openCheckForm) {
      reset(selected?.id ? selected : initValues);
      return;
    }
    reset(initValues);
    // eslint-disable-next-line
  }, [openCheckForm]);

  const origin_date = useWatch({
    control,
    name: "origin_day",
  });

  const date_out = useWatch({
    control,
    name: "check_out_time",
  });

  useEffect(() => {
    if (!openCheckForm) return;
    getDay("day_in", origin_date);
  }, [origin_date, openCheckForm])

  const validateCheck = useRef(debounce((name) => trigger(name), 500)).current;

  return (
    <FormDialog
      open={openCheckForm}
      title={`${t(selected?.id ? "edit" : "add")} ${t("checada")}`}
      style={{ width: '800px', margin: 'auto' }}
      handleClose={handleCloseCheckForm}
      dynamicComponent={
        <form onSubmit={handleSubmit(handleSaveCheck)} autoComplete="off" noValidate>
          <DialogContent dividers className="modal-content">
            <Card>
              <CardContent>
                <Grid container spacing={1} className="mb-1">

                  {!daysValidation(origin_date, date_out, daysAssistant) &&
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        {`La fecha esta fuera del rango de días atrás configurado (${daysAssistant} días).`}
                      </Alert>
                    </Grid>
                  }

                  <Grid item xs={12} sm={1.5}>
                    <FormInput
                      fieldInput="TextField"
                      control={control}
                      name="day_in"
                      label={t("day")}
                      disabled={true}
                      sx={{ backgroundColor: "var(--disabled)" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormInput
                      fieldInput="date"
                      control={control}
                      autoFocus={true}
                      name="origin_day"
                      isRequired={true}
                      label={t("origin-day")}
                      max={new Date()}
                      {...(startDate ? { min: new Date(startDate.split('-').join('/')) } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <DateInInput control={control} validate={validateCheck} />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <DateOutInput control={control} validate={validateCheck} />
                  </Grid>
                  <Grid item xs={12} sm={1.5}>
                    <FormInput
                      fieldInput="TextField"
                      control={control}
                      name="hours"
                      label={t("Total")}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInput
                      control={control}
                      fieldInput="customselect"
                      freeSolo
                      name="request_comment"
                      isRequired
                      label={t("Comentario")}
                      options={comments}
                      disableClearable
                      inputProps={{ maxLength: 251 }}
                      onInputChange={(e, _, onChange) => {
                        if (typeof e.value === 'string') {
                          onChange(e);
                        }
                      }}
                    />
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
          </DialogContent>

          <DialogActions>
            <Button design="text" type="button" onClick={handleCloseCheckForm}>
              {t("cancel")}
            </Button>
            <Button design="contained" type="submit" disabled={!daysValidation(origin_date, date_out, daysAssistant)}>
              {t("save")}
            </Button>
          </DialogActions>
        </form>
      }
    ></FormDialog>
  );
}

export default React.memo(CheckForm);

const DateInInput = ({ control, validate }) => {

  return (
    <FormInput
      name="check_in_time"
      control={control}
      fieldInput="datetime"
      label="Fecha entrada"
      max={new Date()}
      onChange={(e) => {
        validate('check_out_time');
        return e;
      }}
    />
  );
}

const DateOutInput = ({ control, validate }) => {
  const date_in = useWatch({ control, name: "check_in_time" });

  return (
    <FormInput
      name="check_out_time"
      control={control}
      fieldInput="datetime"
      label="Fecha salida"
      min={date_in}
      max={addDays(new Date(), 1)}
      onChange={(e) => {
        validate('check_in_time');
        return e;
      }}
    />
  );
}