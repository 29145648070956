import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { genericPaymentRequest, paymentInitValues, paymentTypesInput } from "../utils/utilities";
import { paymentValidationSchema } from "../utils/validations";
import { overtimeMopersAPI } from "../../../../../../services/mopers";
import { resolveError } from "../../../../../../common/resolve-error";
import { useDispatch } from "react-redux";
import { lockedWindow, unlockedWindow } from "../../../../../../../store/actions";
import { successCreated } from "../../../../../../common/notification-messages";
import { isFunction, omit } from "lodash";
import { showSuccessNotification } from "../../../../../../../App/components/Notifications";

export const MODAL_KEY = 1;

function usePayment({
    modalValues,
    handleClose,
    handleRefresh,
    selected,
    worker,
}) {
    const dispatch = useDispatch();
    const initValues = omit(paymentInitValues, ['money_request_time', 'txt_request_time']);
    const [paymentTypes, setPaymentTypes] = useState([]);

    const isDT = selected?.p_type === 3;
    const { value: open } = modalValues[MODAL_KEY];

    const {
        control,
        handleSubmit,
        clearErrors,
        setValue,
        setError,
        reset,
    } = useForm({
        resolver: yupResolver(paymentValidationSchema()),
        defaultValues: initValues,
        mode: "onChange",
    });

    useEffect(() => {
        if (open) {
            getPaymentTypes();
            reset({
                ...initValues,
                cover_reason: 2,
                isDT,
                comment: null,
                payment_type: 1,
                money_request_time: selected?.time_request,
            });
        }
    }, [open])

    async function getPaymentTypes() {
        const request = {
            worker: worker?.id,
            day: selected?.origin_day,
            tree: true,
        };
        try {
            const response = await overtimeMopersAPI.getPaymentsAvailable(request);
            const payments = paymentTypesInput.filter(item => response[item.value]);
            setPaymentTypes(payments);
        } catch (error) {
            resolveError(error);
        }
    }

    async function onSubmit(params) {
        dispatch(lockedWindow());
        const request = genericPaymentRequest(params, worker, selected, selected?.p_type, params?.payment_type, true);
        try {
            await overtimeMopersAPI.createMoper(request);
            handleClose(MODAL_KEY, reset);
            dispatch(unlockedWindow());
            showSuccessNotification(successCreated());
            if (isFunction(handleRefresh)) {
                handleRefresh(false, selected);
            }
        } catch (error) {
            dispatch(unlockedWindow());
            resolveError(error);
        }
    }

    return {
        control,
        handleSubmit,
        clearErrors,
        handleClose,
        paymentTypes,
        worker,
        isDT,
        setError,
        setValue,
        onSubmit,
        selected,
        open,
    };
}

export default usePayment;