import {
	config,
	configMedia,
	API_PUBLIC_ADDRESS,
	configFile
} from './config';
import axios from 'axios';
import {
	ServerAPI,
	getUrl,
	ParseParams,
} from "./ServerAPI";

export const workerPayrollSchemasApi = ServerAPI("worker-payroll-schemas");
export const workerVoucherSchemasApi = ServerAPI("worker-voucher-schemas");
export const workerPaymentAccountApi = ServerAPI("worker-payment-accounts");
export const workerVoucherAccountApi = ServerAPI("worker-voucher-accounts");
export const workerFamilyApi = ServerAPI("worker-family");
export const validateAccountAPI = ServerAPI("workers/validate-account");
export const workerPayrollRecordsApi = ServerAPI("worker-payroll-records");
export const historicalIntegratingFactorAPI = ServerAPI('worker-integration-records');
export const extraDataAPI = ServerAPI('worker-extra-fields');
export const coWorkerTypesAPI = ServerAPI('co-worker-types');
export const workerPaymentAccountEncryptPermissionsApi = ServerAPI("worker-payment-accounts/encrypt-permissions");

export const workersReducedWeeksApi = ServerAPI("workers/reduced-weeks");
export const workersPensionApi = ServerAPI("workers/pensions");
export const maritalStatusApi = ServerAPI("marital-status");
export const workersJourneytypesApi = ServerAPI("workers/journey-types");
export const genderAPI = ServerAPI('genders');
export const workersExportSATApi = ServerAPI('workers/export-sat');
export const penddingSignaturesAll = ServerAPI('pendding-signatures/all');
export const penddingSignaturesWorker = ServerAPI('pendding-signatures/worker');
export const penddingSignaturesMopers = ServerAPI('pendding-signatures/mopers');

const include_config = true;

const penddingSignaturesApi = () => {

	const baseName = "pendding-signatures";
	const baseApi = ServerAPI(baseName);

	const cancelSignature = async (id, params) => {
		const url = getUrl(`${baseName}/${id}/cancel`, true);
		return axios.patch(url, ParseParams({ params }), config())
			.then((response) => response.data);
	};

	return {
		...baseApi,
		cancelSignature,
	}
};

export const penddingSignaturesAPI = penddingSignaturesApi();

const WorkersApi = () => {

	const baseName = "workers";
	const baseApi = ServerAPI(baseName);

	/**
* @param {number} id
*
* @param {{ tenant:number, catalog:object }} request
*/
	const exceptionConfig = (id, request) => {
		const url = getUrl(`${baseName}/${id}/exception-config`, true);
		return axios.patch(url, ParseParams({ params: request }), config())
			.then((response) => response.data);
	};

	/**
	* @param {number} id
	*
	* @param {{ tenant:number, catalog:object }} request
	*/
	const putPhoto = (id, request) => {
		const url = getUrl(`${baseName}/${id}/upload-profile-image`, true);
		return axios.put(url, request, configMedia())
			.then((response) => response.data);
	};

	/**
	* @param {number} id
	*
	* @param {{ tenant:number, catalog:object }} request
	*/
	const postClone = (id, request) => {
		const url = getUrl(`${baseName}/${id}/clone`, true);

		return axios.post(url, ParseParams({ params: request }), config())
			.then((response) => response.data);
	};

	/**
	* @param {number} id
	*
	* @param {{ tenant:number, catalog:object }} request
	*/
	const postContract = (id, request) => {
		const url = getUrl(`${baseName}/${id}/add-contract`, true);

		return axios.post(url, ParseParams({ params: request }), config())
			.then((response) => response.data);
	};

	/**
	 * @param {number} id
	 *
	 * @param {{tenant?: number,workers?: string}} data
	 */
	const getContractFile = (id, data) => {

		const params = { ...ParseParams({ params: data }), responseType: 'blob' }
		const url = getUrl(`${baseName}/${id}/print-contract`);

		return axios.get(url, configFile(params))
			.then((response) => window.URL.createObjectURL(response.data));
	};

	/**
	* @param {{ tenant?: number, second_surname:string, name: string,first_surname:string, birthday:date }} params
	*/
	const validateWorker = (params) => {
		const url = getUrl(`${baseName}/validate-worker`);

		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	};

	/**
	* @param {{ tenant?: number, curp:string }} params
	*/
	const validateWorkerCURP = (params) => {
		const url = getUrl(`${baseName}/validate-curp`);

		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	};

	/**
	* @param {{ tenant?: number, rfc:string }} params
	*/
	const validateWorkerRFC = (params) => {
		const url = getUrl(`${baseName}/validate-rfc`);

		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	};

	/**
	* @param {{ tenant?: number, nss:string }} params
	*/
	const validateWorkerNSS = (params) => {
		const url = getUrl(`${baseName}/validate-nss`);

		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	};

	/**
	* @param {{ tenant?: number, nss:string }} params
	*/
	const putStructureSalaries = (id, params) => {
		const url = getUrl(`${baseName}/${id}/movement`, true);

		return axios.put(url, ParseParams({ params }), config())
			.then((response) => response.data);
	};

	/**
	 * @param {{ tenant?: number }} params
	 */
	const getSalaries = (params) => {
		const url = getUrl(`${"get-salaries"}`);

		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	};

	/**
	 * @param {{ tenant?: number }} params
	 */
	const getTenantCountry = (params) => {
		const url = getUrl(`${"get-tenant-country"}`);

		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	};

	/**
	* @param {{ tenant:number, catalog:object }} request
	*/
	const leave = (id, params) => {
		const url = getUrl(`${baseName}/${id}/leave`, true);

		return axios.post(url, ParseParams({ params }), config())
			.then((response) => response.data);
	};

	/**
	* @param {{ tenant:number, catalog:object }} request
	*/
	const reentry = (id, params) => {
		const url = getUrl(`${baseName}/${id}/re-entry`, true);

		return axios.put(url, ParseParams({ params }), config())
			.then((response) => response.data);
	};

	/**
   * @param {{ tenant?: number }} params
   */
	const getLastRecords = (id, params) => {
		const url = getUrl(`${baseName}/${id}/last-records`);

		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	};

	/**
   * @param {{ tenant?: number }} params
   */
	const importSat = (params) => {
		const url = getUrl(`${baseName}/import-sat-response`, true);

		return axios.put(url, ParseParams({ params }), configMedia())
			.then((response) => response.data);
	};

	/**
* @param {{ tenant?: number }} params
*/
	const getCalendarInfo = (id, params) => {
		const url = getUrl(`${baseName}/${id}/absenteeism`, true);

		return axios.post(url, ParseParams({ params }), config())
			.then((response) => response.data);
	};

	const invitateKiosk = (id, params) => {
		const url = getUrl(`${baseName}/${id}/send-kiosco-invitation`, true);

		return axios.get(url, ParseParams({ params, include_config }))
			.then((response) => response.data);
	};

	const createUser = id => {
		const url = getUrl(`${baseName}/${id}/create-users`, true);
		return axios.put(url, {}, config()).then(response => response.data);
	}

	const resetDevice = id => {
		const url = getUrl(`${baseName}/${id}/remove-device`, true);
		return axios.delete(url, config()).then(response => response.data);
	}

	const getAll = (params) => {
		const url = getUrl(`${baseName}/all`, true);
		return axios.get(url, ParseParams({ params, include_config }))
			.then(response => response.data);
	}


	return {
		...baseApi,
		getAll,
		putPhoto,
		postClone,
		postContract,
		getContractFile,
		validateWorker,
		validateWorkerCURP,
		validateWorkerRFC,
		validateWorkerNSS,
		putStructureSalaries,
		getSalaries,
		getTenantCountry,
		reentry,
		leave,
		getLastRecords,
		importSat,
		getCalendarInfo,
		exceptionConfig,
		invitateKiosk,
		createUser,
		resetDevice,
	}
};

export const workersApi = WorkersApi();

const workerContracts = () => {
	const baseName = 'contracts-records';
	const basePermanent = 'workers';
	const baseApi = ServerAPI(baseName);

	const updateContract = (params, body) => {
		const completeApi = ServerAPI(`${baseName}/update-contracts`);
		return completeApi.postUrl(params, body);
	}
	const createContract = (params, body) => {
		const completeApi = ServerAPI(`${basePermanent}/${body.worker}/add-contract`);
		return completeApi.postUrl(params, body);
	}
	return {
		baseApi,
		updateContract,
		createContract,
	}
};

export const workerContractsAPI = workerContracts();

const WorkersPublicApi = () => {

	const baseName = API_PUBLIC_ADDRESS;

	// const getBornStates = (params) => {
	// 	return axios.get(`${baseName}/mx/states/`, ParseParams({ params, include_config }))
	// 		.then(response => response.data);
	// };

	const getCountries = (params) => {
		return axios.get(`${baseName}/country/`, ParseParams({ params, include_config }))
			.then(response => response.data);
	};

	/**
	* @param {{ country: string, zipcode:string, signal?: AbortSignal }} params
	*/
	const getAddress = (params) => {
		return axios.get(`${baseName}/zipcode/`, ParseParams({ params, include_config }))
			.then(response => response.data);
	};

	return {
		//getBornStates,
		getCountries,
		getAddress
	}
};

export const workersPublicApi = WorkersPublicApi();
