/* React tools */
import React, {
	// useState,
	//useCallback
} from 'react';
//import { useForm as useReactForm } from "react-hook-form";
/* Language */
//import { useTranslation } from 'react-i18next';

/* Libraries Components */
//import { debounce } from "lodash";

/* Global Components */
// import FormInput from "../../../../@components/form/Field";
import { ButtonsContainer, Create, Refresh } from '../../../../../../App/components/Buttons';
import Grid from '@mui/material/Grid2';
import { useGridContext } from '../../hooks/useGrid';


const Filters = () => {
	const { refresh, addEdit } = useGridContext();

	return (
		<Grid container>
			<Grid size={{ xs: 12 }}>
				<ButtonsContainer>
					<Refresh onClick={refresh} />
					<Create onClick={addEdit} />
				</ButtonsContainer>
			</Grid>
		</Grid>
	);
}

export default Filters;