import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GridContext } from "../../contexts/GridContext";
import { MODAL_KEY as PAY_TIME_KEY } from "../../hooks/useTimePayment";
import { MODAL_KEY as PAY_KEY } from "../../hooks/usePayment";
import { useTranslation } from "react-i18next";
import { isFunction, isObject, isString, size } from "lodash";
import { buildHour, isMoperCanceled, omitValues, paymentTypes, toHours } from "../../utils/utilities";
import { GenericLabel, useCanViewSalary } from "../../../../../../SignaturesByWorker/components/Shared";
import ModernPopup from "../../../../../../SignaturesByWorker/components/Details/components/Popup";
import { FlowAndMessage, MainInfo, MoperInfo } from "../../../../../../SignaturesByWorker/components/Details/components/DynamicMoper";
import PopupRequest from "./PopupRequest";
import { useModalsContext } from "../../hooks/useModals";
import { currentUserInfo } from "../../../../../../../common/validate-tenant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockKeyhole } from "@fortawesome/pro-light-svg-icons";
import { CustomTooltip } from "../../../../../../../../App/components/Templates/cells";
import { BaseButton } from "../../../../../../../../App/components/Buttons";
import { ReactComponent as RequestIcon } from "../../../../../icons/ov_request_icon.svg";
import { ReactComponent as MoneyIcon } from "../../../../../icons/ov_txt_icon.svg";
import { ReactComponent as TxTIcon } from "../../../../../icons/ov_money_icon.svg";
import { formatMoney } from "../../../../../../GraphicSignatures/utilities/utils";
import { centerOrigin, centerTransform } from "../../utils/constants";
import { t } from "i18next";
import { hasTxT } from "../TxTGate";
import { hasBuiltMoper, moperConstructor } from "../../../../../../SignaturesByWorker/components/Details/components/models";
import { customMoperModels } from "../../models";
import "../../../../../styles/style.scss";

export const isFutureDate = (date) => {
    if (!date) return;
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    const itemDate = new Date(date.split('-').join('/'));
    return itemDate > today;
}

const dayDetailsKeys = [
    { key: 'amount', validation: [1], label: 'Monto', abbr: 'D', minutes: false },
    { key: 'amount', validation: [3, 1], label: 'Monto DT', abbr: 'DT_D', minutes: false },
    { key: 'amount', validation: [4], label: 'Monto TD', abbr: 'TD_D', minutes: false },
    { key: 'amount', validation: [0, 1], label: 'Monto PD', abbr: 'PD_D', minutes: false },
    { key: 'amount', validation: [0, 2], label: 'Monto FT', abbr: 'FT_D', minutes: false },
    { key: 'amount', validation: [1, 6], label: 'Monto IN', abbr: 'IN_D', minutes: false },
    { key: 'minutes_double', label: 'Horas dobles', abbr: 'HD_H', minutes: true },
    { key: 'minutes_triple', label: 'Horas triples', abbr: 'HT_H', minutes: true },
    { key: 'triple_amount', label: 'Montos triples', abbr: 'HT_D', minutes: false },
    { key: 'double_amount', label: 'Montos dobles', abbr: 'HD_D', minutes: false },
    { key: 'minutes_requested', validation: [1], label: 'Horas', abbr: 'H', minutes: true },
    { key: 'minutes_requested', validation: [3, 1], label: 'Horas', abbr: 'H', minutes: true },
    { key: 'minutes_requested', validation: [2], label: 'Horas TxT', abbr: 'TXT_H', minutes: true },
    { key: 'minutes_requested', validation: [3, 2], label: 'Horas TxT', abbr: 'TXT_H', minutes: true },
    { key: 'minutes_canceled', validation: [2], label: 'Canceladas TxT', abbr: 'CTXT_H', minutes: true },
    { key: 'minutes_requested', validation: [1, 6], label: 'Implicitas', abbr: 'IN_H', minutes: true },
];

function Overtime({ item }) {
    const salariesPerm = useCanViewSalary();

    Overtime.propTypes = {
        item: PropTypes.object,
    };

    const { verifiedMoper, handleRefresh, worker } = useContext(GridContext);
    const byCheck = ((item?.type_calculation ?? 1) === 1) || !verifiedMoper;

    const refresh = async () => await handleRefresh(false, item);

    return (
        <div className="detail-container">
            <RequestContainer
                item={item}
                byCheck={byCheck}
                showReq={verifiedMoper}
                className={!salariesPerm ? 'ov-increased-gap' : ''}
                divider
            />
            <SummaryContainer
                item={item}
                worker={worker}
                refresh={refresh}
                className={!salariesPerm ? 'ov-increased-gap' : ''}
                divider
            />
            <PaymentTypesContainer
                item={item}
                refresh={refresh}
                perm={verifiedMoper}
                divider={salariesPerm}
            />
            {salariesPerm &&
                <AmountsContainer
                    item={item}
                    worker={worker}
                    refresh={refresh}
                />}
        </div>
    );
};

export default Overtime;

export const PaymentTypesContainer = ({
    item,
    perm,
    refresh,
    className = '',
    onlyRead = false,
    divider = false,
}) => {

    PaymentTypesContainer.propTypes = {
        item: PropTypes.object,
        perm: PropTypes.bool,
        className: PropTypes.string,
        onlyRead: PropTypes.bool,
        refresh: PropTypes.func,
        divider: PropTypes.bool,
    };

    const userPerms = currentUserInfo()?.user_info?.moper_attendance?.filter(el => el.has_perm)?.map(el => el.description);
    const reqPerms = {
        td: userPerms?.includes(9),
        dt: userPerms?.includes(10),
    };

    const byCheck = ((item?.type_calculation ?? 1) === 1) || !perm;

    const workdayComplete = (byCheck ? item?.total_real_rounded : item?.total_calculated_rounded) >= item?.minutes_workday;
    const incidence = [3, 4].includes(parseInt(item?.incidence)) && !byCheck;
    const imType = item.in ? 7 : 6;

    const paymentsShow = paymentTypes.map(el => {
        const lowName = el.name.toLowerCase();
        const dayItem = item?.[lowName];
        const canReq = reqPerms[lowName];
        if (isObject(dayItem)) {
            el.has_perm = canReq;
        }
        return el;
    }).filter(el => el && el.value !== imType);

    return (
        <div className={`payment-types-container ${divider ? 'ov-right-divider' : ''} ${className}`}>
            {paymentsShow.map((el) => (
                <PaymentType
                    key={el.name}
                    item={el}
                    row={item}
                    refresh={refresh}
                    onlyRead={onlyRead || !workdayComplete || incidence}
                />
            ))}
        </div>
    );
}

export const HoursCell = ({ item, showRequest, title, value }) => {

    HoursCell.propTypes = {
        item: PropTypes.object,
        showRequest: PropTypes.bool,
        title: PropTypes.string,
        value: PropTypes.any,
    };

    const { handleOpen } = useModalsContext();

    const userPerms = currentUserInfo()?.user_info?.moper_attendance?.filter(el => el.has_perm)?.map(el => el.description);
    const canReq = userPerms?.includes(7) || userPerms?.includes(8);
    const tooltipTitle = canReq ? 'Solicitar horas extra' : 'No tiene permisos para solicitar horas extra';

    function handleOTPayment() {
        if (!canReq) return;
        handleOpen(PAY_TIME_KEY, item);
    }

    return (
        <div className="surplus">
            <span className="title-s">
                {title}
            </span>
            <span className={`hours ${value < 0 ? 'negative' : ''}`}>
                {buildHour(value)}
            </span>
            {
                (value > 0 && showRequest) &&
                <CustomTooltip title={tooltipTitle}>
                    <button
                        className="extra-hours-icon"
                        onClick={handleOTPayment}
                    >
                        <RequestIconButton perm={canReq} />
                    </button>
                </CustomTooltip>
            }
        </div>
    );
};

const requestButtonLabel = (canReq, balance, showReq) => {
    let buttonLabel = canReq ? t('request') : 'Sin permisos';

    if (!balance) {
        buttonLabel = 'Sin saldo';
    }

    if (!showReq) {
        buttonLabel = 'Moper inactivo';
    }

    return buttonLabel;
}

export const RequestContainer = ({
    item,
    className = '',
    showReq = true,
    byCheck = true,
    divider = false,
    onlyRead = false,
}) => {

    RequestContainer.propTypes = {
        item: PropTypes.object,
        showReq: PropTypes.bool,
        byCheck: PropTypes.bool,
        divider: PropTypes.bool,
        onlyRead: PropTypes.bool,
        className: PropTypes.string,
    };

    const { handleOpen } = useModalsContext();

    const exceed = (!byCheck ? item?.overtime_calculated : item?.overtime_real) ?? 0;
    const balance = item?.balance ?? 0;
    const requestedTime = exceed - balance;

    const userPerms = currentUserInfo()?.user_info?.moper_attendance?.filter(el => el.has_perm)?.map(el => el.description);
    const canReq = userPerms?.includes(7) || userPerms?.includes(8);
    const tooltipTitle = canReq ? 'Solicitar horas extra' : 'No tiene permisos para solicitar horas extra';
    const disabledBtn = !canReq || !balance || onlyRead || !showReq;
    let buttonLabel = requestButtonLabel(canReq, balance, showReq);

    const data = [
        { label: 'Horas mayor a jornada', value: toHours(exceed) },
        { label: 'Solicitadas', value: toHours(requestedTime) },
        { label: 'Disponibles', value: toHours(balance), primary: true }
    ];

    function handleOTPayment() {
        if (!canReq) return;
        handleOpen(PAY_TIME_KEY, item);
    }

    return (
        <div className={`overtime-request-container ${divider ? 'ov-right-divider' : ''} ${className}`}>
            <div className="inner-labels-container">
                {data.map(el =>
                    <GenericLabel
                        key={`ov-label-${el.label}`}
                        label={el.label}
                        value={el.value}
                        primaryLabel={el.primary}
                        style={{ fontWeight: 'bold' }}
                        disableTooltip
                    />)}
            </div>
            {!onlyRead &&
                <CustomTooltip title={tooltipTitle}>
                    <span>
                        <BaseButton
                            disabled={disabledBtn}
                            onClick={handleOTPayment}
                            className='overtime-request-button'
                        >
                            <RequestIconButton perm={canReq} />
                            {buttonLabel}
                        </BaseButton>
                    </span>
                </CustomTooltip>}
        </div>
    );
};

const totalProp = (array, property) => {
    return array?.reduce((total, obj) => {
        const value = obj?.[property];
        if (value) {
            let fValue = value;
            if (isString(fValue)) {
                fValue = parseFloat(fValue.replace(/\$/g, ''));
            }
            total += fValue ?? 0;
        }
        return total ?? 0;
    }, 0) ?? 0;
}

export const getSummary = data => {
    const implicits = data?.filter(el => el.is_implicit)?.map(el => el.exactMoper);
    const txts = data?.filter(el => el.catalog_key === 'txt_overtime')?.map(el => el.exactMoper);
    const notImplicit = data?.filter(el => !el.exactMoper?.is_implicit)?.map(el => el.exactMoper);
    return {
        txts: toHours(totalProp(txts, 'minutes_requested')),
        implicits: toHours(totalProp(implicits, 'minutes_requested')),
        doubles: toHours(totalProp(notImplicit, 'minutes_double')),
        triples: toHours(totalProp(notImplicit, 'minutes_triple')),
    };
}

export const buildSummary = (item, worker) => {
    const {
        implicits,
        doubles,
        triples,
        txts,
    } = getSummary(item?.request_details);
    return [
        { label: 'Implicitas', value: implicits, icon: MoneyIcon },
        { label: 'Dobles', value: doubles, icon: MoneyIcon },
        { label: 'Triples', value: triples, icon: MoneyIcon },
        (hasTxT(worker) ? { label: 'TXT', value: txts, icon: TxTIcon } : {})
    ].filter(el => el.label);
};

export const SummaryContainer = ({
    item,
    worker,
    refresh,
    className = '',
    divider = false,
}) => {

    SummaryContainer.propTypes = {
        item: PropTypes.object,
        refresh: PropTypes.func,
        divider: PropTypes.bool,
        worker: PropTypes.object,
        className: PropTypes.string,
    };

    const data = buildSummary(item, worker);

    return (
        <SummaryContent
            data={data}
            item={item}
            worker={worker}
            className={className}
            refresh={refresh}
            divider={divider}
        />
    );
};

const SummaryContent = ({
    item,
    data,
    worker,
    refresh,
    className = '',
    divider = false
}) => {

    SummaryContent.propTypes = {
        data: PropTypes.array,
        item: PropTypes.object,
        worker: PropTypes.object,
        refresh: PropTypes.func,
        divider: PropTypes.bool,
        className: PropTypes.string,
    };

    const [anchor, setAnchor] = useState(null);
    const requestsSize = size(item?.request_details);

    useEffect(() => {
        if (requestsSize || !anchor) return;
        onClose();
    }, [item?.request_details])

    const onClick = (event) => {
        if (!requestsSize) return;
        setAnchor(event.currentTarget);
    };

    const onClose = () => setAnchor(null);

    return (
        <RequestsComponent
            anchor={anchor}
            item={item}
            worker={worker}
            refresh={refresh}
            onClose={onClose}
            anchorOrigin={centerOrigin}
            transformOrigin={centerTransform}
        >
            <div className={`overtime-summary-container ${requestsSize ? 'has-size' : ''} ${divider ? 'ov-right-divider' : ''} ${className}`} onClick={onClick}>{/*NOSONAR*/}
                <DividerTitle title={'Resumen'} />
                <div className="inner-labels-container">
                    {data.map(el =>
                        <GenericLabel
                            key={`ov-sum-${el.label}`}
                            label={el.label}
                            value={el.value}
                            labelIcon={el.icon}
                            disableTooltip
                        />)}
                </div>
            </div>
        </RequestsComponent>
    );
}

const hasDouble = (data) => data?.some(el => el?.minutes_double > 0);
const hasTriple = (data) => data?.some(el => el?.minutes_triple > 0);

const getImplicitType = (data) => {
    if (hasTriple(data)) return 2;
    if (hasDouble(data)) return 1;
    return null;
};

const getAmounts = data => {
    const getFilteredData = (key) => data?.filter(el => el.exactMoper?.custom_key === key)?.map(el => el.exactMoper);
    const inData = getFilteredData('in');
    const imData = getFilteredData('im');
    const bnData = getFilteredData('bn');
    const dtData = getFilteredData('dt');
    const ftData = getFilteredData('ft');
    const tdData = getFilteredData('td');
    const pdData = getFilteredData('pd');
    const notImplicit = data?.filter(el => !el.exactMoper?.is_implicit)?.map(el => el.exactMoper);
    const inType = getImplicitType(inData);
    const imType = getImplicitType(imData);
    const bnType = getImplicitType(bnData);
    return {
        in_: formatMoney(totalProp(inData, 'total_amount')),
        im: formatMoney(totalProp(imData, 'total_amount')),
        bn: formatMoney(totalProp(bnData, 'total_amount')),
        dt: formatMoney(totalProp(dtData, 'amount')),
        ft: formatMoney(totalProp(ftData, 'amount')),
        td: formatMoney(totalProp(tdData, 'amount')),
        pd: formatMoney(totalProp(pdData, 'amount')),
        hd: formatMoney(totalProp(notImplicit, 'double_amount')),
        ht: formatMoney(totalProp(notImplicit, 'triple_amount')),
        in_type: inType,
        im_type: imType,
        bn_type: bnType,
    };
}

const getInLabel = (label, type) => {
    if (type === 2) return label + 3;
    if (type === 1) return label + 2;
    return label;
}

export const AmountsContainer = ({
    item,
    worker,
    refresh,
    divider = false,
}) => {

    AmountsContainer.propTypes = {
        item: PropTypes.object,
        worker: PropTypes.object,
        refresh: PropTypes.func,
        divider: PropTypes.bool,
    };

    const {
        bn,
        dt,
        ft,
        im,
        in_,
        pd,
        td,
        hd,
        ht,
        in_type,
        im_type,
        bn_type,
    } = getAmounts(item?.request_details);

    const hasIn = item?.request_details?.find(el => el.exactMoper?.custom_key === 'in');
    const imLabel = getInLabel(hasIn ? 'IN' : 'IM', hasIn ? in_type : im_type);

    const data = [
        { label: imLabel, value: hasIn ? in_ : im },
        { label: 'DT', value: dt },
        { label: 'HD', value: hd },
        { label: 'FT', value: ft },
        { label: 'HT', value: ht },
        { label: 'TD', value: td },
        { label: getInLabel('BN', bn_type), value: bn },
        { label: 'PD', value: pd },
    ];

    return (
        <AmountsContent
            data={data}
            worker={worker}
            refresh={refresh}
            divider={divider}
            item={item}
        />
    );
};

const AmountsContent = ({ data, item, worker, refresh, divider = false }) => {

    AmountsContent.propTypes = {
        data: PropTypes.array,
        item: PropTypes.object,
        worker: PropTypes.object,
        refresh: PropTypes.func,
        divider: PropTypes.bool,
    };

    const [anchor, setAnchor] = useState(null);
    const requestsSize = size(item?.request_details);

    useEffect(() => {
        if (requestsSize || !anchor) return;
        onClose();
    }, [item?.request_details])

    const onClick = (event) => {
        if (!requestsSize) return;
        setAnchor(event.currentTarget);
    };

    const onClose = () => setAnchor(null);

    return (
        <RequestsComponent anchor={anchor} item={item} onClose={onClose} refresh={refresh} worker={worker}>
            <div className={`overtime-amounts-container ${requestsSize ? 'has-size' : ''} ${divider ? 'ov-right-divider' : ''}`} onClick={onClick}>{/*NOSONAR*/}
                <DividerTitle title={'Importes'} />
                <div className="inner-labels-container">
                    {data.map(el =>
                        <GenericLabel
                            key={`ov-amnt-${el.label}`}
                            label={el.label}
                            value={el.value}
                            disableTooltip
                        />)}
                </div>
            </div>
        </RequestsComponent>
    );
}

export const DayDetailsContainer = ({ item, refresh, onCancel }) => {

    DayDetailsContainer.propTypes = {
        item: PropTypes.object,
        refresh: PropTypes.func,
        onCancel: PropTypes.func,
    };

    const salariesPerm = useCanViewSalary();

    const { t } = useTranslation();

    const dayDetails = getDayDetails(item)?.filter(el => {
        if (isString(el.value)) {
            return el.value.includes('$') && salariesPerm;
        }
        return el;
    });
    const totals = getTotals(dayDetails);
    const { money, txt, hours } = totals ?? {};
    const requestsSize = size(item?.request_details);
    const [anchor, setAnchor] = useState(null);

    useEffect(() => {
        if (requestsSize || !anchor) return;
        onClose();
    }, [item?.request_details])

    const onItemClick = (event) => {
        if (!requestsSize) return;
        setAnchor(event.currentTarget);
    };

    const onClose = () => setAnchor(null);

    const totalData = [
        { label: t('TXT'), value: txt },
        { label: t('money'), value: `$${money}` },
        { label: t('hours'), value: hours },
    ];

    return (
        <>
            <div className="day-details" onClick={onItemClick}>{/*NOSONAR*/}
                <div className="day-details-header">
                    <span className="title-d">{t('Detalles del dia')}</span>
                    <hr />
                </div>
                <div className="detail-info">
                    {size(dayDetails) ?
                        dayDetails?.map((el) => (
                            <DetailItem
                                key={el?.comp_field}
                                item={el}
                            />
                        ))
                        :
                        <div className="no-details-message">
                            <span>{t('No hay detalles en este día')}</span>
                        </div>
                    }
                </div>
                <div className="day-details-footer">
                    <span>{'Totales'}</span>
                    <div>
                        {
                            totalData.filter(el => size(el))
                                .map((el) => (
                                    <GenericLabel
                                        key={el.label}
                                        label={el.label}
                                        value={el.value}
                                        fontSize="var(--detailInfoFontSize)"
                                    />
                                ))
                        }
                    </div>
                </div>
            </div>
            <ModernPopup
                target={anchor}
                handleClose={onClose}
                className="detail-popup-component"
                content={
                    <PopupRequest
                        requests={item.request_details}
                        totals={totalData}
                        refresh={refresh}
                        onCancel={onCancel ?? refresh}
                    />
                }
            />
        </>
    );
};

const RequestsComponent = ({
    children,
    anchor,
    onClose,
    item,
    worker,
    refresh,
    anchorOrigin,
    transformOrigin,
}) => {

    RequestsComponent.propTypes = {
        children: PropTypes.any,
        anchor: PropTypes.object,
        onClose: PropTypes.func,
        item: PropTypes.object,
        refresh: PropTypes.func,
        worker: PropTypes.object,
        anchorOrigin: PropTypes.object,
        transformOrigin: PropTypes.object,
    };

    const dayDetails = getDayDetails(item);
    const { txt, money, hours } = getTotals(dayDetails);

    const totalData = [
        (hasTxT(worker) ? { label: t('TXT'), value: txt } : {}),
        { label: t('money'), value: `$${money}` },
        { label: t('hours'), value: hours },
    ];

    return (
        <>
            {children}
            <ModernPopup
                target={anchor}
                handleClose={onClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                className="detail-popup-component"
                content={
                    <PopupRequest
                        requests={item.request_details}
                        totals={totalData}
                        worker={worker}
                        refresh={refresh}
                        onCancel={refresh}
                    />
                }
            />
        </>
    );
}

const getPaymentTooltip = (item, canRequest) => {
    const { has_perm, auto } = item ?? {};

    if (has_perm && canRequest) {
        return `Solicitar ${item?.desc}`;
    }

    if (!has_perm && !auto) {
        return `No tiene permisos para solicitar ${item?.desc}`;
    }

    return item?.desc;
}

const PaymentType = ({
    item,
    row,
    refresh,
    onlyRead = false,
}) => {

    PaymentType.propTypes = {
        item: PropTypes.object,
        row: PropTypes.object,
        onlyRead: PropTypes.bool,
        refresh: PropTypes.func,
    };

    const { handleOpen } = useModalsContext();

    const popupOmit = ['origin_day'];
    const nameLower = item.name.toLowerCase();
    const payItem = row[nameLower];
    const autoRequested = payItem === true;
    const requested = payItem?.request ?? autoRequested;
    const canRequest = payItem?.can_request ?? autoRequested
    const request = row.request_details.find(el => el?.exactMoper?.custom_key === nameLower);
    const exactMoper = request?.exactMoper;
    const canceled = isMoperCanceled(exactMoper);

    const title = getPaymentTooltip(item, canRequest);
    const isAuto = item?.auto;
    const showReq = !onlyRead && !requested && !isAuto && canRequest;
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        if (request) return;
        handleClose();
    }, [request])

    const allSigned = (signs) => {
        if (!size(signs)) return false;
        const filtered = signs?.filter(el => el?.name !== 'cancel');
        for (let sign in filtered) {
            const item = filtered[sign];
            if (item?.is_signed !== true) {
                return false;
            }
        }
        return true;
    };

    const finished = allSigned(exactMoper?.flow_signature) || (item.auto && requested);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function getTimeRequest() {
        switch (item.value) {
            case 3:
                return row?.minutes_workday;
            case 4:
                return row?.number_hours_double;
            default:
                return 0;
        }
    }

    function handleAction(e) {
        if (onlyRead && !isAuto) return;
        if (!requested) {
            if (!showReq || !item?.has_perm) return;
            openModal();
            return;
        }
        handleClick(e);
    }

    const openModal = () => {
        row.modal = item?.desc;
        row.temp_pay = nameLower;
        row.p_type = item.value;
        row.time_request = getTimeRequest();
        handleOpen(PAY_KEY, row);
    }

    const getClassname = () => {
        if (canceled) return 'canceled';
        if (finished) return 'finished';
        if (requested) return 'requested';
        return '';
    }

    function onSign(data) {
        if (isFunction(refresh)) {
            refresh(data);
        }
    }

    return (
        <>
            <CustomTooltip title={title}>
                <button
                    className={`payment-type-wrap column ${canRequest ? 'can-request' : ''} ${getClassname()}`}
                    onClick={handleAction}
                >
                    <div className="prefix-wrapper">
                        <span className="prefix">{item.name}</span>
                    </div>
                    {showReq &&
                        <div className="m-icon-wrapper">
                            <div className="icon">
                                <RequestIconButton perm={item?.has_perm} />
                            </div>
                        </div>}
                </button>
            </CustomTooltip>
            <ModernPopup
                target={anchorEl}
                handleClose={handleClose}
                className="main-popup-component"
                content={
                    <PopupComponent
                        moper={exactMoper}
                        request={request}
                        fieldsOmit={popupOmit}
                        onlyRead={onlyRead}
                        onSign={onSign}
                    />
                }
            />
        </>
    );
};

export const DividerTitle = ({ title, style = {}, className = '' }) => {

    DividerTitle.propTypes = {
        title: PropTypes.string,
        style: PropTypes.object,
        className: PropTypes.string,
    };

    return (
        <div className={`overtime-divider-title ${className}`} style={style}>
            <span>{title}</span>
            <div />
        </div>
    );
};

const RequestIconButton = ({ perm }) => perm ? <RequestIcon /> : <FontAwesomeIcon icon={faLockKeyhole} />;

RequestIconButton.propTypes = {
    perm: PropTypes.bool,
};

export const PopupComponent = ({
    moper,
    mopers,
    request,
    onSign,
    onEdit,
    fieldsOmit,
    style = {},
    listMode = false,
    onlyRead = false,
}) => {

    PopupComponent.propTypes = {
        moper: PropTypes.object,
        request: PropTypes.object,
        mopers: PropTypes.array,
        fieldsOmit: PropTypes.array,
        onSign: PropTypes.func,
        onEdit: PropTypes.func,
        listMode: PropTypes.bool,
        style: PropTypes.object,
        onlyRead: PropTypes.bool,
    };

    const fields = omitValues(request?.data, fieldsOmit);

    return (
        <div className="custom-popup-component" style={style}>
            <div className="custom-popup-request-header">
                <span className="popup-title">
                    {!listMode ? 'Detalles de la solicitud' : 'Detalles de las solicitudes'}
                </span>
                <hr />
            </div>
            <div className="mopers-detail-items-wrapper">
                {!listMode ?
                    <DetailMoperItem
                        moper={moper}
                        onSign={onSign}
                        onEdit={onEdit}
                        onlyRead={onlyRead}
                        item={request}
                        data={fields}
                    />
                    :
                    mopers?.map(item => (
                        <DetailMoperItem
                            key={item?.exactMoper?.moper?.id}
                            moper={item?.exactMoper}
                            onSign={onSign}
                            onEdit={onEdit}
                            onlyRead={onlyRead}
                            item={item}
                            data={item?.data}
                        />
                    ))}
            </div>
        </div>
    );
};

export const DetailMoperItem = ({ moper, onSign, onEdit, onlyRead, item, data }) => {

    const req = hasBuiltMoper(item) ? item : moperConstructor(moper, customMoperModels, true);
    const moperData = data ?? req?.data;
    const hasData = moperData?.filter(el => el?.value !== '-').length > 0;

    return (
        <div className="mopers-detail-item-flow">
            <DetailMoper
                request={req}
                hasData={hasData}
                fields={moperData}
                moper={req?.exactMoper}
            />
            {hasData &&
                <FlowAndMessage
                    exactMoper={req?.exactMoper}
                    refresh={onSign}
                    onEdit={onEdit}
                    onlyRead={onlyRead}
                    showMessage
                    showLevel
                />}
        </div>
    );
}

const DetailMoper = ({ request, fields, moper, hasData }) => {

    return (
        <div className="popup-moper-info-data">
            <MainInfo
                {...request}
                moper={moper}
                statusResponsive
            />
            {!hasData ?
                <div className="no-moper-info-wrapper">
                    <strong>{'No hay información disponible para este moper'}</strong>
                    <span>{'Este moper fue creado a partir de una importación'}</span>
                </div>
                :
                <MoperInfo data={fields} moper={moper} />}
        </div>
    );
};

export const genericProcess = [
    { value: 'Actualmente, la solicitud se encuentra' },
    { value: 'en proceso', color: 'primary' },
    { value: 'de firma' }
];
export const genericFinished = 'La solicitud ha sido completada exitosamente';

const DetailItem = ({ item }) => {

    DetailItem.propTypes = {
        item: PropTypes.object,
    };

    const { label, value } = item ?? {};

    return (
        <div className="daydetail-field-btn">
            <GenericLabel
                label={label}
                value={value}
                fontSize="var(--detailInfoFontSize)"
            />
        </div>
    );
};

export const getDayDetails = (item) => {
    const requests = item?.request_details;

    function getValue(primary, secondary) {
        const value = secondary?.[primary.key];
        primary.value = !primary?.minutes ? parseFloat(value ?? 0) : ((value ?? 0) / 60);
        return primary;
    }

    return requests?.flatMap((m, pIndex) => {
        const el = m.exactMoper;
        return dayDetailsKeys?.map((labelItem, cIndex) => {
            const li = { ...labelItem };
            const payType = el?.pay_type;
            const bonusType = el?.bonus_type;
            const restPaymentType = el?.payment_type_rest_worked ?? bonusType;
            li.comp_field = `${pIndex}_${cIndex}`;
            if (li?.validation) {
                if (restPaymentType) {
                    if (li.validation.includes(payType) && li.validation.includes(restPaymentType) && el?.[li.key]) {
                        return getValue(li, el);
                    }
                }
                if ((li.validation.includes(payType) && size(li.validation) === 1) && el?.[li.key]) {
                    return getValue(li, el);
                }
            } else if (el?.[li.key]) {
                return getValue(li, el);
            }
        })?.filter(el => el?.value);
    })?.reduce((acum, ul) => {
        const exists = acum?.find(acItem => acItem?.label === ul?.label);
        if (exists) {
            exists.value += ul?.value;
        } else {
            acum.push(ul);
        }
        return acum;
    }, [])?.flatMap(jk => {
        if (!jk?.minutes && size(jk)) {
            jk.value = `$${jk.value}`;
        }
        return jk;
    })?.filter(el => el);
};

export const getTotals = (details) => {
    const money = sumProperties(details, 'amount')?.toFixed(2);
    const hours = sumProperties(details, 'minutes_requested');
    const double_hours = sumProperties(details, 'minutes_double');
    const triple_hours = sumProperties(details, 'minutes_triple');
    const txt = sumProperties(details?.filter(el => el?.validation?.includes(2) && size(el?.validation) === 1), 'minutes_requested');
    return { txt, money, hours, triple_hours, double_hours };
};

const sumProperties = (array, property) => {
    return array?.reduce((total, obj) => {
        if (obj?.key?.includes(property)) {
            let value = obj?.value;
            if (isString(value)) {
                value = parseFloat(value.replace(/\$/g, ''));
            }
            total += value ?? 0;
        }
        return total ?? 0;
    }, 0) ?? 0;
};

DetailMoper.propTypes = {
    moper: PropTypes.object,
    request: PropTypes.object,
    fields: PropTypes.array,
    hasData: PropTypes.bool,
};

DetailMoperItem.propTypes = {
    moper: PropTypes.object,
    onSign: PropTypes.func,
    onEdit: PropTypes.func,
    onlyRead: PropTypes.bool,
    item: PropTypes.object,
    data: PropTypes.array,
};