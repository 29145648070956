import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useForm as useReactForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchema, bimesters, initValues } from "../utils/util";
import {
    hideNotificationWarning,
    lockedWindow,
    showConfirmNotification,
    showNotificationError,
    showNotificationWarning,
    unlockedWindow,
} from "../../../../store/actions";
import FileSaver from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import {
    initialFilter,
    initialPage,
} from "../../../../App/components/GridCustomProps";
import { implementService } from "../../../services/implemet-service";
import { parseRequest } from "../../../common/parse-request";
import { currentTenant } from "../../../common/validate-tenant";
import { employerRegistrationAPI } from "../../../services/enterprise-structure";
import TemplateInfo from "../../../@components/modal/SettlementComparasionInfo";
import { emaEbaComparisonApi, infonavitEbaUpdateAPI } from "../../../services/IMSS";
import { socketsApi } from "../../../services/socket-report";
import { CustomTooltip } from "../../../../App/components/Templates/cells";
import { resolveError } from "../../../common/resolve-error";
import TriggerNotificationSocket from "../../../common/trigger-notification-socket";
import FormInput from "../../../@components/form/Field";
import { getNavItem } from "../../../@components/navigation/utilities";
import { showSuccessNotification } from "../../../../App/components/Notifications";
import useAbrhilNavigation from "../../../@components/navigation/contextsAndControllers/useAbrhilNavigation";

const DATA_ITEM_KEY = "key"
let COMPARISON_TYPE = "ema"

function useGrid() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const path = getNavItem('modules@liquidation', false, true);
    const { openWindowItem } = useAbrhilNavigation();

    const tenant = useSelector((state) => state.tenant);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [total, setTotal] = useState(0);
    const [source, setSource] = useState([]);
    const [init, setInit] = useState(false);
    const [filter, setFilter] = useState(initialFilter);
    const [page, setPage] = useState(initialPage);
    const [maxMonth, setMaxMonth] = useState(new Date());
    const [isRead, setIsRead] = useState(false);
    const [workRiskAbrhil, setWorkRiskAbrhil] = useState(0);
    const [workRiskEma, setWorkRiskEma] = useState(0);
    const navigateStyle = {
        cursor: 'pointer',
        fontWeight: 'bold',
        marginTop: '8px',
        color: '#1E73AB',
    }

    const {
        handleSubmit,
        control,
        setValue,
        getValues,
        trigger
    } = useReactForm({
        resolver: yupResolver(ValidationSchema),
        mode: "onChange",
        defaultValues: initValues,
    });

    const year = useWatch({ control, name: "year" });
    const monthObserver = useWatch({ control, name: "month" });
    const bimesterObserver = useWatch({ control, name: "bimester" });
    const view = useWatch({ control, name: "view" });
    const buttomAction = useWatch({ control, name: "buttom_action" });

    const idGetter = getter(DATA_ITEM_KEY);

    useEffect(() => {
        if (!init) return;
        handleRefresh();
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (!source.length && page.skip && init) {
            setPage(initialPage);
        }
        // eslint-disable-next-line
    }, [source]);

    useEffect(() => {
        if (parseInt(view) === 0 && monthObserver) {
            for (let i = 0; i < bimesters.length; i++) {
                const splitted_bimesters = bimesters[i].value.split(',');
                if (splitted_bimesters[0] === monthObserver.replace(/^0+/, '')
                    || splitted_bimesters[1] === monthObserver.replace(/^0+/, '')) {
                    setValue('bimester', bimesters[i].value);
                }
            }
        }
    }, [monthObserver])

    useEffect(() => {
        if (parseInt(view) === 1) {
            const new_month = bimesterObserver.split(',');
            setValue('month', new_month[0].replace(/\b(\d)\b/g, '0$1'));
        }
    }, [bimesterObserver])

    useEffect(() => {
        setSource([]);
        setTotal(0);
        setInit(false);
        setPage(initialPage);
        setFilter(initialFilter);
        // eslint-disable-next-line
    }, [tenant]);

    useEffect(() => {
        let date = new Date();

        if (year?.toString() === date.getFullYear().toString()) {
            setMaxMonth(date);

            let currentMonth = date.getMonth() + 1;

            if (currentMonth < Number(getValues("month"))) {
                setValue("month", currentMonth.toString());
            }
        } else {
            setMaxMonth(undefined);
        }
        // eslint-disable-next-line
    }, [year]);

    function handleRefresh() {
        setInit(true);
        getData(
            parseRequest(
                {
                    tenant: currentTenant(),
                    distinct: "",
                },
                filter,
                page
            )
        );
    }

    function getData(request) {
        dispatch(lockedWindow());

        implementService(employerRegistrationAPI.get(request), (response) => {
            setTotal(response.count);
            setSource(response.results);
        }).finally(() => dispatch(unlockedWindow()));
    }

    function handleFilter(event) {
        setFilter(event.filter);
        handleRefresh();
    }

    function handlePage(event) {
        setPage({
            ...page,
            skip: event.page.skip,
            take: event.page.take,
        });
    }

    const setData = (objectSelected) => {
        let newRecord = Object.keys(objectSelected)
            .filter((key) => objectSelected[key]).toString();
        setValue("employer_registration", newRecord, { shouldValidate: true });
    };

    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedRecord = getSelectedState({
                event,
                dataItemKey: DATA_ITEM_KEY,
            });
            setValue("name_employer_registration", event.dataItem.name, { shouldValidate: true });
            setSelectedRecord(newSelectedRecord);
            setData(newSelectedRecord);
            // eslint-disable-next-line
        },
        [selectedRecord, DATA_ITEM_KEY]
    );

    const handleInfo = () => {
        dispatch(
            showConfirmNotification({
                type: "info",
                title: t("general-information"),
                hasConfirm: false,
                message: (<TemplateInfo />)
            })
        );
    };

    const handleNavigate = () => {
        dispatch(hideNotificationWarning());
        openWindowItem(path);
    }

    const onSubmit = (values) => {
        buttomAction === "process" ? handleProcess(values) : handleDownload(values)
    }

    const handleProcess = async (values) => {
        dispatch(lockedWindow());
        const request = {
            file: values.file,
            year: values.year,
            month: values.month ?? null,
            bimonthly: values.bimester ? bimesters.find((f) => f.value === JSON.parse(JSON.stringify(values.bimester))).send : null,
            employer_registration: values.employer_registration,
            name_employer_registration: values.name_employer_registration,
            recalculate: values.recalculate ?? "False",
        }
        let comparisonApi;
        if (!values.missingEma) {
            COMPARISON_TYPE = parseInt(view) === 0
                ? (delete request.bimonthly, comparisonApi = emaEbaComparisonApi.postMedia, 'ema')
                : (delete request.month, comparisonApi = emaEbaComparisonApi.postMediaEBA, 'eba');
        } else {
            COMPARISON_TYPE = 'ema';
            delete request.bimonthly;
            comparisonApi = emaEbaComparisonApi.postMedia;
        }

        try {
            let response = await comparisonApi(request);
            if (!response.blob) {
                if (COMPARISON_TYPE === "ema") {
                    setIsRead(true);
                    setWorkRiskAbrhil(response.workrisk);
                    setWorkRiskEma(response.workrisk);
                } else if (response?.data?.result === "Se encontraron diferencias.") {
                    dispatch(showConfirmNotification({
                        maxWidth: "sm",
                        message: response.detail || response.data.detail,
                        description:
                            <span>
                                {response.result || response.data.result}
                                <br /><br />
                                Desea actualizar el infonavit ahora?
                                <br /><br />
                            </span>,
                        onConfirm: async () => {
                            delete request.file;
                            delete request.recalculate;
                            try {
                                dispatch(lockedWindow());
                                let response = await infonavitEbaUpdateAPI.post(request);
                                showSuccessNotification(response.detail || response.data.detail);
                            } catch (error) {
                                dispatch(showNotificationError({
                                    maxWidth: "sm",
                                    message: t(`${error.response?.data.detail ?? error.response?.data}`),
                                }))
                            } finally {
                                dispatch(unlockedWindow());
                            }
                        }
                    }));
                    return;
                }
                showSuccessNotification(response.detail || response.data.detail);
                return;
            }
            dispatch(
                showNotificationWarning({
                    maxWidth: "sm",
                    message: response.message,
                    description: 'Se descargó un archivo que contiene dicha información',
                })
            );
            FileSaver.saveAs(
                response.blob,
                `duplicates_infonavit_records_report_${format(
                    new Date(),
                    "yyyy-MM-dd hh:mm:ss"
                )}`
            );
        } catch (error) {
            if (error.response?.data == `No hay información en la liquidación ${COMPARISON_TYPE === "ema" ? "mensual" : "bimestral"}` || error.response?.status == 400) {
                dispatch(showNotificationWarning({
                    maxWidth: "sm",
                    message: t(`${error.response?.data.detail ?? error.response?.data}`),
                    description: error.response?.status == 400
                        ? "El contenido del documento debe coincidir con los parámetros seleccionados"
                        : <span>
                            {t("require-liquidation-of-employer-registration")} <br />
                            <CustomTooltip
                                title={t(`${t("go-to")} ${t("liquidation")}`)}
                                placement="top"
                            >
                                <div
                                    style={navigateStyle}
                                    onClick={() => handleNavigate()}>
                                    {`${t("go-to")} ${t("liquidation")}`}
                                </div>
                            </CustomTooltip>
                        </span>,
                }));
                return;
            }
            if (error.response?.status == 406) {
                let missingEma;
                let splitted_bimesters_values;
                let splitted_bimesters_names;
                if (error.response?.data === "La comparación de la EMA no se ha realizado.") {
                    missingEma = true;
                    splitted_bimesters_values = bimesterObserver.split(',');
                    splitted_bimesters_values[0] = splitted_bimesters_values[0].replace(/\b(\d)\b/g, '0$1');
                    splitted_bimesters_values[1] = splitted_bimesters_values[1].replace(/\b(\d)\b/g, '0$1');
                    for (let i = 0; i < bimesters.length; i++) {
                        if (bimesters[i].value === bimesterObserver) {
                            splitted_bimesters_names = bimesters[i].label.split('-');
                        }
                    }
                } else {
                    missingEma = false;
                }
                dispatch(showConfirmNotification({
                    maxWidth: "sm",
                    message: t(`${error.response?.data.detail ?? error.response?.data}`),
                    description: !missingEma
                        ? `¿Desea calcular la ${COMPARISON_TYPE.toUpperCase()} ahora?`
                        : <span>
                            ¿Desea calcular la {COMPARISON_TYPE === "ema" ? "EBA" : "EMA"} ahora?
                            <br /><br />
                            Si es así, por favor seleccione el mes requerido:
                            <br /><br />
                            <FormInput
                                fieldInput="RadioGroup"
                                control={control}
                                name="month"
                                options={[
                                    { id: splitted_bimesters_values[0], name: splitted_bimesters_names[0].trim() },
                                    { id: splitted_bimesters_values[1], name: splitted_bimesters_names[1].trim() },
                                ]}
                            />
                        </span>,
                    onConfirm: () => {
                        handleProcess({ ...values, recalculate: "True", missingEma });
                    }
                }));
                return;
            }
            dispatch(showNotificationError({
                maxWidth: "sm",
                message: t(`${error.response?.data.detail ?? error.response?.data}`),
            }));
        } finally {
            dispatch(unlockedWindow());
        }
    };

    async function handleDownload(values) {
        const request = {
            year: values.year,
            month: values.month ?? null,
            bimonthly: values.bimester ? bimesters.find((f) => f.value === JSON.parse(JSON.stringify(values.bimester))).send : null,
            employer_registration: values.employer_registration,
            name_employer_registration: values.name_employer_registration
        }
        COMPARISON_TYPE = parseInt(view) === 0 ? (delete request.bimonthly, 'ema') : (delete request.month, 'eba');

        try {
            let response = await emaEbaComparisonApi.downloadReport(COMPARISON_TYPE, request);
            TriggerNotificationSocket(`${COMPARISON_TYPE}_comparison`, response.task);
        } catch (error) {
            resolveError(error);
        } finally {
            dispatch(unlockedWindow());
        }
    }

    return {
        handleProcess,
        isRead,
        handleSubmit,
        control,
        setValue,
        trigger,
        source,
        onSelectionChange,
        DATA_ITEM_KEY,
        selectedRecord,
        idGetter,
        filter,
        handleFilter,
        workRiskAbrhil,
        workRiskEma,
        page,
        total,
        init,
        handlePage,
        handleRefresh,
        handleInfo,
        maxMonth,
        view,
        handleDownload,
        onSubmit
    };
}

export const downloadComparisonReport = async (taskID) => {
    try {
        let response = await socketsApi.downloadReport(taskID);
        FileSaver.saveAs(
            response,
            `Reporte de ${COMPARISON_TYPE}_${format(
                new Date(),
                "yyyy-MM-dd hh:mm:ss"
            )}`
        );
    } catch (error) {
        return error;
    }
}

export default useGrid;