import PropTypes from "prop-types"
import * as React from "react";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCircleCheck,
	faCircleXmark,
} from "@fortawesome/pro-light-svg-icons";

const { t } = i18next;

/**
 *
 * @param {boolean} status : Debe ser un valor boleano que regresara el status
 * @param {{
	*icons:Array,
	*title:Array,
	*style:Array
 * }} otherOptions : Se manda en caso de que el label tenga otras propiedades para mostrar, solo son propiedades de 2 en 2
 * @returns {LabelComponent}
 */

export const statusLabel = (status, otherOptions) => {

	if (status === null) {
		return null;
	}
	status = status ? 1 : 0;

	const icons = otherOptions?.icons ?? [faCircleXmark, faCircleCheck];
	const title = otherOptions?.title ?? ["inactive", "active"];
	const style = otherOptions?.style ?? ["redActionLabel", "greenActionLabel"];
	return (
		<span className={"actionLabel " + style[status]} style={{pointerEvents: "none"}} >
			<FontAwesomeIcon icon={icons[status]} />  {t(title[status])}
		</span>
	);
}

const StatusColumn = ({
	field,
	rowType,
	dataItem,
	is_active,
	className,
	style,
	otherOptions = {}
}) => {

	if (rowType) {
		let status
		if(field.split(".").length === 2) {
			const value = field.split(".")
			status = dataItem[value[0]][value[1]]
		}else{
			status = dataItem[field];
		}
		if (rowType === "groupHeader") {
			return null;
		}
		return (
			<td className={`${className} k-command-cell text-center defaultCursor `} style={style}>
				{statusLabel(status, otherOptions)}
			</td>
		)
	}

	return <div>{statusLabel(is_active, otherOptions)}</div>;
};

StatusColumn.propTypes = {
	className: PropTypes.any,
	dataItem: PropTypes.any,
	field: PropTypes.any,
	is_active: PropTypes.any,
	otherOptions: PropTypes.any,
	rowType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
	style: PropTypes.any
}

export default StatusColumn;