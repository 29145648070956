import React, { useContext } from "react";
import PropTypes from "prop-types";
import FormDialog from "../../../../../../../../App/components/dialog/FormDialog";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import DialogContent from "../../../../../../../styled/DialogContent";
import DialogActions from "../../../../../../../styled/DialogActions";
import Button from "../../../../../../../styled/Button";
import { MODAL_KEY } from "../../hooks/useDayCalculate";
import { DayCalculateContext } from "../../contexts/DayCalculateContext";
import CalendarIcon from "../../../../../icons/calendar.svg";
import ClockIcon from "../../../../../icons/clock.svg";
import CalculateIcon from "../../../../../icons/calculate.svg";
import RoundingIcon from "../../../../../icons/rounding.svg";
import { uid } from "uid";
import Radio from '@mui/material/Radio';
import { ReactComponent as EnterIcon } from "../../../../../icons/icono_entrada.svg";
import { ReactComponent as ExitIcon } from "../../../../../icons/icono-salida.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowRight,
} from "@fortawesome/pro-solid-svg-icons";
import { forEach, isFunction, trim } from "lodash";
import { buildCheck, buildHour } from "../../utils/utilities";
import { CustomTooltip } from "../../../../../../../../App/components/Templates/cells";
import { clearAccents } from "../../../../../../../../App/components/SpeechRecognition/utils/commands";
import { faCircleQuestion } from "@fortawesome/pro-light-svg-icons";
import { GridContext } from "../../contexts/GridContext";

const LEFT_OPTION_VALUE = 2;
const RIGHT_OPTION_VALUE = 1;

export const dateString = ({ day_name, day, month, year }) => year ? `${day_name}, ${day} de ${month} del ${year}` : null;

function DayCalculate() {
    const { t } = useTranslation();

    const {
        onChange,
        selection,
        handleSubmit,
        handleClose,
        selected,
        onSubmit,
        open,
    } = useContext(DayCalculateContext);

    const {
        config,
        ...others
    } = selected?.overtime ?? {};

    const mode = clearAccents(config?.shift_mode?.toLowerCase());
    const shiftMode = {
        'libre': 'LB',
        'lunes-viernes': 'L-V',
        'sabado-domingo': 'S-B',
    }[mode];

    const configs = [
        { icon: CalendarIcon, title: 'Tipo de horario', value: config?.shift_type },
        { icon: ClockIcon, title: 'Horas de jornada', value: trim(`${(config?.journey_hours ?? 0) / 60} horas ${shiftMode ?? ''}`), tooltip: config?.shift_mode },
        { icon: CalculateIcon, title: 'Tipo de cálculo', value: config?.calculation_overhours },
        { icon: RoundingIcon, title: 'Redondeo a partir de', value: `${config?.minutes_rounding} minutos` },
    ];

    return (
        <FormDialog
            open={open}
            title={t('Calculo del dia')}
            titleCenter={dateString(others)}
            style={{
                width: "1100px",
                margin: "auto",
            }}
            className="day-calculate-dialog modern-dialog-design center-title"
            handleClose={() => handleClose(MODAL_KEY)}
            dynamicComponent={
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
                    <DialogContent dividers className="modal-content">
                        <div className="day-calculate-card">
                            <div className="day-calculate-card-content">
                                <Grid item xs={12} container spacing={1} className="custom-content">
                                    {
                                        configs.map((el) => (
                                            <Grid key={el?.title} item xs={3}>
                                                <Card className="config-item">
                                                    <CardContent className="config-item-card-content">
                                                        <div className="config-item-wrapper">
                                                            <div className="icon-wrapper">
                                                                <img alt={uid()} src={el.icon} loading="lazy" />
                                                            </div>
                                                            <div className="info-wrapper">
                                                                <span className="label">
                                                                    {el.title}
                                                                </span>
                                                                {
                                                                    el?.tooltip ?
                                                                        <CustomTooltip title={el.tooltip}>
                                                                            <span className="value">
                                                                                {el.value}
                                                                            </span>
                                                                        </CustomTooltip>
                                                                        :
                                                                        <span className="value">
                                                                            {el.value}
                                                                        </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                                <Grid item xs={12} container spacing={1} className="calc-choices-wrapper">
                                    <Grid item xs={6}>
                                        <Card
                                            className={`calc-choice`}
                                            onClick={() => onChange({ target: { value: LEFT_OPTION_VALUE } })}
                                        >
                                            <CardContent>
                                                <ChoiceTemplate
                                                    title="Por Horario"
                                                    description={'Checadas por horario'}
                                                    value={LEFT_OPTION_VALUE}
                                                    LComponent={<LeftContent1 type={LEFT_OPTION_VALUE} />}
                                                    RComponent={<RightContent1 type={LEFT_OPTION_VALUE} />}
                                                    onChange={onChange}
                                                    selection={selection}
                                                />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Card
                                            className={`calc-choice`}
                                            onClick={() => onChange({ target: { value: RIGHT_OPTION_VALUE } })}
                                        >
                                            <CardContent>
                                                <ChoiceTemplate
                                                    title="Por Checadas"
                                                    description={'Checadas reales'}
                                                    value={RIGHT_OPTION_VALUE}
                                                    LComponent={<LeftContent1 type={RIGHT_OPTION_VALUE} />}
                                                    RComponent={<RightContent1 type={RIGHT_OPTION_VALUE} />}
                                                    onChange={onChange}
                                                    selection={selection}
                                                />
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button design="text" type="button" onClick={() => handleClose(MODAL_KEY)}>
                            {t("cancel")}
                        </Button>
                        <Button design="contained" type="submit">
                            {t("save")}
                        </Button>
                    </DialogActions>
                </form>
            }
        />
    );
};

export default DayCalculate;

const ChoiceTemplate = ({
    title,
    disabled,
    LComponent,
    RComponent,
    onChange,
    selection,
    value,
    description,
}) => {
    const LeftContent = isFunction(LComponent) ? <LComponent /> : LComponent;
    const RightContent = isFunction(RComponent) ? <RComponent /> : RComponent;

    return (
        <div className="day-calculate-info-wrapper">
            <div className="day-calculate-header">
                <Radio
                    checked={selection === value}
                    className={`custom-radio-button ${disabled ? 'disabled' : ''}`}
                    disabled={disabled}
                    onChange={onChange}
                    value={value}
                    name={`choice-button-${uid()}`}
                    inputProps={{ 'aria-label': value }}
                />
                <span className="option-title">
                    {title ?? "-"}
                    <CustomTooltip title={description}>
                        <FontAwesomeIcon icon={faCircleQuestion} />
                    </CustomTooltip>
                </span>
            </div>
            <div className="body-content-wrapper">
                {!!LeftContent && <div className="left-content-wrapper">{LeftContent}</div>}
                {RightContent ?? null}
            </div>
        </div>
    );
};

const LeftContent1 = ({ type }) => {
    const { selected } = useContext(DayCalculateContext);
    const { verifiedMoper } = useContext(GridContext);
    const { checks } = selected ?? {};
    const byCheck = ((type ?? 1) === 1) || !verifiedMoper;

    return (
        <div className="custom-left-content">
            {
                checks?.map((el) => (
                    <div key={el.id} className="check-item">
                        <div>
                            <div className="check-icon-wrapper">
                                <EnterIcon />
                            </div>
                            <div>
                                <span>
                                    {"Entrada"}
                                </span>
                                <span className="blue">
                                    {buildCheck(!byCheck ? el?.clock_process?.[0]?.check_in_calculated : el?.check_in)}
                                </span>
                            </div>
                        </div>
                        <div>
                            <div className="check-icon-wrapper">
                                <ExitIcon />
                            </div>
                            <div>
                                <span>
                                    {"Salida"}
                                </span>
                                <span className="blue">
                                    {buildCheck(!byCheck ? el?.clock_process?.[0]?.check_out_calculated : el?.check_out)}
                                </span>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

const RightContent1 = ({ type }) => {
    const { selected } = useContext(DayCalculateContext);
    const { verifiedMoper } = useContext(GridContext);
    const byCheck = ((type ?? 1) === 1) || !verifiedMoper;
    const {
        total_real_rounded,
        total_calculated_rounded,
        overtime_calculated,
        overtime_real,
        checks,
    } = selected ?? {};

    function getTotalMinutes() {
        let totalMinutes = 0;
        forEach(checks, (check) => {
            const totalClock = check?.clock_process?.[0]?.total_minutes_calculated;
            const total = parseInt((byCheck ? (check?.total_minutes ?? 0) : (totalClock ?? 0)));
            totalMinutes += total;
        });
        return totalMinutes;
    }

    return (
        <div className="custom-right-content">
            <div className="worked-hours-wrapper">
                <div className="icon-container">
                    <img alt={uid()} src={ClockIcon} loading="lazy" />
                </div>
                <div>
                    <span>
                        {"Horas trabajadas"}
                    </span>
                    <div>
                        <span className="hours">
                            {buildHour(getTotalMinutes())}
                        </span>
                        <FontAwesomeIcon icon={faArrowRight} />
                        <span className="rounded-hours">
                            {buildHour(!byCheck ? total_calculated_rounded : total_real_rounded)}
                        </span>
                    </div>
                </div>
            </div>
            <div className="surplus-wrapper">
                <div>
                    <div>
                        <span>
                            {"Nocturnas"}
                        </span>
                        <span className="blue">
                            {"0:00"}
                        </span>
                    </div>
                    <div>
                        <span>
                            {"Extras"}
                        </span>
                        <span className="blue">
                            {buildHour(!byCheck ? overtime_calculated : overtime_real)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

ChoiceTemplate.propTypes = {
    title: PropTypes.string,
    disabled: PropTypes.bool,
    LComponent: PropTypes.any,
    RComponent: PropTypes.any,
    onChange: PropTypes.func,
    selection: PropTypes.number,
    value: PropTypes.number,
    description: PropTypes.string,
};

LeftContent1.propTypes = {
    type: PropTypes.number,
};

RightContent1.propTypes = {
    type: PropTypes.number,
};