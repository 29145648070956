import PropTypes from "prop-types"
import React from "react";
import { isFunction, pick } from "lodash";
//--project imports
import DialogTitle from '../../../core/styled/DialogTitle';
import Dialog from '../../../core/styled/Dialog';
import { GenericLabel } from "../../../core/modules/SignaturesByWorker/components/Shared";
import { Grid } from "@mui/material";
import { SolidDivider } from "../../../core/modules/MOPERSByWorker/components/container/Overtime/components/Modals/TimePayment";
/**
 *
 * @param {object} props
 * @param {boolean} props.open
 * @param {string} props.title
 * @param {?string} props.subtitle
 * @param {function} props.handleClose
 * @param {boolean} props.onCloseIcon
 * @param {string | number | null} props.maxWidth
 * @param {JSX.Element} props.dynamicComponent
 * @rxeturns
 */
const FormDialog = ({
	maxWidth,
	title,
	titleCenter,
	handleClose,
	dynamicComponent,
	innerSubtitle,
	subtitle,
	children,
	sx = {},
	onCloseIcon,
	header,
	headerDivider = false,
	...others
}) => {
	return (
		<Dialog
			fullWidth={true}
			maxWidth={maxWidth ?? "lg"}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick' && isFunction(handleClose)) {
					return handleClose();
				}
				return event;
			}}
			aria-labelledby="customized-dialog-title"
			{...pick(others, ["open", "BackdropProps", "className", "style", "sx", "PaperProps"])}
			sx={{ ...sx, zIndex: (theme) => theme.zIndex.drawer + 1 }}
		>
			<DialogTitle
				title={title}
				subtitle={subtitle}
				titleCenter={titleCenter}
				onClose={handleClose}
				onCloseIcon={onCloseIcon}
				customHeader={header}
			/>
			{!!innerSubtitle &&
				<Grid item container className="abrhil-dialog-inner-subtitle">
					<GenericLabel label={innerSubtitle} fontSize='1em' wrapLabel />
				</Grid>}
			{headerDivider &&
				<Grid item container className="abrhil-dialog-header-divider">
					<SolidDivider />
				</Grid>}
			{dynamicComponent}
			{children}
		</Dialog >
	);
};

FormDialog.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.array,
	]),
	dynamicComponent: PropTypes.element,
	handleClose: PropTypes.func,
	header: PropTypes.any,
	headerDivider: PropTypes.bool,
	innerSubtitle: PropTypes.any,
	maxWidth: PropTypes.string,
	onCloseIcon: PropTypes.any,
	subtitle: PropTypes.any,
	sx: PropTypes.object,
	title: PropTypes.any,
	titleCenter: PropTypes.any
}

export default FormDialog;

export const initialDialog = {
	open: false,
	isEdit: false,
	data: {}
};