import i18next from 'i18next';
import { date, string } from "yup";
import {
	parse,
	isDate,
	format,
	isEqual,
	isAfter,
	isBefore,
} from 'date-fns';
import { dateValue } from '../../../App/components/Dates/utilities';
import { hasValue } from '../GeneralUtilities';

const { t } = i18next;

export const serverDateFormat = "yyyy-MM-dd";
export const dateValidation = (required, valueFormat) => {

	let validation = date()
		.transform((value, originalValue) => {
			if (valueFormat && !isDate(originalValue)) {
				return originalValue && originalValue !== ""
					? parse(originalValue, valueFormat, new Date())
					: null;
			}
			return originalValue === '' ? null : value;
		})
		.nullable()
		.typeError(t('validation:date'))
		.min(new Date("01/01/1920"), t('validation:date'));

	if (required) {
		validation = validation.required(t('validation:required'));
	}

	return validation;
};

/**
 * Funcion que nos sirve para evaluar las fechas con formato string en el sistema, sin transformarlos a un formato fecha que es lo que hace yup con la validación anterior
 * @param {object} props
 * @param {?boolean} props.required - Indica se la fecha a evaluar es requerida o no
 * @param {?string} props.valuesFormat - Indica el formato que se estan manejando para las fechas por default "yyyy-MM-dd"
 * @param {?date|string} props.min - Indica la fecha mínima que puede ser ingresada (Si es un string el formato debe coincidir con el values format)
 * @param {?date|string} props.max - Indica la fecha máxima que puede ser ingresada (Si es un string el formato debe coincidir con el values format)
 * @param {?boolean} props.isDatetime - Indica si lo que se esta evaluando es Fecha y hora , por default se evalua solo fecha
 * @param {?{required:?string,min:?string,max:?string}} props.customMessages - Mensajes custumizados cuando caigan en la validación correspondiente
 * @returns {DateValidation}
 */
export const stringDateValidation = ({
	required = false,
	min,
	max,
	valuesFormat,
	isDatetime = false,
	customMessages = {}
}) => {

	valuesFormat = valuesFormat ?? `yyyy-MM-dd${isDatetime ? " HH:mm:ss" : ""}`;

	let validation = string();
	let msg = "";
	if (required) {
		msg = customMessages?.required ?? 'validation:required';
		validation = validation.required(t(msg));
	} else {
		validation = validation.nullable();
	}

	if (hasValue(min)) {
		const minFormated = formatDate(min, valuesFormat, isDatetime);
		min = dateValue(min, valuesFormat);
		msg = customMessages?.min ?? 'validation:min.date';
		validation = validation.test(
			'min-date-validation',
			t(msg, { min: minFormated }),
			value => {
				value = dateValue(value, valuesFormat);
				const validationValue = isEqual(value, min) || isAfter(value, min);
				return validationValue;
			}
		);
	}

	if (hasValue(max)) {
		const maxFormated = formatDate(max, valuesFormat, isDatetime);
		max = dateValue(max, valuesFormat);
		msg = customMessages?.max ?? 'validation:max.date';
		validation = validation.test(
			'max-date-validation',
			t(msg, { max: maxFormated }),
			value => {
				value = dateValue(value, valuesFormat);
				return isEqual(value, max) || isBefore(value, max);
			}
		);
	}

	return validation;
};

const formatDate = (date, valuesFormat, isDatetime) => {

	const formatOutput = isDatetime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';

	if (isDate(date)) {
		date = format(date, valuesFormat);
	}

	const value = parse(date, valuesFormat, new Date());

	return format(value, formatOutput);
};