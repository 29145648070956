
import React from 'react';
import Avatar from '@mui/material/Avatar';
import { omit } from "lodash";
import { CustomTooltip } from '../../../App/components/Templates/cells';
import { hasValue, valueOrOption } from '../../common/GeneralUtilities';
import { workerFullName } from '../employees/constants';

const colors = ["#3F51B5", "#2196F3", "#F98080", "#009688", "#F50057", "#F6B9FF", "#4CAF50", "#44D2D9", "#967400"];
const colorsG = ["#8593DF", "#9FD1FA", "#FFA4A4", "#7FCDC5", "#FF7FAC", "#F4AFFF", "#A9E6AB", "#ADFBFF", "#FFEEB6"];

/**
 * *Funcion que sera util para regresar las propiedades de un avatar de MUI como lo son las iniciales y el color de fondo
 * @param {string} string : La palabra que sera usada para sacar las iniciales en caso de no poder renderizar la imagen del avatar
 * @param {url} photo : La ruta de la imagen del avatar que se va a usar
 * @param {boolean} showColors
 * @param {Object} sx
 * @returns {object}
 */
export const avatarProps = ({
	string,
	photo,
	showColors = true,
	sx = {},
	fontSize,
}) => {

	/* eslint-enable no-bitwise */
	const splited = valueOrOption(string, "?").toUpperCase().split(' ');
	const alt = `${splited[0][0]}${splited[1] ? splited[1][0] : ''}`; /*  like "AA" */
	const charCodes = parseInt(alt
		.split('') /*  => ["A", "A"] */
		.map(char => char.charCodeAt(0)) /*  => [65, 65] */
		.join(''), 10); /*  => "6565" */

	const colorIndex = charCodes % colors.length;
	const bgColor = colors[colorIndex];
	const bgColorG = colorsG[colorIndex];
	sx = { ...sx, margin: "auto" };
	const extraProps = !showColors || !hasValue(string) ? { ...sx } : {
		sx: {
			...sx,
			className: " ",
			background: `radial-gradient(${bgColor} 0%, ${bgColorG} 100%)`,
			fontSize: "1rem !important"
		},
		children: <div className='no-avatar-image' style={{ fontSize: fontSize ?? '12px' }}>{alt}</div>,
	};

	return !hasValue(photo) ? {
		alt: "",
		src: null,
		...extraProps
	} : {
		alt: string,
		src: photo,
		...extraProps
	};
};

/**
 * Componente que nos va renderizar un avatar de mui y un tooltip para ver ese avatar mas grande si es necesario
 * @param {object} props
 * @param {string} props.string : Va ser el texto que se va a usar si no se renderiza la foto
 * @param {url} props.photo : Es la url de donse se va a caragar la foto a mostrar
 * @extra El avatar esta basado en el componente de MUI asi que se le pueden pasar todas las propiedades que maneja
 * @see https://mui.com/material-ui/react-avatar/
 * @example
 * <AvatarAndTooltip
 *		photo={"https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2080&q=80"}
 *		string="image"
 *		sx={{ width: 50, height: 50 }}
 * />

*/
export const AvatarAndTooltip = ({
	position = "left",
	hiddenTooltip = false,
	withTooltip = true,
	className,
	...allProps
}) => {

	const {
		src,
		children,
		...photoProps
	} = avatarProps(allProps);

	const content = () => {
		return (
			<Avatar
				className={className}
				{...omit(allProps, ["showColors", "position"])}
				{...photoProps}
				src={src}
				onContextMenu={preventContextMenu}
			>
				{children}
			</Avatar>
		);
	};

	return (
		withTooltip ?
			<CustomTooltip title={<TooltipImage src={src} hiddenTooltip={hiddenTooltip} />} placement={position} >
				{content()}
			</CustomTooltip >
			:
			content()
	);
};

/**
 * Retorna un tooltipo con la imagen mas grande del avatar
 * @param {object} param
 * @param {string} param.src - Ruta de la imagen
 * @param {boolean} param.hiddenTooltip - Indida si se muestra el tooltip o no
 * @returns {string|img}
 */
const TooltipImage = ({ src, hiddenTooltip }) => {
	if (!hasValue(src) || hiddenTooltip) {
		return "";
	}
	return <img alt="" src={src} width="210px" height="210px" />;
};

/**
 * Regresa el avatar utilizado generalmente para las cabeceras del trabajador
 * @param {object} param
 * @param {string} param.worker - El objeto del trabajador
 * @param {boolean} param.sx - Propiedades custumizadas para el avatar
 * @param {boolean} param.fontSize - Tamaño de fuente para las letras alternativas
 * @param {boolean} param.useBorder - Indica si el avatar va a mostrar el border estilizado
 * @returns {AvatarAndTooltip}
 */
export const WorkerAvatar = ({
	worker,
	sx,
	fontSize,
	useBorder = false,
	size = 90,
	withTooltip = true,
	style = {},
}) => {

	worker = valueOrOption(worker, { name: "" });
	fontSize = valueOrOption(fontSize, parseInt(size * .26));
	return (
		<AvatarAndTooltip
			style={style}
			photo={worker?.photo?.medium_square_crop}
			string={workerFullName(worker)}
			sx={{ width: size, height: size, ...sx, }}
			fontSize={`${fontSize}px`}
			className={useBorder && hasValue(worker?.name) ? "avatar-bordered" : ""}
			withTooltip={withTooltip}
		/>
	);
};

/**
 * @param e
 */
const preventContextMenu = (e) => {
	e.preventDefault();
};