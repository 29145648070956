import { hasValue } from './GeneralUtilities';
import { getDomain } from "./domain";
import { cancelLockedTasks, cancelTasks } from "./trigger-notification-socket";


export const isAbrhil = () => {
	return window.location.hostname === getDomain();
};

export const authentication = () => {
	return hasValue(localStorage.getItem('access'));
};

export const logout = () => {
	if (localStorage.length > 0) {
		cancelTasks();
		cancelLockedTasks();
		const designType = localStorage.getItem('design_type');
		const theme = localStorage.getItem('theme');
		const user_persist = localStorage.getItem('user_persist');
		const tenantSelection = localStorage.getItem('tenant_selection');
		localStorage.clear();
		localStorage.setItem('design_type', designType);
		localStorage.setItem('theme', theme);
		if (user_persist) {
			localStorage.setItem("user_persist", user_persist);
		}
		if (tenantSelection) {
			localStorage.setItem('tenant_selection', tenantSelection);
		}
	}
	// process.env.REACT_APP_REDIRECT_URL
	// Se utiliza el origin del window para redireccionar al login del subdominio correspondiente
	window.location.replace(window.location.origin);
};