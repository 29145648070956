import React, { useState, useEffect, forwardRef, Fragment } from 'react';
import Grid from "@mui/material/Grid2";
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from "react-redux";
import { hideAdvanceFilters, showNotificationWarning } from '../../../store/actions';
import { useTranslation } from "react-i18next";
import { isString, omit } from "lodash";
import { initialFilter } from '../../../App/components/GridCustomProps';
import {
	hasInvalids,
	DateRangeController,
	FilterController,
	formatProps,
} from './utilities';
import Button from '../../styled/Button';

/* styles */
import "./style.scss";
import { hasValue, valueOrOption } from '../../common/GeneralUtilities';
import SubTitle from '../layout/Subtitle';

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="left" ref={ref} {...props} timeout={500} />;
});

/**
 * 	Los fields se pasan con el siguiente formato
 * @example
 * const fields = {
 * 	"Basicos": [
 * 		["key", "Clave", "input"], <= [field,label,filtertype]
 * 		["name", "Nombre", "input"]
 * 	],
 * 	"Avanzados 1": [
 * 		["description", "Descripción", "date"],
 * 		["is_active", "Activo", "dropdown", [[], "key", "label"]] <= cuarto elemento para listas [datos,key,label]
 * 	]
 * };
 * <AdvanceFilters onFilter={filterChange} fields={fields}/>
*/
const AdvanceFilters = () => {

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		show,
		onFilter,
		fields,
		initialFilters,
		filters,
	} = useSelector(state => state.filter);

	const [values, setValues] = useState({});

	useEffect(() => {
		if (show) {
			setValues({ ...(filters?.filters || filters || {}) });
		}
		// eslint-disable-next-line
	}, [filters]);

	const confirm = () => {
		if (hasInvalids("advance-filters-form-container")) {
			dispatch(showNotificationWarning({ message: t("filters-errors") }));
			return;
		}
		close();
		onFilter({ filters: { ...values } });
	};

	const clean = () => {
		close();
		onFilter({ ...(initialFilters || initialFilter()) });
	};

	const close = () => {
		dispatch(hideAdvanceFilters());
	};

	const onChange = (field, value, changeCallback) => {
		let newValues = values || {};
		if (hasValue(value)) {
			newValues[field] = value;
		} else {
			delete newValues[field];
		}
		setValues(newValues);
		if (typeof changeCallback === "function") {
			changeCallback(newValues, field, value);
		}
	};

	const filtersKeys = Object.keys(fields || {});

	return (
		<Dialog
			open={show}
			TransitionComponent={Transition}
			// keepMounted
			onClose={close}
			aria-describedby="advancefilter-slide-description"
			className="advanceFilters"
		>
			<DialogTitle className="filtersTitle">
				{t("advanced-filters")}
				<IconButton
					aria-label="close"
					className=""
					onClick={close}
				>
					<FontAwesomeIcon icon={faXmark} className="closeModalIcon" />
				</IconButton>
			</DialogTitle>

			<DialogContent dividers>
				<Grid id="advance-filters-form-container" container rowSpacing={1} columnSpacing={2}>
					{filtersKeys.map((keyName, i) => (
						<Fragment key={itemKey(i)}  >
							<SubTitle title={t(keyName)} asV2 asDivider className='mb-1' />
							{valueOrOption(fields[keyName], [])
								.filter(item => hasValue(item))
								.map((item, index) => {
									const allProps = formatProps(item);
									const colProps = {
										size: { xs: 12, md: 6, lg: 6, xl: 6, ...allProps?.colProps }
									};
									const commonProps = {
										...omit(allProps, ["colProps"]),
										label: isString(item[1]) ? t(item[1]) : item[1],
										values: values,
										onChange: onChange,
										asV2: true,
									};

									if ((item[2] || "").toLowerCase() !== "daterange") {
										return (
											<Grid {...colProps} key={itemKey(`${i}_${index}`)} >
												<FilterController {...commonProps} />
											</Grid>
										);
									}
									return (
										<Fragment key={itemKey(`${i}_${index}`)} >
											<DateRangeController {...commonProps} colProps={colProps} />
										</Fragment>
									);
								})}
						</Fragment>
					))}
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button design="flat" onClick={clean} >
					{t("clean")}
				</Button>
				<Button onClick={confirm} design="contained" >
					{t("filter")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AdvanceFilters;

const itemKey = key => `key_${key}`;