import React, { createContext, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { initialDialog } from "../../../../App/components/dialog/FormDialog";
import { useRequestLoad } from "../../MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence";
import { establishmentKeyAPI } from "../../../services/training";
import { showSuccessNotification } from "../../../../App/components/Notifications";
import { successCreated, successUpdated } from "../../../common/notification-messages";
import { useGridContext } from "./useGridController";
import { pick, size } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "../utilities/validations";
import { getFileName } from "../components/Table";
import { fileManagerAPI } from "../../../services/file-manager";

const initValues = {
    id: null,
    branch: null,
    stps_key: null,
    worker_signature: null,
    company_signature: null,
    employer_registration: null,
    worker_representative: null,
    company_representative: null,
};

export const fileFromUrl = async (url) => {
    try {
        const resp = await fileManagerAPI.fetchFile(url);
        return new File([resp], getFileName(url), { type: resp.type });
    } catch (error) {
        return getFileName(url);
    }
}

function useDialogController() {
    const { dialog, setDialog, onRefresh } = useGridContext();
    const { loading, fetch } = useRequestLoad();
    const { open, isEdit, data } = dialog;

    const {
        control,
        reset,
        setValue,
        getValues,
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        defaultValues: initValues,
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (!open) return;
        initializeItem();
    }, [open]);

    const initializeItem = async () => {
        const currentItem = pick(data, Object.keys(initValues));
        if (size(currentItem)) {
            currentItem.worker_signature = await fileFromUrl(currentItem.worker_signature);
            currentItem.company_signature = await fileFromUrl(currentItem.company_signature);
            reset({
                ...currentItem,
                branch: currentItem?.branch?.id,
                employer_registration: currentItem?.employer_registration?.id
            });
        }
    };

    const handleClose = () => {
        setDialog(initialDialog);
        reset(initValues);
    };

    const getApi = (data) => {
        if (isEdit) {
            return establishmentKeyAPI.updateItem(data.id, data);
        }
        return establishmentKeyAPI.createNew(data);
    };

    const onSubmit = (data) => {
        fetch({
            api: getApi(data),
            callback: onSuccess
        });
    };

    const onSuccess = () => {
        handleClose();
        onRefresh();
        showSuccessNotification(isEdit ? successUpdated() : successCreated());
    };

    return {
        open,
        data,
        isEdit,
        control,
        loading,
        reset,
        setValue,
        onSubmit,
        getValues,
        handleSubmit,
        handleClose,
    };
};

export const DialogContext = createContext({});

export const useDialogContext = () => useContext(DialogContext);

export default function DialogController({
    children,
}) {
    const values = useDialogController();

    return (
        <DialogContext.Provider value={values}>
            {children}
        </DialogContext.Provider>
    );
};

DialogController.propTypes = {
    children: PropTypes.any,
};