import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
//--router
import { useNavigate } from "react-router-dom";

/* style */
import "./Styles.scss";
import { getCurrentPath } from "../../../core/@components/navigation/utilities";
import { dashboardPath } from "../../../core/common/validate-tenant";
import LottieUse from "../../../core/common/LottieUse";

const PageNotFound = () => {

	const { t } = useTranslation();
	const navigate = useNavigate();
	const currentPath = getCurrentPath();

	return (
		<Fragment>
			<div id="container-404">
				<div className="container">
					<main>
						<Grid container spacing={1}>
							<Grid item xs={12} className={"text-center"}>
								<LottieUse
									icon={"404_2"}
									className="icon-404-blue"
								/>
								<h2 className="h2-404">{t("page-not-found.title")}</h2>
								<p>
									{t("page-not-found.message")}
								</p>
								{
									currentPath !== dashboardPath() &&
									<Button
										className={"mt-4"}
										variant="contained"
										size="large"
										onClick={() => navigate("/")}
									>
										{t("back-dashboad")}
									</Button>
								}
							</Grid>
						</Grid>
					</main>
				</div>
			</div>
		</Fragment>
	);
};

export default PageNotFound;
