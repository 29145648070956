// ICONS
import { ReactComponent as Attendance } from "../../../../assets/icons/attendance.svg";
import { ReactComponent as CompleteShift } from "../../../../assets/icons/complete_shift.svg";
//// import JobPositionChange from "../../../../assets/icons/job_position_change.svg";
import { ReactComponent as Money } from "../../../../assets/icons/overtime_money.svg";
//// import NewCheck from "../../../../assets/icons/new_check.svg";
import { ReactComponent as SpecialConcepts } from "../../../../assets/icons/special_concepts.svg";
import { ReactComponent as Time } from "../../../../assets/icons/overtime_txt.svg";
import { ReactComponent as Vacations } from "../../../../assets/icons/vacations.svg";
import { ReactComponent as WithEnjoyment } from "../../../../assets/icons/with_enjoyment.svg";
import { ReactComponent as WithoutEnjoyment } from "../../../../assets/icons/without_enjoyment.svg";
import { ReactComponent as IncapacityIcon } from "../../../../assets/icons/imss.svg";

import { formatMoney } from "../../../../../GraphicSignatures/utilities/utils";
import { reversDate } from '../../../../../../../App/components/Select/PayrollPeriod/itemUtilities';
import { formatedDate } from ".";
import { buildHours } from "../../../../../MOPERSByWorker/components/container/Overtime/utils/utilities";
import { capitalize, omit } from "lodash";
import { KEYS_ABSENTEEISM, TXT_KEYS } from "../../../../../MOPERSByWorker/components/container/Overtime/components/Signatures/PermissionGate";
import { parseDatetime } from "../../../../../../common/GeneralUtilities";
import { CATEGORIES } from "../../../../../MOPERSByWorker/components/container/SpecialConcepts/util/util";
import { applySpaces } from "../../../../../../common/StructureUtilities";

export const calcWHours = value => buildHours(value?.minutes_workday - value?.missing_time);
export const getCategory = value => capitalize(CATEGORIES[value]);
const calculateAmount = (value, moper) => {
    if (moper.category === 1) {
        const multi = moper.concept.config_concept?.[0]?.max_value;
        const pValue = parseFloat(value);
        const pMaxValue = parseFloat(multi ?? 0);
        value = pValue * pMaxValue;
    }
    return formatMoney(value);
}
const calculateUnity = (value, moper) => {
    if (moper.category === 2) {
        return null;
    }
    return value;
}

const treatChecks = data => {
    return data?.map(el => {
        const itemKey = el?.key;
        if (['check_in', 'check_out'].includes(itemKey)) {
            const emptyCheck = `${applySpaces(2)}--:--`;
            const adjust = data.find(li => li.key === `${itemKey}_ad`);
            if (adjust?.value === el?.value) {
                adjust.value = emptyCheck;
                adjust.class = null;
            } else {
                el.value = emptyCheck;
            }
        }
        return el;
    });
}

const buildConceptName = (name, moper) => `${moper?.concept?.key} - ${name}`;

// MOPER MODELS

const autoMoperDraft = {
    'Aplicado el': { value: 'created_at', format: (value) => parseDatetime(value, 'dd/MM/yyyy HH:mm a') },
    'Solicitado por': { value: 'user.username' },
};

const implicitContent = {
    'Dia origen': { value: 'origin_bonus_date', format: reversDate, key: 'origin_day' },
    'Montos dobles': { value: 'double_amount', format: formatMoney, key: 'double_amount' },
    'Montos triples': { value: 'triple_amount', format: formatMoney, key: 'triple_amount' },
    'Monto total': { value: 'total_amount', format: formatMoney, key: 'total_amount' },
};

const implicitModels = {
    6: {
        key: 'in',
        is_implicit: true,
        name: 'Implicita nocturna',
        ...implicitContent
    },
    7: {
        key: 'im',
        is_implicit: true,
        name: 'Implicita mixta',
        ...implicitContent
    },
    8: {
        key: 'im',
        is_implicit: true,
        name: 'Implicita',
        ...implicitContent
    }
};

const absenteeismDefDraft = {
    'Fecha inicio': { value: 'start_date', format: reversDate, key: 'start_date' },
    'Fecha fin': { value: 'end_date', format: reversDate, key: 'end_date' },
    'Días': { value: 'days', key: 'days' },
    'Descansos': { value: 'total_days_offs', key: 'total_days_offs' },
    'Total de días': { value: 'total_days', key: 'total_days' },
    'Se presenta el': { value: 'presentation_date', format: reversDate, key: 'presentation_date' },
    'Festivos': { value: 'total_holidays', key: 'total_holidays' },
    'Motivo': { value: 'absenteeism_reason.description', format: capitalize, key: 'reason', class: 'yellow' },
};

const inabilityModel = {
    'Fecha inicio': { value: 'initial_date', format: reversDate, key: 'initial_date' },
    'Fecha fin': { value: 'end_date', format: reversDate, key: 'end_date' },
    'Días': { value: 'days', key: 'days' },
    'Total de días': { value: 'days', key: 'days' },
    'Se presenta el': { value: 'presentation_date', format: reversDate, key: 'presentation_date' },
    'Motivo': { value: 'absenteeism_reason.description', format: capitalize, key: 'reason', class: 'yellow' },
};

export const moperClockModel = {
    relativeKey: false,
    pathname: 'Ajuste de asistencia',
    moperKeys: [KEYS_ABSENTEEISM[4]],
    icon: Attendance,
    dataTreatment: treatChecks,
    model: {
        'Entrada actual': { value: 'clock_worker_checker.check_in', format: (value, moper) => formatedDate(value, moper, false, true), key: 'check_in' },
        'Salida actual': { value: 'clock_worker_checker.check_out', format: (value, moper) => formatedDate(value, moper, false, true), key: 'check_out' },
        'Dia origen': { value: 'origin_day', format: reversDate, key: 'origin_day' },
        'Ajuste entrada': { value: 'check_in', format: (value, moper) => formatedDate(value, moper, false, true), key: 'check_in_ad', class: 'yellow' },
        'Ajuste salida': { value: 'check_out', format: (value, moper) => formatedDate(value, moper, false, true), key: 'check_out_ad', class: 'yellow' },
        'blank': null,
    },
}

export const moperConceptModel = {
    relativeKey: false,
    pathname: 'concept.description',
    moperKeys: ["conceptos"],
    icon: SpecialConcepts,
    nameFormat: buildConceptName,
    model: {
        'Dia origen': { value: 'origin_day', format: (value, moper) => formatedDate(value, moper, true), key: 'origin_day' },
        'Tipo de captura': { value: 'category', key: 'category', format: getCategory },
        'Unidad': { value: 'total', format: calculateUnity, key: 'total' },
        'blank': null,
        'Monto': { value: 'value', format: calculateAmount, key: 'value' },
    },
}

export const moperCompleteShiftModel = {
    relativeKey: false,
    pathname: 'Completar turno',
    moperKeys: [KEYS_ABSENTEEISM[5]],
    icon: CompleteShift,
    model: {
        'Dia origen': { value: 'origin_day', format: reversDate, key: 'origin_day' },
        'Entrada': { value: '--:--' },
        'Horas laboradas': { value: 'worked_day_info', format: calcWHours, key: 'worked_hours' },
        'Salida': { value: '--:--' },
        'Horas por cubrir': { value: 'worked_day_info.missing_time', format: buildHours, key: 'missing_time' },
    },
}

export const moperAbsenteeismModel = {
    relativeKey: 'catalog_key',
    iconKey: 'catalog_key',
    moperKeys: [...Object.values(KEYS_ABSENTEEISM).slice(0, 3), 'i', 'v', 'pc', 'pg'],
    pathname: 'absenteeism_reason.absenteeism.name',
    icon: {
        vacaciones: Vacations,
        dv: Vacations,
        ausen_con_goce: WithEnjoyment,
        ausen_sin_goce: WithoutEnjoyment,
        i: IncapacityIcon,
    },
    model: {
        vacaciones: { ...omit(absenteeismDefDraft, ['Motivo']) },
        f: { ...autoMoperDraft },
        df: { ...autoMoperDraft },
        i: { ...inabilityModel },
        default: { ...absenteeismDefDraft },
    },
}

export const moperRestBankModel = {
    relativeKey: false,
    pathname: 'Descanso trabajado',
    moperKeys: [TXT_KEYS[3], KEYS_ABSENTEEISM[6]],
    validation: (item) => !item?.bonus_type && !item?.is_implicit,
    icon: 'DT',
    model: {
        key: 'dt',
        'Dia origen': { value: 'origin_day', format: reversDate, key: 'origin_day' },
        'Total de horas': { value: 'minutes_requested', format: buildHours, key: 'hours' },
    },
}

export const moperOvertimeTxtModel = {
    relativeKey: false,
    pathname: 'Tiempo por tiempo',
    moperKeys: [TXT_KEYS[2], KEYS_ABSENTEEISM[6]],
    validation: (item) => !item?.bonus_type && !item?.is_implicit,
    icon: Time,
    model: {
        'Dia origen': { value: 'origin_day', format: reversDate, key: 'origin_day' },
        'Total de horas': { value: 'minutes_requested', format: buildHours, key: 'hours' },
    },
}

export const moperOvertimeMoneyModel = {
    relativeKey: false,
    pathname: 'Dinero',
    moperKeys: [TXT_KEYS[1], KEYS_ABSENTEEISM[6]],
    icon: Money,
    validation: (item) => !item?.bonus_type && !item?.is_implicit,
    model: {
        'Dia origen': { value: 'calculated.origin_day', format: reversDate, key: 'origin_day' },
        'Total de horas': { value: 'minutes_requested', format: buildHours, key: 'hours' },
        'Monto': { value: 'total_amount', format: formatMoney, key: 'amount' },
    },
}

export const moperBonusPaymentModel = {
    relativeKey: 'bonus_type',
    iconKey: 'bonus_type',
    validation: (item) => !!item?.bonus_type || item?.is_implicit,
    moperKeys: [KEYS_ABSENTEEISM[6], TXT_KEYS[2], TXT_KEYS[4], TXT_KEYS[3], TXT_KEYS[1]],
    icon: {
        1: 'PD',
        2: 'FT',
        3: 'DT',
        4: 'TD',
        5: 'BN',
        6: 'IN',
        7: 'IM',
    },
    model: {
        1: {
            key: 'pd',
            name: 'Prima dominical',
            'Dia origen': { value: 'origin_bonus_date', format: reversDate, key: 'origin_day' },
            'Monto': { value: 'amount', format: formatMoney, key: 'amount' },
            'Porcentaje': { value: 'sunday_bonus_percent', format: (value) => `${value}%`, key: 'percentage' },
        },
        2: {
            key: 'ft',
            name: 'Festivo trabajado',
            'Dia origen': { value: 'origin_bonus_date', format: reversDate, key: 'origin_day' },
            'Monto': { value: 'amount', format: formatMoney, key: 'amount' },
            'Porcentaje': { value: 'holiday_bonus_percent', format: (value) => `${value}%`, key: 'percentage' },
        },
        3: {
            key: 'dt',
            name: 'Descanso trabajado',
            'Dia origen': { value: 'origin_bonus_date', format: reversDate, key: 'origin_day' },
            'Total de horas': { value: 'minutes_requested', format: buildHours, key: 'hours' },
            'Monto': { value: 'amount', format: formatMoney, key: 'amount' },
        },
        4: {
            key: 'td',
            name: 'Turno doble',
            'Dia origen': { value: 'origin_bonus_date', format: reversDate, key: 'origin_day' },
            'Monto': { value: 'amount', format: formatMoney, key: 'amount' },
        },
        5: {
            key: 'bn',
            name: 'Bono nocturno',
            is_implicit: true,
            'Dia origen': { value: 'origin_bonus_date', format: reversDate, key: 'origin_day' },
            'Monto': { value: 'amount', format: formatMoney, key: 'amount' },
        },
        ...implicitModels,
    },
}