import React, {
	useState,
	useRef,
	useCallback,
	useEffect,
	useMemo
} from 'react';
import { useTranslation } from "react-i18next";
import { uid } from 'uid';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import withValueField from "../Templates/withValueField";
import { listNoDataRender } from '../Templates/listNoDataRender';
import { parseRequest } from '../../../core/common/parse-request';
import { each, isEqual, debounce, size as _size, isArray, } from 'lodash';
import { forFormat, itemRender, valueRender } from './utilities';
import { asciiValidate, trim } from '../../../core/common/validations/validationFilter';
import { hasValue, valueOrOption } from '../../../core/common/GeneralUtilities';
import { KendoLabelContainer } from '../Templates/SideLabelContainer';
import { kendoSizeClasses } from '../sizesUtilities';
import { conditional } from '../../../core/@components/grid/CommandCell';
import { responsiveHeightProps } from './KendoDropDown';
const DropDownListWithValueField = withValueField(DropDownList);

const ServerDropdown = ({
	byValue,
	id,
	name,
	label,
	onChange,
	value,
	serverApi,
	extraFilters,
	format,
	fields,
	searchBy,/* Se usa para saber que propiedad es la que se le manda al back para que filtre, NOTA: si existen filterEfields y el filtro escrito cumple las reglas este no se usara  */
	filterEfields, /* En caso de que se necesite otros campos ademas del searchBy se manda esta propiedad separada por "|"  ej: "key|name"  */
	filterSplit, /* Si existen filterEfields este campo va a servir para hacer un split del filtro escrito y lo repartira entre los filterEfields */
	noDefault = false,
	searchOnExtraFilters = false,
	responsiveHeight = false,
	disabled,
	maxHeight,
	popupSettings = {},
	onlyActives = true,
	sideLabel = false,
	...others
}) => {

	const { size, className } = kendoSizeClasses(others);
	const { t } = useTranslation();
	const searchField = searchBy || "name";
	const fieldId = valueOrOption(id, "dropdown_" + uid());
	const valueField = valueOrOption(others.valueField, "value");
	const textField = valueOrOption(others.textField, "label");
	const dropdownRef = useRef();

	const defaultItem = {
		...forFormat(valueOrOption(format, "")),
		[valueField]: "",
		[textField]: null,
	};

	const pageSize = 10000;
	const [data, setData] = useState([]);
	const [filter, setFilter] = useState("");
	const extras = useRef({ ...(valueOrOption(extraFilters, {})) });
	const customMsg = useRef(null);

	const dropdownWidth = (valueOrOption(dropdownRef.current?.component, dropdownRef.current))?._element?.offsetWidth;

	const requestData = async (filter) => {
		const filters = {
			...filter,
			...extras.current,
		};

		let params = parseRequest(
			{
				tree: true,
				limit: pageSize,
				offset: 0,
				...(conditional(fields, { fields: fields }, {})),
			},
			{ filters }
		);

		if (onlyActives) {
			params["is_active"] = true;
		}

		await serverApi(params)
			.then(response => {
				customMsg.current = null;
				if (!response?.results && isArray(response)) {
					setData(response);
					return response;
				}
				setData(response.results);
				return response.results;

			})
			.then(results => {
				if (_size(results) === 1 && searchOnExtraFilters) {
					const rowValue = results[0][valueField];
					if (value !== rowValue) {
						makeChange({
							value: rowValue,
							target: { value: rowValue },
							type: "change"
						}, results[0]);
					}
				}
			})
			.catch(error => {
				customMsg.current = valueOrOption(error?.response?.data?.detail, t("error-get-message"));
				setData([]);
			});
		// eslint-disable-next-line
	};

	useEffect(() => {
		let auxFilter = filter;
		let useThis = { [searchField]: filter };
		if (filterEfields && filterSplit) {
			const filterSplited = valueOrOption(filter, "").split(filterSplit);
			const fsLength = filterSplited.length;
			if (fsLength > 1) {
				useThis = {};
				const fefSplited = filterEfields.split("|");
				let lastEfield = "";
				each(fefSplited, (eField, index) => {
					const eValue = trim(filterSplited[index]);
					auxFilter.replace(eValue || "nomatchreplace");

					if (hasValue(eValue)) {
						useThis[eField] = eValue;
					}

					if (fefSplited.length < fsLength && index === fefSplited.length - 1) {
						useThis[eField] = trim(useThis[eField] + auxFilter);
					}

					if (index > fsLength && lastEfield !== "") {
						useThis[lastEfield] = trim(useThis[lastEfield] + auxFilter);
					} else {
						lastEfield = eField;
					}
				});
			}
		}
		requestData(useThis);
		// eslint-disable-next-line
	}, [filter]);

	const onFilterChange = event => {
		const filterValue = asciiValidate(event.filter.value);
		setData([]);
		setFilter(filterValue);
	};

	const makeSearch = () => {
		if (isEqual(extras.current, extraFilters)) {
			return;
		}
		extras.current = extraFilters;
		requestData(filter);
	};

	const onOpen = useCallback(() => {
		makeSearch();
		// eslint-disable-next-line
	}, [extraFilters]);

	useEffect(() => {
		if (searchOnExtraFilters && !disabled) {
			makeSearch();
		}
		// eslint-disable-next-line
	}, [extraFilters]);

	const debouncedResults = useMemo(() => {
		return debounce(onFilterChange, 800);
	}, []);

	useEffect(() => {
		return debouncedResults.cancel();
	});

	const handleChange = (e) => {
		const finded = data.find(item => item[valueField] === e.value);
		makeChange(e, finded);
	};

	const makeChange = (e, object) => {
		e["objectValue"] = valueOrOption(object, {});
		if (typeof onChange === "function") {
			e.type = "change";
			onChange(e);
		}
	};

	const properties = {
		...others,
		size: size,
		label: "",
		ref: dropdownRef,
		id: fieldId,
		name: valueOrOption(name, fieldId),
		filterable: true,
		listNoDataRender: (element) => listNoDataRender(element, customMsg.current),
		data: data,
		value: value,
		onChange: handleChange,
		dataItemKey: valueField,
		// defaultItem: defaultItem,
		...(conditional(!noDefault, { defaultItem }, {})),
		onFilterChange: debouncedResults,
		onOpen: onOpen,
		disabled: disabled,
		className: `${valueOrOption(className, "")} asMui`,
		popupSettings: {
			...responsiveHeightProps(responsiveHeight, dropdownWidth, data, maxHeight),
			...popupSettings,
		}
	};

	const simply = !(byValue || valueField);
	return (
		<KendoLabelContainer
			label={label}
			editorId={fieldId}
			editorValue={value}
			className={"custom-floating-label"}
			sideLabel={sideLabel}
		>
			{simply && <DropDownList {...properties} />}
			{!simply && <DropDownListWithValueField
				{...properties}
				itemRender={(li, itemProps) => itemRender(li, itemProps, format || textField, valueField)}
				valueRender={(li, itemProps) => valueRender(li, itemProps, format || textField, valueField)}
				valueField={valueField}
				textField={textField}
			/>}
		</KendoLabelContainer>
	);
};

export default ServerDropdown;