import { chain } from "lodash";

export const MAP_REQUEST_PAYROLL = (key, data) => {
  data.employer_registration =  data.employer_registration === '*' ? data.employer_registration :  data.employer_registration?.toString();
  data.area=  data?.area === '*' ? data?.area : data?.area?.toString();
  data.branch = data?.branch === '*' ? data?.branch : data?.branch?.toString();
  data.department= data?.department === '*' ? data?.department : data?.department?.toString();

  //Funcion para mapear la data antes de ser enviada al back 
  const item = {
    "reports-payroll-concepts": ({
      period,
      employer_registration,
      branch,
      area,
      department,
      backend_path,
      vacation_cap4,
      total_zero,
      order1,
      concept
    }) => {
      const order_by = order1 ? "1,3,5" : "10,7,1"
      const map_concept = chain(concept)
        .pickBy(value => value === true)
        .keys()
        .map(key => key.replace('_', ''))
        .value()
        .toString()

      const request = {
        period,
        employer_registration,
        branch,
        area,
        department,
        vacation_cap4,
        total_zero,
        order_by,
        concept: map_concept,
        backend_path
      }
      return request
    },
    "reports-differences-between-periods":({ 
      employer_registration,
      branch,
      backend_path,
      difference,
      payroll_period_initial, 
      payroll_period_final
    }) => {
      const request = {
        payroll_period_initial,
        payroll_period_final,
        employer_registration,
        branch,
        backend_path,
        difference
      }
      return request
    }
  }


  if (item[key]) {
    return item[key](data)
  }
  
  return data
}