import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.scss";
import Button from '../../../styled/Button';
import IconUse from '../../../common/IconUse';
import useHeader from './useHeader';
import SwitchMui from '../../../../App/components/Booleans/SwitchMui';
import { usePackages } from '../usePackages';
import FormDialog from '../../../../App/components/dialog/FormDialog';
import DialogContent from '../../../styled/DialogContent';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { Grid as GridKendo, GridColumn } from '@progress/kendo-react-grid'
import { IconButton as IconButtonCustom } from '../../../../App/components/Buttons';
import { faPen, faXmark, faCheck } from "@fortawesome/pro-light-svg-icons";
import { faPen as faPenAlt } from "@fortawesome/pro-solid-svg-icons";
import { searchLabel } from '../util';

const buttonAlias = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end"
}

function Index() {
  const { handleSelectPackage } = useHeader()
  const { selectPackage, data, handleEditPackage } = usePackages()

  return (
    <div id="header_package">
      <div className='content'>
        <Grid container spacing={1}>
          <Grid item container direction="row" xs={11} spacing={1} className="list-module">
            {data.map((m, i) => {
              const Icon = m.icon
              return <Grid item key={i} style={{ paddingLeft: "20px" }}>
                <button
                  className={`item-module ${m.id === selectPackage?.id ? "item-select" : ""}`}
                  onClick={(e) => handleSelectPackage(e, m)}>
                  {m.icon && <Icon width="20" height="20" />}
                  <div className={`${searchLabel(m.alias) ? '':'capitalize-first-letter'}`}>
                    {m.alias.toUpperCase()}
                  </div>
                  {m?.editable && <SwitchMui
                    checked={m.value}
                    onChange={(e) => handleEditPackage("value", e.value, m)}
                    size="small"
                  />}
                </button>
              </Grid>
            })}
          </Grid>
          <Grid item xs={1} sx={buttonAlias}>
            <ChangeAlias />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Index

function ChangeAlias() {
  const { t } = useTranslation()
  const { data, handleEditPackage } = usePackages()
  const editField = 'inEdit'
  const [showModal, setShowModal] = useState(false)
  const originalData = useRef([])
  const [source, setSource] = useState([])

  useEffect(() => {
    if (!data.length) return
    originalData.current = data.filter(f => !KEYS[f.key])
    setSource(data.filter(f => !KEYS[f.key]))
  }, [showModal, data])

  const KEYS = {
    "abrhil_package": true,
    "human_resource_package": true,
  }

  const itemChange = (event) => {
    const field = event.field || ''
    const newData = source.map((item) =>
      item.key === event.dataItem.key
        ? {
          ...item,
          [field]: event.value.toUpperCase()
        }
        : item
    )
    setSource(newData)
  }
  const CustomCell = (props) => <CommandCell {...props} edit={onEdit} update={onUpdate} cancel={onCancel} editField={editField} />

  const onEdit = (dataItem) => {
    setSource(
      source.map((item) => {
        if (item.id === dataItem.id) {
          return { ...item, inEdit: true }
        } else {
          return { ...item, inEdit: false }
        }
      })
    )
  }

  const onUpdate = async (dataItem) => {
    if (!dataItem.alias) return
    handleEditPackage("alias", dataItem.alias, dataItem)
  }

  const onCancel = () => setSource(originalData.current)
  return <React.Fragment>
    <Button className={`systemButton`} onClick={() => setShowModal(true)}>
      <IconUse icon={faPen} />
      <span className="buttonLabel">{t("edit-name")}</span>
    </Button>
    <FormDialog
      open={showModal}
      title={t('edit-name')}
      maxWidth='md'
      handleClose={() => {
        setShowModal(false)
      }}
      dynamicComponent={
        <form autoComplete='off' noValidate>
          <DialogContent dividers className='modal-content'>
            <Card>
              <CardContent>
                <GridKendo data={source} onItemChange={itemChange} editField={editField}>
                  <GridColumn className="upper-text" field='alias' title={t('alias')} />
                  <GridColumn title={t('actions')} cell={CustomCell} width={100} />
                </GridKendo>
              </CardContent>
            </Card>
          </DialogContent>
        </form>
      }
    />
  </React.Fragment>

}

const CommandCell = ({
  editField,
  update,
  cancel,
  edit,
  dataItem,
}) => {

  const { t } = useTranslation();

  if (dataItem[editField]) {
    return (
      <td className="k-command-cell">
        <IconButtonCustom
          onClick={() => update(dataItem)}
          iconProps={{ style: { color: "var(--green)" } }}
          title={t("save")}
          icon={faCheck}
        />
        <IconButtonCustom
          onClick={() => cancel(dataItem)}
          iconProps={{ style: { color: "var(--red)" } }}
          title={t("cancel")}
          icon={faXmark}
        />
      </td>
    );
  }

  return (
    <td className="k-command-cell">
      <IconButtonCustom
        onClick={() => edit(dataItem)}
        title={t("edit")}
        icon={faPen}
        altIcon={faPenAlt}
      />
    </td>
  );
};