import PropTypes from "prop-types"
import React, { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import i18next from 'i18next';
import { isFunction, omit, pick, size } from 'lodash';
/* material ui */
import MuiIB from '@mui/material/IconButton';/* IconButton */
import Stack from '@mui/material/Stack';
import {
	faFileExport,
	faFileArrowDown,
} from "@fortawesome/pro-light-svg-icons";
import { faX } from "@fortawesome/pro-regular-svg-icons";
/* components */
import Button from "../../core/styled/Button";
import PermissionsGate from '../../permissions/PermissionsGate';
import { showAdvanceFilters, showNotificationWarning } from '../../store/actions';
import { hasErrorTetant } from '../../core/common/validate-tenant';
import { errorTenant } from '../../core/common/notification-messages';
import { CustomTooltip } from './Templates/cells';
import IconUse from '../../core/common/IconUse';

const { t } = i18next;

export const ButtonsContainer = ({
	spacing,
	className,
	children,
	justifyContent,
	style = {}
}) => (
	<Stack
		style={style}
		spacing={spacing ?? 1}
		direction="row"
		justifyContent={justifyContent || "flex-end"}
		className={className ?? "mb-3"}
		alignItems="center"
	>
		{children}
	</Stack>
);

ButtonsContainer.propTypes = {
	children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element,
    ]),
	className: PropTypes.string,
	justifyContent: PropTypes.string,
	spacing: PropTypes.number,
	style: PropTypes.object
}

/**
 *
 * @param {object} params
 * @param {string} params.label - Label de texto que sera mostrado dentro del boton
 * @param {FontAwesomeIcon|"string"} params.icon - Icono badado en componente react de fontawesome
 * @param {function} params.onClick - Función que ae realizara al momento de dar click em el boton
 * @param {?string} params.className - Una clase custom si es que la necesitara
 * @param {?"start"|"end"} params.iconPlacement - Indica si el icono se va a poner al principio o al final del boton
 * @param {?"contained"|"text"|"flat"|"create"|"outlined"} params.design - Indica el estilo del boton que se va a usar, por default outlined
 * @param {?"error"|"success"|"info"} params.color - Indica que color se va a usar para el boton
 * @returns {BaseButtonComponent}
 */
export const BaseButton = forwardRef(function ButtonFunction({
	label,
	icon,
	onClick,
	className,
	iconPlacement = "start",
	children,
	...others
}, ref) {
	return (
		<Button {...others} innerRef={ref} className={`${className ?? ""} systemButton`} onClick={onClick} data-cy={others["data-cy"] ?? label}>
			{icon && iconPlacement !== "end" ? <IconUse icon={icon} /> : null}
			{children}
			<span className={`buttonLabel ${icon ? "" : "no-icon"} ${iconPlacement === "end" ? "is-end-icon" : ""} ${iconPlacement === "center" ? "is-center-icon" : ""} `}>
				{t(label)}
			</span>
			{icon && iconPlacement === "end" ? <IconUse icon={icon} /> : null}
		</Button>
	);
});

BaseButton.propTypes = {
	children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element,
    ]),
	className: PropTypes.string,
	icon: PropTypes.any,
	iconPlacement: PropTypes.string,
	label: PropTypes.any,
	onClick: PropTypes.func
}

export const Refresh = ({
	sx,
	onClick,
	className,
	disabled = false,
	label = t('consult'),
	...others
}) => (
	<PermissionsGate scopes={['read']}>
		<Button {...others} disabled={disabled} className={`${className ?? ""} systemButton`} onClick={onClick} data-cy="refresh">
			<IconUse icon={'refresh'} size={sx || "1x"} />
			<span className="buttonLabel">{label}</span>
		</Button>
	</PermissionsGate>
);

Refresh.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	label: PropTypes.func,
	onClick: PropTypes.func,
	sx: PropTypes.any
}

export const Create = ({
	sx,
	onClick,
	style = {},
	className,
	disabled = false,
	title = t('add-new'),
	scopeProps = {},
}) => (
	<PermissionsGate scopes={['write']} {...scopeProps}>
		<Button design='contained' style={style} disabled={disabled} className={`${className ?? ""} systemButton`} onClick={onClick} data-cy="create">
			<IconUse icon={"new"} size={sx || "1x"} />
			<span className="buttonLabel">{title}</span>
		</Button>
	</PermissionsGate>
);

Create.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	scopeProps: PropTypes.object,
	style: PropTypes.object,
	sx: PropTypes.any,
	title: PropTypes.any
}

export const Edit = ({
	onClick,
	label,
	className,
	disabled = false,
}) => (
	<PermissionsGate scopes={['write']}>
		<Button className={`${className ?? ""} systemButton`} onClick={onClick} disabled={disabled} data-cy="edit">
			<IconUse icon={"edit"} />
			<span className="buttonLabel">{label || t('edit')}</span>
		</Button>
	</PermissionsGate>
);

Edit.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	label: PropTypes.any,
	onClick: PropTypes.func
}

export const SaveAndCancel = ({
	onCancel,
	className,
	disabled = false,
	hideCancel = false,
	onSave
}) => {

	const commons = {
		className: `${className ?? ""} systemButton`,
		design: "contained",
		style: { marginLeft: "10px" }
	};
	const saveProps = isFunction(onSave) ? { onClick: onSave } : { type: "submit" };
	return (
		<>
			{!hideCancel && <Cancel {...commons} onClick={onCancel} />}
			<PermissionsGate scopes={['write']}>
				<Button {...commons} disabled={disabled} {...saveProps}>
					<IconUse icon={"save"} />
					<span className="buttonLabel">{t("save")}</span>
				</Button>
			</PermissionsGate>
		</>
	);
};

SaveAndCancel.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	hideCancel: PropTypes.bool,
	onCancel: PropTypes.func,
	onSave: PropTypes.func
}

export const Cancel = ({
	className,
	style,
	...others
}) => {
	return (
		<Button
			color="error"
			type="button"
			className={`${className ?? ""} systemButton`}
			style={{ marginLeft: "10px", ...style }}
			{...others}
		>
			<IconUse icon={faX} />
			<span className="buttonLabel">{t("cancel")}</span>
		</Button>
	);
};

Cancel.propTypes = {
	className: PropTypes.string,
	style: PropTypes.any
}

/**
 * Boton especial para la importación de datos en el sistema
 * @param {object} params
 * @param {function} params.onChange - Esta funcion es la que se ejecuta al momento de guardar la importación
 * @param {?object} params.sx - Estilos que se le aplican al icono del boton
 * @param {?function} params.onClick - Función que se aplica al clickear sobre el boton
 * @returns {ImportButton}
 */
export const Import = (
	//{
	// sx,
	// onClick,
	// onChange,
	// className,
	//}
) => {
	return null;
};

/**
 *
 * @param {object} params
 * @param {?object} params.sx - Estilos que se le aplican al icono del boton
 * @param {function} params.onClick - Función que se aplica al clickear sobre el boton
 * @returns {ExportButton}
 */
export const Export = ({
	sx,
	onClick,
	className,
	disabled = false
}) => (
	<PermissionsGate scopes={['read']}>
		<Button disabled={disabled} className={`${className ?? ""} systemButton`} onClick={onClick} data-cy="export">
			<IconUse icon={faFileExport} size={sx || "1x"} />
			<span className="buttonLabel">{t('export')}</span>
		</Button>
	</PermissionsGate>
);

Export.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	sx: PropTypes.any
}

export const Download = ({
	className,
	sx,
	onClick,
	label = t('download'),
	disabled = false,
	icon = faFileArrowDown
}) => (
	<PermissionsGate scopes={['read']}>
		<Button className={`${className ?? ""} systemButton`} onClick={onClick} disabled={disabled} type="submit" data-cy="download">
			<IconUse icon={icon} size={sx || "1x"} />
			<span className="buttonLabel">{label}</span>
		</Button>
	</PermissionsGate>
);

Download.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.any,
	label: PropTypes.any,
	onClick: PropTypes.func,
	sx: PropTypes.any
}

export const AdvanceFilters = ({
	validateTenant = true,
	filters,
	fields,
	onFilter,
	className = "",
	omitFields = [],
	pickFields = [],
	initialFilters,
	customCounter = false,
	count = 0,
	isModernButton = false,
	withLabel = true,
	disabled = false,
	withoutActive = false
}) => {

	const dispatch = useDispatch();
	const { show } = useSelector(state => state.filter);
	const used = omit(filters?.filters || filters || {}, ["with_permission_user", "search", ...omitFields]);
	const pickUsed = size(pickFields) ? pick(used, pickFields) : used;

	const getFiltersCount = (used) => {
		let count = size(used);
		if (used?.filters) {
			count -= 1;
		}
		if (withoutActive && count > 1) {
			count -= 1;
		}
		return count;
	};

	let total = getFiltersCount(pickUsed);

	if (customCounter) {
		total = count;
	}

	const openAdvanceFilters = () => {
		if (disabled) { return; }
		if (hasErrorTetant() && validateTenant !== false) {
			dispatch(showNotificationWarning(errorTenant()));
			return;
		}

		dispatch(showAdvanceFilters({
			onFilter: onFilter,
			initialFilters: initialFilters,
			fields: fields,
			filters: filters
		}));
	};

	useEffect(() => {
		if (!show) { return; }
		openAdvanceFilters();
		// eslint-disable-next-line
	}, [fields]);

	if (isModernButton) {
		const hasFilters = total > 0;
		return (
			<button
				className={`modern-advanced-filters ${className} ${hasFilters ? 'active' : ''}`}
				onClick={openAdvanceFilters}
				data-cy="advance-filters"
				disabled={disabled}
			>
				<IconUse icon={"AdvanceFilters"} />
				{withLabel && <span>{t("advanced-filters")}</span>}
				{hasFilters && <span className="counter-dot">{total}</span>}
			</button>
		);
	}

	return (
		<Button
			className={`${className ?? ""} systemButton ${total ? "activeButtonFilter" : ""}`}
			onClick={openAdvanceFilters}
			data-cy="advance-filters"
			disabled={disabled}
		>
			<IconUse icon={"AdvanceFilters"} />
			<span className="buttonLabel">{t("advanced-filters")}</span>
			<span className="numberSpan k-badge-solid k-badge-primary">{total}</span>
		</Button>
	);
};

AdvanceFilters.propTypes = {
	className: PropTypes.string,
	count: PropTypes.number,
	customCounter: PropTypes.bool,
	disabled: PropTypes.bool,
	fields: PropTypes.any,
	filters: PropTypes.any,
	initialFilters: PropTypes.any,
	isModernButton: PropTypes.bool,
	omitFields: PropTypes.array,
	onFilter: PropTypes.any,
	pickFields: PropTypes.array,
	validateTenant: PropTypes.bool,
	withLabel: PropTypes.bool,
	withoutActive: PropTypes.bool
}

/**
 * Bottton general del sistema para aplicar los icon buttons
 * @param {object} params
 * @param {function} params.onClick - función acción del boton
 * @param {node} params.icon - tiene que ser un icon de fontawesome
 * @param {node} params.altIcon - tiene que ser un icon de fontawesome
 * @param {object} params.buttonProps - objeto con propiedades del boton, son todas las que puede tener el icon button de material
 * @param {object} params.iconProps - objeto con propiedades del icono, son toda las que puede contener el Elemento de fontAwesome
 * @param {?string} params.title - El titulo a mostrar al posicionarse sobre el boton
 * @param {?"contained"|"text"|"flat"|"create"|"outlined"} params.design - Indica el estilo del boton que se va a usar, por default outlined
 * @returns {ButtonNode}
 */
export const IconButton = ({
	onClick,
	icon,
	altIcon,
	buttonProps,
	iconProps,
	color,
	title = "",
	disabled = false,
	design = "flat",
}) => (
	<CustomTooltip title={disabled || buttonProps?.disabled ? (buttonProps?.title ?? "") : title} >
		<div style={{ display: "inline-flex" }}>
			<MuiIB
				size={"small"}
				disabled={disabled}
				color={color}
				{...(omit(buttonProps, ["title"]) || {})}
				className={`design-${design} ${buttonProps?.className ?? ""} actionButton ${altIcon ? "hasAlt" : ""}`}
				onClick={onClick}
			>
				{altIcon && <IconUse {...(iconProps || {})} className={`${iconProps?.className ?? ""} alt`} icon={altIcon} />}
				<IconUse {...(iconProps || {})} className={`${iconProps?.className ?? ""} normal`} icon={icon} />
			</MuiIB>
		</div>
	</CustomTooltip>
);

IconButton.propTypes = {
	altIcon: PropTypes.any,
	buttonProps: PropTypes.any,
	color: PropTypes.any,
	design: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.any,
	iconProps: PropTypes.any,
	onClick: PropTypes.func,
	title: PropTypes.string
}
