import {isAbrhil} from '../common/auth';
import {ServerAPI} from './ServerAPI';

export const ApiAbrhilSettings = id => {
  return ServerAPI (`companies/${id}/settings`, false);
};

export const ApiAbrhilSettingsUpdate = id => {
  return ServerAPI (`companies/${id}/settings/update`, false);
};

export const ApiAbrhilPackageUpdate = id => {
  return ServerAPI (`companies/${id}/package/update`, false);
};

export const ApiAbrhiReduxSettings = id => {
  return ServerAPI (`companies/${id}/redux-settings`, false);
};

export const packagesV2Api = id => {
  return {
    packages: ServerAPI (
      `${isAbrhil () ? `companies/${id}/` : ''}packages`,
      false
    ),
    packagesUpdate: ServerAPI (
      `${isAbrhil () ? `companies/${id}/` : ''}package/update`,
      false
    ),
    settings: ServerAPI (
      `${isAbrhil () ? `companies/${id}/` : ''}settings`,
      false
    ),
    settingsUpdate: ServerAPI (
      `${isAbrhil () ? `companies/${id}/settings/update` : 'settings'}`,
      false
    ),
  };
};

export const ApiClientReduxSettings = ServerAPI ('redux-settings', false);
export const ApiAdminPackages = ServerAPI ('packages', false);
export const ApiClientSettings = ServerAPI ('settings', false);
export const ApiClientPackage = ServerAPI ('package', false);

export const ApiAdminPackage = name => {
  return ServerAPI (name, false);
};
