import { isBefore } from "date-fns";
import { size as _size } from 'lodash';
import { parseDatetime } from "../../../../core/common/GeneralUtilities";
import { computedType, periodStatus } from "../../../../core/modules/PayrollPeriods/dictionaries";

export const backColor = (option) => {
	if (!_size(option)) { return {}; }
	return {
		"1-1": { color: "var(--greenAction)", backgroundColor: "var(--greenActionBg)" },
		"0-1": { color: "var(--blueAction)", backgroundColor: "var(--blueActionBg)" },
		"1-0": { color: "var(--orangeAction)", backgroundColor: "var(--orangeActionBg)" },
	}[payrollValueType(option)] ?? {};
};

export const payrollPeriodFormatLavel = (option, simply) => {
	if (!_size(option)) {
		return simply ? "" : {};
	}

	const { key, start_date, end_date, } = option;
	if (simply) {
		return `${key} ${reversDate(start_date)} - ${reversDate(end_date)}`;
	}

	return {
		key: key,
		dates: `${reversDate(start_date)} - ${reversDate(end_date)}`
	};
};

export const reversDate = (date) => date?.split("-").reverse().join("/");

export const payrollValueType = option => `${option?.list ? 1 : 0}-${option?.payroll ? 1 : 0}`;

export const isPeriodLockedOrClosed = (item) => isLocked(item) || isClosed(item);

export const isClosed = (item) => item?.status === periodStatus.close;

export const isLocked = (item) => {
	if (item?.computed_type === computedType.settlement) {
		return false;
	}
	return isBefore(parseDatetime(item?.locked_at, "asDate"), new Date());
};

