import React, { useCallback, useEffect, useState } from "react";
import { Stepper, Step } from '@progress/kendo-react-layout';
import { CustomTooltip } from "../../../../../../../../App/components/Templates/cells";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
} from "@fortawesome/pro-light-svg-icons";
import {
    faCircle,
    faXmark,
    faPen,
} from "@fortawesome/pro-solid-svg-icons";
import { isFunction, omit, size } from "lodash";
import { resolveError } from "../../../../../../../common/resolve-error";
import { moperSignaturesApi } from "../../../../../../../services/mopers";
import { currentTenant } from "../../../../../../../common/validate-tenant";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
    lockedWindow,
    unlockedWindow,
} from "../../../../../../../../store/actions";
import { hasValue } from "../../../../../../../common/GeneralUtilities";
// import { signatureStatus } from "../../utils/utilities";
import TooltipCancel from "./TooltipCancel";
import { parseDate } from "../../../../../../Attendance/components/Table/common/utilities";
import { CancelMoper } from "../../../../../../SignaturesByWorker/components/Details/components/DynamicMoper";
import { removeDatasFailed, useRequestLoad } from "../../hooks/useResolveIncidence";
import { Loader } from "../../../../../../SignaturesByWorker/components/Shared";
import { showSuccessNotification } from "../../../../../../../../App/components/Notifications";
import "./styles.scss"

/**
* Componente para visualizar y firmar el flujo de firmas
* @param { object } props
* @param { object } props.checkItem : Elemento de tipo checada el cual debe contener flow_signatures y su respectivo objeto moper
* @param { function } props.onChange : Función extra a ejecutar cuando se firme
* @param { function } props.handleCancel : Función personalizada para cancelación de requisición, la función recibe como prop el elemento cancel del flujo de firmas
* @param { function } props.handleEdit : Función personalizada para la edición de la requisición
* @param { boolean } props.minimal : Variable que determina si mostrar la versión simplificada con el texto minimo conservando el diseño original
* @param { boolean } props.forTable : Variable que determina si mostrar la versión simplificada para tablas o no, por defecto en false
* @param { boolean } props.withWorkgroup : Variable que determina si mostrar el grupo de trabajo en la parte inferior de la firma
* @param { boolean } props.showLevel : Variable que determina si mostrar el nivel en la parte inferior de la firma
* @param { boolean } props.noSeparation : Variable que determina si mostrar separación entre la firma y la linea de progreso, también elimina el fondo
* @param { boolean } props.customSign : Variable que determina si usar la función por defecto para firmar, si está activa se utilizará la función onChange, por defecto en false
* @param { object } props.signByGroup : Objeto para firmar por grupo, que incluye el moper_catalog y la propiedad que determina el identificador del grupo de la firma, puede ser el worker o workgroup, por defecto se firma individualmente
* @param { boolean } props.showCancelSign : Variable que determina si mostrar el botón de cancelar, por defecto en true
* @example
* <Flow
*	item={checkElement}
*	onChange={handleChange}
*   handleCancel={handleCancel}
* />
* @version 1.1.1
* @author Emilio Jarey Mendez Torres
**/

function Flow({
    onChange,
    checkItem,
    handleEdit,
    handleCancel,
    signByGroup,
    onlyRead = false,
    showLevel = true,
    onlyCancel = false,
    withWorkgroup = true,
    forTable = false,
    noSeparation = false,
    minimal = false,
    customSign = false,
    showCancelSign = true,
    stepperClassName = ""
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [step, setStep] = useState(0);
    const {
        moper,
        parent_moper_id,
        flow_signature: signatures
    } = checkItem ?? {};

    const showEdit = isFunction(handleEdit);
    const cancelSign = signatures?.find(el => el?.name === 'cancel');
    const canceled = cancelSign?.is_signed;
    const [items, setItems] = useState([]);
    const { loading, fetch } = useRequestLoad();

    useEffect(() => {
        if (!signatures) return;
        const flowItems = signatures.map((el, key) => ({
            label: el?.name,
            status: el?.is_signed,
            order: el?.order,
            byUser: el?.can_sign && !onlyRead,
            // user_sign: el?.user_sign,
            signed_at: el?.signed_at,
            signed_by: el?.signed_by,
            next: (!!signatures?.[key - 1]?.is_signed && !el?.is_signed) || (key === 0 && !el?.is_signed && el?.can_sign),
        })).sort((a, b) => a.order - b.order).filter(el => el?.label !== 'cancel');
        setItems(flowItems);
        const currentSign = getLastSignature(flowItems);
        setTimeout(() => {
            setStep(currentSign);
        }, 300);
    }, [signatures])

    function getLastSignature(signatures) {
        let lastSign = 0;
        for (let i = size(signatures) - 1; i >= 0; i--) {
            if (signatures[i]?.status === true) {
                lastSign = i;
                break;
            }
        }
        return lastSign;
    }

    const handleSign = useCallback((e) => {
        if (loading) return;
        const index = e.value;
        const canSign = items[index]?.byUser && items[index]?.next;
        const signed = items[index]?.status;
        if ((!canSign || signed) && !signByGroup) return;
        if (!hasValue(signByGroup?.workgroup) && customSign) {
            if (isFunction(onChange)) {
                onChange(items);
            }
            return;
        }
        signByGroup ? signGroup() : singleSign(e, index);
    }, [loading, items]);

    const simulateSign = (index, value, sign = true) => {
        const newItems = [...items];
        const nextIndex = value + 1;
        newItems[index].status = sign;
        newItems[index].next = !sign;
        if (newItems[nextIndex]) {
            newItems[nextIndex].next = sign;
            if (newItems[nextIndex].user_sign) {
                newItems[nextIndex].byUser = sign;
            }
        }
        signatures[index].is_signed = sign;
        setItems(newItems);
        if (!sign && index > 0) {
            index -= 1;
        }
        setStep(index);
    }

    async function singleSign(e, index) {
        const itemSign = signatures[e.value];
        const request = {
            tenant: currentTenant(),
            order: itemSign?.order,
            workgroup_id: itemSign?.workgroup_id,
            moper: moper?.id ?? parent_moper_id,
        }
        simulateSign(index, e.value, true);
        fetch({
            api: moperSignaturesApi.sign(request),
            callback: async (resp) => {
                showSuccessNotification(resp?.data);
                if (isFunction(onChange)) {
                    await onChange(resp);
                }
            },
            onFailed: (err) => {
                simulateSign(index, e.value, false);
                removeDatasFailed(err);
                resolveError(err);
            }
        });
    }

    async function signGroup() {
        dispatch(lockedWindow());
        const requestToUrl = { moper_catalog: signByGroup.moper_catalog };
        const body = omit(signByGroup, 'moper_catalog');
        try {
            const response = await moperSignaturesApi.signGroup(body, requestToUrl);
            dispatch(unlockedWindow());
            showSuccessNotification(response?.data);
            if (isFunction(onChange)) {
                onChange(response);
            }
        } catch (error) {
            dispatch(unlockedWindow());
            resolveError(error);
        }
    }

    function handleCancelRequest() {
        if (isFunction(handleCancel)) {
            handleCancel(checkItem);
        }
    }

    function handleEditRequest() {
        if (isFunction(handleEdit)) {
            handleEdit(checkItem);
        }
    }

    if (!size(signatures?.filter(el => el?.name !== 'cancel'))) return null;

    if (forTable) {
        return (
            <td className={`custom-stepper-flow-table ${stepperClassName}`}>
                <CustomStepper
                    items={items}
                    step={step}
                    forTable={forTable}
                    onlyRead={onlyRead}
                    loading={loading}
                    cancelSign={cancelSign}
                    canceled={canceled}
                    showCancel={showCancelSign}
                    showEdit={showEdit}
                    onChange={onChange}
                    isCustomCancel={isFunction(handleCancel)}
                    handleSign={handleSign}
                    handleEdit={handleEditRequest}
                    handleCancelRequest={handleCancelRequest}
                    t={t}
                    signByGroup={signByGroup}
                    checkItem={checkItem}
                />
            </td>
        );
    }

    return (
        <div className={`custom-stepper-flow-signature ${stepperClassName}`}>
            <CustomStepper
                items={items}
                step={step}
                canceled={canceled}
                onlyRead={onlyRead}
                loading={loading}
                cancelSign={cancelSign}
                showCancel={showCancelSign}
                isCustomCancel={isFunction(handleCancel)}
                showEdit={showEdit}
                showLevel={showLevel}
                onlyCancel={onlyCancel}
                withWorkgroup={withWorkgroup}
                noSeparation={noSeparation}
                minimal={minimal}
                onChange={onChange}
                handleSign={handleSign}
                handleEdit={handleEdit}
                handleCancelRequest={handleCancelRequest}
                t={t}
                signByGroup={signByGroup}
                checkItem={checkItem}
            />
        </div>
    );
}

export default Flow;

const CustomStepper = ({
    step,
    items,
    onlyRead,
    loading,
    isCustomCancel,
    onChange,
    handleSign,
    showCancel,
    cancelSign,
    onlyCancel,
    minimal,
    showLevel,
    showEdit,
    canceled,
    handleEdit,
    noSeparation,
    withWorkgroup,
    handleCancelRequest,
    forTable,
    t,
    signByGroup,
    checkItem
}) => {

    function getOptionCancel() {
        return (
            <>
                {showEdit &&
                    <CustomTooltip
                        title={t('edit')}>
                        <div className={`cancel-request-button-wrapper`}>
                            <div className={`cancel-button edit`} onClick={handleEdit}>
                                <FontAwesomeIcon icon={!showEdit ? faXmark : faPen} />
                            </div>
                            <span>{t('edit')}</span>
                        </div>
                    </CustomTooltip>}
                {(showCancel && !onlyRead) &&
                    <CancelMoper
                        moper={checkItem}
                        disabled={loading}
                        onClick={isCustomCancel ? handleCancelRequest : null}
                        onCancel={onChange}
                    />}
            </>
        );
    }

    return (
        hasValue(signByGroup) ?
            (
                <div className="custom-sign-group-wrapper">
                    <div className="sign-click">
                        <span className="sign" onClick={handleSign}>{t("sign")}</span>
                        {showCancel &&
                            <span className="cancel" onClick={handleCancelRequest}>{t("cancel")}</span>
                        }
                    </div>
                    <span>{t("group-sign")}</span>
                </div>
            )
            : (
                <div className="custom-flow-stepper-wrapper">
                    <CustomTooltip title={canceled ? <TooltipCancel cancelSign={cancelSign} checkItem={checkItem} /> : ""} placement="bottom" style={{ width: "100%" }}>
                        <>
                            {
                                !onlyCancel &&
                                <Stepper
                                    id="custom-stepper"
                                    className={`flow-custom-stepper ${canceled ? 'canceled' : ''}`}
                                    value={step}
                                    items={items}
                                    linear
                                    item={(props) =>
                                        <CustomStep
                                            {...props}
                                            onClick={!canceled ? handleSign : null}
                                            loading={loading}
                                            forTable={forTable}
                                            noSeparation={noSeparation}
                                            withWorkgroup={withWorkgroup}
                                            showLevel={showLevel}
                                            minimal={minimal}
                                            canceled={canceled}
                                        />}
                                />
                            }
                            {(showCancel || showEdit) && getOptionCancel()}
                        </>
                    </CustomTooltip>
                </div>
            )
    );
};

const Node = ({ status, next, loading }) => {

    if (status) {
        return <FontAwesomeIcon icon={faCircleCheck} />;
    }

    if (next) {
        return <FontAwesomeIcon icon={faCircle} />;
    }

    if (loading) {
        return <Loader size={15} />;
    }

    return null;
}

const CustomStep = ({
    byUser,
    status,
    next,
    label,
    loading,
    minimal,
    onClick,
    showLevel,
    noSeparation,
    withWorkgroup,
    forTable,
    canceled,
    signed_at,
    signed_by,
    index,
    ...others
}) => {

    const canSign = byUser && next;

    return (
        <Step {...others} className={`flow-custom-step ${canSign ? 'can-sign' : ''} ${status ? 'signed' : ''}`}>
            <CustomTooltip title={
                <TooltipInfo
                    signedBy={signed_by}
                    signedAt={signed_at}
                    forTable={forTable || minimal}
                    canceled={canceled}
                    label={label}
                    byUser={byUser}
                    status={status}
                />
            }>
                <div className={`k-step-custom-wrap ${noSeparation ? 'no-padding' : ''}`}>
                    <button
                        onClick={() => (canSign && !loading) ? onClick({ value: index }) : null}
                        className={`k-step-indicator ${canSign ? 'can-sign' : ''} ${status ? 'signed' : ''} ${next ? 'next' : ''}`}
                        aria-hidden="true"
                    >
                        <span className="k-step-indicator-text">
                            <Node status={status} next={next} loading={loading} />
                        </span>
                    </button>
                </div>
            </CustomTooltip>
            {
                !forTable && !minimal ?
                    <CustomTooltip title={label}>
                        <span className={`k-step-label ${canSign ? 'can-sign' : ''} ${status ? 'signed' : ''}`}>
                            {showLevel &&
                                <span className="level-label">
                                    {`Nivel ${index + 1}`}
                                </span>
                            }
                            {
                                withWorkgroup &&
                                <div className="wg-info-wrapper">
                                    <span className={`k-step-text-custom`}>
                                        {label}
                                    </span>
                                </div>
                            }
                            <span className={`sign-status ${status ? 'success' : ''} ${!status && next ? 'in-process' : ''}`}>
                                {canceled ? 'Cancelado' : byUser ? (status ? 'Completado' : 'Firma para continuar') : (status && !next ? 'Completado' : status && next ? 'En proceso' : !status && 'Pendiente')}
                            </span>
                        </span>
                    </CustomTooltip>
                    : (minimal && (showLevel || withWorkgroup)) ?
                        <span className={`k-step-label ${canSign ? 'can-sign' : ''} ${status ? 'signed' : ''}`}>
                            {showLevel &&
                                <span className="level-label">
                                    {`Nivel ${index + 1}`}
                                </span>
                            }
                            {
                                withWorkgroup &&
                                <div className="wg-info-wrapper">
                                    <span className={`k-step-text-custom`}>
                                        {label}
                                    </span>
                                </div>
                            }
                        </span>
                        :
                        null
            }
        </Step>
    );
};

export const TooltipInfo = ({
    signedAt,
    signedBy,
    forTable,
    canceled,
    label,
    byUser,
    status,
}) => {

    return (
        <div className="custom-tooltip-flow-signature">
            {
                status ?
                    <div>
                        {
                            forTable ?
                                <span>
                                    {label}
                                </span>
                                :
                                null
                        }
                        <span className="date">
                            {signedAt && parseDate(signedAt, 'dd/MM/yyyy HH:mm')}
                        </span>
                        <span className="author">
                            {signedBy}
                        </span>
                    </div>
                    : byUser ?
                        <div>
                            {
                                forTable ?
                                    <span>
                                        {label}
                                    </span>
                                    :
                                    null
                            }
                            {
                                !canceled ?
                                    <span>
                                        {'Firmar'}
                                    </span> :
                                    null
                            }
                        </div>
                        :
                        <span>{label}</span>
            }
        </div>
    );
};

export const NotificationMessage = ({
    title,
    message,
    showTitle = true,
}) => {
    const { t } = useTranslation();

    return (
        <div className="notification-custom-body-message">
            {showTitle && <span className="custom-notification-title">{title ?? t('success-general-title')}</span>}
            <span className="custom-notification-content">{message}</span>
        </div>
    );
};