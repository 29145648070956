import PropTypes from "prop-types"
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";
import { find, size } from "lodash";
import { dropdownProps as PropGen} from './utilities';
import FormInput from '../../../../form/Field';
import { valueOrOption } from '../../../../../common/GeneralUtilities';

export const RolesDropdown = ({
	control,
	setValue,
	getValues,
	roles = [],
	dropdownProps = null
}) => {

	const { t } = useTranslation();
	const company = useWatch({ control, name: "company" });
	const data = find(roles, ["company", company])?.roles ?? [];

	useEffect(() => {
		if (!size(data)) { return; }
		const id = getValues("role");
		const current = find(data, ["id", id]);
		setValue("role", valueOrOption(current?.id, null));
		//eslint-disable-next-line
	}, [data, company]);

	return (
		<FormInput
			{...dropdownProps || PropGen}
			data={data}
			control={control}
			name={"role"}
			label={t("role")}
			onChange={e => {
				setValue("roleObj", e.objectValue);
				return e;
			}} />
	);
};

RolesDropdown.propTypes = {
  control: PropTypes.any,
  dropdownProps: PropTypes.any,
  getValues: PropTypes.func,
  roles: PropTypes.array,
  setValue: PropTypes.func
}
