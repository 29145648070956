import React, { useState } from 'react';
import i18next from 'i18next';
import { size, isUndefined, uniq, reject } from "lodash";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Checkbox } from "@progress/kendo-react-inputs";

import { hasErrorTetant } from '../../common/validate-tenant';
import Form from './Form';
import { getFilterName } from './utilities';

const { t } = i18next;

/**
 * Componente intermediario que regresa los filtros basicos como dropdowns o inputs
 * @param {object} param
 * @param {function} param.onCloseMenu - Función que se realiza al cerrar el column menu
 * @param {function} param.onFilterChange - Función que se realiza al aplicar el filtro
 * @param {string} param.column - Columna sobre la que se esta aplicando el filtro
 * @param {object} param.filter - Los filtros aplicados en el sistema
 * @param {array} param.dataProps - Los datos para lo filtros tipo dropdown o multiselects
 * @param {string} param.fieldPrefix - Prefijo del filtro que se esta aplicando
 * @param {object} param.inputProps - Propiedades extras para aplicar al componente que se va a usar como filtro
 * @returns {MultiCheckMenuComponent}
 */
export const MultiCheckMenu = ({
	onCloseMenu,
	onFilterChange,
	column,
	filter,
	dataProps,
	fieldPrefix,
	inputProps,
}) => {
	const field = getFilterName(column?.field, fieldPrefix);
	const [selected, setSelected] = useState(filter?.filters?.[field] ?? []);

	const options = dataProps[0] ?? [];
	const keyField = dataProps[1] ?? "selected";
	const textField = dataProps[2] ?? "label";
	const disabled = !size(selected);

	const cleanFilter = () => {
		if (!isUndefined(filter?.filters?.[field])) {
			delete filter?.filters?.[field];
		}
		changeAndClose(filter);
	};

	const changeFilters = (e) => {
		e.preventDefault();

		if (!hasErrorTetant()) {
			filter.filters[field] = uniq(selected);
		}

		if (typeof inputProps?.changeCallback === "function") {
			inputProps.changeCallback(filter, changeAndClose); /* be sure in changeCallback use the changeAndClose */
			return;
		}

		changeAndClose(filter);
	};

	const changeAndClose = (newFilters) => {
		onFilterChange(newFilters);
		onCloseMenu();
	};

	const onChange = (id, checked) => {
		let news = checked
			? [...selected, id]
			: reject(selected, rejected => rejected === id);
		setSelected(news);
	};

	return (
		<Form disabled={disabled} cleanFilter={cleanFilter} changeFilters={changeFilters}>
			<PerfectScrollbar style={{ maxHeight: "180px" }}>
				{options.map((item, key) => (
					<div key={key} >
						<Checkbox
							checked={selected.includes(item[keyField])}
							onChange={({ value }) => onChange(item[keyField], value)}
							label={item[textField]}
						/>
					</div>
				))}
			</PerfectScrollbar>
			<b>
				{t("selected")} : {size(selected)}
			</b>
		</Form>
	);
};

export default MultiCheckMenu;