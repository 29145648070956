import { store } from "../../../../store";


const valuesDefault = {
  ausen_con_goce: 1,
  ausen_sin_goce: 2,
  vacaciones: 3,
  ajuste_asist: 4,
  completar_turno: 5,
  contrataciones: 6,
  conceptos: 7,
  promociones: 8,
  firmar_lista: 9,
  txt_overtime: 10,
  txt_money: 11,
  txt_double_shift: 12,
  txt_worked_rest: 13,
}

export const getPermissionCancelMoper = (key) => {
  return store.getState().userConfigs.user_info.cancel_mopers?.find(f => f.description === valuesDefault[key])?.has_perm;
}