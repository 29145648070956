import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faFloppyDisk, faBan } from '@fortawesome/pro-light-svg-icons';
import Grid from '@mui/material/Grid';
import { useWatch } from "react-hook-form";
import { CalculateSocketsDictionary, fields_2, fields_3, mapData } from '../util';
import { failedExecute, successUpdated } from '../../../../../common/notification-messages';
import { enterpriseStructureSettingsMultiAPI } from '../../../../../services/enterprise-structure';
import { parseRequest } from '../../../../../common/parse-request';
import {
  intersection,
  uniq,
  flatMap,
  keys,
  head,
  last,
  filter,
} from 'lodash';
import ComponentsAlert from './ComponentsAlert';
import { resolveError } from '../../../../../common/resolve-error';
import { lockedWindow, lockedWindowMessages, showNotificationError, unlockedWindow } from '../../../../../../store/actions';
import useSocketsController from '../../../../../../App/components/Sockets/useSocketsController';
import { showSuccessNotification } from '../../../../../../App/components/Notifications';
import { usePackages } from '../../../usePackages';

const CLOSE_WARNING = {
  open: false,
  type: null,
  messages: null,
  resultData: null,
  resultAll: null
}

function ButtonEdit({ edit, setEdit, cancel, control, refresh, filters, setValue, locked }) {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { tenant } = usePackages()

  const calculateListener = (response) => {
    const {
      status,
      message,
      task
    } = response?.message ?? {};

    dispatch(lockedWindowMessages(message));
    if (status === "SUCCESS") {
      const nameSocket = response.socket.url.includes("recalculate-mopers") ? "recalculate_mopers" : "multi_recalculate_mopers"
      finishTask(nameSocket, task);
    }

    if (status == "FAILURE") {
      dispatch(unlockedWindow());
      dispatch(showNotificationError(failedExecute("unexpected-error-message")));
    }
  };


  const {
    definedSocket,
    getSocketByName,
    getActiveSocket,
    handleCancel,
  } = useSocketsController({
    defaultFunctions: false,
    messageListener: calculateListener,
    onSuccessCalback: cleanMultiplos,
    customDictionary: CalculateSocketsDictionary
  });

  const [warning, setWarning] = useState(CLOSE_WARNING)

  const data = useWatch({ control, name: 'data' });
  const all = useWatch({ control, name: 'all' });

  const implementSocket = async (data, customApi, filters, socketName) => {

    try {
      const response = await customApi({...data, tenant}, parseRequest({}, filters, {}),false)
      dispatch(lockedWindow());
      setWarning(CLOSE_WARNING)
      if (response.task) {
        definedSocket(socketName, response.task);
      } else {
        //si no hay task es una peticion sin sockets
        cleanMultiplos()
      }
    } catch (error) {
      resolveError(error)
    }
  };

  const finishTask = (name, task) => {
    const socket = getSocketByName(name);
    handleCancel(task, getActiveSocket(socket?.url));
  };


  function handleEdit() {
    setEdit(true)
  }

  function closeWarning() {
    setWarning(CLOSE_WARNING)
    handleCancelModal()
  }

  async function saveData(resultData, resultAll) {
    if (resultData["overtime-config"].length) {
      implementSocket(resultData, enterpriseStructureSettingsMultiAPI.multi_overtime, filters, "multi_recalculate_mopers")
    }

    if (Object.keys(resultAll).length) {
      implementSocket(resultAll, enterpriseStructureSettingsMultiAPI.multi_overtime_massive, filters, "recalculate_mopers")
    }

    if (!resultData.length && Object.keys(resultAll).length) {
      handleCancelModal()
    }
    setValue("data", [])
    setValue("all", {})
  }

  function handleSetWarning(open, type, messages, resultData, resultAll) {
    setWarning({
      open,
      type,
      messages,
      resultData,
      resultAll
    })
  }

  async function handleSave() {

    const resultData = mapData(data, true)
    const resultAll = mapData(all, false)

    const row_type_1 = intersection(fields_2.concat(fields_3), uniq(flatMap(resultData, keys)))
    const all_type_1 = intersection(fields_2.concat(fields_3), keys(resultAll))

    const row_type_2 = intersection(fields_2, uniq(flatMap(resultData, keys)))
    const all_type_2 = intersection(fields_2, keys(resultAll))

    const row_type_3 = intersection(fields_3, uniq(flatMap(resultData, keys)))
    const all_type_3 = intersection(fields_3, keys(resultAll))


    if (row_type_1.length >= 2 || all_type_1.length >= 2) {
      handleSetWarning(true, 1, "", resultData, resultAll)
    } else if (row_type_2.length === 1 || all_type_2.length === 1) {
      if (row_type_1.length && all_type_1.length && head(row_type_1) != last(all_type_1)) {
        handleSetWarning(true, 1, "", resultData, resultAll)
      } else {
        handleSetWarning(true, 2, all_type_2[0] || row_type_2[0], resultData, resultAll)
      }
    } else if (row_type_3.length === 1 || all_type_3.length === 1) {
      //validate Implicitas 
      const mix_implicit0 = filter(resultData, { mix_implicit: 0 }).length
      const mix_implicit1 = filter(resultData, item => item.mix_implicit > 0).length
      if (all_type_3[0] === "mix_implicit") {
        // validate all mix_implicit1
        handleSetWarning(true, 3, resultAll.mix_implicit ? "mix_implicit1" : "mix_implicit0", resultData, resultAll)
      }
      else if (mix_implicit0 && mix_implicit1) {
        //mix de implicit1
        handleSetWarning(true, 1, "", resultData, resultAll)
      }
      else if (mix_implicit0 || mix_implicit1) {
        //implicit
        handleSetWarning(true, 3, mix_implicit0 ? "mix_implicit0" : "mix_implicit1", resultData, resultAll)
      } else {
        handleSetWarning(true, 3, all_type_3[0] || row_type_3[0], resultData, resultAll)
      }
    } else {
      const data = { "overtime-config": resultData }
      saveData(data, resultAll)
    }

  }

  function cleanMultiplos() {
    refresh()
    setEdit(false)
    showSuccessNotification(successUpdated());
  }

  function handleCancelModal() {
    cancel()
  }

  if (edit) {
    return (
      <Fragment>
        {warning.open && <ComponentsAlert {...warning} closeWarning={closeWarning} saveData={saveData} />}
        <Grid item className="mt-2">
          <Button style={{ width: 'fit-content', float: 'right', marginLeft: '10px' }}
            fullWidth
            size="small"
            variant="contained"
            onClick={handleSave}
            endIcon={
              <FontAwesomeIcon className="icon-button" icon={faFloppyDisk} />
            }
          >
            {t('save')}
          </Button>
        </Grid>
        <Grid item>
          <Button style={{ width: 'fit-content', float: 'right' }}
            fullWidth
            variant="contained"
            className="mr-3"
            size="small"
            onClick={handleCancelModal}
            endIcon={<FontAwesomeIcon className="icon-button" icon={faBan} />}
          >
            {t('cancel')}
          </Button>
        </Grid>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {locked && <Grid item className="mt-2" sx={{ width: 'fit-content', float: 'right' }}>
          <Button
            variant="contained"
            fullWidth={true}
            size="small"
            onClick={handleEdit}
            endIcon={<FontAwesomeIcon className="icon-button" icon={faPen} />}
          >
            {t('edit')}
          </Button>
        </Grid>}
      </Fragment>
    );
  }
}

export default ButtonEdit;
