import { lazy } from '../utilities';

const abrhilIcons = {
	advancefilters: {
		allowedTag: "Advance-Filters",
		Icon: lazy(async () => import("./AdvanceFilters.svg")),
	},
	annual: {
		allowedTag: "Annual",
		Icon: lazy(async () => import("./Annual.svg")),
	},
	bimonthly: {
		allowedTag: "Bimonthly",
		Icon: lazy(async () => import("./Bimonthly.svg")),
	},
	calculate: {
		allowedTag: "Calculate",
		Icon: lazy(async () => import("./Calculate.svg")),
	},
	complement: {
		allowedTag: "Complement",
		Icon: lazy(async () => import("./Complement.svg")),
	},
	delete: {
		allowedTag: "Delete",
		Icon: lazy(async () => import("./Delete.svg")),
	},
	edit: {
		allowedTag: "Edit",
		Icon: lazy(async () => import("./Edit.svg")),
	},
	file: {
		allowedTag: "File",
		Icon: lazy(async () => import("./File.svg")),
	},
	fileedit: {
		allowedTag: "File-Edit",
		Icon: lazy(async () => import("./FileEdit.svg")),
	},
	fileentry: {
		allowedTag: "File-Entry",
		Icon: lazy(async () => import("./FileEntry.svg")),
	},
	fileprograming: {
		allowedTag: "File-Programing",
		Icon: lazy(async () => import("./FilePrograming.svg")),
	},
	initialize: {
		allowedTag: "Initialize",
		Icon: lazy(async () => import("./Initialize.svg")),
	},
	monthly: {
		allowedTag: "Monthly",
		Icon: lazy(async () => import("./Monthly.svg")),
	},
	moreoptions: {
		allowedTag: "More-Options",
		Icon: lazy(async () => import("./MoreOptions.svg")),
	},
	new: {
		allowedTag: "New",
		Icon: lazy(async () => import("./New.svg")),
	},
	payrollbloq: {
		allowedTag: "Payroll-Bloq",
		Icon: lazy(async () => import("./PayrollBloq.svg")),
	},
	print: {
		allowedTag: "Print",
		Icon: lazy(async () => import("./Print.svg")),
	},
	save: {
		allowedTag: "Save",
		Icon: lazy(async () => import("./Save.svg")),
	},
	comment: {
		allowedTag: "Comment",
		Icon: lazy(async () => import("./comment.svg")),
	},
	refresh: {
		allowedTag: "Refresh",
		Icon: lazy(async () => import("./Refresh.svg")),
	},
	switch: {
		allowedTag: "Switch",
		Icon: lazy(async () => import("./switch.svg")),
	},
	import: {
		allowedTag: "Import",
		Icon: lazy(async () => import("./Import.svg")),
	},
	warning: {
		allowedTag: "Warning",
		Icon: lazy(async () => import("./Warning.svg")),
	},
	sort: {
		allowedTag: "Sort",
		Icon: lazy(async () => import("./Sort.svg")),
	},
};

export default abrhilIcons;
