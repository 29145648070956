import axios from "axios";
import {
	ServerAPI,
	getUrl,
	ParseParams,
} from './ServerAPI';
import { config, configFile } from "./config";

export const contractsAPI = ServerAPI('contracts-types');

export const tagsApi = ServerAPI("tags");

export const contractsLFTAPI = ServerAPI('lft-contract');

export const classificationsSUAAPI = ServerAPI('sua-classifications');

const ContractsAndFormatsAPI = () => {

	const baseName = "formats";

	const baseApi = ServerAPI(baseName);

	const getPDF = (id, data, isRender) => {
		const type = isRender ? "render" : "preview";
		const url = getUrl(`${baseName}/${id}/${type}`);
		const params = ParseParams({params :data, include_config : true });

		return axios
			.get(url, { ...params, responseType: 'blob' })
			.then(response => window.URL.createObjectURL(response.data));
	};

	const getContract = async (id, data) => {
		const url = getUrl(`${baseName}/${id}/render`);
		const params = ParseParams({ params: data, include_config: true });
		return axios.get(url, { ...params })
			.then(async (response) => response.data);
	}

	const getFormatExpire = (data) => {
		const url = getUrl(`expired-contracts`);
		const params = ParseParams({ params: data, include_config: true });
		return axios.get(url, params).then((response) => response.data);
	};

	const livePreview = (id, request, isPdf = false) => {
		const url = getUrl(`${baseName}/${id}/live-preview`, true);

		if (isPdf) {
			return axios
				.put(url, request, configFile())
				.then(response => window.URL.createObjectURL(response.data));
		}
		return axios
			.put(url, request, config())
			.then(async (response) => response.data);

	};

	return {
		...baseApi,
		getPDF,
		getFormatExpire,
		getContract,
		livePreview
	};
}

export const contractsFormatsAPI = ContractsAndFormatsAPI();