import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from 'react';
import { uid } from 'uid';
import { uniq, size as _size, isObject, isFunction } from 'lodash';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import withValueField from "../Templates/withValueField";
import { filterBy } from "@progress/kendo-data-query";
import { listNoDataRender } from '../Templates/listNoDataRender';
import { valueRender, formatLabel } from './utilities';
import { objectValue } from '../../../core/@components/form/utilities';
import { hasValue } from '../../../core/common/GeneralUtilities';
import { KendoLabelContainer } from '../Templates/SideLabelContainer';
import { kendoSizeClasses } from '../sizesUtilities';
import { conditional } from "../../../core/@components/grid/CommandCell";

const DropDownListWithValueField = withValueField(DropDownList);

export const responsiveHeightProps = (responsive, width, data, maxHeight) => ({
	popupClass: `customPopup ${conditional(responsive, 'responsive-drop-height', '')}`,
	...(conditional(responsive, {
		style: {
			...(conditional(responsive && data?.length, { display: 'flex' }, {})),
			...(conditional(maxHeight, { maxHeight }, {})),
			width,
			direction: 'ltr',
			zIndex: 100,
		}
	}, {})),
	...(conditional(responsive && data?.length, { height: `${data?.length * 25}px` }, {})),
});

/**
 * Componente dropdown basado en kendo, para mas propiedades leer la documentación oficial
 * @param {object} params
 * @param {?boolean} params.byValue - Configura si el dropdown va a regresar en la selección solo el valor del objeto o el objeto completo
 * @param {?string} params.id - El id que se le va a configurar al input
 * @param {?string} params.label - El label qie se va a mostrar en el input
 * @param {?string} params.name - El nombre que va a tener el input
 * @param {array} params.data - Las opciones seleccionables del input
 * @param {function} params.onChange - La función que se ejecuta al seleccionar un elemnto
 * @param {?string} params.valueField - El atributo de cada objeto que sera usado como valor al seleccionar
 * @param {?string} params.textField - El atributo de cada objeto que sera mostrado para seleccionar
 * @param {*} params.value - El valor del input
 * @param {boolean} params.noDefault - Si se va a poder seleccionar el objeto vacio por default
 * @param {boolean} params.filterable - Si el array se puede filtrar o no ,  por default cuando supera los 5 items se puede filtrar
 * @param {*} params.filter - El filtro aplicado al input
 * @param {?string} params.format - El formato final que se mostrara al renderizar los valores ej. Key|name -> 001 -> label
 * @param {?string} params.className - Cualquier clase custom que se le quiera aplicar al input
 * @param {?string} params.floatingClassname - Cualquier clase custom que se quiera aplicar directamente al floating label
 * @param {?boolean} params.sideLabel - Indica si el label del input se va a mostrar a un lado
 * @returns {DropdownComponent}
 */
const KendoDropdown = ({
	byValue = true,
	id,
	label,
	name,
	data,
	onChange,
	valueField,
	textField,
	value,
	noDefault,
	filterable,
	filter,
	format,
	loading,
	maxHeight,
	asMui = true,
	popupSettings = {},
	floatingClassname = "",
	responsiveHeight = false,
	sideLabel = false,
	selectUnique = true,
	openOnFocus = false,
	handleOpen,
	handleClose,
	...others
}) => {

	const { size, className } = kendoSizeClasses(others);
	const isPrimitive = useRef(false);
	const fieldId = id || "dropdown_" + uid();
	const [initial, setInitial] = useState([]);
	const [list, setList] = useState([]);
	const filt_r = useRef(null);
	const vField = valueField ?? "value";
	const tField = textField ?? "label";
	const defValue = { defaultItem: conditional(byValue, { [vField]: null, [tField]: null }, null) };
	const [opened, setOpened] = useState(false);
	const dropdownRef = useRef();
	const alreadyOpen = useRef(false);

	const dropdownWidth = (dropdownRef.current?.component ?? dropdownRef.current)?._element?.offsetWidth;

	useEffect(() => {
		const uData = uniq(data || []).map(item => {
			if (typeof (item) !== "object") {
				isPrimitive.current = true;
				return item;
			}
			item.forSearch = formatLabel(format || tField, item);
			return item;
		});
		setInitial(uData);
		filt_r.current = null;
		//eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		initializeData(initial);
		//eslint-disable-nex-line
	}, [filter, initial]);

	const initializeData = (dataList) => {
		if (_size(filter) && isObject(filter)) {
			dataList = filterBy(dataList, filter);
		}
		setList(dataList);
		selectFirst(dataList);
	};

	const selectFirst = (dataList) => {
		if (_size(dataList) === 1 && selectUnique) {
			const item = conditional(byValue, dataList[0][vField], dataList[0]);
			if (item !== value) {
				handleChange(objectValue({}, item));
			}
		}
	};

	const filterData = (filter) => {
		if (isPrimitive.current) {
			return filterBy(initial, filter);
		}
		return filterBy(initial, { ...filter, field: "forSearch" });
	};

	const filterChange = (event) => {
		filt_r.current = event.filter?.value;
		setList(filterData(event.filter));
	};

	const handleChange = (e) => {
		if (byValue) {
			const finded = data.find(item => item[vField] === e.value);
			e["objectValue"] = finded || {};
		}

		if (typeof onChange === "function") {
			e.type = "change";
			onChange(e);
		}
	};

	const handleOpenFloating = (e) => {
		const event = e?.nativeEvent;
		if (event?.type === "keydown" && event?.keyCode !== 32) return;
		if (isFunction(handleOpen)) {
			handleOpen();
		}
		setOpened(true);
	};

	const handleCloseFloating = () => {
		if (isFunction(handleClose)) {
			handleClose();
		}
		setOpened(false);
		filt_r.current = null;
		initializeData(initial);
	};

	const onFocus = () => {
		if (!openOnFocus) return;
		setTimeout(() => {
			handleOpenFloating();
		}, !alreadyOpen.current ? 300 : 0);
	}

	const comonProps = {
		listNoDataRender: listNoDataRender,
		...others,
		ref: dropdownRef,
		size: size,
		id: fieldId,
		loading,
		name: (name || fieldId),
		data: list,
		label: "",
		onBlur: handleCloseFloating,
		onClose: handleCloseFloating,
		onOpen: handleOpenFloating,
		onFocus: onFocus,
		className: `${className ?? ""} ${conditional(asMui, "asMui", "")}`,
		onChange: handleChange,
		filterable: conditional(filterable === false, false, initial.length > 5),
		value: value,
		filter: filt_r.current,
		onFilterChange: filterChange,
		...(conditional(list.length > 0 && !noDefault, defValue, {})),
		popupSettings: {
			...responsiveHeightProps(responsiveHeight, dropdownWidth, data, maxHeight),
			...popupSettings,
		},
		opened: opened,
		validationMessage: " "
	};

	const extraProps = isPrimitive.current ? {} : {
		textField: "forSearch",
		dataItemKey: vField,
	};

	return (
		<KendoLabelContainer
			label={label}
			editorId={fieldId}
			editorValue={hasValue(value) && hasValue(comonProps?.data)}
			className={`${floatingClassname} ${"custom-floating-label"}`}
			sideLabel={sideLabel}
		>
			{!byValue && <DropDownList {...comonProps} {...extraProps} />}
			{byValue && <DropDownListWithValueField
				valueRender={(li, itemProps) => valueRender(li, itemProps, format || tField, vField)}
				{...comonProps}
				valueField={vField}
				textField={"forSearch"}
			/>}
		</KendoLabelContainer>
	);
};

KendoDropdown.propTypes = {
	asMui: PropTypes.bool,
	byValue: PropTypes.bool,
	data: PropTypes.any,
	filter: PropTypes.any,
	filterable: PropTypes.bool,
	floatingClassname: PropTypes.string,
	format: PropTypes.any,
	handleClose: PropTypes.func,
	handleOpen: PropTypes.func,
	id: PropTypes.any,
	label: PropTypes.any,
	loading: PropTypes.any,
	maxHeight: PropTypes.any,
	name: PropTypes.any,
	noDefault: PropTypes.any,
	onChange: PropTypes.func,
	openOnFocus: PropTypes.bool,
	popupSettings: PropTypes.any,
	responsiveHeight: PropTypes.bool,
	selectUnique: PropTypes.bool,
	sideLabel: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool
	]),
	textField: PropTypes.any,
	value: PropTypes.any,
	valueField: PropTypes.any
}

export default KendoDropdown;