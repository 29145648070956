import { toLower } from "lodash";
import { downloadComparisonReport } from "../../../core/modules/EMA-EBA/hooks/useGrid";
import { downloadMissingIdseReport } from "../../../core/modules/IDSEAudit/hooks/useForm";
import { downloadWorkerReport } from "../../../core/modules/WorkerMovementsReport/hooks/useForm";
import { downloadSuaReport } from "../../../core/modules/BSettlementComparasion/hooks/useSettlementComparasion";
import { downloadAttendanceList } from "../../../core/modules/Attendance/hooks/usePrintList";
import { downloadEntriesExits } from "../../../core/modules/Attendance/hooks/useCalendarChecks";
import { downloadReport } from "../../../core/modules/Reports/components/Modal/Payroll/hooks/usePayrollForm";
import { downloadContractReport } from "../../../core/modules/Worker/Contracts/hooks/usePrintFormats";
import { downloadFile } from './utilities';
import { OnCreatedTenant } from "../../../core/modules/AbrhilApp/Company";
import { valueOrOption } from '../../../core/common/GeneralUtilities';
import { OnSuccessApply } from "../../../core/modules/Attendance/hooks/useApplyModule";

export const SocketsDictionary = (tenant) => {

	const getUrl = route => `${tenant}/${route}/`;

	const reportConstructor = route => ({
		url: getUrl(route),
		callbackWS: downloadReport,
		cancel_api: 'task-report',
	});

	const constructor = (route, filename, apiName, xlsx = false) => ({
		url: getUrl(route),
		callbackWS: async (taskID, repo_name) => {
			return await downloadFile(taskID, valueOrOption(repo_name, filename, apiName, xlsx));
		},
	});

	return {
		calculate_ptu_report: constructor("payroll-calculate-response/ptu_report", 'calculate-ptu-report'),
		bimonthly_variables_report: constructor("bimonthly-payroll-variable/report", 'bimonthly-payroll-variable'),
		delete_report: constructor("bimonthly-payroll-variable/delete-report", 'bimonthly-payroll-variable-delete'),
		worker_settlement_report: constructor("worker-settlement-report", 'worker-settlement-report'),
		payroll_policy_report: constructor("payroll-policy", 'payroll-policy'),
		payroll_provision_policy: constructor("payroll-provision-policy-vacations", 'payroll-provision-policy'),
		vacations_balance_report_pdf: constructor("vacation-balance/pdf-report", 'vacation-balance-report'),
		vacations_balance_report_xlsx: constructor("vacation-balance/xlsx-report", 'vacation-balance-report'),
		worker_movements: {
			url: getUrl('worker-movement-report'),
			callbackWS: downloadWorkerReport,
		},
		ema_comparison: {
			url: getUrl('ema-comparison-report'),
			callbackWS: downloadComparisonReport,
		},
		eba_comparison: {
			url: getUrl('eba-comparison-report'),
			callbackWS: downloadComparisonReport,
		},
		missing_idse: {
			url: getUrl('missing-idse-report'),
			callbackWS: downloadMissingIdseReport,
		},
		idse_hiring_report: {
			url: getUrl('idse_hiring_transfer_report'),
			callbackWS: downloadReport,
		},
		idse_all_report: {
			url: getUrl('idse_transfer_all_movement_report'),
			callbackWS: downloadReport,
		},
		sua_infonavit: {
			url: getUrl('payroll_sua_comparison_report'),
			callbackWS: downloadSuaReport,
		},
		contracts_catalog: {
			url: getUrl('formats-render'),
			callbackWS: downloadContractReport,
		},
		payroll_dispersion_layout: constructor("payroll-report-dispersion-layout", "payroll-dispersion-layout"),
		payroll_dispersion_report: constructor("payroll-report-dispersion-report", "payroll-dispersion"),
		voucher_dispersion_layout: constructor("voucher-dispersion-layout", "voucher-dispersion-layout"),
		payroll_report: reportConstructor('payroll-report'),
		payroll_report_incidences: reportConstructor('payroll-report-incidences'),
		payroll_report_employer_registration: reportConstructor('payroll-report-employer-registration'),
		payroll_report_netos: reportConstructor('payroll-report-netos'),
		payroll_report_dedu_perc: reportConstructor('payroll-report-dedu-perc'),
		payroll_report_difference_linear: reportConstructor('payroll-report-difference-linear'),
		payroll_report_capture_not_applied: reportConstructor('payroll-report-capture-not-applied'),
		payroll_report_excel_general: reportConstructor('payroll-report-excel-general'),
		payroll_report_taxes: reportConstructor('payroll-report-taxes'),
		payroll_report_concept: reportConstructor('payroll-report-concept'),
		payroll_report_perceptions_difference: reportConstructor('payroll-report-perceptions-difference'),
		report_birthday: reportConstructor('report-birthday'),
		report_validity: reportConstructor('report-validity'),
		report_contract: reportConstructor('report-contract'),
		report_antiquity: reportConstructor('report-antiquity'),
		attendancelist_print: {
			url: getUrl('attendancelist-report'),
			callbackWS: downloadAttendanceList,
		},
		attendancelist_massive_report: {
			url: getUrl('attendancelist-massive-report'),
			callbackWS: downloadAttendanceList,
		},
		entries_exits_print: {
			url: getUrl('entriesexits-report'),
			callbackWS: downloadEntriesExits,
		},
		m_liquidation: {
			url: getUrl('monthly_liquidation'),
			callbackWS: null,
		},
		b_liquidation: {
			url: getUrl('bimonthly_liquidation'),
			callbackWS: null,
		},
		m_liquidation_report: {
			url: getUrl('monthly_liquidation_report'),
			callbackWS: downloadReport,
		},
		b_liquidation_report: {
			url: getUrl('bimonthly_liquidation_report'),
			callbackWS: downloadReport,
		},
		multi_create_users: {
			url: getUrl('multi-create-users'),
			callbackWS: null,
		},
		idse_confirmation: {
			url: getUrl('idse_confirmation'),
			callbackWS: downloadReport,
		},
		generic_export: {
			url: getUrl('export'),
			callbackWS: (taskID, filename) => downloadFile(taskID, `${toLower(filename?.split('_')?.[0])}`, "export-data", true),
		},
		create_tenant: {
			url: `tenant_creation/`,
			callbackWS: OnCreatedTenant,
		},
		apply_holidays: {
			url: getUrl('apply-holidays'),
			callbackWS: OnSuccessApply,
		},
		apply_faults: {
			url: getUrl('apply-absences'),
			callbackWS: OnSuccessApply,
		},
		apply_breaks: {
			url: getUrl('apply-days-off'),
			callbackWS: OnSuccessApply,
		},
		cirse_excel: constructor('cirse-excel', 'cirse', null, true),
		dc3_format: {
			url: getUrl('dc-3-format'),
			callbackWS: downloadReport,
		},
		list_course_report: {
			url: getUrl('list-course-report'),
			callbackWS: downloadReport,
		},
		replicate_flow_workgroups: {
			url: getUrl('copy-flow-signature'),
		}
	};
};