import React from "react";
import FormDialog from "../../../../../../App/components/dialog/FormDialog";
import { useTranslation } from "react-i18next";
import DialogController, { useDialogContext } from "../../hooks/useDialog";
import DialogContent from "../../../../../styled/DialogContent";
import { BasicDialogActions } from "../../../../../styled/DialogActions";
import Grid from '@mui/material/Grid2';
import Tabs from "./components/Tabs";
import DynamicRender from "./components/DynamicRender";
import TabsHeader from "./components/TabsHeader";

export const gridSize = (xs, sm, md, lg, xl) => ({
    size: {
        xs,
        sm,
        md,
        lg,
        xl,
    }
});

export const VERTICAL_CENTER = { display: 'flex', alignItems: 'center' };

function AddEdit() {

    return (
        <DialogController>
            <Content />
        </DialogController>
    );
};

export default AddEdit;

const Content = () => {
    const { t } = useTranslation();
    const {
        open,
        onClose,
        handleSubmit,
        onSubmit,
    } = useDialogContext();

    return (
        <FormDialog
            open={open}
            maxWidth="md"
            className="minimalist-dialog-design company-dialog"
            handleClose={onClose}
        >
            <DialogContent>
                <Grid container spacing={2} className="full-height" style={{ alignItems: 'flex-start' }}>
                    <Grid container {...gridSize(5)}>
                        <Grid container {...gridSize(12)} direction="column">
                            <TabsHeader />
                            <Tabs />
                        </Grid>
                    </Grid>
                    <Grid container {...gridSize(7)} className="full-height">
                        <DynamicRender />
                    </Grid>
                </Grid>
            </DialogContent>
            <BasicDialogActions
                onAccept={handleSubmit(onSubmit)}
                onCancel={onClose}
                submitLabel={t('save')}
            />
        </FormDialog>
    );
}