import { t } from "i18next";
import { reversDate } from '../../../../../../../App/components/Select/PayrollPeriod/itemUtilities';
import { formatMoney } from "../../../../../GraphicSignatures/utilities/utils";
import { buildCheck, buildHours } from "../utils/utilities";
import { parseDatetime } from "../../../../../../common/GeneralUtilities";
import { moperAbsenteeismModel, moperBonusPaymentModel, moperRestBankModel } from "../../../../../SignaturesByWorker/components/Details/components/models/models";
import { capitalize, omit } from "lodash";
import { createdAtFormatted } from "../../SpecialConcepts/models";

const checksBuilder = (data) => {
    return data?.map((check, index) => {
        return { [`${t('check-in')} ${index + 1}`]: buildCheck(check?.check_in), [`${t('check-out')} ${index + 1}`]: buildCheck(check?.check_out) };
    });
};

export const customMoperModels = {
    moper_bonus_payment: {
        model: {
            ...moperBonusPaymentModel.model,
            1: {
                key: 'pd',
                name: 'Prima dominical',
                'Dia origen': { value: 'origin_bonus_date', format: reversDate, key: 'origin_day' },
                'Monto': { value: 'amount', format: formatMoney, key: 'amount' },
                'Porcentaje': { value: 'sunday_bonus_percent', format: (value) => `${value}%`, key: 'percentage' },
                'F. solicitud': createdAtFormatted,
            },
            2: {
                key: 'ft',
                name: 'Festivo trabajado',
                'Dia origen': { value: 'origin_bonus_date', format: reversDate, key: 'origin_day' },
                'Monto': { value: 'amount', format: formatMoney, key: 'amount' },
                'F. solicitud': createdAtFormatted,
            },
            3: {
                key: 'dt',
                name: 'Descanso trabajado',
                'Dia origen': { value: 'origin_bonus_date', format: reversDate, key: 'origin_day' },
                'Total de horas': { value: 'minutes_requested', format: buildHours, key: 'hours' },
                'Monto': { value: 'amount', format: formatMoney, key: 'amount' },
                'F. solicitud': createdAtFormatted,
            },
            4: {
                key: 'td',
                name: 'Turno doble',
                'Dia origen': { value: 'origin_day', format: reversDate, key: 'origin_day' },
                'Monto': { value: 'amount', format: formatMoney, key: 'amount' },
                'F. solicitud': createdAtFormatted,
            },
        }
    },
    moper_overtime_money: {
        model: {
            'Dia origen': { value: 'origin_day', format: reversDate, key: 'origin_day' },
            'Total de horas': { value: 'minutes_requested', format: buildHours, key: 'hours' },
            'Monto': { value: 'total_amount', format: formatMoney, key: 'amount' },
            'F. solicitud': createdAtFormatted,
        }
    },
    moper_overtime_txt: {
        model: {
            'Dia origen': { value: 'origin_day', format: reversDate, key: 'origin_day' },
            'Total de horas': { value: 'minutes_requested', format: buildHours, key: 'hours' },
            'Horas canceladas': { value: 'minutes_canceled', format: buildHours, key: 'canceled_hours' },
            'F. solicitud': createdAtFormatted,
        }
    },
    moper_rest_bank: {
        model: {
            ...moperRestBankModel.model,
            'Dia origen': { value: 'origin_day', format: reversDate, key: 'origin_day' },
            'Total de horas': { value: 'minutes_requested', format: buildHours, key: 'hours' },
            'F. solicitud': createdAtFormatted,
        }
    },
    moper_complete_shift: {
        model: {
            'Dia origen': { value: 'origin_day', format: reversDate, key: 'origin_day' },
            'Checadas': { value: 'checks', format: checksBuilder, key: 'checks' },
            'Entrada': { value: 'checks.0.check_in', key: 'check_in', format: (value) => parseDatetime(value) },
            'Horas laboradas': { value: 'total_real_rounded', format: buildHours, key: 'worked_hours_real' },
            'Horas laboradas redondeadas': { value: 'total_calculated_rounded', format: buildHours, key: 'total_calculated_rounded' },
            'Salida': { value: 'checks.0.check_out', key: 'check_out', format: (value) => parseDatetime(value) },
            'Horas por cubrir': { value: 'missing_time', format: buildHours, key: 'missing_time' },
        }
    },
    moper_absenteeism: {
        relativeKey: 'key',
        iconKey: 'key',
        pathname: 'name',
        icon: {
            ...moperAbsenteeismModel.icon,
            v: moperAbsenteeismModel.icon.vacaciones,
            dv: moperAbsenteeismModel.icon.vacaciones,
            pc: moperAbsenteeismModel.icon.ausen_con_goce,
            pg: moperAbsenteeismModel.icon.ausen_sin_goce,
        },
        model: {
            ...moperAbsenteeismModel.model,
            default: {
                ...omit(moperAbsenteeismModel.model.default, ['Motivo']),
                'Tipo': { value: 'description', format: capitalize, key: 'type' },
                'Días': { value: 'days', key: 'days' },
                'Descansos': { value: 'total_days_off', key: 'total_days_off' },
            },
            dv: {
                ...moperAbsenteeismModel.model.vacaciones,
                'Días': { value: 'days', key: 'days' },
                'Descansos': { value: 'total_days_off', key: 'total_days_off' },
            },
            v: {
                ...moperAbsenteeismModel.model.vacaciones,
                'Días': { value: 'days', key: 'days' },
                'Descansos': { value: 'total_days_off', key: 'total_days_off' },
            }
        }
    }
};