
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AbrhilWindowProvider } from '../../contextsAndControllers/useWindow';
import Window from './Window';
import TabsContainer from './TabsContainer';
import { getCurrentPath } from '../../utilities';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Pages from "../../Routes";
import Index from "../../../../modules/Welcome/index"
// import Index from "./FieldsTester"
import IconsSet from '../../../../common/IconsSet';

const Windows = () => {

	const [fixedElement, setFixedElement] = useState(false);
	const { isStamp } = useSelector(state => state.navigate);

	useEffect(() => {
		let current = getCurrentPath();
		setFixedElement(current.includes("packages"));
		//eslint-disable-next-line
	}, []);
	const classNameback = isStamp ? 'stamp-background' : '';
	return (
		<div className={`windows-container ${classNameback}`}>
			<TabsContainer fixedElement={fixedElement} />
			<div id="windows-container">
				{
					fixedElement ?
						<PerfectScrollbar id={"app-main-container-scroller"} className={"app-main__inner gradientBg"}>
							<Pages />
						</PerfectScrollbar>
						:
						<>
							<IconsSet />
							<Index/>
							<AbrhilWindowProvider windowNumber={1}>
								<Window id={`window_1`} />
							</AbrhilWindowProvider>
							<AbrhilWindowProvider windowNumber={2}>
								<Window id={`window_2`} />
							</AbrhilWindowProvider>
							<AbrhilWindowProvider windowNumber={3}>
								<Window id={`window_3`} />
							</AbrhilWindowProvider>
							<AbrhilWindowProvider windowNumber={4}>
								<Window id={`window_4`} />
							</AbrhilWindowProvider>
							<AbrhilWindowProvider windowNumber={5}>
								<Window id={`window_5`} />
							</AbrhilWindowProvider>
							<AbrhilWindowProvider windowNumber={6}>
								<Window id={`window_6`} />
							</AbrhilWindowProvider>
						</>
				}
			</div>
		</div>
	);
};

export default Windows;