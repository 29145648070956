import React, { memo, useEffect, useState } from "react";
import { Card } from "@progress/kendo-react-layout";
import { size } from 'lodash';
import Gif from "./lightAssets/PayRoll_A3_l.gif";
import PNG from "./lightAssets/PayRoll_A3_l.png";
import GifD from "./darkAssets/PayRoll_A3_d.gif";
import PNGD from "./darkAssets/PayRoll_A3_d.png";
import { hasValue } from '../../../core/common/GeneralUtilities';

const messages = [
	"Calculando nómina",
	"Procesando datos",
	"Esto puede tardar unos minutos",
	"Seguimos en cálculo",
	"Tu cálculo de nómina estará listo en breve",
	"Falta poco",
	"Esperando finalizar el cálculo",
	"Pronto podras consultar todos los trabajadores que fueron calculados",
];
const Locked = () => {

	const [imgUsed, setImgUsed] = useState(null);
	const [imgUsed2, setImgUsed2] = useState(null);
	const [message, setMessage] = useState({
		text: "Calculando nómina",
		index: 0
	});

	const loadMessages = () => {
		setInterval(() => {
			setMessage(state => {
				const newIndex = state?.index + 1 === size(messages) ? 0 : state?.index + 1;
				return {
					text: messages[newIndex],
					index: newIndex
				};
			});
		}, [getTime(15)]);
	};

	const changeImg = () => {
		setTimeout(() => {
			setImgUsed(PNG);
			setImgUsed2(PNGD);
		}, [getTime(4)]);
	};

	useEffect(() => {
		loadMessages();
		setImgUsed(Gif);
		setImgUsed2(GifD);
		changeImg();
		//eslint-disable-next-line
	}, []);

	return (
		<Card className="calculating-loader-card">
			<div className="loaderLogo" >
				{hasValue(imgUsed) && <img src={imgUsed} alt="loading..." className='light' />}
				{hasValue(imgUsed2) && <img src={imgUsed2} alt="loading..." className='dark' />}
			</div>
			<div className="messages" >
				{message?.text}
			</div>
			<div className="progress-container">
				<div className="abrhil-progress-bar">
					<div className="abrhil-progress-bar-value"></div>
				</div>
			</div>
		</Card>
	);
};
export default memo(Locked);

const getTime = (seconds) => 1000 * seconds;