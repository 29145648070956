import React, { cloneElement } from 'react';
import i18next from 'i18next';
import { isFunction } from 'lodash';
/* Fontawesome */
import { faLock, faLockOpen, faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* other templates */
import { CustomTooltip } from '../../Templates/cells';
import { TooltipContentTemplate } from './TooltipContentTemplate';

/* utilities */
import { hasValue, valueOrOption } from '../../../../core/common/GeneralUtilities';
import { backColor, payrollPeriodFormatLavel, isPeriodLockedOrClosed } from './itemUtilities';
import LottieUse from '../../../../core/common/LottieUse';
import { BaseButton } from '../../Buttons';

const { t } = i18next;

export const payrollPeriodItemRender = (li, item) => {
	item = item?.dataItem ?? item;
	if (!item) { return; }
	return cloneElement(li, { ...li.props, style: backColor(item) }, itemTemplate(item));
};

export const payrollPeriodItemvalueRender = (li, item, onReload) => {
	item = item?.dataItem ?? item;
	const value = item?.id;
	if (!hasValue(value)) {
		return <span className="dd-internal-elem k-input-value-text" />;
	}
	return cloneElement(li, { ...li.props, style: backColor(item) }, itemTemplate(item, true, onReload));
};

const itemTemplate = (item, include_internal_class = false, onReload) => {

	const { key, dates } = payrollPeriodFormatLavel(item, false);
	const internal_class = include_internal_class ? "dd-internal-elem" : "";
	const lockClass = isPeriodLockedOrClosed(item) ? "lockedPeriod" : "";

	return (
		<CustomTooltip
			className={`tooltipWrap ${internal_class}`}
			title={<TooltipContentTemplate item={item} />}
		>
			<div className={`periodItem ${internal_class}`}>
				<FontAwesomeIcon
					className={`${internal_class} periodIcon ${lockClass}`}
					icon={isPeriodLockedOrClosed(item) ? faLock : faLockOpen}
				/>
				<span className={`item-list-label ${internal_class}`}>
					<b className={internal_class}>{key}</b>
					<span className={internal_class}>{dates}</span>
				</span>
				{isFunction(onReload) && <FontAwesomeIcon icon={faArrowsRotate} onClick={onReload} />}
			</div>
		</CustomTooltip>
	);
};

const Skeleton = ({ customMsg, searchValues, onReload }) => {

	const { computed_type, current_month, current_year, payroll_type } = valueOrOption(searchValues, {});
	let msg = customMsg;
	if (!hasValue(msg)) {
		const ct = hasValue(computed_type) ? 1 : 0;
		const pt = hasValue(payroll_type) ? 1 : 0;
		const cy = hasValue(current_year) ? 1 : 0;
		const cm = hasValue(current_month) ? 1 : 0;

		msg = {
			'1-1-1-1': 'no-periods-all-filters',
			'1-1-1-0': 'no-periods-type-year-filters',
			'1-1-0-0': 'no-periods-type-filters',
		}[`${ct}-${pt}-${cy}-${cm}`] ?? 'no-periods';

		msg = t(msg);
	}


	return (
		<span className="abrhil-list-no-records-msg">
			<LottieUse loop icon={"calendar"} className={`player-no-list-items`} style={{ width: "170px", maxWidth: "100%" }} />
			<label>{msg}</label>
			{isFunction(onReload) && (
				<div style={{ marginTop: ".5em", marginBottom: ".5em" }}>
					<BaseButton label={t("refresh")} onClick={onReload} />
				</div>
			)}
		</span>
	);
};

/* for dropdowns and muultiselect normal */
export function NoPeriodsDataRender(element, customMsg, searchValues, onReload) {
	return cloneElement(
		element,
		{ ...element.props },
		<Skeleton customMsg={customMsg} searchValues={searchValues} onReload={onReload} />
	);
};