import React from "react";
import { useTranslation } from "react-i18next";

import { CustomTooltip } from '../../../../../App/components/Templates/cells';
import timbraLogo from '../../assets/images/timbra-logo.png';
import abrhillogo from '../../assets/images/abrhil-logo.png';


const Timbrasoft = ({ stampMode, setStampMode }) => {
	const { t } = useTranslation();

	return (
		<div className='timbrasoft-container icon-pointer' >
			<CustomTooltip title={stampMode ? t("back-abrhil") : t("go-timbrasoft")} className="menu-item-tooltip" placement="left">
				<span className='side-menu-item-icon' onClick={() => setStampMode(!stampMode)}>
					{stampMode
						? <img src={abrhillogo} alt="abrhil-logo" className='menu-abrhil-logo' />
						: <img src={timbraLogo} alt="timbra-logo" className='menu-timbra-logo' />
					}
				</span>
			</CustomTooltip>
		</div>
	);
};

export default Timbrasoft;