import React from 'react';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import MUIDialogActions from '@mui/material/DialogActions';
import Button from './Button';
import { isFunction } from 'lodash';

const def = {
	padding: '15px 29px',
	border: 0
};

const StyledDialogActions = styled(MUIDialogActions)(def);

const DialogActions = (props) => {
	return (
		<StyledDialogActions {...props}>
			{props.children}
		</StyledDialogActions>
	);
};

export default DialogActions;

export const BasicDialogActions = ({
	onCancel,
	onAccept,
	loading,
	submitLabel,
}) => {

	const { t } = useTranslation();

	return (
		<DialogActions>
			{isFunction(onCancel) &&
				<Button design="text" type="button" onClick={onCancel} >
					{t('cancel')}
				</Button>
			}
			{isFunction(onAccept) &&
				<Button design="contained" onClick={onAccept} loading={loading} >
					{submitLabel ?? t('accept')}
				</Button>
			}
		</DialogActions>
	);
};