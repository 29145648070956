import React from 'react';
import { Grid } from "@mui/material";
import { Skeleton } from "@progress/kendo-react-indicators";
import MainCard from '../../../../App/components/MainCard';
import { ButtonsContainer } from '../../../../App/components/Buttons';

/**
 * Layout skeleton para los loaders de las páginas
 */
const PageSkeleton = () => {

    const buttonsStyle = {
        width: 100,
        height: 25,
        borderRadius: "3em",
    };

    const commons = {
        shape: "rectangle",
        animation: {
            type: "wave",
        }
    };

    const divBorder = {
        border: "1px solid var(--borderGray)",
    };

    return (
        <MainCard principal >
            <Grid container spacing={2} className="pageLoader">
                <Grid item xs={12}>
                    <ButtonsContainer className={"mb-1"}>
                        <Skeleton {...commons} style={buttonsStyle} />
                        <Skeleton {...commons} style={buttonsStyle} />
                    </ButtonsContainer>
                </Grid>
                <Grid item xs={12} >
                    <div className="principal-grid " style={divBorder}>
                        <div style={divBorder} >
                            <Skeleton
                                {...commons}
                                style={{
                                    width: "100%",
                                    height: 30,
                                }}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default PageSkeleton;