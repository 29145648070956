import i18n from "i18next";


export const urlExport = "workers";
export const columnsToExport = (state) => [
	[
		{ name: "key", text: i18n.t('key'), isSelected: true },
		{ name: "first_surname", text: i18n.t("first-surname"), isSelected: true },
		{ name: "second_surname", text: i18n.t("second-surname"), isSelected: true },
		{ name: "name", text: i18n.t('name'), isSelected: true },
		{ name: "employer_registration_exp", text: i18n.t('employer-registration'), validation: state?.human_resource_package?.employer_registration?.value },
		{ name: "employer_registration_rfc", text: i18n.t('business-name'), isSelected: false },
		{ name: "branch_exp", text: i18n.t('branch'), validation: state?.human_resource_package?.branch?.value },
		{ name: "area_exp", text: i18n.t('area'), validation: state?.human_resource_package?.area?.value },
		{ name: "department_exp", text: i18n.t('department'), validation: state?.human_resource_package?.department?.value },
		{ name: "job_position_exp", text: i18n.t('job-position'), validation: state?.human_resource_package?.job_position?.value },
		{ name: "integration_factor_type_exp", text: i18n.t('benefit-type'), isSelected: false },
		{ name: "antiquity_date", text: i18n.t('antiquity-date'), isSelected: false },
	],
	[
		{ name: "rfc", text: i18n.t('rfc'), validation: state?.human_resource_package?.rfc?.value },
		{ name: "curp", text: i18n.t('curp'), validation: state?.human_resource_package?.curp?.value },
		{ name: "nss", text: i18n.t('nss'), validation: state?.human_resource_package?.nss?.value },
		{ name: "gender", text: i18n.t('gender'), isSelected: false },
		{ name: "birthday", text: i18n.t('birthday'), isSelected: false },
		{ name: "marital_status", text: i18n.t('marital-status'), isSelected: false },
		{ name: "salary", text: i18n.t('daily-salary'), isSelected: false },
		{ name: "last_integrated_salary", text: i18n.t('integrated-salary'), isSelected: false },
		{ name: "contract_type", text: i18n.t('contract-type'), isSelected: false },
		{ name: "validity", text: i18n.t('validity'), isSelected: false },
		{ name: "contract_end_date", text: i18n.t('contract-end-date'), isSelected: false },
	],
	[
		{ name: "address", text: i18n.t('address'), isSelected: false },
		{ name: "colony_address", text: i18n.t('suburb'), isSelected: false },
		{ name: "city_address", text: i18n.t('city'), isSelected: false },
		{ name: "municipality_address", text: i18n.t('municipality'), isSelected: false },
		{ name: "zipcode_address", text: i18n.t("postal-code"), isSelected: false },
		{ name: "state_address", text: i18n.t('state'), isSelected: false },
		{ name: "country_address", text: i18n.t('country'), isSelected: false },
		// { name: "hiring", text: i18n.t('first-admission'), isSelected: false },
		{ name: "last_reentry_date", text: i18n.t('date-last-reentry'), isSelected: false },
	]
];
