import { ParseParams, ServerAPI, getUrl } from './ServerAPI';
import { config, API, configFile, configMedia } from './config';
import axios from 'axios';

export const downloadFile = async (params) => {

  var headers = new Headers(config().headers);
  headers.append("Content-Type", "application/json");

  const query = Object.keys(params).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&')

  return fetch(API + `/transfer-sua/?${query}`,
    {
      method: "GET",
      headers: headers
    })
    .then(response => {
      if (response.ok) {
        return response.blob()
      } else {
        return Promise.reject('error 404')
      }
    })
    .then(blob => blob)
}

export const employerRegistration = async (params) => {

  var headers = new Headers(config().headers);
  headers.append("Content-Type", "application/json");

  const query = Object.keys(params).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&')

  return fetch(API + `/worker-transfer-sua/?${query}`,
    {
      method: "GET",
      headers: headers
    })
    .then(function (response) { return response.blob(); })
    .then(function (blob) { return blob })
}

export const IMSSClassAPI = ServerAPI('imss-classes');
export const IMSSFractionAPI = ServerAPI('imss-fractions');
export const imssReasonLeavingApi = ServerAPI("imss-reason-leaving");
export const IMSSDivisionsAPI = ServerAPI('imss-divisions');
export const IMSSGroupsAPI = ServerAPI('imss-groups');
export const IMSSRecords = ServerAPI('imss-records');
export const severanceOldRecordsApi = ServerAPI("severance-old-age-records");
export const severanceOldRecordsYearsApi = ServerAPI("severance-old-age-records/years");
export const workplaceRiskRecordsApi = ServerAPI("workplace-risk-records");
export const workplaceRiskRecordsYearsApi = ServerAPI("workplace-risk-records/years");
export const severanceOldEmployerRecordsApi = ServerAPI("severance-old-age-employer-records");
export const severanceOldEmployerRecordsYearsApi = ServerAPI("severance-old-age-employer-records/years");
export const imssLimitBranchAPI = ServerAPI("imss-limit-branch");
export const infonavitEbaUpdateAPI = ServerAPI("infonavit-eba-update");

const SettlementComparisonApi = () => {

  const downloadFile = async (baseName, body) => {

    const url = getUrl(`${baseName}/report`, true);

    return axios.post(url, body, configFile())
      .then((response) => {
        return new Blob([response.data], { type: 'application/pdf' });
      })
  };

  const comparison = async (baseName, body) => {

    const url = getUrl(`${baseName}/start-comparison`, true);

    return axios.post(url, body, configFile())
      .then((response) => {
        if (response.headers['content-type'] === "application/json") {
          return response
        } else {
          return new Blob([response.data], { type: 'application/pdf' })
        }
      })
  };

  const verifyData = (baseName, params) => {

    const url = getUrl(`${baseName}/verify`, true);
    return axios.post(url, ParseParams({ params }), config())
      .then((respose) => respose.data);
  }

  return {
    downloadFile,
    comparison,
    verifyData
  }
}

export const settlementComparisonApi = SettlementComparisonApi();

const SUASettlementComparisonApi = () => {

  const comparison = async (body) => {

    const url = getUrl(`payroll-sua-comparison`, true);

    return axios.post(url, body, config())
      .then(async(response) => response.data);
  };

  const verifyData = (params) => {

    const url = getUrl(`payroll-sua-comparison/verify`, true);
    return axios.get(url, config(params))
      .then((respose) => respose.data);
  }

  return {
    downloadFile,
    comparison,
    verifyData
  }
}

export const suaSettlementComparisonApi = SUASettlementComparisonApi();


const EmaEbaComparisonApi = () => {

  const baseName = "ema-comparison";

  const baseApi = ServerAPI(baseName);

  const postMediaEBA = async (body) => {

    const url = getUrl(`eba-comparison`, true);

    return axios.post(url, body, configMedia())
      .then((response) => {
        if (response.data.file_content) {
          const file = atob(response.data.file_content);
          const arrayBuffer = new Uint8Array(file.length);
          for (let i = 0; i < file.length; i++) {
            arrayBuffer[i] = file.charCodeAt(i);
          }
          const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
          const message = response.data.message;
          return { blob, message };
        } else {
          return response;
        }
      })
  };

  const downloadReport = async (type, body) => {

    const url = getUrl(`${type}-comparison-report`, true);

    return axios.post(url, body, config())
      .then(async (response) => response.data);
  };


  return {
    ...baseApi,
    postMediaEBA,
    downloadReport
  }
}

export const emaEbaComparisonApi = EmaEbaComparisonApi();
