import React, { useState } from "react";
import PropTypes from "prop-types";
import DynamicMoper from "../../../../../../SignaturesByWorker/components/Details/components/DynamicMoper";
import { GenericLabel } from "../../../../../../SignaturesByWorker/components/Shared";
import { t } from "i18next";
import SearchBox from "../../../../../../../@components/general/SearchBox";
import { size, toLower, trim } from "lodash";
import GridNoRecordsRender from "../../../../../../../../App/components/Templates/GridNoRecordsRender";
import { uid } from "uid";

const validateSearch = (search, fields) => {
    const values = fields?.map(el => toLower(el)) ?? [];
    return values.some(el => el?.includes(search));
}

function PopupRequest({ refresh, onCancel, requests, totals = {} }) {
    const [search, setSearch] = useState('');

    return (
        <div className="custom-popup-component">
            <div className="custom-popup-request-header">
                <span className="popup-title">
                    {'Detalles del día'}
                </span>
                <hr />
            </div>
            <SearchBox
                className='mopers-requests-search'
                value={search}
                setSearch={setSearch}
            />
            <Container
                refresh={refresh}
                onCancel={onCancel}
                requests={requests?.filter(el => search ? validateSearch(toLower(trim(search)), [el?.name, el?.folio]) : el)}
            />
            <div className="detail-card-footer">
                <span>Totales</span>
                {totals?.map(el => <GenericLabel key={el?.label ?? uid()} label={t(el?.label)} value={el?.value} />)}
            </div>
        </div>
    )
}
export default PopupRequest;

const Container = ({ refresh, requests, onCancel }) => {

    return (
        <div className="day-details-requests">
            {size(requests) ?
                requests?.map(el => (
                    <DynamicMoper
                        key={`detail-moper-item-${el?.exactMoper?.moper?.id}`}
                        refresh={refresh}
                        onCancel={onCancel}
                        moper={el}
                        showActions={false}
                        statusResponsive
                        showFlowCancel
                    />
                ))
                :
                <GridNoRecordsRender init />}
        </div>
    );
};

PopupRequest.propTypes = {
    refresh: PropTypes.func,
    onCancel: PropTypes.func,
    requests: PropTypes.array,
    totals: PropTypes.array,
}

Container.propTypes = {
    refresh: PropTypes.func,
    onCancel: PropTypes.func,
    requests: PropTypes.array,
}
